export const reduxObject = {
	"generalStore": [
		{ "reduxKey": "getMasterDbHeader", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getDropdown", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getMasterDbFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getMasterDbDropdown", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getDocumentDropdown", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getMasterDbDocumentDropdown", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getDataExportModal", "successMessage": false, "loading": false },
		{ "reduxKey": "cancelDataExportModal", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getHeaders", "successMessage": false, "loading": false },
		{ "reduxKey": "getQuickFilters", "successMessage": false, "loading": false },
		{ "reduxKey": "downloadReport", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "downloadDocuments", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createEmailSchedules", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteEmailSchedules", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "emailReport", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getImageTracking", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getRefreshReport", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createQuickFilters", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createHeaders", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getBarcodeReport", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSearchFilters", "successMessage": false, "loading": false },
		{ "reduxKey": "getEmailSchedules", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllAudit", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAudit", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAuditDetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentSummary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getGeneralList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentSecondList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentThirdList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentFourthList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTableComponentFifthList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getGenericExcelTemplate", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "validateGenericExcelSheet", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSupplymintTraningGuides", "successMessage": false, "loading": false },
		{ "reduxKey": "emailTranscript", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteUploadsComments", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getUserEmails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllComment", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "qcAddComment", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "commentNotification", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "removeMultipleFileUpload", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getProcExcelTemplate", "successMessage": false, "loading": false },
		{ "reduxKey": "validateProcExcel", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "submitProcExcel", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getUploadedUtilityDocument", "successMessage": false, "loading": "fullPageLoader" },
		// { "reduxKey": "getAnnouncement", "successMessage": false, "loading": false },
		{ "reduxKey": "getDocumentDownloadFilepath", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "utilityDocumentDownload", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getPurchaseTermOrderInfo", "successMessage": false, "loading": false },
		{ "reduxKey": "userSessionCreate", "successMessage": false, "loading": false },
		{ "reduxKey": "getUserDataMapping", "successMessage": false, "loading": "toastLoader"},
		{ "reduxKey": "getCommentsDocumentsList", "successMessage": false, "loading": false },
	],
	"authStore": [
		{ "reduxKey": "registerNewVendorMobile", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getMergeAccountList", "successMessage": false, "loading": false },
		{ "reduxKey": "getAllMergeVendorList", "successMessage": false, "loading": false, },
		{ "reduxKey": "mergeVendorsAccount", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createNewUser", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCurrrentUserAuthDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getCurrrentUserAuthDetailsCopy", "successMessage": false, "loading": "" },
		{ "reduxKey": "login", "successMessage": false, "loading":  false},
		{ "reduxKey": "getOtp", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "verifyOtp", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "resetPassword", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "refreshToken", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getUserProfile", "successMessage": false, "loading": false },
		{ "reduxKey": "getSupportUser", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "editUser", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getProfileMapping", "successMessage": false, "loading": false },
		{ "reduxKey": "updateUserInformation", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getNotificationActive", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateNotificationActive", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getNotificationModule", "successMessage": false, "loading": false },
		{ "reduxKey": "getNotificationSubModule", "successMessage": false, "loading": false },
		{ "reduxKey": "getNotificationConfigModule", "successMessage": false, "loading": false },
		{ "reduxKey": "getNotificationEmail", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateNotificationEmail", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "switchEnt", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateOrgId", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "changePassword", "successMessage": true, "loading": "fullPageLoader" },

		// vendor on boarding
		{ "reduxKey": "getMenuDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getMenuBarDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getVendorOnboardingPercentage", "successMessage": false, "loading": "toastloader" },
		{ "reduxKey": "saveVendorOnBoardingDetails", "successMessage": false, "loading": "toastloader" },
		{ "reduxKey": "getFactoryDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "newVendoronboardingRegister", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getBootSettingsForApplication", "successMessage": false, "loading": "toastLoader" },
		{"reduxKey" : "loginUsingWechat", "successMessage": true, "loading": "fullPageLoader"},
		{"reduxKey" : "setupMfa", "successMessage": false, "loading": "toastLoader"},
		{"reduxKey" : "enableMfa", "successMessage": true, "loading": "toastLoader"},
		{"reduxKey" : "get2FaBarcode", "successMessage": false, "loading": "toastLoader"},
		{ "reduxKey": "getAllEnterprises", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "sendEnterpriseVendor", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCurrrentUserEntDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "getCurrrentUserEntModules", "successMessage": false, "loading": false },
		{ "reduxKey": "checkUserNameValidation", "successMessage": false, "loading": "toastLoader" },

	],
	"digiArsStore": [
		{ "reduxKey": "getReplenishmentSummary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getOrderRequirement", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSummaryAllJob", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "arsPlanGetRepSum", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSummaryLastJob", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "summaryToStatus", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getReplenishmentHistory", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTransferOrder", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTransferOrderDropdown", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getTransferOrderDropdownV2", "successMessage": false, "loading": false },
		{ "reduxKey": "getTransferOrderStatus", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadExcelTransferOrder", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "downloadTemplate", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getAdhoc", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "deleteAdhoc", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateAdhocItemsPerWO", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateAdhoc", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getHeaderConfig", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAdhocItemsPerWO", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "dataSync", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTemplate", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "lastCustomUploadFile", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getCreateAdhocPrimaryInfo", "successMessage": false, "loading": false },
		{ "reduxKey": "createAdhocReplenishment", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "retryTransferOrder", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "upapproveTransferOrder", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadArsCustom", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getReviewReasonSummary", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDataWithoutFilter", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getReviewReason", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getfiltersArsData", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getTransferOrderDetail", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateTODetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllMultiLog", "successMessage": false, "loading": false },
		{ "reduxKey": "getArsDashboard", "successMessage": false, "loading": "toastLoader" },

	],
	"salesManagementStore": [
		{ "reduxKey" : "getSalesOrderConfiguration", "successMessage": false, "loading": false},
		{ "reduxKey": "getSoColors", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSalesCustomers", "successMessage": false, "loading": false },
		{ "reduxKey": "getSalesCustomersDetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSalesCustomersPricelist", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateSalesCustomersPricelist", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateSalesCustomersCreditLine", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updatedBuyingPlanStatus", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSalesCustomers", "successMessage": false, "loading": "false" },
		{ "reduxKey": "getSalesDispatchLocation", "successMessage": false, "loading": "false" },
		{ "reduxKey": "getSalesDeliveryLocation", "successMessage": false, "loading": "false" },
		{ "reduxKey": "getSalesTransporter", "successMessage": false, "loading": "false" },
		{ "reduxKey": "getSalesAgents", "successMessage": false, "loading": "false" },
		{ "reduxKey": "getViewFilters", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateDeliveryChallanStatus", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "retryDeliveryChallanStatus", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "soV2ViewProduct", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getStatusOutstandingAmount", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateSoOrder", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "retrySo", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "downloadSoPdf", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteSoItems", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadSalesEditExcel", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSalesBagDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSOStatusCount", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSOPerfAnalysis", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSalesDashboardSummary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSalesDashboardOrderSummaryDetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "soV2UpdateBagProduct", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSizeColorDataScan", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "soV2AddBag", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSalesDcDetail", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "soV2SaveOrder", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "soV2CopyOrder", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "soGetSummary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "scanDcItem", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "soV2RemoveProduct", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "convertBuyingPlanToIndent", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "copyBuyingPlan", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAllPriceList", "successMessage": false, "loading": false },
		{ "reduxKey": "soItemSet", "successMessage": false, "loading": false },
		{ "reduxKey": "soCreateItemSet", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getErpDcCode", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "createDc", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateDeliveryDate", "successMessage": true, "loading": "fullPageLoader" },
	],
	"seasonPlanningStore": [
		{ "reduxKey": "getAssortmentPlanning", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "generateMbqSuggestions", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSitePlanning", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getEventMappingPlanning", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAutoConfigData", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "deleteAutoConfigTrigger", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteSitePlanning", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getArsSiteFilters", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getSitePlanningSites", "successMessage": false, "loading": false },
		{ "reduxKey": "getDefaultSitePlanningSites", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "saveSitePlanning", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteEventPlanning", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createEventPlanning", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAllWarehouseJobs", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getArsAndSiteFilterItem", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getArsAndSiteFilterCustom", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getArsAndSiteFilterSite", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getArsFilterCount", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "createRunOnDemandJob", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "stopRunOnDemandNew", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "jobRunDetailNew", "successMessage": false, "loading": false },
		{ "reduxKey": "currentJobRunDetail", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getMRPHistory", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getMRPRange", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSiteAndItemFilterConfiguration", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getGenericTableData", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateMRPRange", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updatePlanningParameterConfiguration", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "savePlanningParameter", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "ruleEngineJobRunStatistics", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getReplenishmentPlanningParameter", "successMessage": false, "loading": "toastLoader" },
	],
	"administrationStore": [
		{ "reduxKey": "updateExcelUploadTemplateCategory", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getExcelUploadTemplateCategory", "successMessage": false, "loading": false },
		{ "reduxKey": "updateApiIntegration", "successMessage": false, "loading": false },
		{ "reduxKey": "getApiIntegrationApiList", "successMessage": false, "loading": false },
		{ "reduxKey": "saveCostSheetCatalogSetting", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCostSheetCatalogSetting", "successMessage": false, "loading": false },
		{ "reduxKey": "getModuleRole", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getModuleUsers", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getModuleUserDataMapping", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getOrganizationList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAdministrationDataSync", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getModuleApiLogs", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getEmailNotificationLog", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getReplenishmentOutputLogs", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getApiLogRetry", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "resendEmailNotification", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getEmailBody", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "userConfig", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "userSetMapping", "successMessage": false, "loading": false },
		{ "reduxKey": "userAvailableMappingData", "successMessage": false, "loading": false },
		{ "reduxKey": "saveUserDataMapping", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "rolemoduleRoleCheck", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "rolemasterModuleData", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "rolemasterModuleSubmit", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "rolemoduleRoleDelete", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "coreUserCreateUpdate", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "userCreateSendEmail", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "userUpdateStatus", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "coreOrgGetAllOrganisation", "successMessage": false, "loading": false },
		{ "reduxKey": "multiLevelConfigGetAllRoles", "successMessage": false, "loading": false },
		// Global Configuration Api
		{ "reduxKey": "getTableHeaderConfigModule", "successMessage": false, "loading": false },
		{ "reduxKey": "getTableHeaderConfigHeaders", "successMessage": false, "loading": false },
		{ "reduxKey": "getTableDocumentFormat", "successMessage": false, "loading": false },
		{ "reduxKey": "getTableModuleDocumentFormat", "successMessage": false, "loading": false },
		{ "reduxKey": "updateTableModuleDocumentFormat", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getApiCofiguration", "successMessage": false, "loading": false },
		{ "reduxKey": "getApiOutboundCofiguration", "successMessage": false, "loading": false },
		{ "reduxKey": "getSystemMaintainenceConfig", "successMessage": false, "loading": false },
		{ "reduxKey": "updateSystemMaintainenceConfig", "successMessage": false, "loading": false },
		{ "reduxKey": "getAnnoucements", "successMessage": false, },
		{ "reduxKey": "deleteAnnoucements", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateAnnoucements", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createAnnoucements", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getTrainingGuides", "successMessage": false, "loading": false },
		{ "reduxKey": "deleteTrainingGuides", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createTrainingGuides", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateTrainingGuides", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateOutboundApiConfiguration", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createApiIntegration", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "clearApiIntegration", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDataManagementConfig", "successMessage": false, "loading": false },
		{ "reduxKey": "buildResourceConfig", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "arsSiteConfigChanges", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getBarcodeProfileConfig", "successMessage": false, "loading": false },
		{ "reduxKey": "updateBarcodeProfileConfig", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createBarcodeProfileConfig", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSalesMappingFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getDigivendConfig", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateSalesMappingFilter", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateProductCatalogDataMapping", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateDigivendConfig", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDigiArsConfig", "successMessage": false },
		{ "reduxKey": "getDigiArsMapping", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getGeneralSetting", "successMessage": false, },
		{ "reduxKey": "verifyDomain", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "verifyCustomDomain", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadGeneralSettingImages", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateGeneralSetting", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getProcFormSettings", "successMessage": false, "loading": false },
		{ "reduxKey": "updateProcFormSettings", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCatDescSetting", "successMessage": false, "loading": false },
		{ "reduxKey": "updateCatDescSetting", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getMultilevelApprovalModule", "successMessage": false, "loading": false },
		{ "reduxKey": "getMultilevelApprovalDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "updateMultilevelApprovalDetails", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDigicatFormSettings", "successMessage": false, "loading": false },
		{ "reduxKey": "updateDigicatFormSettings", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAssortmentCurrentSetting", "successMesssage": false, "loading": false },
		{ "reduxKey": "updateAssortmentCurrentSetting", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateExcludedReportArs", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getExcludedReportArs", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getMappedArsFilterConfiguration", "successMessage": false, "loading": false },
		{ "reduxKey": "getNotMappedArsFilterConfiguration", "successMessage": false, "loading": false },
		{ "reduxKey": "updateArsFilterConfiguration", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteBarcodeProfile", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "forcePasswordReset", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getEmailNotificationTable", "successMessage": false, "loading": false },
		{ "reduxKey": "saveEmailNotificationTable", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateIpRestriction", "successMessage": true, "loading": false },
		{ "reduxKey": "resetDocumentFormat", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteUser", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "masterRoleCreate", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getPermissionDetailCommon", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "roleGetCategoryAll", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "masterRoleGetAll", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "masterRoleGetPermission", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "masterRoleDelete", "successMessage": true, "loading": "fullPageLoader" },
	],
	"masterDbStore": [
		{ "reduxKey": "getCollectionsList", "successMessage": false, "loading": false },
		{ "reduxKey": "getMailingListDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "deleteMailingList", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteMailingListDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "createNewMailingList", "successMessage": false, "loading": "fullPageLoader" },
		{"reduxKey": "createNewMailingListDetails", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getAnnoucementDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "getAnnoucementUsersList", "successMessage": false, "loading": false },
		{ "reduxKey": "createNewSite", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "editSite", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getVendorsList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getMasterItems", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getMasterAgents", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "sendVerificationEmail", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "accessVendorPortal", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "allowPortalAccess", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "allowHoldUnholdVendor", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "sendVerificationLink", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getUserRolesList", "successMessage": false, "loading": false },
		{ "reduxKey": "createNewVendor", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getMasterDataHsnCode", "successMessage": false, "loading": false },
		{ "reduxKey": "getMasterDataArticleCode", "successMessage": false, "loading": false },
		{ "reduxKey": "getMasterDataItemCatDescCode", "successMessage": false, "loading": false },
		{ "reduxKey": "uploadMasterDataImages", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getMasterDataCatDesc", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getDefaultMasterDataHsnCode", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "createItems", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "editMasterDataItems", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "resyncMasterItems", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateMasterDataItemsStatus", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCreateTicketModuleList", "successMesssage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getCreateTicketIssuesList", "successMessage": false, "loading": "fullPageLoader" },
		{ reduxKey: "allRetailer", successMessage: true, loading: false },
		{ reduxKey: "allRoles", successMessage: true, loading: false },
		{ reduxKey: "saveSalesAgents", successMessage: true, loading: "fullPageLoader", },
		{ reduxKey: "addUser", successMessage: true, loading: "fullPageLoader" },
		{ reduxKey: "deleteSalesAgents", successMessage: true, loading: "fullPageLoader", },
		{ reduxKey: "deleteCustomers", successMessage: true, loading: "fullPageLoader", },
		{ reduxKey: "deleteCustomers", successMessage: true, loading: "fullPageLoader", },
		{ reduxKey: "accessCustomerPortal", successMessage: true, loading: false },
		{ reduxKey: "deleteSelectedMaterialLibraryItems", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "saveCustomers", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "updateCustomersRoles", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "userVendorEnableAccess", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "deleteTransporters", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "updateTransportersRoles", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "saveTransporters", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "updateAgentsRoles", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "updateAgent", successMessage: true, loading: "fullPageLoader" },
		{ "reduxKey": "createNewTicketWithAttachments", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteMaterialLibraryItem", "successMessage": true, "loading": "toastLoader" },
		{ reduxKey: "updateVendorRoles", successMessage: true, loading: "toastLoader" },
		{ reduxKey: "getWorkflowSettingModules", "successMessage": false, loading: false },
		{ reduxKey: "getWorkflowSettingConfiguration", "successMessage": false, "loading": false },
		{ reduxKey: "updateWorkflowSetting", "successMessage": true, "loading": "fullPageLoader" },
		{ reduxKey: "getLrConfiguration", "successMessage": false, "loading": false },
		{ reduxKey: "updateLrConfiguration", "successMessage": true, "loading": "fullPageLoader" },
		{ reduxKey: "vendorLogin", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateMaterialLibrary", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadBulkItems", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "approveVendorOnboarding", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "inactiveExternalUser", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createNewTicket", "successMessage": true, "loading": "fullPageLoader" },
	],
	"superAdminStore": [
		{ "reduxKey": "updateUserActiveState", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateUserPasswordState", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateEnterpriseConfiguration", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateOrganizationConfiguration", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "editEnterpriseList", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "editOrganisationList", "successMessage": true, "loading": "fullPageLoader" },
	],
	'digivendStore': [
		{"reduxKey" : "getOrderRequest", "successMessage": false, "loading": "fullPageLoader"},
		{ "reduxKey": "getRetailerShipmentRetry", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSystemConfigKeys", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "EnterpriseGetPoDetailsremQty", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "EnterpriseGetPoDetailsremQtyUpdate", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "EnterpriseCancelPo", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "EnterpriseHoldAsn", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "extendPOValidity", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "bulkOperation", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "approvePOConfirm", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "enterpriseApprovedPo", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "shipmentConfirmCancel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getComqcInvoice", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "uploadUtilityDocument", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getCompleteDetailShipment", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "addMultipleCommentQc", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateExtensionDate", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateVendorCancelLr", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getAllVendorEntryDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "indentBasePoV2", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "approveAppt", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateGateEntryCancel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getArsCancel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getSystemConfigData", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "addWHApptSite", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "addWHApptThreshold", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "addWHApptSiteDetails", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getWHApptSiteDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getWHApptThreshold", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "deleteWHApptThreshold", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getPoMrpRange", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getVendorInvoice", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateInvoice", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "findSiteCapacity", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateVendor", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateTrackingStatus", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "retryLR", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "appotDetWhAsnDet", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllOrderStatus", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "uploadVendorFilesImages", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateTnaStatus", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateBillDiscounting", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "calculateBillDiscounting", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "submitBillDiscounting", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateAutoASNLevels", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createAutoASNLevels", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteAutoASNLevels", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAutoRefresh", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createAutoASNLevelsExcel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "addAutoASNConfig", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "poGlobalUploadExcel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "poGlobalConfigDelete", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getAllComments", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poGlobalConfigCreateUpdate", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "vendFindAllAddress", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "vendFindAllLocation", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllTransporters", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "vendorpoGetPodetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "vendorshipFindSrdetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "vendorshipCreateSrNew", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "formSettingGetLr", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "vendorlogiLrCreate", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "vendorlogiFindLrAuto", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getPoStockReport", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getCreateOrderHistory", "successMessage": false, "loading": false },
		{ "reduxKey": "createPoArticle", "successMessage": false, "loading": false },
		{ "reduxKey": "utilityDocumentDelete", "successMessage": false, "loading": false },
		{ "reduxKey": "vendorlogiLrGetRate", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateLogistics", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getShipmentAdviceAllSs", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "vendorlogiRecommendation", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poGlobalConfigCreate", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDashboardSiteFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getWhDashboard", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "whDashboardDetail", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getBoxAnalysisDashboard", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getASNDashboardDetails", "successMessage": false, "loading": "toastLoader" },

		{ "reduxKey": "getOrderTrackingDashboardUpperDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getOrderTrackingDashboardBottomDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getOrderTrackingDashboardGrcDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getOrderTrackingDashboardGraphDetails", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "saveSiteAndItemFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getARSDashboardData", "successMessage": false, "loading": "toastLoader" },

		{ "reduxKey": "deleteWHApptSiteDetails", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "entlogiUpdateShipment", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "documentGetAllFile", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getOrderRequestColor", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "createOrderRequestIndent", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteAddressLineItem", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateAddress", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAllTNAOrderHistory", "successMessage": false, "loading": false },
		{ "reduxKey": "checkAsnRestriction", "successMessage": false, "loading": "toastLoader" },
		
	],
	"reportsAndAnalyticsStore": [
		{ "reduxKey": "summaryReport", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getVendorName", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getRequestLedger", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "catSizeFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "geneRateFilter", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getStoreAnalaysis", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "performanceReport", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "atv", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "lyComparision", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "mboReportPrimary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "mboReportSecondary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "mboReportTertiary", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "merchFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "merchSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "merchThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "merchFourth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "regionalFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "regionalSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "regionalThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "regionalFourth", "successMessage": false, "loading": "toastLoader" },

		// footfall summary report
		{ "reduxKey": "getFootfallSummary", "successMessage": false, "loading": "toastLoader" },

		// stock size set report
		{ "reduxKey": "sizeSetFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "sizeSetsecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "firstexpandMain", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "secondexpandMain", "successMessage": false, "loading": "toastLoader" },


		//// weekly sales report request
		{ "reduxKey": "stockSetFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetFourth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetFifth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetSixth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "stockSetSeventh", "successMessage": false, "loading": "toastLoader" },

		// Gv analysis
		{ "reduxKey": "gvAnalysis", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "gvAnalysissec", "successMessage": false, "loading": "toastLoader" },

		// choose store profile
		{ "reduxKey": "sitePlanningFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "storeMianAnalysis", "successMessage": false, "loading": "toastLoader" },

		{ "reduxKey": "itemWiseReport", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemWiseReportSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemWiseReportThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemWiseReportFourth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemWiseReportFifth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemWiseReportSixth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "storeInventery", "successMessage": false, "loading": "toastLoader" },
		//  store sales detail
		{ "reduxKey": "storeSalesDetail", "successMessage": false, "loading": "toastLoader" },
		// channel wise net sales analyisis
		{ "reduxKey": "channelwiseFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "channelwiseSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "channelwiseThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "channelwiseFourth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getJistReportCustomer", "successMessage": false, "loading": false },
		{ "reduxKey": "getJistReportDelivery", "successMessage": false, "loading": false },
		{ "reduxKey": "getJistReportDepartment", "successMessage": false, "loading": false },
		// shipment box barcdoe
		{ "reduxKey": "shipmentBoxBarcode", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "barCodePdf", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "growDegrow", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "genGetApiFirst", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "genGetApiSecond", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "genGetApiThird", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "genGetApiForth", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "genGetApiFifth", "successMessage": false, "loading": "toastLoader" },
	],
	"digiProcStore": [
		{ "reduxKey": "procGenericApi", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAavailablekeys", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getOtb", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "newMultipleLineItem", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "apiAfterSelection", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "indentBasePo", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poViewImages", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "markUp", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getSizeColor", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "procSubmit", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "genericSelectItem", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "genGetApiV2", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "po_approve_reject", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "downloadData", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "discardPoPiData", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getLineItem", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateViewOrderExtensionDate", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "piUpdate", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "gen_IndentBased", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getPiLineItem", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "uploadManageMrpExcel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteMrpRange", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getModalMrp", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "editMrpRange", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createMrp", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "uploadManageOtbExcel", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "updateManageOtb", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteManageOtb", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "systemConfig", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "submitSystemConfig", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getProcMasterInfo", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "getAllFabric", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "createUpdateFabricNomination", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "deleteFabricNomination", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "editManageOtb", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "createNewManageOtb", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "piPoDashboardFilter", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "piDashBottom", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poErpRetry", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateItemUdfMapping", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "catDescDropdown", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "itemCatDescUdf", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poArticle", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "poUdfMapping", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "updateUdfMapping", "successMessage": true, "loading": "toastLoader" },
		{ "reduxKey": "getCnameList", "successMessage": false, "loading": "toastLoader" },
		{ "reduxKey": "procDataMapping", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "mappingExcelExport", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSelectedItembarcode", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "systemConfigGetSdcConfig", "successMessage": false, "loading": "toastLoader" },
	],
	"digicatStore": [
		{ "reduxKey": "resentProductSurveyEmail", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getProductSurveyDetail", "successMessage": false, "loading": false },
		{ "reduxKey": "getSubmittedSurveyDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "updateProdctSurveyStatus", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "submitProductSurvey", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createNewProductSurvey", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getAllCollectionDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "createNewCollection", "successMessage": false, "loading": false },
		{ "reduxKey": "getCollectionsList", "successMessage": false, "loading": false },
		{ "reduxKey": "updateAndApproveProduct", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "updateDigicatStatus", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getSamplingCostingForm", "successMessage": false, "loading": false },
		{ "reduxKey": "updateSamplingCostingForm", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "getDigicatMaster", "successMessage": false, "loading": false },
		{ "reduxKey": "getDigicatMasterDetails", "successMessage": false, "loading": false },
		{ "reduxKey": "createProduct", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getProductDetail", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "createFabric", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "loadFabric", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateFabric", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "createCostsheet", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "updateCostsheet", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "loadCostsheet", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "getDigicatalogueFilters", "successMessage": false, "loading": "fullPageLoader" },
		{ "reduxKey": "deleteProducts", "successMessage": true, "loading": "fullPageLoader" },
		{ "reduxKey": "dispatchSampleCostsheet", "successMessage": true, "loading": "fullPageLoader", },
		{ "reduxKey": "catUpdateStatusSampleCosting", "successMessage": true, "loading": "toastLoader", },
		{ "reduxKey": "saveDigicatMasterDetails", "successMessage": true, "loading": "false", },
		{ "reduxKey": "getProductDetailCustom", "successMessage": false, "loading": "toastLoader", },
	]
}
