import { CONFIG } from '../../config/index';
export const superAdminStore = [
    {
        title: "updateUserActiveStateData",
        actionPrefix: 'updateUserActiveState',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/update`
        }
    },
    {
        title: "updateUserPasswordStateData",
        actionPrefix: 'updateUserPasswordState',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/user/send/reset/password/mail`
        }
    },
    {
        title: "updateEnterpriseConfigurationData",
        actionPrefix: 'updateEnterpriseConfiguration',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/enterprise/update`
        }
    },
    {
        title: "updateOrganizationConfigurationData",
        actionPrefix: 'updateOrganizationConfiguration',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/organization/update`
        }
    },
    {
        title: "editEnterpriseListData",
        actionPrefix: 'editEnterpriseList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "editOrganisationListData",
        actionPrefix: 'editOrganisationList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}${payload.apiUrl}`
        }
    },
]