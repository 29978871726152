import React from 'react'

const UploadExcelSkeletons = (props) => {
    const renderSkeletons = () => {
        switch (props.step) {
            default: {
                return <div className='px-4 mt-[8px] pb-7'>
                    <div className='generic-skeleton !w-[100px]'></div>
                    <div className='generic-skeleton !w-[250px] !h-[30px]'></div>
                    <div className='generic-skeleton !w-[130px]'></div>
                    <div className='generic-skeleton !h-[230px] mt-[8px]'></div>
                </div>
            }
        }
    }
    return (
        renderSkeletons()
    )
}

export default UploadExcelSkeletons