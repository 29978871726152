import React from 'react'
import GenericCustomButton from '../../../../../genericComponents/GenericCustomButton';
import { useState } from 'react';
import Icons from '../../../../../assets/icons';
import LanguageTranslator from '../../../../../locals/LanguageTranslator';

const AnnoucementStrip = ({
    posts = [],
    setSelectedData,
    setSelectedModal,
    setStripAnnoucements, //To update the annoucement list
}) => {
    const [currentActivePost, setCurrentActivePost] = useState(0);

    const handleChange = (type) => {
        switch (type) {
            case "inc": {
                currentActivePost < posts?.length - 1 ? setCurrentActivePost(prev => prev + 1) : setCurrentActivePost(0)
                break;
            }
            case "dec": {
                currentActivePost >= 1 && setCurrentActivePost(prev => prev - 1);
                break;
            }
            case "dismissNotification": {
                let updatedPosts = [...posts];

                updatedPosts = updatedPosts?.slice(currentActivePost + 1, updatedPosts?.length) || [];

                // Update the current strip annoucements here
                if (updatedPosts?.length === 0) {
                    sessionStorage.removeItem('stripAnnoceuments')
                } else {
                    sessionStorage.setItem('stripAnnoceuments', JSON.stringify(updatedPosts))
                }

                setStripAnnoucements(updatedPosts);

                // to enhance speed
                const nothing = [1, 2, 3].filter((num) => num);

                break;
            }
        }

    }
    return (<>
        <div
            className='announcement-strip'
            onClick={(e) => {
                e.stopPropagation()
                setSelectedModal('stripAnnoucenmentPreview')
                setSelectedData(currentActivePost);
            }}
        >
            <div className='as-left'>
                {/* render the icon */}
                {<Icons.AnnouncementAlert />}
                {/* Render the message */}
                <p><span style={{fontWeight:700}}>{posts?.[currentActivePost]?.["title"] ? `${posts?.[currentActivePost]?.["title"]} : ` : "" }</span> {posts?.[currentActivePost]?.["message"] || "-"}</p>
                {/* render the external link  */}
                {posts?.[currentActivePost]?.external_link &&
                    <GenericCustomButton
                        label={posts?.[currentActivePost]?.["stripButtonLabel"] || "Know More"}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(posts?.[currentActivePost]?.external_link, '_blank');
                        }}
                    />
                }
            </div>
            {/* Render the Dismiss button for subscription */}
            <div className='as-right'>
                {
                    posts?.[currentActivePost]?.type == "subscriptionLevelL1" && <GenericCustomButton
                        className="next-btn"
                        onClick={(e) => { handleChange("dismissNotification"); e.stopPropagation() }}
                        label="Dismiss"
                    />
                }
            </div>

            {/* Render the next and prev button of the annoucement strip */}
            {
                posts?.length > 1 &&
                <div className='as-right'>
                    <GenericCustomButton
                        disabled={currentActivePost == 0}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleChange("dec")
                        }}
                        label="Prev"
                    />

                    {<p><span>{currentActivePost + 1}</span><span className='m-lr-3'>of</span><span>{posts?.length || 0}</span></p>}
                    <GenericCustomButton
                        className="next-btn"
                        disabled={currentActivePost + 1 == posts?.length}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleChange("inc")
                        }}
                        label="Next"
                    />
                </div>
            }
        </div>
    </>
    )
}

export default AnnoucementStrip