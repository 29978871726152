import { authStore } from "../reduxState/authStore.js";
import {generalStore} from '../reduxState/generalStore.js'
import {digiArsStore} from '../reduxState/digiArsStore.js'
import {salesManagementStore} from '../reduxState/salesManagementStore.js'
import {seasonPlanningStore} from '../reduxState/seasonPlanningStore.js'
import {administrationStore} from '../reduxState/administrationStore'
import {masterDbStore} from '../reduxState/masterDbStore'
import {superAdminStore} from '../reduxState/superAdminStore';
import { digivendStore } from '../reduxState/digiVendStore.js';
import {reportsAndAnalyticsStore} from '../reduxState/reportsAndAnalyticsStore.js'
import { digiProcStore } from "../reduxState/digiProcStore.js";
import { digicatStore } from "../reduxState/digicatStore.js";

export const APIState = {
    authStore,
    generalStore,
    digiArsStore,
    salesManagementStore,
    seasonPlanningStore,
    administrationStore,
    masterDbStore,
    superAdminStore,
    digivendStore,
    reportsAndAnalyticsStore,
    digiProcStore,
    digicatStore
}
