/* eslint-disable default-case */
import React from 'react'
import ListComponent from '../../genericComponents/ListComponent'
import Icons from '../../assets/icons'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { parseJwt } from '../../helper/genericFunction'
import RequestOnboardingComponent from '../../authComponents/vendorOnboarding/components/RequestOnboardingComponent'
import { dispatchHandler } from '../../helper/apiHelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { sendButtonClickEventToGoogleAnalytics } from '../../helper/helperTableFunctions'

const HelpAndSupportDropdownComponent = (props) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const uType = parseJwt(sessionStorage.getItem('token')).uType?.toUpperCase() || "ENT";
    const history = useHistory();
    return (
        <div className='help-dropdown'>
            <ListComponent
                label={{
                    content: "Help & Support"
                }}
                closeModal={props.closeHelpModal}
                headerKeys={{
                    "leftIcon": {},
                    "label": {}
                }}
                itemKeys={{
                    "leftIcon": ["leftIcon"],
                    "label": ["label"]
                }}
                submitHandler={(data) => {
                    sendButtonClickEventToGoogleAnalytics({
                        label: `Help Section - ${data?.label}`, location : location,
                    })
                    switch (data?.label) {
                        case "New Ticket": {
                            if (props.createNewTicket) {
                                props.createNewTicket();
                            }
                            break;
                        }
                        case "All Tickets": {
                            history.push("/settings/manageSupportTicket")
                            break;
                        }
                        case "Help": {
                            props.setSelectedModal("helpDocumentModal")
                            break;
                        }
                        case "Training Guides": {
                            dispatchHandler(dispatch, "getSupplymintTraningGuidesRequest", {})
                            props.setSelectedModal("trainingGuidesModal")
                            break;
                        }
                        case "Support Documentation": {
                            window.open("http://support.supplymint.com/", "_blank")
                            break;
                        }
                        case "Keyboard Shortcuts": {
                            props.setSelectedModal("keyboardShortcutsModal")
                            break;
                        }
                        case "Escalation Matrix": {
                            props.setSelectedModal("escalationMatrixModal")
                            break;
                        }
                        case "Manage Onboarding Requests": {
                            history.push("/vendor/request/onboarding")
                            break;
                        }
                    }
                    props.closeHelpModal();
                }}
                getListData={() => {
                    const listData = [
                        { "leftIcon": <Icons.HelpNewTicket />, "label": "New Ticket" },
                        { "leftIcon": <Icons.AllTicket />, "label": "All Tickets" },
                        { "leftIcon": <Icons.VideoIcon />, "label": "Training Guides" },
                        { "leftIcon": <Icons.SupportDoc />, "label": "Support Documentation" },
                        { "leftIcon": <Icons.KeyboardShortcut />, "label": "Keyboard Shortcuts" },
                    ];
                    if(uType?.toUpperCase() == "VENDOR" ){
                        listData?.push({ "leftIcon": <Icons.AddGreenIcon />, "label": "Manage Onboarding Requests" })
                    }
                    // Conditionally add an object based on the value of uType
                    if (uType === 'ENT') {
                        listData.push({ "leftIcon": <Icons.EscalationMatrix />, "label": "Escalation Matrix" });
                    }
                    return listData;
                }}
            />
        </div>
    )
}

export default HelpAndSupportDropdownComponent