let AUTH_CONFIG = {
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot/password",
    RESET_PASSWORD: "/reset/password",
    FORGOT_USER: "/forgot/username",
    REFRESH_TOKEN: "/refresh/token",
    ACTIVATE_USER: "/activate/user",
    CHANGE_PASSWORD: "/change/password",
    SWITCH_ORGANIZATION: "/switch/org/info"
  };    
  
  console.log("Environment :: " + process.env.REACT_APP_NODE_ENV);
  
  const temp = {
      "production" : "https://prodauth.supplymint.com/user/auth",
      "quality" : "https://qaauth.supplymint.com/user/auth",
      "dev" : "https://devauth.supplymint.com/user/auth",
      "sandbox" : "https://prodauth.supplymint.com/user/auth"
  }
  
  AUTH_CONFIG = {...AUTH_CONFIG, BASE_URL: temp[process.env.REACT_APP_NODE_ENV ?? "production"]}
  
  export { AUTH_CONFIG };
  