import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import Icons from '../../../../assets/icons';
import NoDataFound from '../../../NoDataFound'
import LanguageTranslator from '../../../../locals/LanguageTranslator';

const PurchaseDiscountAndTexPopover = (props) => {

    let { termConfiguration = {}, item = {}, handleMouseLeave, handleMouseEnter } = props;

    const dispatch = useDispatch();

    const [purhcaseTermData, setPurchaseTermData] = useState(null);

    const getPurchaseTermOrderInfoData = useSelector(state => state?.generalStore?.getPurchaseTermOrderInfoData);


    useEffect(() => {
        // Get the data here
        let timer;
        if(termConfiguration?.["documentId"] && item?.[termConfiguration?.["documentId"]]){
            if(props.item?.purchaseTermData){
                setPurchaseTermData(props.item?.purchaseTermData)
            }else{
                timer = setTimeout(() => {
                    dispatchHandler(dispatch, "getPurchaseTermOrderInfoRequest", {
                        "documentId" : item?.[termConfiguration?.["documentId"]]
                    })
                }, 2000)
            }
        }

        return(() => {
            clearTimeout(timer)
        })
    },[])

    useEffect(() => {
        if(getPurchaseTermOrderInfoData?.isSuccess){
            setPurchaseTermData(getPurchaseTermOrderInfoData?.data?.resource || []);
            if(getPurchaseTermOrderInfoData?.data?.resource?.length > 0) 
                props.item.purchaseTermData = getPurchaseTermOrderInfoData?.data?.resource
        }
        if(getPurchaseTermOrderInfoData?.isError){
            setPurchaseTermData([]);
        }
    },[getPurchaseTermOrderInfoData])

    const divsArray = Array.from({ length: 4 }, index => index + 1);

    if(getPurchaseTermOrderInfoData?.isLoading || purhcaseTermData == null){
        return <div className='purchase-term-dropdown-skeleton' onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
            <div className='w-100 p-lr-12 p-tb-10 m-bot-10'>
                <div className='flex justify-between w-100'>
                    <div className='generic-skeleton hgt-15 w-30 m-rgt-18 '></div>
                    <div className='generic-skeleton hgt-15 w-30 m-rgt-18'></div>
                    <div className='generic-skeleton hgt-15 w-30'></div>
                </div>
                {divsArray.map(()=> <div className='flex justify-between w-100 m-top-10'>
                    <div className='w-33 p-rgt-10'>
                        <div className='generic-skeleton hgt-10 w-80 m-bot-5'></div>
                        <div className='generic-skeleton hgt-10 w-100'></div>
                    </div>
                    <div className='w-33 p-rgt-10'>
                        <div className='generic-skeleton hgt-10 w-80 m-bot-5'></div>
                        <div className='generic-skeleton hgt-10 w-100'></div>
                    </div>
                    <div className='w-33 p-rgt-10'>
                        <div className='generic-skeleton hgt-10 w-80 m-bot-5'></div>
                        <div className='generic-skeleton hgt-10 w-100'></div>
                    </div>
                </div>)}
            </div>
        </div>
    }

    if(purhcaseTermData?.length == 0){
        return <div className='purchase-term-dropdown-skeleton' onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
           <NoDataFound />
        </div>
    }

    let headers = {
        "finchargeRate" : "Fin Charge Rate",
        "charge" : "Charge",
        // "basicAmt" : "Basic Amount",
    }

  return (
    <div className='purchase-term-dropdown' onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        <div className='ptd-inner'>
            {purhcaseTermData?.map(item => (
                <div className='ptd-col'>
                    <LanguageTranslator tag="span" className='ptd-main-head'>{item?.["chgname"] || ""}</LanguageTranslator>
                    {
                        Object.keys(headers || {})?.map(key => (
                            <div className='ptdmh-bot'>
                                <LanguageTranslator tag="p">{headers?.[key] || ""}</LanguageTranslator>
                                {/* Chage Rs to rupees sign */}
                                <h3>
                                    <Icons.RupeeIcon className='m-rgt-5' /> 
                                    <LanguageTranslator>{item?.[key] || "0"}</LanguageTranslator>
                                </h3>
                            </div>
                        ))
                    }
                </div>
            ))}
        </div>
    </div>
  )
}

export default PurchaseDiscountAndTexPopover