import React from 'react'
import Icons from '../../assets/icons'

const UploadExcelInfo = () => {
    return (
        <div className='uehr-info'>
            <Icons.InfoIcon />
            <div className='uehr-dropdown'>
                <ul>
                    <li>
                        <label className="generic-checkbox">
                            <input type="checkBox" checked={true} />
                            <span className="checkmark1 c-maroon"></span>
                            Maroon: Mandatory fields
                        </label>
                    </li>
                    <li>
                        <label className="generic-checkbox">
                            <input type="checkBox" checked={true} />
                            <span className="checkmark1 c-red"></span>
                            Red: Data Issue fields
                        </label>
                    </li>
                    <li>
                        <label className="generic-checkbox">
                            <input type="checkBox" checked={true} />
                            <span className="checkmark1 c-orange"></span>
                            Orange: Mismatched fields
                        </label>
                    </li>
                    <li>
                        <label className="generic-checkbox">
                            <input type="checkBox" checked={true} />
                            <span className="checkmark1 c-yellow"></span>
                            Yellow: Extinct fields
                        </label>
                    </li>
                    <li>*Contact Supplymint for more info...</li>
                </ul>
            </div>
        </div>
    )
}

export default UploadExcelInfo