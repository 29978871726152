import { message } from 'antd';
import { CONFIG } from '../../config/index';
export const administrationStore = [
    {
        title: "getModuleRoleData",
        actionPrefix: 'getModuleRole',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/rolemaster/getrole/data`
        }
    },
    {
        title: "getModuleUsersData",
        actionPrefix: 'getModuleUsers',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/get/all`
        }
    },
    {
        title: "getModuleUserDataMappingData",
        actionPrefix: 'getModuleUserDataMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/get/all/mapping/data`
        }
    },
    {
        title: "getOrganizationListData",
        actionPrefix: 'getOrganizationList',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/all/organisation/details`
        }
    },
    {
        title: "getAdministrationDataSyncData",
        actionPrefix: 'getAdministrationDataSync',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/custom/get/all/reference`
        }
    },
    {
        title: "getModuleApiLogsData",
        actionPrefix: 'getModuleApiLogs',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/find/log`
        }
    },
    {
        title: "getEmailNotificationLogData",
        actionPrefix: 'getEmailNotificationLog',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/find/log`
        }
    },
    {
        title: "getApiLogRetryData",
        actionPrefix: 'getApiLogRetry',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/retry/log`
        }
    },
    {
        title: "resendEmailNotificationData",
        actionPrefix: 'resendEmailNotification',
        message: 'Request Completed Successfully',
        requestData: {
            method: 'GET',
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/resend/${payload}`
        }
    },
    {
        title: "getEmailBodyData",
        actionPrefix: 'getEmailBody',
        message: 'Request Completed Successfully',
        requestData: {
            method: 'GET',
            buildUrl: (payload) => `${CONFIG.BASE_URL}/notification/email/body/${payload}`
        }
    },
    {
        title: "userConfigData",
        actionPrefix: 'userConfig',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/config`
        }
    },
    {
        title: "userSetMappingData",
        actionPrefix: 'userSetMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/set/mapping/data`
        }
    },
    {
        title: "userAvailableMappingDataData",
        actionPrefix: 'userAvailableMappingData',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/available/mapping/data`
        }
    },
    {
        title: "saveUserDataMappingData",
        actionPrefix: 'saveUserDataMapping',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/datamapping/info`
        }
    },
    {
        title: "rolemoduleRoleCheckData",
        actionPrefix: 'rolemoduleRoleCheck',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/rolemaster/rolemodule/role/check`
        }
    },
    {
        title: "rolemasterModuleDataData",
        actionPrefix: 'rolemasterModuleData',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "rolemasterModuleSubmitData",
        actionPrefix: 'rolemasterModuleSubmit',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "rolemoduleRoleDeleteData",
        actionPrefix: 'rolemoduleRoleDelete',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/rolemaster/rolemodule/role/delete/${payload.id}`
        }
    },
    {
        title: "coreUserCreateUpdateData",
        actionPrefix: 'coreUserCreateUpdate',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "userCreateSendEmailData",
        actionPrefix: 'userCreateSendEmail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/create/send/email`
        }
    },
    {
        title: "userUpdateStatusData",
        actionPrefix: 'userUpdateStatus',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/status`
        }
    },
    {
        title: "coreOrgGetAllOrganisationData",
        actionPrefix: 'coreOrgGetAllOrganisation',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/all/organisation`
        }
    },
    {
        title: "multiLevelConfigGetAllRolesData",
        actionPrefix: 'multiLevelConfigGetAllRoles',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/multi/level/config/get/all/roles`
        }
    },

    // Global Configuration Api's
    {
        title: "getTableHeaderConfigModuleData",
        actionPrefix: "getTableHeaderConfigModule",
        message: "",
        errorMessage: "Failed to get the Table Headers Configuration!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/headerconfig/get/table/menu?type=${payload?.type}`
        }
    },
    {
        title: "getTableHeaderConfigHeadersData",
        actionPrefix: "getTableHeaderConfigHeaders",
        message: "",
        errorMessage: "Failed to get the Table Headers!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/headerconfig/get/table/header`
        }
    },
    {
        title: "getTableDocumentFormatData",
        actionPrefix: "getTableDocumentFormat",
        message: "",
        errorMessage: "Failed to get the Document Format Data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/organization/module?tableName=${payload?.type}`
        }
    },
    {
        title: "getTableModuleDocumentFormatData",
        actionPrefix: "getTableModuleDocumentFormat",
        message: "",
        errorMessage: "Failed to get the Document Format Data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/get/codeFormat?moduleCode=${payload.type}`
        }
    },
    {
        title: "updateTableModuleDocumentFormatData",
        actionPrefix: "updateTableModuleDocumentFormat",
        message: "Document Format Updated Successfully.",
        errorMessage: "Failed to update the Document Format Data!",
        requestData: {
            method: "PUT",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/update/codeFormat`
        }
    },
    {
        title: "getApiCofigurationData",
        actionPrefix: "getApiCofiguration",
        message: "",
        errorMessage: "Failed to get the Inbound API Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/erp/details`
        }
    },
    {
        title: "getApiOutboundCofigurationData",
        actionPrefix: "getApiOutboundCofiguration",
        message: "",
        errorMessage: "Failed to get the Outbound Api Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/get/partner/details`
        }
    },
    {
        title: "getSystemMaintainenceConfigData",
        actionPrefix: "getSystemMaintainenceConfig",
        message: "",
        errorMessage: "Failed to get the System Maintainence Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/enterprise/get/all`
        }
    },
    {
        title: "updateSystemMaintainenceConfigData",
        actionPrefix: "updateSystemMaintainenceConfig",
        message: "",
        errorMessage: "Failed to update the System Maintainence Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/sm/admin/enterprise/update`
        }
    },
    {
        title: "getAnnoucementsData",
        actionPrefix: "getAnnoucements",
        message: "",
        errorMessage: "Failed to get the Annoucements Configuration!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/all/announcement`
        }
    },
    {
        title : "deleteAnnoucementsData",
        actionPrefix :"deleteAnnoucements",
        message : "Announcement Deleted Successfully.",
        errorMessage : "Failed to delete the Announcement!",
        requestData : {
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/org/delete/announcement`
        }
    },
    {
        title : "updateAnnoucementsData",
        actionPrefix :"updateAnnoucements",
        message : "Announcement Data Updated Successfully.",
        errorMessage : "Failed to update the Announcement!",
        requestData : {
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/org/update/announcement`
        }
    },
    {
        title : "createAnnoucementsData",
        actionPrefix :"createAnnoucements",
        message : "New Announcement Created Successfully.",
        errorMessage : "Failed to create the new Announcement !",
        requestData : {
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/org/create/announcement`
        }
    },
    {
        title : "getTrainingGuidesData",
        actionPrefix :"getTrainingGuides",
        message : "New Announcement Created Successfully.",
        errorMessage : "Failed to get the training guides!",
        requestData : {
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/org/get/all/training/guide`
        }
    },
    {
        title: "deleteTrainingGuidesData",
        actionPrefix: "deleteTrainingGuides",
        message: "Training Guide Deleted Successfully.",
        errorMessage: "Failed to delete the training guides!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/delete/training/guide`
        }
    },
    {
        title: "createTrainingGuidesData",
        actionPrefix: "createTrainingGuides",
        message: "Training Guide Created Successfully.",
        errorMessage: "Failed to created the training guides!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/create/training/guide`
        }
    },
    {
        title: "updateTrainingGuidesData",
        actionPrefix: "updateTrainingGuides",
        message: "Training Guide Updated Successfully.",
        errorMessage: "Failed to update the training guides!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/update/training/guide`
        }
    },
    {
        title: "updateOutboundApiConfigurationData",
        actionPrefix: "updateOutboundApiConfiguration",
        message: "Outbound Api Configuration Updated Successfully.",
        errorMessage: "Failed to Update the Outbound Api Configuraiton!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/active/inactive/api`
        }
    },
    {
        title: "createApiIntegrationData",
        actionPrefix: "createApiIntegration",
        message: "Outbound Api Integration Successfull.",
        errorMessage: "Failed to Perform Api Integration.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/setup/api/integration`
        }
    },
    {
        title: "clearApiIntegrationData",
        actionPrefix: "clearApiIntegration",
        message: "Outbound Api Integration Deleted Successfully.",
        errorMessage: "Failed to Delete Api Integration.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/delete/erp/org/logs`
        }
    },
    {
        title: "getDataManagementConfigData",
        actionPrefix: "getDataManagementConfig",
        message: "",
        errorMessage: "Failed to get the data.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/all/data/management?moduleCode=${payload.type}`
        }
    },
    {
        title: "buildResourceConfigData",
        actionPrefix: "buildResourceConfig",
        message: "Data Building Started!",
        errorMessage: "Failed to Start Data Building Process!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/data/management/build`
        }
    },
    {
        title: "arsSiteConfigChangesData",
        actionPrefix: "arsSiteConfigChanges",
        message: "Sites Configuration Updated Successfully!",
        errorMessage: "Failed to Update the Sites Configuration!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/rule/engine/mapped/create`
        }
    },
    {
        title: "getBarcodeProfileConfigData",
        actionPrefix: "getBarcodeProfileConfig",
        errorMessage: "Failed to Get Barcode Profiles List!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/barcode/get/all`
        }
    },
    {
        title: "updateBarcodeProfileConfigData",
        actionPrefix: "updateBarcodeProfileConfig",
        message: "Barcode Profiles Updated Successfully!",
        errorMessage: "Failed to Update Barcode Profiles List!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/barcode/update`
        }
    },
    {
        title: "createBarcodeProfileConfigData",
        actionPrefix: "createBarcodeProfileConfig",
        message: "New Barcode Profiles Created Successfully!",
        errorMessage: "Failed to Create New Barcode Profiles!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/barcode/create`
        }
    },
    {
        title: "getSalesMappingFilterData",
        actionPrefix: "getSalesMappingFilter",
        errorMessage: "Failed to Get the Configuration Data!.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/siteItemConfigurationFilterData?filterType=${payload.filterType}`
        }
    },
    {
        title: "updateSalesMappingFilterData",
        actionPrefix: "updateSalesMappingFilter",
        message: "Configueation Updated Successfully!",
        errorMessage: "Failed to Update the Configuration Data!.",
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/save/siteItemConfigurationFilterData?filterType=${payload?.filterType}`
        }
    },
    {
        title: "updateProductCatalogDataMappingData",
        actionPrefix: "updateProductCatalogDataMapping",
        message: "Configueation Updated Successfully!",
        errorMessage: "Failed to Update the Configuration Data!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/update/configuration`
        }
    },
    {
        title: "getDigivendConfigData",
        actionPrefix: "getDigivendConfig",
        errorMessage: "Failed to Get the Digivend Configuration Data!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/vendorship/get/asn/dashboard/details`
        }
    },
    {
        title: "updateDigivendConfigData",
        actionPrefix: "updateDigivendConfig",
        message: "Digivend Configuration Updated Successfully!",
        errorMessage: "Failed to Update the Digivend Configuration Data!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/update/key`
        }
    },
    {
        title: "getDigiArsConfigData",
        actionPrefix: "getDigiArsConfig",
        errorMessage: "Failed to get the DigiArs Configuration Data!.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/report/year/configuration`
        }
    },
    {
        title: "getDigiArsMappingData",
        actionPrefix: "getDigiArsMapping",
        errorMessage: "Failed to get the DigiArs Mapping Configuration Data!.",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/get/datamapping/info`
        }
    },
    {
        title: "getGeneralSettingData",
        actionPrefix: "getGeneralSetting",
        errorMessage: "Failed to get the Organization Configuration Data!.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/enterprise/detail`
        }
    },
    {
        title: "verifyDomainData",
        actionPrefix: "verifyDomain",
        message: "Organization Portal URL Verified Successfully!",
        errorMessage: "Failed to verify the Organization Portal URL!.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/profile/url/availability?profileUrl=${payload.url}`
        }
    },
    {
        title: "verifyCustomDomainData",
        actionPrefix: "verifyCustomDomain",
        message: "Organization Custom Domain Verified Successfully!",
        errorMessage: "Failed to verify the Organization Custom Domain!.",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/profile/url/availability?profileUrl=${payload.url}`
        }
    },
    {
        title: "uploadGeneralSettingImagesData",
        actionPrefix: 'uploadGeneralSettingImages',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/custom/core/multiple/file/upload`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "updateGeneralSettingData",
        actionPrefix: "updateGeneralSetting",
        message: "Organization Configuration Data Update Successfully!",
        errorMessage: "Failed to update the Organization Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/update/enterprise`
        }
    },
    {
        title: "getWorkflowSettingModulesData",
        actionPrefix: "getWorkflowSettingModules",
        errorMessage: "Failed to get the Workflow Configuration Modules!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/workflow/modules`
        }
    },
    {
        title: "getWorkflowSettingConfigurationData", 
        actionPrefix: "getWorkflowSettingConfiguration",
        errorMessage: "Failed to get the Workflow Configuration Data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/sdc/configuration?moduleCode=${payload?.type}`
        }
    },
    {
        title: "updateWorkflowSettingData",
        actionPrefix: "updateWorkflowSetting",
        message: "Workflow Settings Updated Successfully!",
        errorMessage: "Failed to update the Workflow Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/update/configuration`
        }
    },
    {
        title: "getLrConfigurationData",
        actionPrefix: "getLrConfiguration",
        errorMessage: "Failed to get the LR Configuration Data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/form/setting/get/lr`
        }
    },
    {
        title: "updateLrConfigurationData",
        actionPrefix: "updateLrConfiguration",
        message: "LR Configuration Updated Successfully!",
        errorMessage: "Failed to update the LR Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/form/setting/create/lr`
        }
    },
    {
        title: "updateLrConfigurationData",
        actionPrefix: "updateLrConfiguration",
        message: "LR Configuration Updated Successfully!",
        errorMessage: "Failed to update the LR Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/form/setting/create/lr`
        }
    },
    {
        title: "getProcFormSettingsData",
        actionPrefix: "getProcFormSettings",
        errorMessage: "Failed to get the Digiproc Configuration Data!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/mapping?isapparel=true`
        }
    },
    {
        title: "updateProcFormSettingsData",
        actionPrefix: "updateProcFormSettings",
        message: "Digiproc Configuration Updated Successfully!",
        errorMessage: "Failed to update the Digiproc Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/update/mapping`
        }
    },
    {
        title: "getCatDescSettingData",
        actionPrefix: "getCatDescSetting",
        errorMessage: "Failed to get the Digiproc Cat/Desc Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/itemudfsetting`
        }
    },
    {
        title: "updateCatDescSettingData",
        actionPrefix: "updateCatDescSetting",
        message: "Digiproc Cat/Desc Configuration Data Updated Successfully!",
        errorMessage: "Failed to update the Digiproc Cat/Desc Configuration Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/update/itemudfsetting`
        }
    },
    {
        title: "getMultilevelApprovalModuleData",
        actionPrefix: "getMultilevelApprovalModule",
        message: "Digiproc Cat/Desc Configuration Data Updated Successfully!",
        errorMessage: "Failed to get the Multilevel Approval Module List!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/multi/level/config/get/module`
        }
    },
    {
        title: "getMultilevelApprovalDetailsData",
        actionPrefix: "getMultilevelApprovalDetails",
        errorMessage: "Failed to get the Multilevel Approval Data List!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/multi/level/config/get/all`
        }
    },
    {
        title: "updateMultilevelApprovalDetailsData",
        actionPrefix: "updateMultilevelApprovalDetails",
        message: "Multilevel Approval Configuration Updated Successfully!",
        errorMessage: "Failed to update the Multilevel Approval Configuration List!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/multi/level/config/create`
        }
    },
    {
        title: "getDigicatFormSettingsData",
        actionPrefix: "getDigicatFormSettings",
        errorMessage: "Failed to get the Digicatalogue Form Settings Data!",
        requestData: {
            // method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/tenant/form/setting`
        }
    },
    {
        title: "updateDigicatFormSettingsData",
        actionPrefix: "updateDigicatFormSettings",
        message: "Form Settings Updated Successfully!",
        errorMessage: "Failed to update the Form Settings Data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/create/tenant/form/setting`
        }
    },
    {
        title: "getAssortmentCurrentSettingData",
        actionPrefix: 'getAssortmentCurrentSetting',
        errorMessage: 'Failed to get the current assortment configuration.',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.uniqueKey;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/data?type`
        }
    },
    {
        title: "updateAssortmentCurrentSettingData",
        actionPrefix: 'updateAssortmentCurrentSetting',
        errorMessage: 'Failed to update the current assortment configuration.',
        message: "Assortment configuration updated successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/assortment/save/assortmentPlanning`
        }
    },
    {
        title: "updateExcludedReportArsData",
        actionPrefix: "updateExcludedReportArs",
        message: "Excluded attributes updated successfully.",
        errorMessage: "Failed to update the excluded attributes!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/update/exclude/report/data`
        }
    },
    {
        title: "getExcludedReportArsData",
        actionPrefix: "getExcludedReportArs",
        errorMessage: "Failed to get the excluded attributes!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/get/exclude/report/data`
        }
    },

    {
        title: "getMappedArsFilterConfigurationData",
        actionPrefix: "getMappedArsFilterConfiguration",
        errorMessage: "Failed to get the ars filters configuration!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/arsItemAndArsSiteFilter?filterType=${payload?.type}`
        }
    },

    {
        title: "getNotMappedArsFilterConfigurationData",
        actionPrefix: "getNotMappedArsFilterConfiguration",
        errorMessage: "Failed to get the ars filters configuration!",
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/get/siteAndItemFilter?filterType=${payload?.type}`
        }
    },
    {
        title: "updateArsFilterConfigurationData",
        actionPrefix: "updateArsFilterConfiguration",
        errorMessage: "Failed to update the ars filters configuration!",
        message: "Ars filter configuration updated successfully!",
        requestData: {
            isPayloadExistParams: true,
            buildUrl: (payload) => `${CONFIG.BASE_URL}/plan/settings/save/arsSiteAndArsItemFilter?filterType=${payload?.type}`
        }
    },
    {
        title: "deleteBarcodeProfileData",
        actionPrefix: "deleteBarcodeProfile",
        errorMessage: "Failed to delete the barcode profile!",
        message: "Barcode profile deleted successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/barcode/delete`
        }
    },
    {
        title: "updateIpRestrictionData",
        actionPrefix: "updateIpRestriction",
        errorMessage: "Failed to update the ip restriction!",
        message: "Ip restriction updated successfully!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/user/update/ip/restriction`
        }
    },
    {
        title : "getEmailNotificationTableData",
        actionPrefix :"getEmailNotificationTable",
        errorMessage : "Failed to get the email events table data!",
        requestData : {
            method : "GET",
            buildUrl : (payload) => `${CONFIG.BASE_URL}/notification/get/all/email/events?moduleCode=${payload?.module}&subModuleCode=${payload?.subModule}`
        }
    },
    {
        title : "saveEmailNotificationTableData",
        actionPrefix :"saveEmailNotificationTable",
        errorMessage : "Failed to save the email events data!",
        message : "Email events saved successfully!",
        requestData : { 
            buildUrl : (payload) => `${CONFIG.BASE_URL}/notification/email/configuration/save`
        }
    },
    {
        title : "resetDocumentFormatData",
        actionPrefix :"resetDocumentFormat",
        errorMessage : "Failed to reset the document formats!",
        message : "Document Formats reset successfully!",
        requestData : { 
            method : "GET",
            buildUrl : (payload) => `${CONFIG.BASE_URL}/system/config/document/format/reset?moduleCode=${payload.moduleCode}`
        }
    },
    {
        title : "forcePasswordResetData",
        actionPrefix :"forcePasswordReset",
        requestData : {
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/org/force/password/reset`
        }
    },
    {
        title : "saveCostSheetCatalogSettingData",
        actionPrefix :"saveCostSheetCatalogSetting",
        errorMessage : "Failed to save the costsheet!",
        message : "Default costsheet saved successfully!",
        requestData : { 
            buildUrl : (payload) => `${CONFIG.BASE_URL}/system/config/document/format/reset`
        }
    },
    {
        title : "getCostSheetCatalogSettingData",
        actionPrefix :"getCostSheetCatalogSetting",
        errorMessage : "Failed to get the default costsheet!",
        requestData : { 
            buildUrl : (payload) => `${CONFIG.BASE_URL}/system/config/document/format/reset`
        }
    },
    {
        title : "deleteUserData",
        actionPrefix :"deleteUser",
        message : "User Deleted successfully!",
        errorMessage : "Failed to Delete User!",
        requestData : { 
            updatePayload: ({ ...payload }) => {
                delete payload.type;
                return payload;
            },
            buildUrl : (payload) => `${CONFIG.BASE_URL}/core/user/inactive/user/${payload.type}`
        }
    },
    {
        title : "getApiIntegrationApiListData",
        actionPrefix :"getApiIntegrationApiList",
        errorMessage : "Failed to get the api list data!",
        requestData : { 
            buildUrl : (payload) => `${CONFIG.BASE_URL}/admin/intapi/get/api/list`
        }
    },
    {
        title: "updateApiIntegrationData",
        actionPrefix: 'updateApiIntegration',
        message: 'Inbound API Integration Status Updated Successfully.',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/intapi/setup/api/integration`
        }
    },
    {
        title: "getExcelUploadTemplateCategoryData",
        actionPrefix: 'getExcelUploadTemplateCategory',
        errorMessage: 'Failed to get the upload template category!',
        requestData: {
            method :"GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/headerconfig/get/table/menu?type=${payload.type}`
        }
    },
    {
        title: "updateExcelUploadTemplateCategoryData",
        actionPrefix: 'updateExcelUploadTemplateCategory',
        message : "Template category configuration updated successfully.",
        errorMessage: 'Failed to update the upload template category!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/system/config/generic/excel/upload/config/submit`
        }
    },
    {
        title: "masterRoleCreateData",
        actionPrefix: 'masterRoleCreate',
        message: 'Roles Master Created Successfully.',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/create`
        }
    },
    {
        title: "getPermissionDetailCommonData",
        actionPrefix: 'getPermissionDetailCommon',
        requestData: {
            method: 'GET',
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/get/permission/detail/${payload?.type}`
        }
    },
    {
        title: "roleGetCategoryAllData",
        actionPrefix: 'roleGetCategoryAll',
        requestData: {
            method: 'GET',
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/get/category/all`
        }
    },
    {
        title: "masterRoleGetAllData",
        actionPrefix: 'masterRoleGetAll',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/get/all`
        }
    },
    {
        title: "masterRoleGetPermissionData",
        actionPrefix: 'masterRoleGetPermission',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/get/permission`
        }
    },
    {
        title: "masterRoleDeleteData",
        actionPrefix: 'masterRoleDelete',
        message: "User role deleted successfully",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_MICRO_SERVICE_URL}/admin/master/role/delete`
        }
    },
]