import React from 'react'

const BoxAnalysisSkeleton = () => {
  return (
    <div className='asn-life-cycle'>
			<div className='flex'>
				<div className='pie-chart-section p-20'>
					<div>
						<div className='generic-skeleton wid-150'></div>
						<div className='generic-skeleton wid-100'></div>
					</div>
					<div className='generic-skeleton wid-150'></div>
				</div>
				<div className='pie-chart-section m-lft-12 p-20'>
					<div>
						<div className='generic-skeleton wid-150'></div>
						<div className='generic-skeleton wid-100'></div>
					</div>
					<div className='generic-skeleton wid-150'></div>
				</div>
			</div>
			<div className='pie-chart-section m-top-12 p-20'>
				<div>
					<div className='generic-skeleton wid-150'></div>
					<div className='generic-skeleton wid-100'></div>
				</div>
				<div className='generic-skeleton wid-150'></div>
			</div>
		</div>
  )
}

export default BoxAnalysisSkeleton