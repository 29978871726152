import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { ReloadContext } from '../../../App';

function BootConfigSetting() {
    const dispatch = useDispatch();

    const getBootSettingsForApplicationData = useSelector(state => state?.generalStore?.getBootSettingsForApplicationData);
    const reloadContext = useContext(ReloadContext);
    // Get the boot config and its setter function from reload context provided from App.js
    const { setBootConfig } = reloadContext || {};

    useEffect(() => {
        if(getBootSettingsForApplicationData?.isSuccess){
            // getBootSettingsForApplicationData.data.resource = {
            //     ...getBootSettingsForApplicationData?.data?.resource,
            //     "loginMethod" : "PASSWORD"//,"PASSWORD", "WECHAT"
            // }
            // console.log("iuagbfjbhadKF", getBootSettingsForApplicationData?.data?.resource)
            //     additionalConfig: {
            //         color: getBootSettingsForApplicationData?.data?.resource?.additionalConfig?.color ,
            //         allowVendorRegistration : 1,
            //     }
            // }
            setBootConfig(getBootSettingsForApplicationData?.data?.resource);
            if(getBootSettingsForApplicationData?.data?.resource?.additionalConfig?.color ){
                let color = getBootSettingsForApplicationData?.data?.resource?.additionalConfig?.color ??  document.documentElement.style.getPropertyValue('--primary-color');
                
                document.documentElement.style.setProperty('--primary-color',color );
            }
        }
    }, [getBootSettingsForApplicationData?.isSuccess])

    // Function : To get the config boot setting api call.
    const getCustomDomainBootSettings = () => {
        dispatchHandler(dispatch, "getBootSettingsForApplicationRequest", {
            profileUrl: window.location.hostname,
        })
    }

    useEffect(() => {
        // Call for the boot config api's and get the open api data;
        // Get the open config boot settings
        if (!window.location?.hostname?.includes("prod.supplymint.com")) {
            getCustomDomainBootSettings()
        }
    },[])

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default BootConfigSetting
