import { CONFIG } from '../../config/index';
import { AUTH_CONFIG } from '../../authConfig/index'


export const reportsAndAnalyticsStore = [
    {
        title: "getVendorNameData",
        actionPrefix: 'getVendorName',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/managevendor/find/vendor`
        },
    },
    {
        title: "getRequestLedgerData",
        actionPrefix: 'getRequestLedger',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ent/analytics/get/ven/fin/tran`
        },
    },
    {
        title: "catSizeFilterData",
        actionPrefix: 'catSizeFilter',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/categorySizeWiseReport`
        },
    },
    {
        title: "geneRateFilterData",
        actionPrefix: 'geneRateFilter',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.key;
                return payload;
            },
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/save/arsSiteAndArsItemFilter?filterType=${payload.key}`
        },
    },
    {
        title: "getStoreAnalaysisData",
        actionPrefix: 'getStoreAnalaysis',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/create/storeAnalysis`
        },
    },


    {
        title: "performanceReportData",
        actionPrefix: 'performanceReport',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/performanceReport`
        },
    },
    {
        title: "trackerKpiData",
        actionPrefix: 'trackerKpi',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/daily/kpi/report`
        },
    },
    {
        title: "atvData",
        actionPrefix: 'atv',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/atv/rangeReport`
        },
    },
    {
        title: "lyComparisionData",
        actionPrefix: 'lyComparision',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/year/salesReport`
        },
    },
    {
        title: "mboReportPrimaryData",
        actionPrefix: 'mboReportPrimary',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "mboReportSecondaryData",
        actionPrefix: 'mboReportSecondary',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "mboReportTertiaryData",
        actionPrefix: 'mboReportTertiary',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },

    // merchandise
    {
        title: "merchFirstData",
        actionPrefix: 'merchFirst',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "merchSecondData",
        actionPrefix: 'merchSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "merchThirdData",
        actionPrefix: 'merchThird',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "merchFourthData",
        actionPrefix: 'merchFourth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },

    // regional 
    {
        title: "regionalFirstData",
        actionPrefix: 'regionalFirst',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "regionalSecondData",
        actionPrefix: 'regionalSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "regionalThirdData",
        actionPrefix: 'regionalThird',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    {
        title: "regionalFourthData",
        actionPrefix: 'regionalFourth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/mboReportinfo`
        },
    },
    // STOCK SIZE REPORT API REQUEST
    {
        title: "sizeSetFirstData",
        actionPrefix: 'sizeSetFirst',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/stock/report/data`
        },
    },
    {
        title: "sizeSetSecondData",
        actionPrefix: 'sizeSetSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/stock/report/data`
        },
    },
    {
        title: "firstexpandMainData",
        actionPrefix: 'firstexpandMain',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/stock/report/data`
        },
    },
    {
        title: "secondexpandMainData",
        actionPrefix: 'secondexpandMain',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/stock/report/data`
        },
    },



    //Weekly sales api request
    {
        title: "stockSetFirstData",
        actionPrefix: 'stockSetFirst',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetSecondData",
        actionPrefix: 'stockSetSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetThirdData",
        actionPrefix: 'stockSetThird',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetFourthData",
        actionPrefix: 'stockSetFourth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetFifthData",
        actionPrefix: 'stockSetFifth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetSixthData",
        actionPrefix: 'stockSetSixth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },
    {
        title: "stockSetSeventhData",
        actionPrefix: 'stockSetSeventh',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/weekly/sales/report/data`
        },
    },

    // gv analysis request
    {
        title: "gvAnalysisData",
        actionPrefix: 'gvAnalysis',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/gv/report/data`
        },
    },
    {
        title: "gvAnalysissecData",
        actionPrefix: 'gvAnalysissec',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/gv/report/data`
        },
    },

    // choose store profile
    {
        title: "sitePlanningFilterData",
        actionPrefix: 'sitePlanningFilter',

        message: 'Request Completed Successfully',
        requestData: {
            method:'GET',
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/plan/settings/get/site-planning-filters`
        },
    },

    {
        title: "storeMianAnalysisData",
        actionPrefix: 'storeMianAnalysis',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/storeAnalysis`
        },
    },

    // choose store profile
    {
        title: "itemWiseReportData",
        actionPrefix: 'itemWiseReport',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },
    {
        title: "itemWiseReportSecondData",
        actionPrefix: 'itemWiseReportSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },
    {
        title: "itemWiseReportThirdData",
        actionPrefix: 'itemWiseReportThird',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },
    {
        title: "itemWiseReportFourthData",
        actionPrefix: 'itemWiseReportFourth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },
    {
        title: "itemWiseReportFifthData",
        actionPrefix: 'itemWiseReportFifth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },
    {
        title: "itemWiseReportSixthData",
        actionPrefix: 'itemWiseReportSixth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/storeAnalysis`
        },
    },

    // storeanalysis 
    {
        title: "storeInventeryData",
        actionPrefix: 'storeInventery',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/all/storeAnalysis`
        },
    },


    // LEDGER REPORT VENDOR NAME
    {
        title: "vendoeNameListData",
        actionPrefix: 'vendoeNameList',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>`${CONFIG.BASE_URL}/vendorportal/managevendor/find/vendor`
        },
    },
    {
        title: "getJistReportCustomerData",
        actionPrefix: 'getJistReportCustomer',
        errorMessage : "Failed To Get the Table Data!",
        requestData: {
            buildUrl: (payload) =>`${CONFIG.BASE_URL}/admin/so/v2/jist/report`
        },
    },
    {
        title: "getJistReportDeliveryData",
        actionPrefix: 'getJistReportDelivery',
        errorMessage : "Failed To Get the Table Data!",
        requestData: {
            buildUrl: (payload) =>`${CONFIG.BASE_URL}/admin/so/v2/jist/report`
        },
    },
    {
        title: "getJistReportDepartmentData",
        actionPrefix: 'getJistReportDepartment',
        errorMessage : "Failed To Get the Table Data!",
        requestData: {
            buildUrl: (payload) =>`${CONFIG.BASE_URL}/admin/so/v2/jist/report`
        },
    },

    // Store Sales Detail
    {
        title: "storeSalesDetailData",
        actionPrefix: 'storeSalesDetail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/data/skechersReport`
        },
    },

    // channel wise net sales analysis 
    {
        title: "channelwiseFirstData",
        actionPrefix: 'channelwiseFirst',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/channel/wise/dashboard`
        },
    },
    {
        title: "channelwiseSecondData",
        actionPrefix: 'channelwiseSecond',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/channel/wise/dashboard`
        },
    },
    {
        title: "channelwiseThirdData",
        actionPrefix: 'channelwiseThird',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/channel/wise/dashboard`
        },
    },
    {
        title: "channelwiseFourthData",
        actionPrefix: 'channelwiseFourth',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/get/channel/wise/dashboard`
        },
    },

    // shipment box report barcode
    {
        title: "shipmentBoxBarcodeData",
        actionPrefix: 'shipmentBoxBarcode',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorship/get/asn/boxes`
        },
    },

    {
        title: "barCodePdfData",
        actionPrefix: 'barCodePdf',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/vendorportal/vendorlogi/find/barcodepdf`
        },
    },

    // 
    {
        title: "growDegrowData",
        actionPrefix: 'growDegrow',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) =>
                `${CONFIG.BASE_URL}/ars/plan/update/inventoryPlanningReport/value`
        },
    },
    {
        title: "genGetApiFirstData",
        actionPrefix: 'genGetApiFirst',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "genGetApiSecondData",
        actionPrefix: 'genGetApiSecond',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "genGetApiThirdData",
        actionPrefix: 'genGetApiThird',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "genGetApiForthData",
        actionPrefix: 'genGetApiForth',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "genGetApiFifthData",
        actionPrefix: 'genGetApiFifth',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`,
        }
    },
    {
        title: "getFootfallSummaryData",
        actionPrefix: 'getFootfallSummary',
        message: 'Request Completed Successfully',
        requestData:{
            buildUrl: (payload) => `${CONFIG.BASE_URL}/ars/plan/get/footfall/report/summary`,
        }
    }
]