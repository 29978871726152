import React from 'react';

class NewTableLoader extends React.Component {
    render () {
        return (
            <div className="manipulate-loader-css">
                <div className='new-table-loader'>
                    <table className='table imt-main-table'>
                        <thead>
                            <tr>
                                <th className="wid-70"><label></label></th>
                                <th><label className='dummy-head'></label></th>
                                <th><label className='dummy-head'></label></th>
                                <th><label className='dummy-head'></label></th>
                                <th><label className='dummy-head'></label></th>
                                <th><label className='dummy-head'></label></th>
                                <th><label className='dummy-head'></label></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                            <tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr><tr>
                                <td><label className='dummy-skeleton-first'></label><label className="dummy-skeleton-first m-lft-15"></label></td>
                                <td className='wid-250'><label className='dummy-skeleton w-75'></label> <label className='ds-first'></label> <label className='ds-first'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                                <td><label className='dummy-skeleton'></label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>            
        )
    }
}

export default NewTableLoader;