import React from 'react';
import { Tooltip } from 'antd';

const MapTooltip = ({ tooltipContent }) => {
    console.log("Tooltip content", tooltipContent);
    return (
        <div className="map-tooltip">
            <div className="mt-row">
                <h3>{tooltipContent?.state}</h3>
            </div>
            <div className="mt-row">
                <h3>Sales Value</h3>
                <p>{tooltipContent?.salesValue}</p>
            </div>
            <div className="mt-row">
                <h3>Sales Qty</h3>
                <p>{tooltipContent?.salesQty}</p>
            </div>
            <div className="mt-row">
                <h3>Return Qty</h3>
                <p>{tooltipContent?.returnQty}</p>
            </div>
            <div className="mt-row">
                <h3>Return Value</h3>
                <p>{tooltipContent?.returnValue}</p>
            </div>
        </div>
    );
};

export default MapTooltip;

