import { combineReducers } from 'redux';
import { registerActionData } from './StateData/apiFunction';
export function makeRootReducer(asyncReducers) {
  return combineReducers({
    ...registerActionData(),
    ...asyncReducers,
  });
}

export default makeRootReducer;
