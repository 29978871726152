import React, { useEffect, useState } from 'react'
import { getDesiredPayload, provideInputs } from './components/CommonFunctions';

const TransactionDetail = ({ menuBarDetailsData = [], getPayloadData, getInitialPayload, getInputErrorState }) => {
    const [inputData, setInputData] = useState({})
    const [errorData, setErrorData] = useState({});

    // logic to storing the inputdata in the required format
    useEffect(() => {
        // Constructing the object with headerKey as the key
        const newData = {};
        menuBarDetailsData?.forEach(item => {
            const updatedValue = item.value.map(field => {
                // If the field type is "upload", parse the value
                if (field.type === "upload" && field.value) {
                    return {
                        ...field,
                        value: typeof field?.value === 'string' && field?.value?.length > 0 ? JSON.parse(field?.value) : field?.value
                    };
                }
                return field;
            });

            // Update the item with the updated value
            newData[item.headerKey] = {
                ...item,
                value: updatedValue
            };
        });
        setInputData(newData);

        // getting the initial payload data
        const initialPayloadData = getDesiredPayload(newData);
        getInitialPayload(initialPayloadData)
    }, [menuBarDetailsData]);

    // function to get the desired payload and transfering to the parent
    useEffect(() => {
        getPayloadData(getDesiredPayload(inputData))
    }, [inputData])

    // getting InputErrorStatedata in parent component
    useEffect(() => {
        getInputErrorState({ inputData, errorData, setErrorData })
    }, [errorData, inputData])

    console.log('inputdata87uiehfdt3bdv3', inputData);
    return (
        <div className='vob-right'>
            {
                Object.values(inputData || {})?.map((inputField, index) => {
                    return <div className='vobrs-input-sec'>
                        <h3> {inputField?.headerName} {inputField?.isSectionMandate && <span className='mandatory'>*</span>} </h3>
                        {provideInputs(inputField, inputData, setInputData, errorData?.[index], (data) => {
                            let updatedErrorData = { ...errorData }
                            updatedErrorData[index] = data
                            setErrorData(updatedErrorData);
                        })}
                    </div>
                })
            }
        </div>
    )
}

export default TransactionDetail