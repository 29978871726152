// Documentation for Generic Form Component
// headerComponent and footerComponent are optional for user
// Documentation end 

import React, { useRef } from 'react'
import GenericFormHeaderComponent from './GenericFormHeaderComponent';
import GenericFormFooterComponent from './GenericFormFooterComponent';
import IconsPath from '../../assets/icons/index'
import GenericInput from '../input/GenericInputComponent';
import { CircleAlert } from 'lucide-react';


const GenericFormComponent = (props) => {

    let { headerComponent, footerComponent, rowInput, formBottomExtras, formTopExtras, availableDocument, warningMsg } = props;

    let inputRef = useRef();

    let {
        rowType = "spread", // This defines we have to scroll the inputs with the list or we have to spread these input fields
        availableInputs = [], // This defines the inputs that are available to render for the given row of the form
        inputState = {},  //State to store the data of the input which is currently selected.
        inputStateChangeFunction = () => { }, // Function to change the input state whenever the input field changes to update the state in parent.
        errorData = {},
        updateErrorData = () => { },
        rowLeftExtras = false,
        rowRightExtras = false
    } = rowInput || {};

    const renderHeaderComponent = () => {

        // Render the header component
        return (
            <GenericFormHeaderComponent
                {...headerComponent}
            />
        )

    }

    const renderFooterComponent = () => {
        return (
            <GenericFormFooterComponent
                {...footerComponent}
            />
        )
    }

    // Function to handle the changes inside the component
    const handleInputChange = (item, data, updatedInputState) => {
        let inputPropsData = item?.inputProps?.() || {};
        // Update the data at the given key
        switch (item?.type) {
            case "sliderInput":
            case "radioInput":
            case "customeDropdown":
            case "dropdown": {
                updatedInputState[item?.key] = data;
                break;
            }
            case "upload": {
                if (inputPropsData?.multipleFileUpload) {
                    updatedInputState[item?.key] = data;
                } else {
                    if(inputPropsData?.api){
                        updatedInputState[item?.key] = data;
                    }else{
                        updatedInputState[item?.key] = data?.target?.files || null;
                    }
                }
                break;
            }
            case "date":
            case "range":
            case "monthYear":
            case "time":
                {
                    updatedInputState[item?.key] = data;
                    break;
                }
            case "decimal":
            case "number":
            default: {
                updatedInputState[item?.key] = data?.target?.value;
                break
            }
        }
        if (item?.onChange) {
            // Calling the onChange function of the input to maintain the functins inside the parent component.
            updatedInputState = item?.onChange(data, updatedInputState) || updatedInputState;
        }
        return updatedInputState;
    }

    const provideRightIcon = (item) => {
        // Function => To provide the default right icon 
        switch (item?.type) {
            case "dropdown": {
                let inputPropsData = item?.inputProps();
                if (item.right == null) {
                    return null
                }
                if (inputPropsData?.api) {
                    return <IconsPath.GlobalSearchIcon />
                } else {
                    return <IconsPath.DownArrowNew />
                }
            }
            default: {
                return null;
            }
        }
    }

    const provideInputValue = (item) => {
        let inputPropsData = item?.inputProps?.() || {};
        switch (item?.type) {
            case "dropdown": {
                if (inputPropsData?.multiselect) {
                    return Object.keys(inputState?.[item?.key] || {})?.length > 0 ? `${Object.keys(inputState?.[item?.key] || {})?.length} Items Selected` || "" : "";
                }
                else {
                    return typeof (inputState?.[item?.key]) == "object" ? inputState?.[item?.key]?.[item.key] || "" : inputState?.[item?.key] || "";
                }
            }
            case "date":
            case "range":
            case "monthYear":
            case "time": {
                return inputState?.[item?.key] != undefined && inputState?.[item?.key] != null ? inputState?.[item?.key] || "" : ""
            }
            case "sliderInput":
            case "radioInput":
            case "upload": {
                return inputState?.hasOwnProperty(item?.key) && inputState?.[item?.key] != undefined && inputState?.[item?.key] != null ? inputState?.[item?.key] : ""
            }
            default: {
                return inputState?.hasOwnProperty(item?.key) && inputState?.[item?.key] != undefined && inputState?.[item?.key] != null ? `${inputState?.[item?.key]}` || "" : ""
            }
        }
    }

    const renderRowInputsComponent = () => {
        return (
            <div className={rowType === "singleRow" ? 'gfb-inner' : 'gfb-inner gfb-multiple-row'}>
                {rowLeftExtras && typeof (rowLeftExtras) == "function" ? rowLeftExtras() : rowLeftExtras}
                {availableInputs?.map((item, index) => {
                    return (
                        <GenericInput
                            availableDocument={availableDocument || null}
                            // Provide all the props from the parent component
                            iconRight={provideRightIcon(item)} // Icon left for all the input field
                            errorData={{ ...errorData }}
                            setErrorData={updateErrorData}
                            dependentFieldsObject={[item?.key]}
                            inputRef={inputRef}
                            {...item}
                            actualInputValue={inputState?.[item?.key]}
                            inputKey={item?.key}
                            inputIndex={props.inputIndex || 0}
                            value={item?.customInputValue ? () => item?.customInputValue(inputState?.[item?.key]) : provideInputValue(item)}
                            onChange={(data) => {
                                let updatedInputState = { ...inputState };
                                updatedInputState = handleInputChange(item, data, updatedInputState);
                                let updatedErrorState = { ...errorData };
                                if (data == null) {
                                    // Run the clear function for errors and blur for all the dependents fields.
                                    item?.dependentFieldsObject?.map(key => {
                                        // Find the input fields which needs to be cleared.
                                        let itemData = availableInputs?.find(val => val.key === key);
                                        updatedInputState = handleInputChange(itemData, null, updatedInputState);
                                        delete updatedErrorState?.[key];
                                    })
                                }
                                // handle the error changes on the input changes
                                if(item?.handleErrorChangeOnInputChange)
                                    updatedErrorState = item?.handleErrorChangeOnInputChange({
                                        errorState : updatedErrorState,
                                    })
                                // Call the input state change function from the parent component
                                updateErrorData?.({ ...updatedErrorState })
                                inputStateChangeFunction(updatedInputState);
                            }}
                        />
                    )
                })}
                {rowRightExtras && typeof (rowRightExtras) == "function" ? rowRightExtras() : rowRightExtras}
            </div>
        )
    }

    return (
        <React.Fragment>

            {/* Render header Component */}
            {/* The above div will be sticked at the top level */}
            {headerComponent && renderHeaderComponent()}

            {/* Render the row of the input component. */}
            <div className='generic-form-body'>
                {formTopExtras && typeof (formTopExtras) == "function" ? formTopExtras() : formTopExtras}
                {renderRowInputsComponent()}
                {formBottomExtras && typeof (formBottomExtras) == "function" ? formBottomExtras() : formBottomExtras}
            </div>

            {/* Render footer Component */}
            {/* The above div will be sticked at the bottom level */}
            {footerComponent && renderFooterComponent()}

        </React.Fragment>
    )
}

export default GenericFormComponent