import React, { useEffect, useState } from 'react'
import { getDesiredPayload, provideInputs } from './components/CommonFunctions';

const FactoryDetails = ({ menuBarDetailsData = [], getPayloadData, getInitialPayload, getInputErrorState }) => {
    const [inputData, setInputData] = useState({})
    const [errorData, setErrorData] = useState({});

    useEffect(() => {
        // Constructing the object with headerKey as the key
        const newData = {};
        menuBarDetailsData?.forEach(item => {
            newData[item?.headerKey] = item;
        });
        setInputData(newData);

        // getting the initial payload data
        const initialPayloadData = getDesiredPayload(newData);
        getInitialPayload(initialPayloadData)
    }, [menuBarDetailsData])

    // function to get the desired payload and transfering to the parent
    useEffect(() => {
        getPayloadData(getDesiredPayload(inputData))
    }, [inputData])

    // getting InputErrorStatedata in parent component
    useEffect(() => {
        getInputErrorState({ inputData, errorData, setErrorData })
    }, [errorData, inputData])

    return (
        <div className='vob-right'>
            {
                Object.values(inputData || {})?.map((inputField, index) => {
                    return <div className='vobrs-input-sec'>
                        <h3> {inputField?.headerName} {inputField?.isSectionMandate && <span className='mandatory'>*</span>} </h3>
                        {provideInputs(inputField, inputData, setInputData, errorData?.[index], (data) => {
                            let updatedErrorData = { ...errorData }
                            updatedErrorData[index] = data
                            setErrorData(updatedErrorData);
                        })}
                    </div>
                })
            }
        </div>
    )
}

export default FactoryDetails