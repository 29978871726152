
import { Manager } from "socket.io-client";
import {CONFIG} from '../config/index'
let preLoginSocket;

const getPreLoginSocketConnection = () => {
    if (preLoginSocket) {
        return preLoginSocket
    }
    return null;
}


const connectWithPreLoginSocketConnection = (config  = {}) => {

    if (!getPreLoginSocketConnection()?.connected) {
        const manager = new Manager(CONFIG.NOTIFICATION_ROUTE_PROD_SOCKET, {
            reconnectionDelayMax: 10000,
            reconnectionAttempts: 4,
            path : "/preLogin",
            transports : ["websocket"]
        });

        preLoginSocket = manager.socket("/")
        // console.log("pre login sockte", preLoginSocket)
        if(preLoginSocket){ 
            preLoginSocket.on('connect', () => {
                console.log("Socket connection created successfully");
            })
            preLoginSocket.on('error', () => {
                console.log("pre Login socket connect error");
            })
        }
    } else {
        return getPreLoginSocketConnection();
    }

}

const disconnectWithPreLoginSocketConnection = () => {
    let socketConnection = getPreLoginSocketConnection();
    if(socketConnection){
        socketConnection?.disconnect?.();
    }
}

export { connectWithPreLoginSocketConnection, getPreLoginSocketConnection, disconnectWithPreLoginSocketConnection }