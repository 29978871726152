import React, { useEffect, useState } from 'react'
import Icons from '../../assets/icons';
const UserOfflineHome = () => {
  const [backOnline, setBackOnline] = useState(false);
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    return (() => {
      window.removeEventListener("online", handleOnline);
    })
  }, [])

  function handleOnline() {
    setBackOnline(navigator.onLine);
  }

  return (
    !backOnline ? <div className='modal'>
      <div className='backdrop'></div>
      <div className='user-offline'>
        <div className='uo-left'>
          <Icons.WifiNotFoundIcon />
        </div>
        <div className='uo-right'>
          <h2>No Internet Connection</h2>
          <p>Slow or no internet connections. Please check your internet connection settings and try again.</p>
        </div>
      </div>
    </div> :
      <div className='modal'>
        <div className='backdrop'></div>
        <div id='' className='user-online'>
          <div className='uo-left'>
            <Icons.WifiEnabledIcon />
          </div>
          <div className='uo-right'>
            <h2>Internet Connection Restored</h2>
            <p>Your internet connection is now stable.</p>
          </div>
        </div>
      </div>
  )
}

export default UserOfflineHome