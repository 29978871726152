import React from 'react'
import Icons from '../../assets/icons'

const Annoucement = (props) => {

    const closeModal = () => {
        props.closeModal();
        sessionStorage.removeItem("showEthanacityPopUp");
    }
    return (
        <div className='gen-annoucement-modal'>
            <div className="gam-head"> 
                <div className="gamh-left">
                    <h3>Hey!</h3>
                    <p>You have new announcements</p>
                </div>
                <div className="gamh-right">
                    <a href='https://styleunion.mydurable.com/' target='_blank'>Know More</a>
                    <button className='generic-default-btn' onClick={() => closeModal()}>Close</button>
                </div>
            </div>
            <div className='gam-body'>
                
                <img src="https://i.ibb.co/kyMdVBN/POPUP-GREEN-CHANNEL-NEW-1.jpg" alt="POPUP-GREEN-CHANNEL-NEW-1" />
            </div>
        </div>
    )
}

export default Annoucement