import React from 'react';
import alertIcon from '../../assets/icons/popup-icon.svg';
import { Modal } from 'antd';

export default class ConfirmationModal extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			openModal : true,
		}
	}
	closeModal = () => {
		document.getElementById("newConfirmationModal")?.classList?.remove("from-center-in")
		document.getElementById("newConfirmationModal")?.classList?.add("from-center-out")
		setTimeout(()=>{
			this.setState({
				openModal: false
			})
			this.props.onCancellation()
		}, 250)
	}
	render() {
		return (
			<Modal open={this.state.openModal} footer = {<React.Fragment></React.Fragment>}
				onCancel={this.closeModal}
				// maskTransitionName=""
				transitionName=""		
				modalRender={() => (
					<div className="modal">
					<div className="backdrop" onClick={this.closeModal}></div>
					<div id = "newConfirmationModal" className={`modal-content new-confirmation-modal from-center-in`}>
						<div className="ncm-top">
							<div className="ncmt-head">
								<div className="ncmt-img">
									<img src={alertIcon} />
								</div>
								<div className="ncmt-close">
									<button type="button" onClick={this.closeModal}>
										<svg xmlns="http://www.w3.org/2000/svg" width="18.51" height="18.51" viewBox="0 0 18.51 18.51">
											<path fill="none" d="M0 0h18.51v18.51H0z"/>
											<path fill="#627da8" d="M10.544 9.454l3.818-3.818 1.091 1.091-3.818 3.818 3.818 3.818-1.091 1.091-3.818-3.818-3.817 3.816-1.091-1.09 3.818-3.818-3.818-3.817 1.091-1.091z" transform="translate(-1.289 -1.289)"/>
										</svg>
									</button>
								</div>
							</div>
							<div className="ncmt-body">
								<h2>{this.props.headMsg}</h2>
								<p>{this.props.paraMsg}</p>
							</div>
						</div>
						<div className="ncm-footer">
							<button className="ncm-no" type="button" onClick={this.closeModal}>{this.props.resetButtonLabel || "No, wait"}</button>
							{this.props.loading ?
							<button type="button" className="ncm-yes btn-loader" disabled><div class="spinner-border text-light btn-loader-inner"></div><div className="btn-loader-text">Yes, proceed</div></button> :
							<button className="ncm-yes" type="button" onClick={this.props.onConfirmation}> Yes, proceed</button>}
						</div>
					</div>
				</div>
				)}
			/>
		);
	}
}