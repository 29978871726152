import React, { useEffect, useState } from 'react'
import GenericFormFooterComponent from '../../../genericComponents/forms/GenericFormFooterComponent'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import GenericInput from '../../../genericComponents/input/GenericInputComponent'
import Icons from '../../../assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import Lottie from 'lottie-react'
import JobSuccessTickMarkGreen from '../../../animationsJSON.js/JobSuccessTickMarkGreen.json'
import useCustomToast from '../../../hooks/useCustomToast' 

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: JobSuccessTickMarkGreen,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    style: {
        width: 150,
        height: 150
    }
};

const NewOnboardingRequestModal = (props) => {

    const customToast = useCustomToast()
    const history = useHistory()
    const dispatch = useDispatch()
    const authStore = useSelector(state => state.authStore)

    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredMobileNo, setEnteredMobileNo] = useState('');
    const [errorData, setErrorData] = useState({});
    const [enterpriseData, setEnterpriseData] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        resetData();
    },[])

    const [mode, setMode] = useState(null);

    const handleChange = (type, data) => {
        switch (type) {
            case "fetchUsingEmail":
            case "fetchUsingMobile" :{
                let check = false;
                if(type == "fetchUsingEmail"){
                    if(enteredEmail?.trim() == "" || !/[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/.test(enteredEmail)){
                        setErrorData({"enteredEmail" : "Please enter a valid email to fetch brands"})
                    }else{
                        check = enteredEmail;
                    }
                }else{
                    if(enteredMobileNo?.trim() == "" || !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(enteredMobileNo)){
                        setErrorData({"enteredMobileNo" : "Please enter a valid mobile number to fetch brands"});
                    }else{
                        check = enteredMobileNo;
                    }
                }
                if(check){
                    setMode(type);
                    dispatchHandler(dispatch, 'getAllEnterprisesRequest', {search : check});
                    setErrorData(null);
                }
                break;
            }
            case "handleEnterpriseVendorRequest":
                if (enterpriseData.length) {
                    dispatchHandler(dispatch, 'sendEnterpriseVendorRequest', {
                        "eIds": [enterpriseData[0]?.id]
                    })
                }
                break;
            default:
                break;
        }
    }

    // logic to store the enterprise data
    useEffect(() => {
        if (authStore?.getAllEnterprisesData?.isSuccess && authStore?.getAllEnterprisesData?.data?.resource?.data) {
            if(authStore?.getAllEnterprisesData?.data?.resource?.data?.length == 0){
                customToast({
                    title: "No Data Found!",
                    description: "No organisation found with given parameters.No organisation found with given parameters.",
                    status: "error",
                })
            }
            setEnterpriseData(authStore?.getAllEnterprisesData?.data?.resource?.data || [])
        }
    }, [authStore?.getAllEnterprisesData?.isSuccess])

    // console.log("enterprise data", enterpriseData);/

    // function to render the form contents 
    const renderNewRequestInputs = () => {
        return <div className='mb-5'>
            <GenericInput
                type='email'
                value={enteredEmail}
                placeholder="Brand's email address"
                label='Email'
                iconLeft={<span className='input-svg ml-1 mr-2'><Icons.EmailOTPIcon /></span>}
                onKeyDown = {e => {
                    if(e.key  == "Enter") handleChange("fetchUsingEmail")
                }}
                onChange={(e) => {
                    let updatedErrorData = { ...errorData };
                    delete updatedErrorData["enteredMobileNo"];
                    setErrorData(updatedErrorData)
                    setEnteredEmail(e.target.value)
                }}
                iconRight = {<GenericCustomButton
                    label = "Fetch"
                    onClick={() => handleChange("fetchUsingEmail")}
                    className={'text-xs font-medium text-primary px-2 hover:opacity-75'}
                />}
                key='email'
                genInputExtraClass='placeholder:font-normal !my-0'
            />  
            <p className={`-z-10 duration-200 text-xs text-red-500 mt-1 ${errorData?.["enteredEmail"] ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>{errorData?.["enteredEmail"]}</p>

            <p className='text-center -mb-2.5 mt-[20px]'>OR</p>
            <GenericInput
                type='mobileNo'
                value={enteredMobileNo}
                placeholder="Brand's Mobile Number"
                label='Mobile'
                iconLeft={<span className='input-svg ml-1 mr-2'><Icons.MOBILE /></span>}
                onKeyDown = {e => {
                    if(e.key  == "Enter") handleChange("fetchUsingMobile")
                }}
                onChange={(e) => {
                    let updatedErrorData = { ...errorData };
                    delete updatedErrorData["enteredEmail"];
                    setErrorData(updatedErrorData)
                    setEnteredMobileNo(e.target.value)
                }}
                iconRight = {<GenericCustomButton
                    label = "Fetch"
                    onClick={() => handleChange("fetchUsingMobile")}
                    className={'text-xs font-medium text-primary px-2 hover:opacity-75'}
                />}
                key='mobileNo'
                genInputExtraClass='placeholder:font-normal !my-0'
            />
            <p className={`-z-10 duration-200 text-xs text-red-500 mt-1 ${errorData?.["enteredMobileNo"] ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>{errorData?.["enteredMobileNo"]}</p>
        </div>
    }

    // render enterprise data
    const renderEnterpriseData = () => {
        return (
            showConfirmationModal ? <div className='flex flex-col items-center justify-center gap-2 min-h-[220px]'>
                <div className='green-tick'>
                    <Lottie
                        {...defaultOptions}
                    />
                </div>
                <h2 className='font-semibold text-xl mb-7'>Request Created Successfully</h2>
            </div> :
                <div className='text-[13px]'>
                    <div>
                        <label className='text-xs font-medium mb-1'>{mode =="fetchUsingMobile" ? 'Mobile' : 'Email'}</label>
                        <div className='flex items-center gap-2 border bg-[#F4F5F7] border-[#DAE4EE] p-[5px]'>
                            {mode =="fetchUsingMobile" ? <span className='input-svg'><Icons.PhoneIcon/></span> : <span className='input-svg ml-1 mr-2'><Icons.EmailOTPIcon/></span>}
                            <span className='font-medium'>{mode == "fetchUsingMobile" ? enteredMobileNo : enteredEmail}</span>
                        </div>

                    </div>
                    <div className='border border-[#DAE4EE] p-[12px] mt-5 space-y-3'>
                        <div className='flex gap-5'>
                            <div className='h-[5rem] w-[5rem]'>
                                <img className='w-full h-full object-center object-cover' src={enterpriseData[0]?.orgLogo} alt='logo' />
                            </div>
                            <div className='-mt-1 space-y-2'>
                                <div>
                                    <label className='mb-0 text-[10px] text-[#8496A9]'>Organization Name</label>
                                    <p className='font-semibold leading-4 mb-0'>{enterpriseData[0]?.name || ''}</p>
                                </div>
                                <div>
                                    <label className='mb-0 text-[10px] text-[#8496A9]'>Mobile Number</label>
                                    <p className='leading-4'>{enterpriseData[0]?.mobileNumber || ''}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className='mb-0 text-[10px] text-[#8496A9]'>Registered Address</label>
                            <p className='leading-4'>{enterpriseData[0]?.billToAddress || ''}</p>
                        </div>
                    </div>
                </div>)
    }

    const resetData = () => {
        setEnteredEmail('');
        setEnteredMobileNo('');
        setErrorData({});
        setEnterpriseData([]);
        setShowConfirmationModal(false);
        setMode(null);
    }

    // render buttons
    const renderButtons = () => {
        if (showConfirmationModal) {
            return [
                {
                    label: 'View Requests', onClick: () => {
                        props.closeModal()
                        setShowConfirmationModal(false);
                        history.push("/vendor/request/onboarding")
                    }, type: "normal", className: "generic-approve-btn"
                },
                {
                    label: 'Create New Request', onClick: () => {
                       resetData();
                    }, type: "normal", className: "generic-approve-btn"
                }
            ];
        }

        if (enterpriseData.length !== 0) {
            return [
                {label : "Back", disabled : null, type : "default", onClick : () => {
                    resetData();
                }},
                { label: "Send Request", disabled: null, type: "primary", onClick: () => handleChange('handleEnterpriseVendorRequest'), sendEventAnalyticsToGoogle : true },
            ];
        }
    };
    
    useEffect(() => {
        if (authStore?.sendEnterpriseVendorData?.isSuccess) { setShowConfirmationModal(true) }
    }, [authStore?.sendEnterpriseVendorData?.isSuccess])

    return (
        <div className='vendorOnboardingRequestModal'>
            {
             (enterpriseData.length == 0 || !showConfirmationModal) &&
                <div className='bg-white sticky top-0 px-2'>
                    <GenericFormHeaderComponent
                        label={{
                            contentPrimary: "Create New Onboarding Request",
                            contentSecondry: "You haven't associated with any brands or retailers yet. To get started, create a new onboarding request by clicking the button below."
                        }}
                        closeModal={props.closeModal}
                    />
                </div>
            }
            <div className={`px-6 overflow-y-auto min-h-[220px]`}>

            {enterpriseData.length > 0 ? renderEnterpriseData() : renderNewRequestInputs()}

            {errorData?.["formErrorData"] && errorData?.["formErrorData"]}

            </div>

            {enterpriseData?.length > 0 && <div className='sticky bottom-0 bg-white px-2'><GenericFormFooterComponent
                buttons={[
                    (enterpriseData?.length > 0 && showConfirmationModal) &&
                     { label: "Close", type: "default", onClick: () => {
                        resetData();
                        props.closeModal()
                    } },
                    ...renderButtons(),
                ]}
            /></div>}

        </div>
    )
}

export default NewOnboardingRequestModal