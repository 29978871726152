export default function script(response) {
  const respData = response.data ? response.data : {};
    console.log("check234234",(respData.status == "2000" || response?.status == "200" ||  response?.status == "201") && respData.error == null && respData?.status != "4020");
    if ((respData.status == "2000" || response?.status == "200" ||  response?.status == "201") && respData.error == null && respData?.status != "4020") {
      let success = true;
      let failure = false;
  
      respData.success = success;
      respData.failure = failure;
  
      return respData;
    } else {
      let success = false;
      let failure = true;
  
      respData.success = success;
      respData.failure = failure;
  
      return respData;
    }
  }
  