import React from 'react'
import { useSelector } from 'react-redux'
import Icons from '../../../assets/icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import TableSkeleton from './TableSkeleton'
import { parseJwt } from '../../../helper/genericFunction'
import LanguageTranslator from '../../../locals/LanguageTranslator'

const BottomTableData = (props) => {
    const { bottomDashboardData, toggleType, upperDashboardData } = props
    let uType = parseJwt(sessionStorage?.getItem('token'))?.uType || "ENT";
    const isENT = uType === 'ENT'

    const history = useHistory()
    const digivendStore = useSelector(state => state.digivendStore)

    const bottomCards = bottomDashboardData?.highlights?.lowerDataV2 || []
    const upperCardData = upperDashboardData?.upperDataV2 || []
    let vendorPOConfirmation = digivendStore?.getSystemConfigKeysData?.data?.resource?.vendorPOConfirmation === 'TRUE' ? true : false;
    let isQcOn = digivendStore?.getSystemConfigKeysData?.data?.resource?.inspectionRequired

    const TableData = [
        vendorPOConfirmation && bottomCards?.po_conf_count !== 0 && {
            label: <div className="shipment-table-ot">
                <LanguageTranslator tag="label">Confirmed POs</LanguageTranslator>
                {upperCardData && isENT ? bottomCards?.within_po_conf_per : bottomCards?.outside_po_conf_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                {
                                    pathname: isENT ? '/enterprise/purchaseOrder/pendingOrders' : '/vendor/purchaseOrder/pendingOrders',
                                    search: '?status=%2522APPROVED%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522'
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                }
            )}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.po_conf_amount_hover : bottomCards?.po_conf_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.po_conf_amount : bottomCards?.po_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522'
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                }
            )} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.po_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_po_conf_amt_hover : bottomCards?.within_po_conf_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_po_conf_amt : bottomCards?.within_po_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div className='amount' onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_po_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_po_conf_amt_hover : bottomCards?.outside_po_conf_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_po_conf_amt : bottomCards?.outside_po_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522APPROVED%2522',
                    state: { id: "outside" }
                }
            )}>
                <div className='count-second flex items-center justify-between'>
                    <LanguageTranslator tag="label">{bottomCards?.outside_po_conf_count}</LanguageTranslator>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        vendorPOConfirmation && bottomCards?.po_pen_count !== 0 && {
            label: <div className="shipment-table-ot">
                <LanguageTranslator tag='label'> {isENT ? 'Pending PO' : 'Awaiting PO'}</LanguageTranslator>
                {upperCardData && bottomCards?.outside_po_pen_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/purchaseOrder/pendingOrders',
                                    search: '?status=%2522PENDING_APPROVAL%2522',
                                    state: { id: "outside" }
                                }
                            )}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.po_pen_amount_hover : bottomCards?.po_pen_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.po_pen_amount : bottomCards?.po_pen_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.po_pen_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_po_pen_amt_hover : bottomCards?.within_po_pen_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_po_pen_amt : bottomCards?.within_po_pen_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_po_pen_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_po_pen_amt_hover : bottomCards?.outside_po_pen_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_po_pen_amt : bottomCards?.outside_po_pen_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: '?filter=%257B%2522status%2522%253A%2522PENDING_APPROVAL%2522%257D',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: '?status=%2522PENDING_APPROVAL%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_po_pen_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        vendorPOConfirmation && bottomCards?.po_rej_count !== 0 && {
            label: <LanguageTranslator tag="label">Rejected POs</LanguageTranslator>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: "?status=%2522REJECTED%2522"
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: "?status=%2522REJECTED%2522"
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.po_rej_amount_hover : bottomCards?.po_rej_qty_hover}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.po_rej_amount : bottomCards?.po_rej_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/purchaseOrder/pendingOrders',
                    search: "?status=%2522REJECTED%2522"
                } : {
                    pathname: '/vendor/purchaseOrder/pendingOrders',
                    search: "?status=%2522REJECTED%2522"
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.po_rej_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <label></label>,
            td4: <label></label>,
            td5: <label></label>,
            td6: <label></label>,
        },

        {
            label: <div className="ship-ot-head">
                <LanguageTranslator tag="label">Shipments</LanguageTranslator>
            </div>,
            td1: <label></label>,
            td2: <label></label>,
            td3: <label></label>,
            td4: <label></label>,
            td5: <label></label>,
            td6: <label></label>,
        },
        bottomCards?.pen_asn_count !== 0 && {
            label: <div className="shipment-table-ot">
                <LanguageTranslator tag="label">ASN Under Approval</LanguageTranslator>
                {upperCardData && bottomCards?.outside_pen_asn_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/shipment/asnUnderApproval',
                                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/shipment/asnUnderApproval',
                                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.pen_asn_amount_hv : bottomCards?.pen_asn_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.pen_asn_amount : bottomCards?.pen_asn_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.pen_asn_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_pen_asn_amt_hv : bottomCards?.within_pen_asn_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_pen_asn_amt : bottomCards?.within_pen_asn_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_pen_asn_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_pen_asn_amt_hv : bottomCards?.outside_pen_asn_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_pen_asn_amt : bottomCards?.outside_pen_asn_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_pen_asn_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        bottomCards?.asn_conf_count !== 0 && {
            label: <div className="shipment-table-ot">
                <LanguageTranslator tag="label">ASN Approved & Under Process</LanguageTranslator>
                {upperCardData && bottomCards?.outside_asn_conf_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/shipment/asnUnderApproval',
                                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/shipment/asnUnderApproval',
                                    search: '?status=%2522SHIPMENT_REQUESTED%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.asn_conf_amount_hv : bottomCards?.asn_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.asn_conf_amount : bottomCards?.asn_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.asn_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_asn_conf_amt_hv : bottomCards?.within_asn_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_asn_conf_amt : bottomCards?.within_asn_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_asn_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_asn_conf_amt_hv : bottomCards?.outside_asn_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_asn_conf_amt : bottomCards?.outside_asn_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/asnUnderApproval',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/shipment/approvedAsn',
                    search: '?status=%2522SHIPMENT_CONFIRMED%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_asn_conf_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        bottomCards?.asn_cancel_count !== 0 && {
            label: <div className="shipment-table-ot">
                <LanguageTranslator tag="label">Rejected ASN</LanguageTranslator>
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/cancelledAsn',
                    search: '?status=%2522SHIPMENT_CANCELLED%2522',
                } : {
                    pathname: '/vendor/shipment/cancelledAsn'
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.asn_cancel_amount_hv : bottomCards?.asn_cancel_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.asn_cancel_amount : bottomCards?.asn_cancel_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/shipment/cancelledAsn',
                    search: '?status=%2522SHIPMENT_CANCELLED%2522',
                } : { pathname: '/vendor/shipment/cancelledAsn' })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.asn_cancel_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <label></label>,
            td4: <label></label>,
            td5: <label></label>,
            td6: <label></label>,
        },

        {
            label: <div className='log-table-ot'>
                <LanguageTranslator tag="label">Logistics</LanguageTranslator>
            </div>,
            td1: <label></label>,
            td2: <label></label>,
            td3: <label></label>,
            td4: <label></label>,
            td5: <label></label>,
            td6: <label></label>
        },
        bottomCards?.lr_req_count !== 0 && {
            label: <div className="shipment-table-ot">
                <div className='logic-ot'>
                    <LanguageTranslator tag="label">Invoices Pending for Approval</LanguageTranslator>
                    <LanguageTranslator tag="span">LR Processing</LanguageTranslator>
                </div>
                {upperCardData && bottomCards?.outside_lr_req_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/logistics/lrProcessing',
                                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/logistics/lrProcessing',
                                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.lr_req_amount_hv : bottomCards?.lr_req_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.lr_req_amount : bottomCards?.lr_req_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.lr_req_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_lr_req_amt_hv : bottomCards?.within_lr_req_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_lr_req_amt : bottomCards?.within_lr_req_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_lr_req_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_lr_req_amt_hv : bottomCards?.outside_lr_req_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_lr_req_amt : bottomCards?.outside_lr_req_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_INVOICE_REQUESTED%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_lr_req_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        bottomCards?.lr_conf_count !== 0 && {
            label: <div className="shipment-table-ot">
                <div className='logic-ot'>
                    <LanguageTranslator tag="label">Approved Invoices</LanguageTranslator>
                    <span>LR Processing</span>
                </div>
                {upperCardData && bottomCards?.outside_lr_conf_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/logistics/lrProcessing',
                                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/logistics/lrProcessing',
                                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.lr_conf_amount_hv : bottomCards?.lr_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.lr_conf_amount : bottomCards?.lr_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.lr_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_lr_conf_amt_hv : bottomCards?.within_lr_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_lr_conf_amt : bottomCards?.within_lr_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_lr_conf_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_lr_conf_amt_hv : bottomCards?.outside_lr_conf_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_lr_conf_amt : bottomCards?.outside_lr_conf_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/lrProcessing',
                    search: '?status=%2522SHIPMENT_SHIPPED%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_lr_conf_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        },
        bottomCards?.lr_count !== 0 && {
            label: <div className="shipment-table-ot">
                <div className='logic-ot'>
                    <LanguageTranslator tag="label">On Your Way For Delivery</LanguageTranslator>
                    <LanguageTranslator tag="span">Message to Vendor</LanguageTranslator>
                </div>
                {upperCardData && bottomCards?.outside_lr_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                    <Icons.DelayIcon />
                    <span className='generic-tooltip'>
                        <div className='ot-tooltip'>
                            <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                            <u onClick={() => history.push(
                                isENT ? {
                                    pathname: '/enterprise/logistics/goodsIntransit',
                                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                                    state: { id: "outside" }
                                } : {
                                    pathname: '/vendor/logistics/goodsIntransit',
                                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                                    state: { id: "outside" }
                                })}><LanguageTranslator>Review Now</LanguageTranslator></u>
                        </div>
                    </span>
                </span>}
            </div>,
            td1: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.lr_amount_hv : bottomCards?.lr_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.lr_amount : bottomCards?.lr_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td2: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                })} className='count-second flex items-center justify-between'>
                <label>{bottomCards?.lr_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td3: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "within" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_lr_amt_hv : bottomCards?.within_lr_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_lr_amt : bottomCards?.within_lr_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "within" }
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "within" }
                })}>
                <label>{bottomCards?.within_lr_count}</label>
                <div className='review-tool'>
                    <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                </div>
            </div>,
            td5: <div className='trc-first' onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "outside" }
                })}>
                <div className='amount'>
                    <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_lr_amt_hv : bottomCards?.outside_lr_qty_hv}</label>
                    <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_lr_amt : bottomCards?.outside_lr_qty}</span>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
            td6: <div onClick={() => history.push(
                isENT ? {
                    pathname: '/enterprise/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "outside" }
                } : {
                    pathname: '/vendor/logistics/goodsIntransit',
                    search: '?status=%2522SHIPMENT_INTRANSIT%2522',
                    state: { id: "outside" }
                })}>
                <div className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.outside_lr_count}</label>
                    <div className='review-tool'>
                        <Icons.ArrowReviewSvg />
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>
            </div>,
        }
    ]

    if (isQcOn === 'TRUE' && isENT) {
        TableData.push(
            {
                label: <div className="ship-ot-head">
                    <LanguageTranslator tag='label'>Inspection Status</LanguageTranslator>
                </div>,
                td1: <label></label>,
                td2: <label></label>,
                td3: <label></label>,
                td4: <label></label>,
                td5: <label></label>,
                td6: <label></label>,
            },
            bottomCards?.pen_qc_count !== 0 && {
                label: <div className="shipment-table-ot">
                    <div className='logic-ot'>
                        <LanguageTranslator tag="label">Inspection Requested</LanguageTranslator>
                        <LanguageTranslator tag="span">Pending Inspection</LanguageTranslator>
                    </div>
                    {upperCardData && bottomCards?.outside_po_conf_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                        <Icons.DelayIcon />
                        <span className='generic-tooltip'>
                            <div className='ot-tooltip'>
                                <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                                <u onClick={() => history.push(
                                    {
                                        pathname: '/enterprise/qualityCheck/pendingQc',
                                        search: '?status=%2522PENDING_QC%2522',
                                        state: { id: "outside" }
                                    }
                                )}><LanguageTranslator>Review Now</LanguageTranslator></u>
                            </div>
                        </span>
                    </span>}
                </div>,
                td1: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: {}
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.pen_qc_amount_hv : bottomCards?.pen_qc_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.pen_qc_amount : bottomCards?.pen_qc_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td2: <div onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: {}
                    })} className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.pen_qc_count}</label>
                    <div className='review-tool'>
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>,
                td3: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: { id: "within" }
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_pen_qc_amt_hv : bottomCards?.within_pen_qc_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_pen_qc_amt : bottomCards?.within_pen_qc_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: { id: "within" }
                    })}>
                    <label>{bottomCards?.within_pen_qc_count}</label>
                    <div className='review-tool'>
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>,
                td5: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: { id: "outside" }
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_pen_qc_amt_hv : bottomCards?.outside_pen_qc_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_pen_qc_amt : bottomCards?.outside_pen_qc_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td6: <div onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC%2522',
                        state: { id: "outside" }
                    })}>
                    <div className='count-second flex items-center justify-between'>
                        <label>{bottomCards?.outside_pen_qc_count}</label>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
            },
            bottomCards?.qc_conf_count !== 0 && {
                label: <div className="shipment-table-ot">
                    <div className='logic-ot'>
                        <LanguageTranslator tag="label">Inspection Required</LanguageTranslator>
                        <LanguageTranslator tag="span">Pending Inspection</LanguageTranslator>
                    </div>
                    {upperCardData && bottomCards?.outside_qc_conf_per >= upperCardData?.maxPercentage && <span className='ot-icon'>
                        <Icons.DelayIcon />
                        <span className='generic-tooltip'>
                            <div className='ot-tooltip'>
                                <LanguageTranslator tag="p">Need Attention ! A major part of orders is not moving as expected.</LanguageTranslator>
                                <u onClick={() => history.push(
                                    {
                                        pathname: '/enterprise/qualityCheck/pendingQc',
                                        search: '?status=%2522PENDING_QC_CONFIRM%2522',
                                        state: { id: "outside" }
                                    }
                                )}><LanguageTranslator>Review Now</LanguageTranslator></u>
                            </div>
                        </span>
                    </span>}
                </div>,
                td1: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522',
                        state: {}
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.qc_conf_amount_hv : bottomCards?.qc_conf_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.qc_conf_amount : bottomCards?.qc_conf_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td2: <div onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522',
                        state: {}
                    })} className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.qc_conf_count}</label>
                    <div className='review-tool'>
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>,
                td3: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522&displayName=%2522ENT_PENDING_QC_CONFIRM%2522',
                        state: { id: "within" }
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.within_qc_conf_amt_hv : bottomCards?.within_qc_conf_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.within_qc_conf_amt : bottomCards?.within_qc_conf_qty}</span>
                        <div className='review-tool'>
                            {/* <Icons.ArrowReviewSvg /> */}
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td4: <div onClick={bottomCards?.within_po_conf_count === 0 ? null : () => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522&displayName=%2522ENT_PENDING_QC_CONFIRM%2522',
                        state: { id: "within" }
                    })}>
                    <label>{bottomCards?.within_qc_conf_count}</label>
                    <div className='review-tool'>
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>,
                td5: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522&displayName=%2522ENT_PENDING_QC_CONFIRM%2522',
                        state: { id: "outside" }
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.outside_qc_conf_amt_hv : bottomCards?.outside_qc_conf_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.outside_qc_conf_amt : bottomCards?.outside_qc_conf_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td6: <div onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/pendingQc',
                        search: '?status=%2522PENDING_QC_CONFIRM%2522&displayName=%2522ENT_PENDING_QC_CONFIRM%2522',
                        state: { id: "outside" }
                    })}>
                    <div className='count-second flex items-center justify-between'>
                        <label>{bottomCards?.outside_qc_conf_count}</label>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
            },
            bottomCards?.qc_cancel_count !== 0 && {
                label: <LanguageTranslator tag='label'>Cancelled Inspection</LanguageTranslator>,
                td1: <div className='trc-first' onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/cancelledInspection',
                        search: "?status=%2522SHIPMENT_INSPECTION_CANCELLED%2522",
                        state: { upper: "upper" }
                    })}>
                    <div className='amount'>
                        <span>{toggleType === 'amountWise' && '₹'}</span><label>{toggleType === 'amountWise' ? bottomCards?.qc_cancel_amount_hv : bottomCards?.qc_cancel_qty_hv}</label>
                        <span className='generic-tooltip'>{toggleType === 'amountWise' ? bottomCards?.qc_cancel_amount : bottomCards?.qc_cancel_qty}</span>
                        <div className='review-tool'>
                            <Icons.ArrowReviewSvg />
                            <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                        </div>
                    </div>
                </div>,
                td2: <div onClick={() => history.push(
                    {
                        pathname: '/enterprise/qualityCheck/cancelledInspection',
                        search: "?status=%2522SHIPMENT_INSPECTION_CANCELLED%2522",
                        state: { upper: "upper" }
                    })} className='count-second flex items-center justify-between'>
                    <label>{bottomCards?.qc_cancel_count}</label>
                    <div className='review-tool'>
                        <LanguageTranslator tag="span" className='generic-tooltip'>Review Now</LanguageTranslator>
                    </div>
                </div>,
                td3: <label></label>,
                td4: <label></label>,
                td5: <label></label>,
                td6: <label></label>,
            },
        )
    }

    return (
        <React.Fragment>
            {digivendStore?.getOrderTrackingDashboardBottomDetailsData?.isLoading ? (<TableSkeleton />) :
                (
                    <table>
                        <thead>
                            <tr>
                                {vendorPOConfirmation ? <th rowSpan="2"><LanguageTranslator tag="label">Orders</LanguageTranslator></th> : <th rowSpan="2"><label></label></th>}
                                <th colSpan="2"><LanguageTranslator tag="label">Total</LanguageTranslator></th>
                                <th colSpan="2"><LanguageTranslator tag="label">On-Track</LanguageTranslator></th>
                                <th colSpan="2">
                                    <div className='del-head-ot'>
                                        <LanguageTranslator tag="label">Delayed</LanguageTranslator>
                                        <Icons.DelayIcon />
                                    </div>
                                </th>
                            </tr>
                            <tr className='osl-sub-head'>
                                <th><LanguageTranslator tag="label">{toggleType === "amountWise" ? "Amount" : "Quantity"}</LanguageTranslator></th>
                                <th><LanguageTranslator tag="label">Count</LanguageTranslator></th>
                                <th><LanguageTranslator tag="label">{toggleType === "amountWise" ? "Amount" : "Quantity"}</LanguageTranslator></th>
                                <th><LanguageTranslator tag="label">Count</LanguageTranslator></th>
                                <th><LanguageTranslator tag="label">{toggleType === "amountWise" ? "Amount" : "Quantity"}</LanguageTranslator></th>
                                <th><LanguageTranslator tag="label">Count</LanguageTranslator></th>
                            </tr>
                        </thead>
                        <tbody>
                            {TableData.filter(row => row).map((tableRow, index) => (
                                <tr key={index}>
                                    <LanguageTranslator tag="td" className='othead'>{tableRow?.label}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='total-qc'>{tableRow?.td1}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='total-qc'>{tableRow?.td2}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='otqty'>{tableRow?.td3}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='otqcount'>{tableRow?.td4}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='dey-qty'>{tableRow?.td5}</LanguageTranslator>
                                    <LanguageTranslator tag="td" className='dey-count'>{tableRow?.td6}</LanguageTranslator>
                                </tr>
                            ))}
                        </tbody>
                    </table>)}
        </React.Fragment>
    )
}

export default BottomTableData