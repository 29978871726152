export const whHeadersData = {
    "item": {
        "value_name": 'Level Name',
        "capacity": 'Defined Capacity',
        "noofasn": 'Number of ASN',
        "qty": 'Qty',
        "noofpendingasn": 'Pending ASN',
        'pendingqty': 'Pending Qty',
        "cartons" : "No. Of Cartons",
        'contribution': 'Contribution %',
        
    },
    "vendor": {
        "vendor_name": "Vendor Name",
        "vendorcode": "Vendor Code",
        "vendor_city_name": "Vendor City",
        "noofasn": 'Number of ASN',
        "qty": 'Qty',
        'fulfillment': 'Fulfilment',

    }
}