import axios from "axios";
axios.defaults.timeout = 90000;
export default function fireAjax(method, URL, data, token, newHeader) {
  
  let requestId = sessionStorage?.getItem("X-REQUEST-ID");

  // We don't  need to send the request id in these cases
  if(["qaauth", "prodauth", "devauth"]?.some(item => URL?.includes(item))){
    requestId = undefined;
  }

  if (method === "POST") {
    let config = { 
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "multipart/form-data",
        "X-Auth-Token": token,
        "Page-Link": window.location.hash.split("?")[0] || undefined,
        "X-REQUEST-ID" : requestId || undefined,
        ...newHeader,
      },
      // cancelToken: cancelTokenSource.token, // Pass the cancel token to the request
    };
    return axios.post(encodeURI(URL), data, config);
  } else if (method === "GET") {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
        "Page-Link": window.location.hash.split("?")[0] || undefined,
        "X-REQUEST-ID" : requestId || undefined,
        ...newHeader,
      },
    };
    return axios.get(encodeURI(URL), config);
  } else if (method === "PUT") {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
        "Page-Link": window.location.hash.split("?")[0] || undefined,
        "X-REQUEST-ID" : requestId || undefined,
        ...newHeader,
      },
    };
    return axios.put(encodeURI(URL), data, config);
  }
}
