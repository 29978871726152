import React from 'react'
import '../../styles/stylesheets/listComponent.scss'

const GenericSummarySkeleton = (props) => {
    const divsArray = Array.from({ length: props.count || 3 }, index => index + 1);
    let width = 20;
    if(props.count>5){
        width = 100/(props.count)
    }
    return (
        <div className="m-lft-30 m-rgt-15">
            <div className='flex bg-white m-top-10 rounded-md p-top-10 p-lft-10'>
                {divsArray.map(() => (
                    <div className={`w-${width} p-rgt-10`}>
                        <div className='generic-skeleton w-50'></div>
                        <div className='generic-skeleton'></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GenericSummarySkeleton