import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom';
import Sidebar from './home/Sidebar';
import SupplymintHeader from './home/SupplymintHeader';
import { stacks, stackNames } from '../constants/routingStack'
import ParentModal from '../genericComponents/ParentModal';
import Dashboard from './dashboard';
import Icons from '../assets/icons';
import CreateTicketModal from './helpSection/CreateTicketModal';
import '../styles/stylesheets/home/Dashboard.scss'
import html2canvas from 'html2canvas'
import UploadExcel from './uploadExcel/UploadExcel';
import { CreateFilterAndHeaderManagement } from '../authComponents/CreateFilterAndHeaderManagement';
import '../styles/stylesheets/helper.scss'
import FullPageLoader from '../loaders/FullPageLoader';
import AuditLogs from './AuditLogs';
import UserProfileHome from './home/profileSection/userProfile/UserProfileHome';
import ChangeSetting from './home/profileSection/changeSetting/ChangeSetting';
import { parseJwt } from '../helper/genericFunction';
import Annoucement from './helpSection/Annoucement';
import DefaultPopUp from './defaultPopUp/DefaultPopUp';
import SupportTickets from './superAdmin/SupportTickets';
import SocketComponent from '../socket/SocketComponent';
import { connectWithSocketConnection, disconnectWithSocketConnection, getSocketConnection } from '../socket/socketHelperConnection';
import LanguageTranslator from '../locals/LanguageTranslator';
import LanguagePopup from '../locals/LanguagePopup';
import { CONFIG } from '../config';
import { dispatchHandler } from '../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import GenericSpinnerLoader from '../loaders/GenericSpinnerLoader';
import { UserDetailsContext } from '../App';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getUpdatedModulesObject } from '../authComponents/login/LoginApiResponse';
import {CombinedProvider} from './contextProviders/CombinedContextProvider';
import {ContextHandlers} from './contextProviders/ContextHandlers';

import { GoogleAnalytics } from '../helper/components/GoogleAnalytics';
import Comments from './digiVend/Comments/Comments';
const NoPageFound = React.lazy(() => import('./helperComponents/NoPageFound'))
const ExcelUploadLogs = React.lazy(() => import('./uploadExcel/ExcelUploadLogs'));
const DataExportLogs = React.lazy(() => import('./DataExportLogs'));

const CommentContext = React.createContext({
	unreadComments: null,
})

const AppStack = (props) => {
	const [selectedModal, setSelectedModal] = useState("");
	const [ssFile, setSsFile] = useState("");
	const history = useHistory()
	const dispatch = useDispatch();
	const location = useLocation();

	// State : To store the unread comments data;
	const [unreadComments, setUnreadComments] = useState(null);
	const [userConfigLoading, setUserConfigLoading] = useState({
		"ent_details" : true,
		"ent_modules" : true,
	});
	let getCurrrentUserEntModulesData = useSelector(state => state?.authStore?.getCurrrentUserEntModulesData);
	let getCurrrentUserEntDetailsData = useSelector(state => state?.authStore?.getCurrrentUserEntDetailsData);

	const [errorData, setErrorData] = useState({});
	let {userDetails, setUserDetails} = useContext(UserDetailsContext)
	useEffect(() => {
		getUnreadCommentCountForThisUser();
	}, [])


	const getUnreadCommentCountForThisUser = async () => {
		// on the mount of this component
		await fetch(`${CONFIG?.NOTIFICATION_ROUTE_PROD}/comment/getBatchedUnreadCommentCount`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-AUTH-TOKEN": sessionStorage.getItem("token"),
			},
		})
			?.then(response => response?.json())
			?.then(response => {
				console.log("unread comments", response);
				if (response?.status === 200) {
					setUnreadComments(response?.data || {});
				}
			})
	}

	const getUserInformation = async () => {
		// on the mount of this component
		dispatchHandler(dispatch, "getCurrrentUserEntDetailsRequest", {
			flowType: "ENT_DETAILS",
			token: sessionStorage.getItem("token"),
		})
		dispatchHandler(dispatch, "getCurrrentUserEntModulesRequest", {
			flowType: "MODULES",
			token: sessionStorage.getItem("token"),
		})
	}

	useEffect(() => {
		getUnreadCommentCountForThisUser();

		getUserInformation()
	}, [])

	useEffect(() => {
		console.log("check234234",getCurrrentUserEntModulesData?.isSuccess);
		if(getCurrrentUserEntModulesData?.isSuccess){
			let data = getCurrrentUserEntModulesData?.data?.resource || {};
			let tokenData = parseJwt(sessionStorage.getItem("token"));
			setUserDetails(prev => {
				if(tokenData?.vendorOnboardingPage === 1 && tokenData?.user?.enterprises?.[0]?.eid == -1){
					prev["modules"] = JSON.stringify([])
					prev["dashboardModules"] = JSON.stringify([]);
				}else{
					prev["modules"] = JSON.stringify(getUpdatedModulesObject(data?.["modules"]?.[0]) || [])
					prev["dashboardModules"] = JSON.stringify(data?.["dashboardModules"] || []);
				}
				return prev;
			})
			setUserConfigLoading(prev => {
				delete prev?.["ent_modules"];
				return prev;
			})
			setErrorData(errorData == null ? {} : {})
		}

		if(getCurrrentUserEntModulesData?.isError){
			setUserConfigLoading(prev => {
				delete prev?.["ent_modules"];
				return prev;
			})
			setErrorData(errorData == null ? {} : {})
		}
	},[getCurrrentUserEntModulesData])

	useEffect(() => {
		if(getCurrrentUserEntDetailsData?.isSuccess){
			let data = getCurrrentUserEntDetailsData?.data?.resource || {};
			setUserDetails(prev => {
				let updatedData = prev?.["loginResponse"] ? JSON.parse(prev?.["loginResponse"]) : {};
				prev["loginResponse"] = JSON.stringify({
					...(updatedData || {}),
					entDetails : data?.["entDetails"]
				})
				return prev;
			})
			setErrorData(errorData == null ? {} : {})
			setUserConfigLoading(prev => {
				delete prev?.["ent_details"];
				return prev;
			})
		}

		if(getCurrrentUserEntDetailsData?.isError){
			setUserConfigLoading(prev => {
				delete prev?.["ent_details"];
				return prev;
			})
			setErrorData(errorData == null ? {} : {})
		}
	},[getCurrrentUserEntDetailsData])

	console.log("User Details", userDetails, userConfigLoading);

	useEffect(() => {
		let socket = getSocketConnection();
		if (socket?.connected) {
			socket.on("new_comment_addition", (data) => {
				if (data?.documentId && data?.documentNumber) {
					let updatedUnreadComments = { ...unreadComments };
					if (!updatedUnreadComments) { updatedUnreadComments = {} }
					updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] = Number(updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] || "") + 1;
					setUnreadComments(updatedUnreadComments);
				}
			})
		}
		return () => {
			let socket = getSocketConnection();
			if (socket?.connected) {
				socket.off("new_comment_addition");
			}
		}
	},[])

	// To handle the selection of modal
	console.log("Unread comments", unreadComments);

	// To provide the routing routes
	const renderStackRouting = (stackName, type) => {
		return stacks?.[stackName]?.[type]?.map((path) => (
			<Route exact path={path} component={stackNames?.[type]} />
		))
	}

	useEffect(() => {
		connectWithSocketConnection({
			history,
			location,
			setUnreadComments,
			unreadComments
		});
	
		const socket = getSocketConnection();
	
		const handleConnect = () => {
			socket.on("new_comment_addition", (data) => {
				if (data?.documentId && data?.documentNumber) {
					let updatedUnreadComments = { ...unreadComments };
					if (!updatedUnreadComments) { updatedUnreadComments = {} }
					updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] = 
						Number(updatedUnreadComments[`${data?.["documentId"]}-${data?.["documentNumber"]}`] || "") + 1;
					setUnreadComments(updatedUnreadComments);
				}
			});
		};
	
		// Attach the event listener only after socket connects
		if (socket?.connected) {
			handleConnect();
		} else {
			socket.on('connect', handleConnect); // Attach listener on 'connect' event
		}
	
		return () => {
			if (socket) {
				socket.off("show_notification");
				socket.off("new_comment_addition");
			}
			disconnectWithSocketConnection();
		};
	}, []);  // Dependency on unreadComments
	

	const renderRouting = () => {
		let modules = JSON.parse(userDetails?.modules || "[]");
		let allModulesNames = modules?.map(item => item?.code);
		return allModulesNames?.map(stackName => Object.keys(stacks?.[stackName] || {})?.length > 0 && (
			// If the stack is available for the given routes;
			Object.keys(stacks?.[stackName])?.map(key => (
				renderStackRouting(stackName, key)
			))
		))
	}
	useEffect(() => {
		let parseToken = parseJwt(sessionStorage.getItem('token'))
		if (sessionStorage.getItem("showEthanacityPopUp") && parseToken?.schemaEntID == "3152")
			console.log("Parse Token", parseToken);
		if (sessionStorage.getItem("showEthanacityPopUp") && parseToken?.schemaEntID == "3152" && parseToken?.uType?.toUpperCase() == "VENDOR")
			setSelectedModal("annoucement");
	}, [])

	const getParentChildProps = () => {
		switch (selectedModal) {
			case "createTicketModal": {
				return {
					closeModal: () => { setSelectedModal(false) },
					postitionProps: { top: "15%", left: "22%", margin: 0, position: "fixed", borderRadius: 4 },
					dimensions: { width: "56vw", height: "70vh" },
					animationType: "center",
					ssFile: ssFile
				}
			}
			case "annoucement": {
				return {
					closeModal: () => { setSelectedModal(false) },
					postitionProps: { top: "10%", left: "25%", margin: 0, borderRadius: 4 },
					dimensions: { width: "50vw", height: "80vh" },
					animationType: "center",
				}
			}
			default:
		}
	}

	const getChildComponent = () => {
		switch (selectedModal) {
			case "createTicketModal": return CreateTicketModal;
			case "annoucement": return Annoucement
			default:
		}
	}

	const captureSS = () => {
		// $("#create_new_ticket").css("visibility", "hidden");
		let app = document.getElementById("app");
		html2canvas(app).then(canvas => {
			canvas.toBlob(blob => {
				let time = (new Date()).getTime();
				let fileName = `SMSS_${time}.png`;
				let updatedSsFile = new File([blob], fileName, { type: "image/png" });
				setSsFile(updatedSsFile)
				// $("#create_new_ticket").css("visibility", "visible")
			});
		})
	}

	const handleChange = (type, data) => {
		switch (type) {
			case "createTicketModal": {
				captureSS();
				setSelectedModal(type);
			}
		}

	}

	if(userConfigLoading?.["ent_details"] || userConfigLoading?.["ent_modules"]){
		return <div className='w-full h-screen bg-white flex items-center justify-center'>
			<div className='space-y-2 text-center'>
				<Icons.InfiniteLoop className='h-[30px] w-auto mx-auto mb-5'/>
				<p className='text-fontBlack text-[19px] font-semibold mb-0'>Loading Your Experience...</p>
				<p className='text-fontGrey text-[13px]'>“ We're getting things ready for you! ”</p>
			</div>
		</div>
	}

	return (
		<CommentContext.Provider value={{
			unreadComments: unreadComments,
			setUnreadComments: (val) => setUnreadComments(val),
			getUnreadCommentCountForThisUser: getUnreadCommentCountForThisUser
		}}>
			<CombinedProvider>
				<Suspense fallback={<FullPageLoader />}>

				{/* The Context Handler Comes here */}
				<ContextHandlers/>
				{/* To send the google analytics */}
				{/* process.env.REACT_APP_NODE_ENV === "production" && */}
				{ <GoogleAnalytics/>}

				{/* To handle the language pop up */}
				<LanguagePopup type={2} />

					{/* To handle the language pop up */}
					<LanguagePopup type={2} />

					{/* Component : To handle the default pop up modals when the user comes to the application */}
					<DefaultPopUp />

					{/* To Handle the Filter and Headers api response */}
					<CreateFilterAndHeaderManagement />


					{/* Render the Side Bar Component */}
					<Sidebar />

					{/* Render the socket component for all realtine actions inside this */}
					<SocketComponent />

					{/* Render the Top Headers of Supplymint Portal */}
					<SupplymintHeader unreadComments={unreadComments} setCurrentDocumentState={props.setCurrentDocumentState} createNewTicket={() => handleChange("createTicketModal")} {...props} />

					<div className='table-component-div1'>
						{/* Set up the module routes */}
						<Switch>
							{renderRouting()}
							<Route exact path="/excel-upload" component={UploadExcel} />
							<Route exact path="/excel-upload-logs" component={ExcelUploadLogs} />
							<Route exact path="/dataExportLogs" component={DataExportLogs} />
							<Route exact path="/excel-upload-proc" render={() => <UploadExcel isFromProc={true} />} />
							<Route exact path="/auditLogs" component={AuditLogs} />
							<Route path='/home' component={Dashboard} />
							<Route path="/profile" component={UserProfileHome} />
							<Route path="/changeSetting" component={ChangeSetting} />
							<Route exact path="/settings/manageSupportTicket" render={() => <SupportTickets type="header" />} />
							<Route exact path="/central/comment" render={() => <Comments />} />

							<Route path='*' component={NoPageFound} />
						</Switch>
					</div>

					{/* The Create New Ticket Modal function will be available for all the windows */}
					<div id="globalAddMoreBtn" className="add-more-btn-home">
						<div className='gct-icon'>
							<div className="gct-plus">
								<Icons.PlusIcon />
							</div>
							<div className='amb-drop-tckt' onClick={() => handleChange("createTicketModal")}>
								<div className="create-gen-ticket">
									<Icons.CreateNewTicketIcon />
									<LanguageTranslator tag="p">Create Ticket</LanguageTranslator>
								</div>
							</div>
						</div>
					</div>
					{selectedModal && <ParentModal
						getParentChildProps={getParentChildProps()}
						childComponent={getChildComponent()}
						closeModal={() => { setSelectedModal(false) }}
					/>}

				</Suspense>
			</CombinedProvider>
		</CommentContext.Provider>
	)
}

export { AppStack, CommentContext }