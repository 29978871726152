/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import GenericSearchModalNew from './tableHelperComponents/GenericSearchModalNew';
import moment from "moment";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import IconsPath from "../../assets/icons/index";
const { RangePicker } = DatePicker;

export default class TableItemLevelFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            filterItems: {...this.props.filterItems},
            checkedFilters: {...this.props.appliedFilters},
            applyFilterEnabled: false,
            headerType: this.props.filterItems["Custom Headers"] === undefined || Object.keys(this.props.filterItems["Custom Headers"]).length === 0 ? "Default Headers" : "Custom Headers",
            saveFilterData:{},
            textDropdown: "",
            dateDropdown: "",
            saveFilterModal: false,
            currentTab: "main",
            // customHeaders: Object.keys(this.props.headersData.main.data).length === 0 ? [] : Object.keys(this.props.headersData.main.data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData.main.data["Default Headers"]) : Object.keys(this.props.headersData.main.data["Custom Headers"]),
            // customHeadersType: this.props.headersData.main["Custom Headers"] === undefined || this.props.headersData.main["Custom Headers"].length === 0 ? "Default Headers" : "Custom Headers",
        }
    }

    componentDidMount() {
        this.setState(prevState => {
            let checkedFilters = {...prevState.checkedFilters};
            Object.keys(prevState.checkedFilters).forEach(key => {
                if(key == "item") {
                    Object.keys(prevState.checkedFilters.item).forEach(key => {
                        if(prevState.itemLevelFilter.columnType[key] === "DATE" && checkedFilters[key].key || prevState.itemLevelFilter.columnType[key] === "NUMBER" || prevState.itemLevelFilter.columnType[key] === "TEXT") {
                            checkedFilters[key] = checkedFilters.item[key]
                        }
                        else if(prevState.itemLevelFilter.columnType[key] === "LOV" || prevState.itemLevelFilter.columnType[key] === "SEARCH") {
                            try {
                                checkedFilters[key] = checkedFilters.item[key].split(",");
                            }
                            catch(err) {
                                checkedFilters[key] = checkedFilters.item[key];
                            }
                        }
                    });
                }
                else {
                    if(prevState.filterItems.columnType[key] === "DATE" && checkedFilters[key].key) {
                        checkedFilters[key] = {
                            ...this.getDateFromTo(checkedFilters[key].key),
                            key: checkedFilters[key].key
                        };
                    }
                    else if(prevState.filterItems.columnType[key] === "LOV" || prevState.filterItems.columnType[key] === "SEARCH") {
                        try {
                            checkedFilters[key] = checkedFilters[key].split(",");
                        }
                        catch(err) {
                            checkedFilters[key] = checkedFilters[key];
                        }
                    }
                }
            });
            return {checkedFilters};
        }, this.handleApplyFilterButton);
    }

    componentDidUpdate() {
        if(this.props.saveSuccess) {
            this.closeSaveFilter();
            this.props.closeFilterModal();
        }
    }

    handleApplyFilterButton = () => { //Enables the "Apply Filter" button if all the checked filters have a value corresponding to them.
        this.setState(prevState => {
            if (Object.keys(prevState.checkedFilters).length === 0 || Object.values(prevState.checkedFilters).some(value => value === "" || value.length === 0 || value.from === "" || value.to === ""))
                return {applyFilterEnabled: false};
            else
                return {applyFilterEnabled: true};
        });
    }

    checkFilter = (key) => {
        if (Object.keys(this.state.checkedFilters).indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key] || this.props.headersData[this.state.currentTab].data.columnType[key]) {
                case "NUMBER": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = {
                            from: "",
                            to: ""
                        };
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                    break;
                }
                case "LOV":
                case "SEARCH": {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = [];
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = prevState.checkedFilters;
                        checkedFilters[key] = "";
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                }
            }
        }
        else {
            this.setState(prevState => {
                let checkedFilters = prevState.checkedFilters;
                delete checkedFilters[key];
                return {checkedFilters};
            }, this.handleApplyFilterButton);
        }
    }

    handleInput = (e, key) => {
        try {
            e.persist();
        }
        catch(err) {}
        
        switch (this.state.filterItems.columnType[key]) {
            case "NUMBER": {
                if (e.target.dataset.type === "from") {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = {
                            ...checkedFilters[key],
                            from: e.target.value
                        };
                        return {checkedFilters};
                    }, ()=> {
                        this.handleApplyFilterButton
                        if(e.key == "Enter") {this.applyFilterOnItemLevel();}
                    });
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = {
                            ...checkedFilters[key],
                            to: e.target.value
                        };
                        return {checkedFilters};
                    }, ()=> {
                        this.handleApplyFilterButton
                        if(e.key == "Enter") {this.applyFilterOnItemLevel();}
                    });
                }
                break;
            }
            case "DATE": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = "";
                        return {checkedFilters};
                    }, ()=> {
                        this.handleApplyFilterButton,
                        this.applyFilterOnItemLevel();
                    });
                }
                else if(["SM_DATE_TODAY_VALUE", "SM_DATE_YESTERDAY_VALUE", "SM_DATE_LAST7DAYS_VALUE", "SM_DATE_LASTWEEK_VALUE", "SM_DATE_THISMONTH_VALUE", "SM_DATE_LASTMONTH_VALUE"].includes(e)) {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = {
                            ...this.getDateFromTo(e),
                            key: e
                        };
                        return {checkedFilters, dateDropdown: ""};
                    }, ()=> {
                        this.handleApplyFilterButton,
                        this.applyFilterOnItemLevel();
                    });
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = {
                            from: moment(e[0].$d).format('YYYY-MM-DD'),
                            to: moment(e[1].$d).format('YYYY-MM-DD')
                        };
                        return {checkedFilters};
                    }, ()=> {
                        this.handleApplyFilterButton,
                        this.applyFilterOnItemLevel();
                    });
                }
                break;
            }
            case "TIME": {
                if (e === null) {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = "";
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                }
                else {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = {
                            from: moment(e[0].$d).format('YYYY-MM-DD HH:mm'),
                            to: moment(e[1].$d).format('YYYY-MM-DD HH:mm')
                        };
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                }
                break;
            }
            case "LOV": {
                this.setState(prevState => {
                    let checkedFilters = {...prevState.checkedFilters}, index = checkedFilters[key].indexOf(e.target.dataset.value);
                    if(!~index) {
                        checkedFilters[key].push(e.target.dataset.value);
                    }
                    else {
                        checkedFilters[key].splice(index, 1);
                    }
                    return {checkedFilters};
                }, this.handleApplyFilterButton);
                break;
            }
            case "SEARCH": {
                this.setState(prevState => {
                    let checkedFilters = prevState.checkedFilters;
                    checkedFilters[key] = [];
                    return {checkedFilters};
                }, this.handleApplyFilterButton);
                break;
            }
            default: {
                this.setState(prevState => {
                    let checkedFilters = {...prevState.checkedFilters};
                    checkedFilters[key] = e.target.dataset.value || (e.target.parentElement.dataset.value || (e.target.value || ""));
                    return {checkedFilters};
                }, ()=> {
                    this.handleApplyFilterButton
                    if(e.key == "Enter" || this.state.textDropdown == key) {this.applyFilterOnItemLevel();}
                });
            }
        }
    }

    selectItems = (field, items) => {
        this.setState(prevState => {
            let checkedFilters = {...prevState.checkedFilters};
            checkedFilters[field.filterKey] = items;
            return {checkedFilters};
        }, this.handleApplyFilterButton);
    }

    getDateFromTo = key => {
        switch(key) {
            case "SM_DATE_TODAY_VALUE": {
                return {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_YESTERDAY_VALUE": {
                return {
                    from: moment().subtract(1, "day").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LAST7DAYS_VALUE": {
                return {
                    from: moment().subtract(7, "days").format('YYYY-MM-DD'),
                    to: moment().subtract(1, "day").format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTWEEK_VALUE": {
                return {
                    from: moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_THISMONTH_VALUE": {
                return {
                    from: moment().startOf('month').format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD')
                };
            }
            case "SM_DATE_LASTMONTH_VALUE": {
                return {
                    from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                    to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                };
            }
            default: {
                return {
                    from: "",
                    to: ""
                };
            }
        }
    }

    showTextDropdown = key => () => {
        if (Object.keys(this.state.checkedFilters).indexOf(key) === -1) {
            switch (this.state.filterItems.columnType[key]) {
                case "LOV": {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = [];
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                    break;
                }
                default: {
                    this.setState(prevState => {
                        let checkedFilters = {...prevState.checkedFilters};
                        checkedFilters[key] = "";
                        return {checkedFilters};
                    }, this.handleApplyFilterButton);
                }
            }
        }
        this.setState({
            textDropdown: key
        }, () => document.addEventListener("click", this.hideTextDropdown));
    }

    hideTextDropdown = () => {
        this.setState({
            textDropdown: ""
        }, () => document.removeEventListener("click", this.hideTextDropdown));
    }

    showSelectDropdown = key => () => {
        this.setState({
            selectDropdown: key
        }, () => document.addEventListener("click", this.hideSelectDropdown));
    }

    hideSelectDropdown = e => {
        let path = e.path || (e.composedPath && e.composedPath());
        if(!path.some(elem => elem.id == "genericFilterSelectDropdown")) {
            this.setState({
                selectDropdown: ""
            }, () => document.removeEventListener("click", this.hideSelectDropdown));
        }
    }

    renderExtraFooter = key => () => {
        return(
            <span className="date-picker-extra-footer">
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_TODAY_VALUE"} onChange={e => this.handleInput("SM_DATE_TODAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Today</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_YESTERDAY_VALUE"} onChange={e => this.handleInput("SM_DATE_YESTERDAY_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Yesterday</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE"} onChange={e => this.handleInput("SM_DATE_LAST7DAYS_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last 7 Days</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTWEEK_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTWEEK_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Week</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_THISMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_THISMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">This Month</span>
                </label>
                <label className="radio-label">
                    <input type="radio" name="daterange" checked={typeof this.state.checkedFilters[key] === "object" && this.state.checkedFilters[key].key === "SM_DATE_LASTMONTH_VALUE"} onChange={e => this.handleInput("SM_DATE_LASTMONTH_VALUE", key)} />
                    <span className="radio-check"></span>
                    <span className="radio-text">Last Month</span>
                </label>
            </span>
        );
    }

    applyFilterOnItemLevel = () => {
        let filter = {};
        let item ={};
      
        Object.keys(this.state.checkedFilters).map(key => {
            if(this.props.headersData.main.data.columnType[key] === "LOV" || this.props.headersData.main.data.columnType[key] === "SEARCH" || this.props.headersData.item.data.columnType[key] === "LOV" || this.props.headersData.item.data.columnType[key] === "SEARCH") {
                if(this.props.headersData.main.data[this.state.customHeadersType][key]){
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else if(this.props.headersData.item.data[this.state.customHeadersType][key]){
                    item[key] = this.state.checkedFilters[key].join();
                }
            }
            else {
                if(this.props.headersData.main.data[this.state.customHeadersType][key]){
                    filter[key] = this.state.checkedFilters[key]
                }
                else if(this.props.headersData.item.data[this.state.customHeadersType][key]){
                    item[key] = this.state.checkedFilters[key]
                }
            }
        });
        this.props.applyFilter({...filter, item})();
    }

    saveMainItemFilters=()=>{
        this.openSaveFilter();
        let filter = {};
        let item ={};
        Object.keys(this.state.checkedFilters).forEach(key => {
            if(this.state.filterItems.columnType[key] === "LOV" || this.state.filterItems.columnType[key] === "SEARCH") {
                if(this.props.headersData.main.data[this.state.customHeadersType][key]){
                    filter[key] = this.state.checkedFilters[key].join();
                }
                else if(this.props.headersData.item.data[this.state.customHeadersType][key]){
                    item[key] = this.state.checkedFilters[key].join();
                }
            }
            else {
                if(this.props.headersData.main.data[this.state.customHeadersType][key]){
                    filter[key] = this.state.checkedFilters[key]
                }
                else if(this.props.headersData.item.data[this.state.customHeadersType][key]){
                    item[key] = this.state.checkedFilters[key]
                }
            }
        });
        let finalFilter = {...filter, item}
       this.setState({saveFilterData:finalFilter});
    }

    openSaveFilter = () => {
        this.setState({
            saveFilterModal: !this.state.saveFilterModal,
        });
    }

    closeSaveFilter = () => {
        this.setState({
            saveFilterModal: false
        });
    }

    render() {
        console.log(1234567890, this.state.checkedFilters);
        return (
            <React.Fragment>
                <tr>
                    <th></th>
                    {Object.keys(this.state.filterItems[this.state.headerType]).map((key) =>
                        <th key={key} className="filter-table-header">
                            {this.state.filterItems.columnType[key] === "NOSORT" ? 
                                <span className="asnfbb-right not-checked"></span> :
                                this.state.filterItems.columnType[key] === "NUMBER" ?
                                <span className="asnfbb-right flex">
                                    <input
                                        type="number" autoComplete="off"
                                        data-type="from"
                                        id={key + "From"}
                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].from}
                                        placeholder="Enter From Value"
                                        onChange={(e) => this.handleInput(e, key)}
                                        onKeyDown={(e) => this.handleInput(e, key)}
                                        className="asnfbb-from"
                                    />
                                    <input
                                        type="number" autoComplete="off"
                                        data-type="to"
                                        className="asnfbb-to"
                                        id={key + "To"}
                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? "" : this.state.checkedFilters[key].to}
                                        placeholder="Enter To Value"
                                        onChange={(e) => this.handleInput(e, key)}
                                        onKeyDown={(e) => this.handleInput(e, key)}
                                    />
                                </span> :
                            this.state.filterItems.columnType[key] === "DATE" ?  
                                <span className="asnfbb-right">
                                    <RangePicker
                                        format="YYYY-MM-DD"
                                        id={key}
                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                        onChange={(e) => this.handleInput(e, key)}
                                        renderExtraFooter={this.renderExtraFooter(key)}
                                        onOpenChange={open => open ? this.setState({dateDropdown: key}) : this.setState({dateDropdown: ""})}
                                        open={this.state.dateDropdown === key}
                                    />
                                </span> :
                            this.state.filterItems.columnType[key] === "TIME" ? 
                                <span className="asnfbb-right">
                                    <RangePicker
                                        format="YYYY-MM-DD HH:mm"
                                        showTime={{format:"HH:mm", defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('00:00', 'HH:mm')]}}
                                        id={key}
                                        value={this.state.checkedFilters[key] === undefined || this.state.checkedFilters[key] === "" ? null : [dayjs(this.state.checkedFilters[key].from), dayjs(this.state.checkedFilters[key].to)]}
                                        onChange={(e) => this.handleInput(e, key)}
                                    />
                                </span>:
                            this.state.filterItems.columnType[key] === "LOV" ? 
                                <span className="asnfbb-right select" onClick={this.showSelectDropdown(key)}>
                                    <input 
                                        type="text"
                                        autoComplete="off"
                                        id={key}
                                        placeholder="Select Options"
                                        value={this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 ? `${this.state.checkedFilters[key][0]} + ${this.state.checkedFilters[key].length - 1}` : this.state.checkedFilters[key].length === 1 ? this.state.checkedFilters[key][0] : ""}
                                        readOnly
                                    />
                                    <img className="asnfbbr-input-arrow" src={IconsPath.DownArrowNew} alt="Down arrow" onClick={this.showTextDropdown(key)} />
                                    {this.state.checkedFilters[key] === undefined ? null : this.state.checkedFilters[key].length > 1 && <span className="asnfbbr-select-count"> + {this.state.checkedFilters[key].length - 1}</span>}

                                    {this.state.selectDropdown &&
                                    <div id="genericFilterSelectDropdown" className="dropdown-menu-city1 gen-width-auto zi999">
                                        <ul className="dropdown-menu-city-item">
                                            {this.state.filterItems["Lov Headers"][key] && this.state.filterItems["Lov Headers"][key].map(item =>
                                                <li key={item}>
                                                    <label className="checkBoxLabel0">
                                                        <input
                                                            type="checkBox"
                                                            checked={this.state.checkedFilters[key].indexOf(item) !== -1}
                                                            onChange={(e) => this.handleInput(e, key)}
                                                            data-value={item}
                                                        />
                                                        <span className="checkmark1"></span>
                                                        {item}
                                                    </label>
                                                </li>
                                            )}
                                        </ul>
                                    </div>}
                                </span> :
                            this.state.filterItems.columnType[key] === "SEARCH" ? 
                                <span className="asnfbb-right search">
                                    <GenericSearchModalNew
                                        field={{
                                            filterKey: key,
                                            entity: this.state.filterItems.tableName[key],
                                            key: this.state.filterItems.tableColumnMap[key],
                                            value: this.state.filterItems[this.state.headerType][key]
                                        }}
                                        emptyAttributes = {true}
                                        showLabel={false}
                                        selectedItems={{[this.state.filterItems.tableColumnMap[key]]: this.state.checkedFilters[key]}}
                                        selectItems={this.selectItems}
                                        isDoneRequire={true}
                                        done={this.applyFilterOnItemLevel}
                                        {...this.props}
                                    />
                                </span> :
                                <span className="asnfbb-right">
                                    <input 
                                        type="text"
                                        id={key}
                                        value={this.state.checkedFilters[key] === undefined ? "" : this.state.checkedFilters[key] === "SM_TEXT_EMPTY_VALUE" ? "is Empty" : this.state.checkedFilters[key] === "SM_TEXT_NOT_EMPTY_VALUE" ? "is Not Empty" : this.state.checkedFilters[key]}
                                        placeholder="Enter Value"
                                        onChange={(e) => this.handleInput(e, key)}
                                        onKeyDown={(e) => this.handleInput(e, key)}
                                        disabled={["SM_TEXT_EMPTY_VALUE", "SM_TEXT_NOT_EMPTY_VALUE"].includes(this.state.checkedFilters[key])}
                                    />
                                    <img className="asnfbbr-input-arrow" src={IconsPath.DownArrowNew} alt="Down arrow" onClick={this.showTextDropdown(key)} />
                                    {this.state.textDropdown == key &&
                                    <span className="asnfbbr-inner">
                                        <span className="asnfbbr-all-vendor">
                                            <li data-value="" onClick={(e) => this.handleInput(e, key)}>
                                                <button>Custom</button>
                                                <span className="asnfbbrav-code">Type custom value</span>
                                            </li>
                                            <li data-value="SM_TEXT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                <button>is Empty</button>
                                            </li>
                                            <li data-value="SM_TEXT_NOT_EMPTY_VALUE" onClick={(e) => this.handleInput(e, key)}>
                                                <button>is Not Empty</button>
                                            </li>
                                        </span>
                                    </span>}
                                </span>}
                        {/* </div> */}
                    </th>)}
                </tr>
            </React.Fragment>
        )
    }
}