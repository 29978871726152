import React from 'react'

const PercentageSummarySkelaton = () => {
    return (
        <div className='vendor-onboarding'>
            <div className='vo-top p-tb-10 !justify-start'>
                <div className='vot-left'>
                    <div className='generic-skeleton w-50 m-0'></div>
                </div>
                <div className='vod-mid'>
                    <div className='vodm-sec wid-300'>
                        <div className='vodms-top'>
                            <div>
                                <div className='generic-skeleton wid-30 hgt-40 m-0 m-rgt-10'></div>
                            </div>
                            <div className='w-100'>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100 m-0'></div>
                            </div>
                        </div>
                        <div className='vodms-bot p-top-5'>
                            <div className='generic-skeleton w-100 m-0'></div>
                        </div>
                    </div>
                    <div className='vodm-sec wid-300'>
                        <div className='vodms-top'>
                            <div>
                                <div className='generic-skeleton wid-30 hgt-40 m-0 m-rgt-10'></div>
                            </div>
                            <div className='w-100'>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100 m-0'></div>
                            </div>
                        </div>
                        <div className='vodms-bot p-top-5'>
                            <div className='generic-skeleton w-100 m-0'></div>
                        </div>
                    </div>
                    <div className='vodm-sec wid-300'>
                        <div className='vodms-top'>
                            <div>
                                <div className='generic-skeleton wid-30 hgt-40 m-0 m-rgt-10'></div>
                            </div>
                            <div className='w-100'>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100 m-0'></div>
                            </div>
                        </div>
                        <div className='vodms-bot p-top-5'>
                            <div className='generic-skeleton w-100 m-0'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='vo-body-summary'>
                <div className='vob-left !overflow-hidden'>
                    <div className='vobl-top !border-0 p-bot-0'>
                        <div className='generic-skeleton m-0 wid-60 hgt-60' style={{ borderRadius: "50%" }}></div>
                        <h1><div className='generic-skeleton'></div></h1>
                        <p><div className='generic-skeleton m-0'></div></p>
                    </div>
                    <div className='vobl-bot'>
                        <div className='flex items-center flex-col p-15'>
                            <div className='generic-skeleton wid-200'></div>
                            <div className='generic-skeleton wid-150 m-0'></div>
                        </div>
                        <div className='flex items-center flex-col p-15'>
                            <div className='generic-skeleton wid-150 hgt-150 m-bot-15' style={{ borderRadius: "50%" }}></div>
                            <div className='generic-skeleton wid-80'></div>
                            <div className='generic-skeleton wid-150 m-0'></div>
                            <div className='generic-skeleton wid-200 hgt-35 m-top-20 m-0'></div>
                        </div>
                    </div>
                </div>
                <div className='vob-right !overflow-hidden'>
                    <div className='vobr-sec'>
                        <div className='vobrs-top'>
                            <div className='generic-skeleton wid-200 m-0'></div>
                            <div className='vobrst-timeline'>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                            </div>
                        </div>
                        <div className='vobrs-bot'>
                            <div className='generic-skeleton wid-200 hgt-35 m-0'></div>
                        </div>
                    </div>
                    <div className='vobr-sec'>
                        <div className='vobrs-top'>
                            <div className='generic-skeleton wid-200 m-0'></div>
                            <div className='vobrst-timeline'>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                            </div>
                        </div>
                        <div className='vobrs-bot'>
                            <div className='generic-skeleton wid-200 hgt-35 m-0'></div>
                        </div>
                    </div>
                    <div className='vobr-sec'>
                        <div className='vobrs-top'>
                            <div className='generic-skeleton wid-200 m-0'></div>
                            <div className='vobrst-timeline'>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                                <div className='generic-skeleton wid-100 m-0 m-rgt-30'></div>
                            </div>
                        </div>
                        <div className='vobrs-bot'>
                            <div className='generic-skeleton wid-200 hgt-35 m-0'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PercentageSummarySkelaton