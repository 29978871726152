import { Progress } from 'antd'
import React from 'react'
import Icons from '../../assets/icons'
import GenericCustomButton from '../../genericComponents/GenericCustomButton'
import { useSelector } from 'react-redux'

const menuBarIconsMapping = {
    'document_and_media': <Icons.DocumentMediaIcon />,
    'finance_detail': <Icons.FinanceIcon />,
    'supplier_basic_details': <Icons.SupplierBasicDetailsIcon />
}
const redirectForm = {
    'Industry & Fabric Details': { index: 1, code: 'IFABDET' },
    'Factory Details': { index: 2, code: 'FACDET' },
    'Company & Location': { index: 3, code: 'COMLC' },
    'Documents Upload': { index: 4, code: 'DOCUPL' },
    'Media Upload': { index: 5, code: 'MEDUPL' },
    'Transaction Details': { index: 6, code: 'TRNDET' },
}

const MenuBar = ({ menuBarData, modulePercentageHeaderWise, setCurrentStep, currentStep}) => {
    const authStore = useSelector(state => state.authStore)
    const getStrokeColor = (percent) => {
        let strokeColor;
        if (percent < 33) {
            strokeColor = 'red'; // red
        } else if (percent >= 33 && percent < 66) {
            strokeColor = 'orange'; // Orange
        } else {
            strokeColor = 'blue'; // Blue
        }
        return strokeColor
    }

    const getIconBasedOnPercentage = (percent) => {
        let icon = ''
        if (percent < 33) {
            icon = <Icons.VOWeakIcon />;
        } else if (percent >= 33 && percent < 66) {
            icon = <Icons.VOModerateIcon />;
        } else {
            icon = <Icons.VOStrongIcon />;
        }
        return icon
    }

    console.log('dsdwyfdfdgqsq', authStore?.getMenuDetailsData?.data);

    // function to redirect 
    const handleRedirect = (category) => {
        setCurrentStep(redirectForm[category]);
    }
    return (
        <div className='vob-left'>
            <h1>Your Progress</h1>
            <div className='vobl-body'>
                {
                    menuBarData?.map((menuItem, indexx) => {
                        return <div className='voblb-sec' key={menuItem?.id}>
                            <div className='voblbs-top'>
                                {menuBarIconsMapping[menuItem?.headerKey]}
                                <h3>{menuItem?.headerName}</h3>
                            </div>
                            {
                                menuItem?.menuBar?.map((item, index) => {
                                    return <div className='voblbs-det' key={index}>
                                        <div className='voblbsd-icon'>
                                            {getIconBasedOnPercentage(modulePercentageHeaderWise?.[menuItem?.headerName]?.[item?.displayName])}
                                        </div>
                                        <div className={`voblbsd-sec ${currentStep?.code == item?.code && "voblbsd-active"}`}>
                                            <div className='w-100 p-rgt-20'>
                                                <h4>{item?.displayName}</h4>
                                                {parseFloat(modulePercentageHeaderWise?.[menuItem?.headerName]?.[item?.displayName])?.toFixed(2) ==0 ? 
                                                <p className='voblbsds-not-started'>Not Started</p>
                                                :
                                                <Progress
                                                    percent={
                                                        typeof modulePercentageHeaderWise?.[menuItem?.headerName]?.[item?.displayName] === 'number'
                                                            ? parseFloat(modulePercentageHeaderWise?.[menuItem?.headerName]?.[item?.displayName])?.toFixed(2)
                                                            : 'Not Started'
                                                    }
                                                    status="active"
                                                    strokeWidth={2}
                                                    strokeColor={getStrokeColor(modulePercentageHeaderWise?.[menuItem?.headerName]?.[item?.displayName] || 0)}
                                                    strokeLinecap="square"
                                                />}
                                            </div>
                                            <GenericCustomButton
                                                className='voblbsds-edit-btn'
                                                label='Edit'
                                                leftIcon={<Icons.EditNewIcon />}
                                                onClick={() => handleRedirect(item?.displayName || '')}
                                            />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default MenuBar