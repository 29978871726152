import React, { useEffect, useState } from 'react'
import GenericToggleComponent from '../../../../genericComponents/GenericToggleComponent';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from '../../../../genericComponents/NoDataFound'
import { GenericFormSpreadTypeComponent } from '../../../../genericComponents/forms/GenericFormSpreadTypeComponent'
import Icons from '../../../../assets/icons';
import LanguageTranslator from '../../../../locals/LanguageTranslator';

const NotificationHandler = () => {
    const [isSettingEnabled, setIsSettingEnabled] = useState(false);
    const authStore = useSelector(state => state.authStore);
    const dispatch = useDispatch();
    const [inputData, setInputData] = useState({});
    const [errorData, setErrorData] = useState({});

    useEffect(() => {
        dispatchHandler(dispatch, "getNotificationActiveRequest")
    }, [])

    useEffect(() => {
        if (authStore?.["updateNotificationActiveData"]?.isSuccess) {
            setIsSettingEnabled(!isSettingEnabled);
            setInputData({});
            setErrorData({});
        }
    }, [authStore?.["updateNotificationActiveData"]?.isSuccess])

    useEffect(() => {
        if (authStore?.["getNotificationActiveData"]?.isSuccess) {
            setIsSettingEnabled(authStore?.["getNotificationActiveData"]?.data?.resource?.["status"]?.toLowerCase() == "active");
        }
    }, [authStore?.["getNotificationActiveData"]?.isSuccess])

    useEffect(() => {
        if (authStore?.["updateNotificationEmailData"]?.isSuccess) {
            // Reset the complete state
            setInputData({});
            setErrorData({});
        }
    }, [authStore?.["updateNotificationEmailData"]?.isSuccess])

    // Set CC Email and the to email values when users have selected the module data
    useEffect(() => {
        if (authStore?.["getNotificationEmailData"]?.isSuccess) {
            let data = authStore?.["getNotificationEmailData"]?.data?.resource || {};
            // Update the previous email set up for the notificaton
            let updatedInputState = { ...inputData };
            if (!updatedInputState?.["basicDetails"]) {
                updatedInputState["basicDetails"] = {}
            }
            updatedInputState["toEmail"] = {};
            if (data?.to?.length > 0) {
                updatedInputState["toEmail"]["email"] = {};
                // Updating the data in this form to make it compatible for List Component & Input Comoponent.
                data?.to?.map(email => {
                    updatedInputState["toEmail"]["email"][email] = {
                        "email": email,
                    }
                })
            }
            // Update the previous cc email set up for the notificaton
            updatedInputState["ccEmail"] = {};
            if (data?.cc?.length > 0) {
                updatedInputState["ccEmail"]["email"] = {};
                // Updating the data in this form to make it compatible for List Component & Input Comoponent.
                data?.to?.map(email => {
                    updatedInputState["ccEmail"]["email"][email] = {
                        "email": email,
                    }
                })
            }
            setInputData({ ...updatedInputState })
        }

        // Remove the error data also for the input field
        setErrorData(prev => {
            delete prev?.["toEmail"]?.["email"];
            delete prev?.["ccEmail"]?.["email"];
            return prev;
        })

    }, [authStore?.["getNotificationEmailData"]?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "finalSubmit": {
                let basicDetails = data?.["basicDetails"];
                let toEmail = data?.["toEmail"]?.["email"];
                let ccEmail = data?.["ccEmail"]?.["email"];
                dispatchHandler(dispatch, "updateNotificationEmailRequest", {
                    "module": basicDetails?.["module"]?.["key"] || "",
                    "subModule": basicDetails?.["subModule"]?.["key"] || "",
                    "type": basicDetails?.["config"]?.["key"] || "",
                    "fromName": "",
                    "from": "",
                    "subject": "",
                    "to": Object?.keys(toEmail || {})?.join(',') || "",
                    "cc": Object?.keys(ccEmail || {})?.join(',') || "",
                    "bcc": "",
                    "channel": "",
                    "templateName": "",
                    "arguments": ""
                })
                break
            }
        }
    }




    const renderNotificationConfig = () => {
        return <div className='nhm-top'>
            <GenericToggleComponent
                loading={authStore?.["getNotificationActiveData"]?.isLoading}
                disabled={authStore?.["getNotificationActiveData"]?.isLoading}
                checked={isSettingEnabled}
                onChange={val => {
                    dispatchHandler(dispatch, "updateNotificationActiveRequest", {
                        "status": isSettingEnabled ? "Inactive" : "Active",
                    })
                }}
            />
            <div className='nhmt-right'>
                <h3>{isSettingEnabled ? <LanguageTranslator >Setting Enabled</LanguageTranslator> : <LanguageTranslator>Setting Disabled</LanguageTranslator>}</h3>
                <p>{isSettingEnabled ? <LanguageTranslator>Email notification in enable now. You can now customize the settings.</LanguageTranslator> : <LanguageTranslator>Enable it to customize the setting</LanguageTranslator>}</p>
            </div>
        </div>
    }

    const getInputProps = (key) => {

        let inputProps = {
            "module": ["getNotificationModule",],
            "subModule": ["getNotificationSubModule", (params) => {
                return {
                    key: inputData?.["basicDetails"]?.["module"]?.["key"] || ""
                }
            }],
            "config": ["getNotificationConfigModule", (params) => {
                return {
                    key: inputData?.["basicDetails"]?.["subModule"]?.["key"] || ""
                }
            },]
        }

        switch (key) {
            case "module":
            case "subModule":
            case "config": {
                return {
                    viewDataType: "table-view",
                    headerKeys: {
                        [key]: {}
                    },
                    itemKeys: {
                        [key]: [key]
                    },
                    api: {
                        reduxState: "authStore",
                        apiName: inputProps?.[key]?.[0] || "",
                        apiPayload: (params) => {
                            if (inputProps?.[key][1]) {
                                return inputProps?.[key]?.[1]?.(params);
                            }
                            return {};
                        },
                        getMaxPage: () => 1,
                    },
                    getListData: (data) => {
                        let obj = data?.resource?.map(item => ({
                            key: Object?.keys(item || {})?.[0],
                            [key]: Object?.values(item || {})?.[0],
                        }))
                        return obj;

                    }
                }
            }
            case "toEmail":
            case "ccEmail": {
                return ({
                    viewDataType: "table-view",
                    headerKeys: {
                        multiSelectCol: {},
                        email: {
                            value: "Email",
                        },
                        username: {
                            value: "Username"
                        },
                    },
                    itemKeys: {
                        "email": ["email"],
                        "username": ["username"]
                    },
                    getListData: (response) => {
                        return response?.resource?.searchResultNew || [];
                    },
                    multiselect: {
                        selectId: "email"
                    },
                    api: {
                        reduxState: "generalStore",
                        apiName: "getSearchFilters",
                        apiPayload: (params) => {
                            return {
                                "entity": "supplymint.users",
                                "key": "email",
                                "code": "username",
                                "search": params?.currentSearch || "",
                                "searchBy": params?.searchFilter || "CONTAINS",
                                "pageNo": params?.currentPage || 1,
                                "moduleName": "",
                                "other_data": {}
                            }
                        },
                        getMaxPage: (states, fucntions, response) => {
                            return response?.resource?.maxPage || 1;
                        },
                    }
                })
            }
        }
    }

    const isEmailDisabled = () => {
        return !inputData?.["basicDetails"]?.["module"]?.["module"] || !inputData?.["basicDetails"]?.["subModule"]?.["subModule"] || !inputData?.["basicDetails"]?.["config"]?.["config"]
    }

    const handleChangeEmail = (basicDetails) => {
        if (basicDetails?.["module"]?.["module"] && basicDetails?.["subModule"]?.["subModule"] && basicDetails?.["config"]?.["config"]) {
            dispatchHandler(dispatch, "getNotificationEmailRequest", {
                moduleName: basicDetails?.["module"]?.["key"] || "",
                subModuleName: basicDetails?.["subModule"]?.["key"] || "",
                configName: basicDetails?.["config"]?.["key"] || "",
            })
        }
    }

    const provideInputFields = (type) => {
        switch (type) {
            case "basicDetails": {
                return [
                    {
                        key: "module", mandatory: true, label: "Module", iconRight: <Icons.DownArrowNew />, type: "dropdown", inputProps: () => getInputProps("module"), onChange: (a, b) => {
                            // If the user have changed their current selection
                            if (a?.["module"] != inputData?.["basicDetails"]?.["module"]?.["module"]) {
                                handleChangeEmail(b);
                                if (b) {
                                    delete b?.["subModule"];
                                    delete b?.["config"];
                                }
                                return b;
                            }
                        }
                    },
                    {
                        key: "subModule", mandatory: true, label: "Functional Module", isDisabled: () => !inputData?.["basicDetails"]?.["module"]?.["module"], iconRight: <Icons.DownArrowNew />, type: "dropdown", inputProps: () => getInputProps("subModule"), onChange: (a, b) => {
                            if (a?.["subModule"] != inputData?.["basicDetails"]?.["subModule"]?.["subModule"]) {
                                handleChangeEmail(b);
                                if (b) {
                                    delete b?.["config"];
                                }
                                return b;
                            }
                        }
                    },
                    {
                        key: "config", mandatory: true, label: "Configuration Property", isDisabled: () => !inputData?.["basicDetails"]?.["subModule"]?.["subModule"], iconRight: <Icons.DownArrowNew />, type: "dropdown", inputProps: () => getInputProps("config"), onChange: (a, b) => {
                            if (a?.["config"] != inputData?.["basicDetails"]?.["config"]?.["config"]) {
                                handleChangeEmail(b);
                            }
                        }
                    }
                ]
                break;
            }
            case "toEmail": {
                return [
                    { key: "email", mandatory: true, iconRight: <Icons.GlobalSearchIcon />, label: "To Email", type: "dropdown", inputProps: () => getInputProps("toEmail"), isDisabled: () => isEmailDisabled() },
                ]
                break;
            }
            case "ccEmail": {
                return [
                    { key: "email", viewDataType: "table-view", label: "CC Email", iconRight: <Icons.GlobalSearchIcon />, type: "dropdown", inputProps: () => getInputProps("ccEmail"), isDisabled: () => isEmailDisabled() },
                ]
            }
        }
    }

    const renderNotificationForm = () => {
        return isSettingEnabled ? <div className='nhm-body'>
            <GenericFormSpreadTypeComponent
                finalSubmitButton={"Save"}

                finalSubmit={(data) => handleChange("finalSubmit", data)}

                inputSections={{
                    "basicDetails": {
                        label: "New Notification",
                        inputFields: () => provideInputFields("basicDetails"),
                    },
                    "toEmail": {
                        inputFields: () => provideInputFields("toEmail"),
                    },
                    "ccEmail": {
                        inputFields: () => provideInputFields("ccEmail"),
                        bottomComponent: () => {
                            return (
                                <div className="gsfb-note">
                                    <LanguageTranslator tag="h5">Note:</LanguageTranslator>
                                    <LanguageTranslator tag="p">Above selected modules are now enable for Email Notification for authorised users</LanguageTranslator>
                                </div>
                            )
                        }
                    }
                }}

                inputState={inputData}
                updateInputState={setInputData}
                errorState={errorData}
                updateErrorState={setErrorData}
            />
        </div> :
            <NoDataFound
                primaryText="Email Notification is Disabled !"
                secondryText="Please enable settings by clicking on above toggle button"
            />
    }

    return (
        <div className='nh-modal'>
            {renderNotificationConfig()}

            {renderNotificationForm()}
        </div>
    )
}

export default NotificationHandler

