import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import Icons from '../../../assets/icons'
import { Progress } from 'antd'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import MultiColorCircularProgress from './MultiColorCircularProgress'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import PercentageSummarySkelaton from './PercentageSummarySkelaton'
import { parseJwt } from '../../../helper/genericFunction'

const iconsMapping = {
    'Finance Details': <Icons.FinanceIcon />,
    'Documents & Media': <Icons.DocumentMediaIcon />,
    'Supplier Basic Details': <Icons.SupplierBasicDetailsIcon />
}

const redirectPayload = {
    'Supplier Basic Details': { index: 1, code: 'IFABDET' },
    'Documents & Media': { index: 4, code: 'DOCUPL' },
    'Finance Details': { index: 6, code: 'TRNDET' }
}

const PercentageSummary = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const authStore = useSelector((state) => state.authStore)

    // Token data
    let tokenData = parseJwt(sessionStorage.getItem('token'));
    // The login response of the api
    const [percenatgeData, setPercentageData] = useState({
        getModulePercentageHeaderWise: {},
        getTotalModulePercentage: {},
        totalOnboardingPercentage: {}
    })

    // calling the percentage apii
    useEffect(() => {
        dispatchHandler(dispatch, 'getVendorOnboardingPercentageRequest')
    }, [])

    // setting the percentage api data 
    useEffect(() => {
        if (authStore?.getVendorOnboardingPercentageData?.isSuccess) {
            setPercentageData(authStore?.getVendorOnboardingPercentageData?.data)
        }
    }, [authStore?.getVendorOnboardingPercentageData?.isSuccess])

    // function to get the color
    const getStrokeColor = (percent) => {
        let strokeColor;
        if (percent < 33) {
            strokeColor = '#FD3757';
        } else if (percent >= 33 && percent < 66) {
            strokeColor = '#FAD271';
        } else {
            strokeColor = '#1B59F8';
        }
        return strokeColor
    }

    // function to redirect 
    const handleRedirect = (category) => {
        history.push({
            pathname: '/vendoronboarding',
            state: { initialStep: redirectPayload[category] }
        })
    }

    return (
        <React.Fragment>
            {authStore?.getVendorOnboardingPercentageData?.isLoading ?
                <PercentageSummarySkelaton /> :
                <div className='vendor-onboarding'>
                    {/* header part */}
                    <div className='vo-top p-tb-10 !justify-start'>
                        <div className='vot-left'>
                            <Icons.SupplymintRIcon />
                        </div>
                        <div className='vod-mid'>
                            {Object.entries(percenatgeData?.getTotalModulePercentage)?.map(([key, value]) => (
                                <div className='vodm-sec' key={key}>
                                    <div className='vodms-top'>
                                        <div>
                                            {iconsMapping[key]}
                                        </div>
                                        <div>
                                            <label>{key}</label>
                                            <span>
                                                <b style={{ color: getStrokeColor(value) }}>
                                                    {Math.floor(value)}% &nbsp;
                                                </b>
                                                Completed
                                            </span>
                                        </div>
                                    </div>
                                    <div className='vodms-bot'>
                                        <Progress
                                            percent={
                                                typeof value === 'number'
                                                    ? parseFloat(value)?.toFixed(2)
                                                    : 0
                                            }
                                            status="active"
                                            strokeWidth={3}
                                            strokeColor={getStrokeColor(value || 0)}
                                            strokeLinecap="square"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* body */}
                    <div className='vo-body-summary'>
                        <div className='vob-left'>
                            <div className='vobl-top'>
                                <Icons.TimeLeftIcon />
                                <h1> You have completed {Math.floor(percenatgeData?.totalOnboardingPercentage)}% of required details</h1>
                                <p>Please complete your all pending details to avail full benefits of supplymint vendor portal.</p>
                            </div>
                            <div className='vobl-bot'>
                                <div className='voblb-head'>
                                    <h2>{tokenData?.firstName == null ? "" : tokenData?.firstName} {tokenData?.lastName == null ? "" : tokenData?.lastName}</h2>
                                    <p>{tokenData?.orgName == null ? "" : tokenData?.orgName}</p>

                                </div>
                                {/* percenctage progress implementation */}
                                <div className='voblb-body'>
                                    <MultiColorCircularProgress
                                        percentage={percenatgeData?.totalOnboardingPercentage}
                                        strokeWidth={3}
                                    />
                                    <h2>{Math.floor(percenatgeData?.totalOnboardingPercentage) || 0}%</h2>
                                    <p>Overall Completion</p>
                                    <GenericCustomButton
                                        label={'Go to Account'}
                                        onClick={() => {
                                            history.push('/home')
                                            props.setAppStackDocState('')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* second */}
                        <div className='vob-right'>
                            {Object.entries(percenatgeData?.getModulePercentageHeaderWise)?.map(([category, subcategories]) => (
                                <div className='vobr-sec' key={category}>
                                    <div className='vobrs-top'>
                                        <h3>{category}</h3>
                                        <div className='vobrst-timeline'>
                                            {Object.entries(subcategories)?.map(([subcategory, percentage]) => (
                                                <div className='vobrstt' key={subcategory} >
                                                    <span>{subcategory}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='vobrs-bot'>
                                        <GenericCustomButton
                                            label='Complete Details'
                                            rightIcon={<Icons.RightArrowGrey />}
                                            onClick={() => handleRedirect(category)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default PercentageSummary