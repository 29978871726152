import React, { Component } from 'react'
// import GenericButton from './genericButton';
import DocumentAuditLogMoreData from './DocumentAuditLogMoreData';
import GenericCustomButton from '../../../GenericCustomButton';
import GenericSpinnerLoader from '../../../../loaders/GenericSpinnerLoader';

export default class DocumentAuditLogDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnChangeData: JSON.parse(this.props.otherData.column_change_json),
            dataHeader: [],
            dataLog: [],
            show: false,
            moreData: false,
            passData: [],
            header: "",
            documentNo: ""
        }
    }

    componentDidMount(){
        if(JSON.parse(this.props.otherData?.sub_entity_detail)?.[0]?.sub_entity){
            this.showingHandle(JSON.parse(this.props.otherData?.sub_entity_detail)?.[0]?.sub_entity)
        }
    }

    componentWillReceiveProps(nextProps, prevState) {
        if (nextProps.generalStore.getAuditDetailData?.isSuccess) {
            this.setState({
                dataHeader: nextProps.generalStore.getAuditDetailData?.data && nextProps.generalStore.getAuditDetailData?.data.resource ? nextProps.generalStore.getAuditDetailData?.data.resource.detailedHeaders : [],
                dataLog: nextProps.generalStore.getAuditDetailData?.data && nextProps.generalStore.getAuditDetailData?.data.resource ? nextProps.generalStore.getAuditDetailData?.data.resource.detailedLogs : [],
            })
        }
    }

    showingHandle = (item) => {
        this.setState({
            show: true
        })
        let payload = {
            transaction_id: this.props?.otherData?.transaction_id,
            entityKey: item
        }
        this.closeMoreModal();
        this.props.handleApiChange("getAuditDetailRequest", payload)
    }

    showMoreData = (item, data, head) => {
        this.setState({
            moreData: true,
            passData: item,
            header: head,
            documentNo: data
        })
    }

    closeMoreModal = () => {
        this.setState({
            moreData: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-content log-event-det-modal">
                    <div className='ledm-head'>
                        <div className='ledmh-icon' onClick={this.props.Close}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.71484 3.32349L11.2445 7.85315L6.71484 12.3828" stroke="#0F172A" stroke-width="1.29419" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <label>Audit Log event details</label>
                        {/* <p>{data?.action_by || }</p> */}
                    </div>
                    <div className='ledm-body overflow-hidden'>
                        <div className='ledmb-overview'>
                            <label>Overview</label>
                            <div className="ledmbo-scroll">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Fields</th>
                                            <th>Old Values</th>
                                            <th className='bor-rgt-n'>New Values</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.columnChangeData.map((data) => (
                                            <tr>
                                                <td className='td-first-col'>{data.ColumnName}</td>
                                                <td>{data.beforeColumnValue ? data.beforeColumnValue : "-"}</td>
                                                <td>{data.afterColumnValue ? data.afterColumnValue : "-"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='ledmb-overview'>
                            <label> Detailed Overview</label>
                            <div className='ledmbo-filter'>
                                <label className='p-0'>Showing Data :</label>
                                <select placeholder='Select Value'  onChange={(e) => this.showingHandle(e?.target?.value)} >
                                    <option disabled={true} value="" >Select</option>
                                    {JSON.parse(this.props.otherData?.sub_entity_detail)?.map((item) => <option value={item?.sub_entity} key={item?.sub_entity}>{item?.display_name}</option>)}
                                </select>
                            </div>
                            {this.props.generalStore?.getAuditDetailData?.isLoading ? <div style={{height:"4vh", width:"100%"}}><GenericSpinnerLoader/></div>  :<div className="ledmbo-scroll">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th> {(this.state.dataHeader.length !== 0 && this.state.show) && "documentRefNo"}</th>
                                            {this.state.dataHeader.map((Item) => (
                                                <th>{Item == "documentRefNo" ? "-" : Item}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.dataLog.map((item) => (
                                            <tr>
                                                <td className='td-first-col'>{item.documentRefNo}</td>
                                                {this.state.dataHeader.map((header) => {
                                                    { console.log(2828, item[header], item[header] && item[header].beforeColumnValue) }
                                                    if (header !== "documentRefNo") {
                                                        return (
                                                            <td>
                                                                <div className='flex items-center'>
                                                                    {item[header] && ((item[header] && item[header].beforeColumnValue) && item[header] && item[header].beforeColumnValue)}
                                                                    {(item[header] && item[header].beforeColumnValue) && <svg width="12" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.36721 3.61275C8.12313 3.36867 7.72741 3.36867 7.48333 3.61275C7.23925 3.85682 7.23925 4.25255 7.48333 4.49663L10.7909 7.80418H3.55078C3.2056 7.80418 2.92578 8.084 2.92578 8.42918C2.92578 8.77435 3.2056 9.05418 3.55078 9.05418H10.7919L7.48333 12.3627C7.23925 12.6068 7.23925 13.0026 7.48333 13.2466C7.72741 13.4907 8.12313 13.4907 8.36721 13.2466L12.7422 8.87163C12.9863 8.62755 12.9863 8.23182 12.7422 7.98775L8.36721 3.61275Z" fill="#8496A9" />
                                                                    </svg>}
                                                                    {item[header] ? (typeof (item[header] && item[header].afterColumnValue) == "object" ?
                                                                        <GenericCustomButton
                                                                            className="td-more-btn"
                                                                            onClick={() => this.showMoreData(item[header] && item[header].afterColumnValue, item.documentRefNo, header)}
                                                                            label="More Data"
                                                                        />
                                                                        : item[header] && item[header].afterColumnValue) : "No Data"}
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                })}
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    </div>
                </div>
                {this.state.moreData && <DocumentAuditLogMoreData Close={() => this.closeMoreModal()} PassData={this.state.passData} documentNo={this.state.documentNo} header={this.state.header} />}
            </React.Fragment>
        )
    }
}
