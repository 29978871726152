import React from 'react'
const divsArray = Array.from({ length: 11 }, index => index + 1);
const divsArray2 = Array.from({ length: 30 }, index => index + 1);

const WhDetailSkeletonLoader = () => {
    return (
        <div className='wab-right w-100 !overflow-hidden'>
            <div className='flex justify-between w-100 p-tb-5 p-lr-10'>
                <div className='flex w-50'>
                    <div className='w-100'>
                        <div className='generic-skeleton w-100'></div>
                        <div className='generic-skeleton w-100'></div>
                    </div>
                    <div className='w-100 p-lft-15'>
                        <div className='generic-skeleton w-100'></div>
                        <div className='generic-skeleton w-100'></div>
                    </div>
                </div>
                <div className='flex justify-end w-20'>
                    <div className='generic-skeleton w-100'></div>
                </div>
            </div>
            <div className='dsr-skeleton'>
                <table>
                    <tbody>
                        {divsArray2.map(() => (
                            <tr>
                                {divsArray.map(() => (
                                    <td>
                                        <div className='generic-skeleton'></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WhDetailSkeletonLoader