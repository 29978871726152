import React, { useState, useRef, useMemo, useEffect } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import IconsPath from '../../assets/icons/index'
import { dispatchHandler } from "../../helper/apiHelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { convertToMB, getFileIcon } from '../../helper/genericFunction'
import GenericSpinnerLoader from "../../loaders/GenericSpinnerLoader";
import GenericCustomButton from "../GenericCustomButton";
import NoDataImg from '../../assets/icons/default-document.svg';
import DefaultVideoIcon from '../../assets/icons/defaultVideoIcon.svg';

const GenericUploadFileComponent = (props) => {
	// fileUploadActionName => It defines at the time at which we have to call the upload api, i.e = "local", "everyTime", "finalSubmit"
	// fileKeys: It defined the keys to be used to access the data from the uploaded files . => ["fileName", "fileSize", "fileUrl"]
	// mediaFileTypeImage : boolean value set to true if the media file upload type is image.
	const { fileTypes = '*', maxSize = 5, fileUploadActionName = "local", renderCustomUploadedFiles, fileKeys, mediaFileTypeImage, maxFiles = 5 } = props;

	const inputRef = useRef();

	const getDefaultImages = {
		PDF: NoDataImg,
		VIDEO: DefaultVideoIcon,
		EXCEL: NoDataImg,
	}

	const customToast = useCustomToast();
	const dispatch = useDispatch();
	const apiReduxData = useSelector(state => state?.[props?.api?.reduxState || "generalStore"]);

	const [currentUploadedFiles, setCurentUplodaedFiles] = useState(props?.currentUploadedFiles || []);
	const [uploadedFiles, setUploadedFiles] = useState(props?.currentUploadedFiles || []);
	const [localUploadedFiles, setLocalUploadedFiles] = useState([]);
	const [loadingFiles, setLoadingFiles] = useState([]);
	const [removedFiles, setRemovedFiles] = useState([]);
	const [localFile, setLocalFile] = useState([])

	useEffect(() => {
		setCurentUplodaedFiles(props?.currentUploadedFiles);
		setUploadedFiles(props?.currentUploadedFiles);
	}, [props?.updateImageState])

	useEffect(() => {
		if (apiReduxData?.[`${props?.api?.apiName}Data`]?.isSuccess) {
			if (props?.getResOutside) {
				props.getResOutside(apiReduxData?.[`${props?.api?.apiName}Data`]?.data)
			}
			if (fileUploadActionName === "finalSubmit") {
				let newValue = apiReduxData?.[`${props?.api?.apiName}Data`]?.data;
				let value = newValue ? props.getResponseData ? props?.getResponseData(newValue) || [] : newValue?.resource || [] : [];
				let initialValue = [];
				currentUploadedFiles?.map(file => {
					if (!removedFiles?.some(item => (item?.[fileKeys?.[0]] || item?.["name"]) === (file?.[fileKeys?.[0]] || file?.["name"]))) {
						initialValue?.push(file);
					}
				})
				props.submitHandler([...initialValue, ...value]);
				props.closeModal();
			} else {
				let resource = props?.getFilesData(apiReduxData?.[`${props?.api?.apiName}Data`]?.data);
				setLoadingFiles([]);
				setUploadedFiles([...resource, ...uploadedFiles]);
			}
		} else if (apiReduxData?.[`${props?.api?.apiName}Data`]?.isError) {
			setLoadingFiles([]);
		}
	}, [apiReduxData?.[`${props?.api?.apiName}Data`]])

	const validateFiles = (files) => {
		const validFiles = [...files]?.filter((file) => {
			const isAllowedType = fileTypes === "*" ? true : fileTypes?.includes(file.type)
			const isSizeWithinLimit = (file.size / (1024 * 1024)) < maxSize;
			return isAllowedType && isSizeWithinLimit;
		});
		return validFiles;
	};

	// to handle the changes inside the component
	const handleChange = (type, data) => {
		switch (type) {
			case "finalSubmit": {
				switch (fileUploadActionName) {
					case "everyTime":
					case "local": {
						props.submitHandler(uploadedFiles);
						break
					}
					case "finalSubmit": {
						if (props?.api) {

							if (props?.deleteImgFromServer && removedFiles?.length !== 0) {
								props.deleteImgFromServer(removedFiles);
							}

							if (uploadedFiles?.length === 0) {
								props.submitHandler([]);
								props.closeModal();
							} else {
								let payload = props.api?.apiPayload(uploadedFiles, removedFiles) || {};
								dispatchHandler(dispatch, `${props?.api?.apiName}Request`, payload);
							}
						}
						break;
					}
					default:
				}
				break;
			}
			case "closeModal": {
				props.closeModal();
				break;
			}
			case "uploadFile": {
				data = validateFiles(data);
				if (data?.length > 0) {
					// If we have valid files.
					switch (fileUploadActionName) {
						case "finalSubmit":
						case "local": {
							// Filter the data if the files is previously uploaded.
							const selectedFiles = data;
							if (selectedFiles.length > 0) {
								const newFiles = Array.from(selectedFiles);
								const newPreviews = newFiles.map((file) => {
									const reader = new FileReader();
									reader.readAsDataURL(file);
									const fileType = file.type.split('/')[1];
									return new Promise((resolve) => {
										reader.onloadend = () => {
											if (fileType === 'pdf' || fileType === 'excel') {
												resolve(null);
											} 
											else {
												resolve(reader.result);
											}
										};
									});
								});
								Promise.all(newPreviews).then((previewResults) => {
									setLocalFile((prevPreviews) => [ ...previewResults, ...prevPreviews]);
								});
							}
							data = data?.filter(item => !uploadedFiles?.some(file => {
								if (file?.[item?.[fileKeys?.[0]]]) {
									return file?.[item?.[fileKeys?.[0]]] === item?.name;
								}
								return file?.name === item?.name;
							}))
							// Find the number of files we can upload.
							let availableFileSlots = Number(maxFiles || "") - Number(uploadedFiles?.length || "");
							// Filter the rest files from the list.
							data = data?.slice(0, availableFileSlots);
							setUploadedFiles([...data, ...uploadedFiles]);
							setLocalUploadedFiles([...data, ...localUploadedFiles]);
							break;
						}
						case "everyTime": {
							if (props?.api) {
								setLoadingFiles(data);
								let payload = props.api?.apiPayload(uploadedFiles) || {};
								dispatchHandler(dispatch, `${props?.api?.apiName}Request`, payload);
							}
							break;
						}
						default:
					}
				} else {
					customToast({
						title: `Either the file size is greater than ${maxSize || 5}MB or the file format is not supported!`,
						status: "error",
					});
				}
				inputRef.current.value = ""
				break;
			}
			case "removeFile": {
				let { file, index } = data;
				let updatedData = uploadedFiles?.filter((item, i) => i != index);
				if (localUploadedFiles?.some(item => item?.name === file?.name)) {
					let updatedLocalUploadedFiles = localUploadedFiles?.filter((item) => item?.name != file?.name);
					setLocalUploadedFiles([...updatedLocalUploadedFiles]);
				} else {
					let updatedRemovedFiles = [...removedFiles];
					updatedRemovedFiles?.push(file);
					setRemovedFiles(updatedRemovedFiles);
				};
				let localFileData = [...localFile];
				if(localFileData?.[index]) {
					localFileData.splice(index, 1)
				}
				setLocalFile(localFileData);
				setUploadedFiles(updatedData);
				break;
			}
			default:
		}
	}

	// Render the header component of the upload file component
	const renderUploadFileHeaderComponent = () => {
		return (
			<div className="flex items-center justify-between">
				<h3>{props?.labelData?.header || "Upload Files"}</h3>
				{props.labelData?.labelChild && <h5>{props.labelData?.labelChild}</h5>}
				{props.closeModal && <button onClick={() => { props.closeModal() }}>
					<IconsPath.ModalCloseIcon />
				</button>}
			</div>
		)
	}

	const isSubmitDisabled = () => {
		//  Function to check if the final submit button is disabled or not.
		if (fileUploadActionName === "finalSubmit") {
			return localUploadedFiles?.length === 0 && removedFiles?.length === 0;
		}

		return false;

	}

	// Render the lower section of the component
	const renderUploadFileFooterComponent = () => {
		return (
			<div className="flex justify-end p-tb-5 p-lr-10">
				{/* Update the Generic Button here */}
				<GenericCustomButton
					type="primary"
					disabled={isSubmitDisabled}
					onClick={() => { handleChange("finalSubmit") }}
					label={"Submit"}
				/>
			</div>
		)
	}

	// Function -> To render all the uploaded files.
	const renderUploadedFiles = () => {
		return (
			uploadedFiles?.length > 0 && (
				<ul>
					{uploadedFiles?.map((file, index) => (
						renderCustomUploadedFiles ? renderCustomUploadedFiles(file) : <li onClick={() => { file?.[fileKeys?.[3] || "type"]?.startsWith('image/') && handleChange("openImage", file) }} key={index} my={2}>
							{<div className="uploaded-img">
								<div className="ui-img v3">
									<img alt="Uploaded Image" src={getDefaultImages?.[getFileIcon(file?.[fileKeys?.[0] || "name"] || file?.["name"])] || file?.[fileKeys?.[2] || "url"] || file?.["url"] || mediaFileTypeImage || localFile?.[index] || NoDataImg} />
								</div>
								<div className="w-100">
									<div className="flex items-center justify-between">
										<h4>{file?.[fileKeys?.[0] || "name"] || file?.["name"]}</h4>
										<button
											onClick={() => handleChange("removeFile", {
												index: index,
												file: file
											})}
											className="iugm-remove-file-btn"
										>
											<IconsPath.ModalCloseIcon className="iugm-remove-file-btn" />
										</button>
									</div>
									{(file?.[fileKeys?.[1] || "size"] || file?.["size"]) && <p>{convertToMB(file?.[fileKeys?.[1] || "size"] || file?.["size"])} MB</p>}
								</div>

							</div>}
						</li>
					))}
				</ul>
			)
		)
	}

	// Function : To render all the uploading files.
	const renderUploadingFiles = () => {
		return (
			loadingFiles?.length > 0 && (
				<ul>
					{loadingFiles?.map((file, index) => (
						<li key={index} my={2}>
							{<div className="uploaded-img">
								<div className="ui-img">
									<img src={mediaFileTypeImage} />
								</div>
								<div className="w-100">
									<div className="flex items-center justify-between">
										<h4>{file?.[fileKeys?.[0] || "name"]}</h4>
										<GenericSpinnerLoader />
									</div>
									{file?.[fileKeys?.[1] || "size"] && <p>{convertToMB(file?.[fileKeys?.[1] || "size"])} MB</p>}
								</div>
							</div>}
						</li>
					))}
				</ul>
			)
		)
	}


	return (
		<div id = "sdfsdfsdf" className="gen-upload-file">
			{/* Render the header component */}
			<div className="gpf-head">
				{renderUploadFileHeaderComponent()}
				<div className="gpfh-tag">
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 6.875C2.84518 6.875 3.125 7.15482 3.125 7.5V12.5C3.125 12.6658 3.19085 12.8247 3.30806 12.9419C3.42527 13.0592 3.58424 13.125 3.75 13.125H11.25C11.4158 13.125 11.5747 13.0592 11.6919 12.9419C11.8092 12.8247 11.875 12.6658 11.875 12.5V7.5C11.875 7.15482 12.1548 6.875 12.5 6.875C12.8452 6.875 13.125 7.15482 13.125 7.5V12.5C13.125 12.9973 12.9275 13.4742 12.5758 13.8258C12.2242 14.1775 11.7473 14.375 11.25 14.375H3.75C3.25272 14.375 2.77581 14.1775 2.42417 13.8258C2.07254 13.4742 1.875 12.9973 1.875 12.5V7.5C1.875 7.15482 2.15482 6.875 2.5 6.875Z" fill="#707494" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M7.05806 0.808058C7.30214 0.563981 7.69786 0.563981 7.94194 0.808058L10.4419 3.30806C10.686 3.55214 10.686 3.94786 10.4419 4.19194C10.1979 4.43602 9.80214 4.43602 9.55806 4.19194L7.5 2.13388L5.44194 4.19194C5.19786 4.43602 4.80214 4.43602 4.55806 4.19194C4.31398 3.94786 4.31398 3.55214 4.55806 3.30806L7.05806 0.808058Z" fill="#707494" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0.625C7.84518 0.625 8.125 0.904822 8.125 1.25V9.375C8.125 9.72018 7.84518 10 7.5 10C7.15482 10 6.875 9.72018 6.875 9.375V1.25C6.875 0.904822 7.15482 0.625 7.5 0.625Z" fill="#707494" />
					</svg>
					<p>Choose files to upload</p>
				</div>
				<input
					type="file"
					className="gpfh-input"
					autoComplete="false"
					ref={inputRef}
					disabled={uploadedFiles?.length == +maxFiles || props.disabledImgInput}
					accept={props.fileTypes?.join(',') || "*"}
					autoFocus={true}
					multiple={true}
					onChange={(e) => handleChange("uploadFile", e.target.files)}
				/>
			</div>
			<div className="gpf-body">
				{/* To render all the currently uploading files. */}
				{renderUploadingFiles()}
				{/* To render all the uploaded files. */}
				{renderUploadedFiles()}
			</div>
			{/*Render the footer component */}
			{renderUploadFileFooterComponent()}
		</div>
	);
};

export default GenericUploadFileComponent;
