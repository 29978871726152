export const setPayloadAsURLParams = (config) => {
    let { data = {}, h, l, newPathName } = config;

    let urlSearchParams = new URLSearchParams("?");
    Object.keys(data || {})?.forEach(key => {
        if ((typeof data[key] != "object" && data[key]) || (typeof data[key] == "object" && Object.keys(data[key] || {})?.length)) {
            urlSearchParams.append(key, encodeURIComponent(JSON.stringify(data[key])));
        }
    });
    h.push({
        pathname: newPathName || l.pathname,
        search: urlSearchParams == "?" ? "" : urlSearchParams.toString()
    });
}

export const getPayloadFromURLParams = (config) => {
    let { l } = config;

    const urlSearchParams = new URLSearchParams(l.search),
        params = Object.fromEntries(urlSearchParams.entries());

    Object.keys(params).forEach(key => {
        params[key] = JSON.parse(decodeURIComponent(params[key]));
    });

    return params || {};
}