import { useEffect } from 'react';
import { dispatchHandler } from '../helper/apiHelperFunctions.js'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import LoginStack from './login/LoginStack.jsx';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import '../styles/stylesheets/home/LoadingPages.scss'
import { setSessionStorageItems, setLocalStorageItems } from './login/LoginApiResponse.js';
import { parseJwt } from '../helper/genericFunction.js';
import { loginRequest } from './login/helper/loginHelperFunctions.js';
import LoginSupplymintAnimation from './LoginSupplymintAnimation.jsx';
import useCustomToast from '../hooks/useCustomToast.js';
import OtherStack from '../otherStacks/OtherStack.jsx';
import { deleteDB } from 'idb';
import { connectWithPreLoginSocketConnection, disconnectWithPreLoginSocketConnection } from '../socket/PreLoginSocktConnection.js';
import { getSocketConnection } from '../socket/socketHelperConnection.js';
import { useContext } from 'react';
import { UserDetailsContext } from '../App.js';

const AuthStack = (props) => {
    const customToast = useCustomToast();

    const {
        isVendorLoginFromRetailer = false, // Will becomer true when user will login for vendor through retailer
        token,
    } = props || {};

    const dispatch = useDispatch();
    const authStore = useSelector(state => state.authStore);
    const location = useLocation();
    const history = useHistory();

    const userDetailsContext = useContext(UserDetailsContext)

    // // Dev Code
    // useEffect(() => {
    //     sessionStorage.setItem()
    // })

    useEffect(() => {
        if (props.token) {
            // Call the login api
            console.log("Sdfsdf", props.isVendorLoginFromRetailer);
            if (props.isVendorLoginFromRetailer) {
                localStorage.clear();
                sessionStorage.clear();
                let parsedToken = parseJwt(token);
                loginRequest({
                    username: parsedToken.prn,
                    password: token,
                    dispatch: dispatch,
                    location: location,
                    hash: "true"
                });
            } else {
                dispatchHandler(dispatch, "getCurrrentUserAuthDetailsRequest", {
                    token: props?.token,
                    flowType: "RELOGIN"
                })
            }
            sessionStorage.setItem("showEthanacityPopUp", true);
        }
    }, [props.isVendorLoginFromRetailer])

    const redirectNewPortal = (token) => {
        let currentDomain = "";
        let domainName = {
            // "localhost": "app",
            "quality1": "quality",
            "dev1": "dev",
            "prod": "app"
        }

        Object.keys(domainName || {}).map(key => {
            // console.log("Domain", window.location.href, key, window.location.href.substring(0, 20).includes(key))
            if (window.location.href.substring(0, 20).includes(key)) {
                currentDomain = key;
            }
        })

        let domain = domainName[currentDomain];

        window.open(`http://${domain}.supplymint.com/#/home?${encodeURIComponent(token)}`, "_self")
        // window.open(`http://localhost:5000/#/home?${encodeURIComponent(token)}`, "_self")

    }

    // Function  : To login the user
    const loginUser = async (data, config) => {

        const { setLoading } = config || {};

        // Check for the redirect url here
        if (data?.["redirectUrl"]) {
            window.open(data?.["redirectUrl"], "_self")
        } else if (data?.["subscription"] == 1) {
            if (data?.redirectNewPortal == 1 && !sessionStorage.getItem("LOG_INTO_NEW_PORTAL")) {
                redirectNewPortal(data?.token);
                setLoading(false)
            } else {
                // Clear the indexed db data when the user logs in inside new portal
                await deleteDB("Supplymint-ai-local-storage",{
                    "blocked" : () => {
                        console.log("PORT5")
                    }
                });

                await setSessionStorageItems(data, userDetailsContext);
                setTimeout(async () => await setLocalStorageItems(data, dispatch), 0)

                // Move to the vendor onboarding stack
                if (data?.vendorOnboardingPage === 1) {
                    let newPathName = "/vendoronboarding"
                    history.push(newPathName)
                    props.setAppStackDocState("otherStack");
                    return;
                }
                else {
                    setTimeout(() => {
                        props.setCurrentDocumentState("authorised");
                        const urlSearchParams = new URLSearchParams(location.search), params = Object.fromEntries(urlSearchParams.entries());
                        if (params.hasOwnProperty("redirect")) {
                            let redirectURL = params.redirect;
                            delete params.redirect;
                            Object.keys(params).forEach(key => {
                                redirectURL += `&${key}=${params[key]}`;
                            });
                            history.push(redirectURL);
                        }else{
                            history.push('/home')
                        }
                    }, 50)
                    sessionStorage.setItem("showEthanacityPopUp", true);
                }
            }
        } else {
            customToast({
                title: "Subscription Error!",
                description: "Your subscription has expired, please renew and try again!",
                status: "error",
            })
            setLoading(false)
        }
    }

    // Use Effect to handle the sign in process
    useEffect(() => {
        if (authStore?.getCurrrentUserAuthDetailsData?.isSuccess) {
            let data = authStore?.getCurrrentUserAuthDetailsData?.data?.resource || {};
            loginIntoSupplymint(data);
        }
    }, [authStore?.getCurrrentUserAuthDetailsData])

    // Use Effect to handle the sign in process
    useEffect(() => {
        if (authStore?.getCurrrentUserAuthDetailsCopyData?.isSuccess) {
            let data = authStore?.getCurrrentUserAuthDetailsCopyData?.data?.resource || {};
            loginIntoSupplymint(data);
        }
    }, [authStore?.getCurrrentUserAuthDetailsCopyData])

    useEffect(() => {
        // console.log("changes23423", getSocketConnection());
        connectWithPreLoginSocketConnection();
        return(() => {
            disconnectWithPreLoginSocketConnection()
        })
    },[props.currentDocumentState])
    

    const loginIntoSupplymint = (data) => {

        // If these keys are present in the params, means user is trying to login inton freshdesk

        setSessionStorageItems(data, userDetailsContext);
        setLocalStorageItems(data, dispatch);
        props.setCurrentDocumentState("authorised");
        if (sessionStorage.getItem("redirectNewUrl")) {
            let path = sessionStorage.getItem("redirectNewUrl")
            console.log(34434343434, path)
            window.history.replaceState({}, "#" + path, "#" + path);
        } else {
            const urlSearchParams = new URLSearchParams(location.search), params = Object.fromEntries(urlSearchParams.entries());
            if (params.hasOwnProperty("redirect")) {
                let redirectURL = params.redirect;
                delete params.redirect;
                Object.keys(params).forEach(key => {
                    redirectURL += `&${key}=${params[key]}`;
                });
                history.push(redirectURL);
            }else{
                history.push('/home')
            }
        }

    }

    const renderAuthStack = () => {
        // On the basis of the current document state of the previous App.js
        switch (props.currentDocumentState) {
            case "unauthorised": {
                return <LoginSupplymintAnimation />
            }
            case "rejected": {
                return <LoginStack loginUser={loginUser} isVendorLoginFromRetailer={isVendorLoginFromRetailer} extraData={props} />
            }
            case 'otherStack': {
                return <OtherStack />
            }
        }
    }

    return (
        renderAuthStack()
    )
}

export default AuthStack;