import React from 'react'
import GenericCustomButton from '../GenericCustomButton';

const GenericFormFooterComponent = (props) => {

    const {buttons} = props;

    return (
        <React.Fragment>
            <div className='generic-form-footer'>
                {/* The buttons div always aligned at the right bottom of the modal */}
                <div></div>
                <div className='gff-right'>
                    {/* Render all the buttons data */}
                    {buttons?.map((item, index) => {
                        return <>
                            <GenericCustomButton
                                sendEventAnalyticsToGoogle = { item?.hasOwnProperty("sendEventAnalyticsToGoogle") ? item?.sendEventAnalyticsToGoogle : true}
                                {...item}
                            />
                        </>
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default GenericFormFooterComponent