import React, {useState, useEffect} from 'react'
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export function GoogleAnalytics (props) {

    const location = useLocation();

    const [lastPageAccessed, setLastPageAccessed] = useState("");

    useEffect(() => {
        ReactGA.initialize("G-N72BT4B76T");
    },[])

    useEffect(() => {
        if(location.pathname != lastPageAccessed && location.pathname && !["quality.supplymint.com", "dev.supplymint.com", "localhost"]?.some(item => window.location.hostname?.includes(item))){
            console.log("Page view changes", location.pathname)
            setLastPageAccessed(location.pathname);
            
            // On locatiojn change fire the ga4 socket event;
		    ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title});
        }
	},[location])

    return <React.Fragment>

    </React.Fragment>
}