import React from 'react'
import '../../styles/stylesheets/SalesManagement/ProductCatalogue.scss'

const divsArray = Array.from({ length: 20 }, index => index + 1);

const SalesProductsSkeletonLoader = (props) => {
    return (
        <div className={`sales-product-${props?.viewProductType}`}>
            <div className='flex flex-wrap'>
                {divsArray.map(() => (
                    <div className='sps-cards'>
                        <div className='generic-skeleton'></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SalesProductsSkeletonLoader