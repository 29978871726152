import React from 'react';
const ReplenishmentStack = React.lazy(() => import('../components/digiArs/replenishment/replenishmentStack'));
const PlanningSettingStack = React.lazy(() => import('../components/digiArs/planningSettings/PlanningSettingsStack'));
const DigiArsStack = React.lazy(() => import('../components/digiArs/digiArsStack/index'))
const AdministrationStack = React.lazy(() => import('../components/administration/AdministrationStack'))
const MasterDataStack = React.lazy(() => import('../components/masterData/MasterDataStack'))
const SalesOrderStack = React.lazy(() => import('../components/salesOrder/SalesOrderStack'))
const SuperAdminStack = React.lazy(() => import('../components/superAdmin/SuperAdminStack'))
const AdminReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/adminReportsStack/AdminReportsStack'));
const ShipmentReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/shipmentReportsStack/ShipmentReportsStack'));
const DigivendStack = React.lazy(() => import('../components/digiVend/DigivendStack'));
const ReplenishmentReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/replenishmentReportsStack/ReplenishmentReportsStack'));
const InventoryManagementReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/inventoryManagementReportsStack/InventoryManagementReportsStack'))
const SalesReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/salesReportsStack/SalesReportsStack'))
const PurchaseReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/purchaseReportsStack/PurchaseReportsStack'))
const AccountingReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/accoutingReportStack/AccountingReportsStack'));
const StockReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/stockReportsStack/StockReportsStack'));
const LogisticsReportsStack = React.lazy(() => import('../components/reportsAndAnalytics/logisticsReportsStack/LogisticsReportsStack'));
const DigiProcStack = React.lazy(() => import('../components/digiProc/DigiProcStack'));
const DigiCatStack = React.lazy(() => import('../components/digicatalogue/DigiCatStack'))

export const stacks = {
    // ARS Stack
    "RDAMAIN": {
        "replenishmentStack": [
            "/inventoryPlanning/order",
            "/inventoryPlanning/summary",
            "/inventoryPlanning/history",
            "/inventoryPlanning/whNew",
            // "/analytics/plannerWorkQueue2"
        ],
        "planningSettingStack": [
            "/inventoryPlanning/assortment",
            "/inventoryPlanning/inventoryAutoConfig",
            "/inventoryPlanning/siteMapping",
            "/inventoryPlanning/event-mapping",
            // "/inventoryPlanning/mrp-range"
        ],
        "digiArsStack": [
            "/inventoryPlanning/manageAdhocRequest",
            "/core/custom/upload/digiars",
            "/inventoryPlanning/transferOrder"
        ]
    },
    // Administration Stack
    "RADMMAIN": {
        "administrationStack": [
            "/administration/rolesMaster/manageRoles",
            "/administration/users",
            "/administration/userDataMapping",
            "/administration/organisation",
            "/administration/dataSync",
            "/administration/apilogs",
            "/administration/auditLogs",
            "/administration/systemMails",
            "/administration/globalConfiguration",
            "/administration/rolesMaster/createRoles",
            "/administration/rolesMaster/manageRolesNew"
        ]
    },
    // Master Data Stack
    "MDMMAIN": {
        "masterDataStack": [
            "/mdm/manageVendors",
            "/vendor/manageVendors",
            "/mdm/manageItems",
            "/mdm/manageAgent",
            "/mdm/manageTransporters",
            "/mdm/materiallibrary",
            "/mdm/fabric/library",
            "/catalogue/cost/sheet/library",
            "/mdm/manageCustomers",
            "/mdm/manageSalesAgents",
            "/mdm/manageSites",
            "/mdm/manageMailingList"
        ]
    },
    // Sales Management Retailer Stack
    "SALMNGMAIN": {
        "salesOrderStack": [
            "/salesManagement/productCatalog",
            "/salesManagement/viewSalesOrders",
            "/salesManagement/customer/customerMapping",
            // "/salesManagement/customer/creditLineManage",
            "/salesManagement/createPricelist",
            "/salesManagement/manageDcChallan",
            "/salesManagement/retailerManageBuy",
            "/salesManagement/bag"
        ]
    },
    // Super Admin Stack
    "RSUPERADM": {
        "superAdminStack": [
            "/mdm/support-tickets",
            "/mdm/users",
            "/mdm/enterprisesPortal"
        ]
    },
    // Retailer Reports & Analytics
    "RDREPOMAIN": {
        // Admin Retailer Reports.
        "replenishmentReportsStack": [
            "/analytics/chooseStoreProfile",
            "/digiars/inventoryPlanningReport",
            "/inventoryPlanning/report4",
            "/digiars/sales/mbo/report",
            "/digiars/sales/weekly/report",
            "/digiars/stock/size/set/report",
            "/digiars/mboReport",
            "/inventoryPlanning/report2",
            "/inventoryPlanning/reordingReport",
            "/inventoryPlanning/transferOrderReport"
        ],
        "inventoryManagementReportsStack": [
            "/inventoryPlanning/sell/through/report",
            "/inventoryPlanning/catSizeReport",
            "/digiars/salesInventoryReport",
            "/inventoryPlanning/topBottomReports",
            "/inventoryPlanning/lastMonthReport",
            "/inventoryPlanning/topMovingReport",
            "/inventoryPlanning/saleContribution",
            "/inventoryPlanning/site/to/site/movement/report"
        ],
        "salesReportsStack": [
            "/analytics/retailerSalesStockItemReport",
            "/salesManagement/salesDrrMisReport",
            "/salesManagement/so/status/report",
            "/analytics/retailerSalesReport",
            "/analytics/retailerSalesReturnReport",
            "/inventoryPlanning/bill/report",
            "/analytics/retailerSalesReport/SALES_PERSON_REPORT",
            "/inventoryPlanning/report5",
            "/inventoryPlanning/report10",
            "/inventoryPlanning/report7",
            "/analytics/retailerGvAnalysis",
            "/inventoryPlanning/report3",
            "/analytics/retailerChannelWiseNetSalesAnalysis",
            "/analytics/hitLineReport",
            "/analytics/footfallReport",
            "/analytics/jistReport",
            "/inventoryPlanning/report6",
            "/inventoryPlanning/report9",
            "/inventoryPlanning/report8",
            "/inventoryPlanning/report1",
            "/analytics/retailerSalesReport/ENT_SALES_ITEM_REPORT",
            "/inventoryPlanning/dailyTrackerKpi/new"
        ],
        "purchaseReportsStack": [
            "/inventoryPlanning/grn/report",
            "/analytics/opsreport",
            "/analytics/dispatchreport",
            "/analytics/retailerPoReport",
            "/analytics/entPurchaseReport",
            "/analytics/potogrnreport",
            "/analytics/multiLevelApprovalLogs",
            "/analytics/tnaorderstatus",
            // "/vendor/analytics/tnaorderstatus",
            "/analytics/poGrnException",
            '/analytics/otb/forcaste/report',
            '/analytics/orderReport',
            '/analytics/vendorPoItemReport',
            "/analytics/otbReport"
        ],
        "accoutingReportStack": [
            "/analytics/retailerLedgerReport",
            "/analytics/retailerOutstandingReport",
            "/analytics/customerOutstandingReport",
            "/analytics/customerLedgerReport"
        ],
        "stockReportsStack": [
            "/analytics/retailerStockReport",
            "/salesManagement/stockDrrMisReport",
            "/analytics/stockagingReport"
        ],
        "logisticsReportsStack": [
            "/mdm/factoryaddressreport",
            "/analytics/logisticsPaymentReconciliation",
            "/analytics/appointmentReport",
            "/analytics/logisticsInventoryReport",
            "/analytics/retailerLrReport"
        ],
        "adminReportsStack": [
            "/vendor/loginLogoutReport",
            "/vendor/activityReport"
        ],
        "shipmentReportsStack": [
            // "/analytics/autoAsnReport",
            "/analytics/shipmentBoxReport",
            "/analytics/retailerInspectionReport",
            "/analytics/retailerAsnReport",
            "/analytics/autoAsnReport"
        ]
    },
    // Vendor Reports & Analytics
    "VDREPOMAIN": {
        "salesReportsStack": [
            "/analytics/vendorSalesReport",
            "/analytics/vendorSalesStockItemReport"
        ],
        "shipmentReportsStack": [
            "/analytics/vendorInspectionReport",
            "/analytics/vendorAsnReport",
            "/analytics/shipmentBoxReport"
        ],
        "purchaseReportsStack": [
            "/analytics/vendorPoReport",
            "/analytics/vendorPoToGrnReport",
            "/vendor/analytics/tnaorderstatus"
        ],
        "stockReportsStack": [
            "/analytics/retailerStockReport",
            "/salesManagement/stockDrrMisReport",
            "/analytics/stockagingReport",
            '/analytics/vendorStockReport'
        ],
        "accoutingReportStack": [
            "/analytics/vendorLedgerReport"
        ],
        "logisticsReportsStack": [
            "/analytics/vendorLrReport",
        ],
    },

    // Retailer Side Digivend
    "RDVMAIN": {
        "digivendStack": [
            "/enterprise/purchaseOrder/pendingOrders",
            "/enterprise/purchaseOrder/processedOrders",
            "/enterprise/purchaseOrder/cancelledOrders",
            "/enterprise/shipment/asnUnderApproval",
            "/enterprise/shipment/approvedAsn",
            "/enterprise/shipment/cancelledAsn",
            "/enterprise/logistics/lrProcessing",
            "/enterprise/logistics/goodsIntransit",
            "/enterprise/logistics/goodsDelivered",
            "/enterprise/logistics/grcStatus",
            "/enterprise/vendorSearchComment",
            "/enterprise/orderRequest",
            "/enterprise/vendorInvoices",
            "/retailer/retailerBillDiscounting",
            "/enterprise/qualityCheck/pendingQc",
            "/enterprise/qualityCheck/cancelledInspection",
            "/digivend/manage/autoASN",
            "/digivend/manage/siteAppointment",
            "/digivend/manage/poConfiguration",
            "/core/custom/upload/digivend",
            "/digivend/searchComment"
        ]
    },

    // Vendor Side Digivend
    "VDVMAIN": {
        "digivendStack": [
            "/vendor/purchaseOrder/pendingOrders",
            "/vendor/purchaseOrder/processedOrders",
            "/vendor/purchaseOrder/cancelledOrders",
            "/vendor/shipment/asnUnderApproval",
            "/vendor/shipment/approvedAsn",
            "/vendor/shipment/cancelledAsn",
            "/vendor/logistics/lrProcessing",
            "/vendor/logistics/goodsIntransit",
            "/vendor/logistics/goodsDelivered",
            "/vendor/logistics/grcStatus",
            "/vendor/vendorSearchComment",
            "/vendor/orderRequest",
            "/vendor/vendorInvoices",
            "/vendor/vendorBillDiscounting",
            "/vendor/request/onboarding"
        ]
    },

    // Customer Side 
    // Customer Sales Order Route
    "CSALMNGMAIN": {
        "customerSalesOrderStack": [
            "/salesManagement/customer/productCatalog",
            "/salesManagement/customer/viewSalesOrders",
            "/salesManagement/bag",
            "/salesManagement/customer/manageBuyingPlan"
        ]
    },

    // Retailer DigiProc 
    "RDPMAIN": {
        "digiProcStack": [
            "/digiproc/createOrder",
            "/digiproc/createIndent",
            "/purchase/purchaseIndentHistory",
            "/purchase/purchaseOrderHistory",
            "/purchase/viewpr",
            "/digiproc/excelUploadHistory",
            '/digiproc/manageOtbPlan',
            "/digiproc/manage/mrpRange",
            '/digiproc/fabricConsumptionLogs',
            '/digiproc/create/fabricNomination',
            '/digiproc/fabricNomination',
            '/core/custom/upload/digiproc'
        ]
    },

    // Digicatalogue Stack
    "PDRMAIN": {
        "digicatStack": [
            "/productCatalogue/addProducts",
            "/catalogue/products/viewProducts",
            "/catalogue/products/history",
            // "/catalogue/products/vendorProduct/list",
            "/productCatalogue/addProducts",
            "/catalogue/products/requested/products",
            "/catalogue/products/historyV2",
            "/catalogue/productSurvey/view",
            "/catalogue/retailer/survey/create",
            "/catalogue/retailer/survey/view"
            // "/catalogue/products/historyV2"
        ]
    },
    // Product catalogue stack 
    "PCVMAIN": {
        "digicatStack": [
            "/productCatalogue/addProducts",
            "/catalogue/products/history",
            "/productCatalogue/vendorProductRequest",
            "/catalogue/costSheet/products",
            "/catalogue/costSheet/library",
            "/catalogue/fabric/library",
            "/catalogue/indent/historyn",
            "/catalogue/products/viewProducts",
            "/catalogue/createIndent",
            "/catalogue/products/historyV2",
        ]
    }
}

export const stackNames = {
    "replenishmentStack": ReplenishmentStack,
    "planningSettingStack": PlanningSettingStack,
    "digiArsStack": DigiArsStack,
    "administrationStack": AdministrationStack,
    "masterDataStack": MasterDataStack,
    "salesOrderStack": SalesOrderStack,
    "superAdminStack": SuperAdminStack,
    "adminReportsStack": AdminReportsStack,
    "shipmentReportsStack": ShipmentReportsStack,
    "digivendStack": DigivendStack,
    "replenishmentReportsStack": ReplenishmentReportsStack,
    "inventoryManagementReportsStack": InventoryManagementReportsStack,
    "salesReportsStack": SalesReportsStack,
    "purchaseReportsStack": PurchaseReportsStack,
    "accoutingReportStack": AccountingReportsStack,
    "stockReportsStack": StockReportsStack,
    "logisticsReportsStack": LogisticsReportsStack,
    "customerSalesOrderStack": SalesOrderStack,
    "digiProcStack": DigiProcStack,
    "digicatStack": DigiCatStack,
}
