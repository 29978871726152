import React from 'react'
import { getTokenData } from '../../helper/apiHelperFunctions';
import ImgPath from '../../assets/images'
import '../../styles/stylesheets/home/LoadingPages.scss'
import GenericCustomButton from '../../genericComponents/GenericCustomButton';
import LanguageTranslator from '../../locals/LanguageTranslator';

const NoPageFound = () => {
    const home = () => {
        if (!getTokenData()) {
            window.location.href = "#/"
        } else {
            window.location.href = "#/home";
        }
    }

    return (
        <div className='page-error'>
            <div className="pe-btn">
                <GenericCustomButton 
                    onClick={home} 
                    className="backbuttonError"
                    label="BACK TO HOME"
                />
            </div>
            <div className="pe-content">
                <LanguageTranslator tag="h1"> 404</LanguageTranslator>
                <LanguageTranslator tag="h3">Sorry, this page or file is broken/could not be found!</LanguageTranslator>
                <LanguageTranslator tag="p">You might want to check your link or perhaps it doeen't exist anymore.</LanguageTranslator>
            </div>
            <div className="pe-footer">
                <span className="pef-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="345" height="70" viewBox="0 0 576 118">
                        <defs>
                            <linearGradient id="a" x1="-187.645%" x2="63.86%" y1="50%" y2="100%">
                                <stop offset="0%" stopColor="#1D00A2" />
                                <stop offset="100%" stopColor="#FFF" />
                            </linearGradient>
                        </defs>
                        <g fill="none" fillRule="nonzero">
                            <path className="rotatingLight" fill="url(#a)" d="M39.169 35.163L576 0l-44 50H39.184A7.184 7.184 0 0 1 32 42.816a7.67 7.67 0 0 1 7.169-7.653z" />
                            <path fill="#414141" d="M31.875 108.44h-1.594l-3.085-47.796h3.085V47.898h-3.187V38.34h3.187L15.938 24 1.594 38.339H4.78v9.56H1.594v12.745h3.085l-3.085 47.797H0V118h31.875v-9.56zM7.969 38.34h15.937v9.56H7.97v-9.56zM24.8 73.389l.598 9.56H6.477l.598-9.56H24.8zM11.156 57.459v-6.373h3.188v6.373h-3.188zm9.563 0H17.53v-6.373h3.188v6.373zM4.78 51.085H7.97v6.373H4.78v-6.373zm.102 57.356l.598-9.56h20.911l.598 9.56H4.883zm22.21-50.983h-3.187v-6.373h3.188v6.373z" />
                        </g>
                    </svg>
                </span>
                <div className="pef-img">
                    <img src={ImgPath.ErrorBackground} alt='No Page Found Error Background.' />
                </div>
            </div>
        </div>
    )
}

export default NoPageFound