import React, { useEffect, useState } from 'react';

const ToastLoader = ({ toastMsg }) => {
  const [width, setWidth] = useState(25 + Math.floor(Math.random() * 25))
  let interval = null;

  useEffect(() => {
    if (document.getElementById("topLoadingBarComplete") !== null) {
      document.getElementById("topLoadingBarComplete").style.width = "0";
      document.getElementById("topLoadingBarComplete").style.opacity = `100%`;
    }
    return (() => {
      if (document.getElementById("topLoadingBarComplete") != null) {
        document.getElementById("topLoadingBarComplete").style.width = `100%`;
        window.setTimeout(() => {
          if (document.getElementById("topLoadingBarComplete")) {
            document.getElementById("topLoadingBarComplete").style.opacity = "0";
          }
        }, 500);
      }
      setWidth(100);
    })
  }, [])

  useEffect(() => {

    interval = window.setInterval(() => {
      let newWidth = width;
      if (newWidth <= 85) {
        setWidth(newWidth += Math.floor((Math.random() * 10) + 1))
      }
    }, 50);

    return (() => {
      window.clearInterval(interval);
    })
  }, [width])

  return (
    <div>
      <div id="topLoadingBar" className="top-loading-bar" style={{ width: `${width}%` }}></div>
    </div>
  );
};

export default ToastLoader;
