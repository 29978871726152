import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ArsDashboardCard1 from './components/ArsDashboardCard1'
import Icons from '../../../assets/icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ArsSummarySkeletonLoader from '../../skeletons/ArsDashboardSkeletonLoader'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import LanguageTranslator from '../../../locals/LanguageTranslator'

const ArsDashboard = ({ jobName, newEngineData }) => {
	const digiArsStore = useSelector((state) => state.digiArsStore)
	const history = useHistory()
	const dispatch = useDispatch();

	const [transferOrderStatusSummary, setTransferOrderStatusSummary] = useState([])
	const [jobRunStatus, setJobRunStatus] = useState({})
	const [lastJobRunData, setLastJobRunData] = useState({})
	const [storeProductAllocation, setStoreProductAllocation] = useState({})
	const [totalReqData, setTotalReqData] = useState({})
	const [jobListSummary, setJobListSummary] = useState([]);

	useEffect(() => {
		dispatchHandler(dispatch, "getSummaryAllJobRequest", {});
	}, [])

	useEffect(() => {
		if (digiArsStore?.["getSummaryAllJobData"]?.isSuccess) {
			setJobListSummary(digiArsStore?.["getSummaryAllJobData"]?.data?.resource);
		}
	}, [digiArsStore?.["getSummaryAllJobData"]?.isSuccess])

	useEffect(() => {
		if (digiArsStore.getArsDashboardData?.isSuccess && digiArsStore?.getArsDashboardData?.data?.resource) {
			setTransferOrderStatusSummary(digiArsStore?.getArsDashboardData?.data?.resource?.transferOrderStatusSummary)
			setLastJobRunData(digiArsStore?.getArsDashboardData?.data?.resource?.lastJobRunData)
			setJobRunStatus(digiArsStore?.getArsDashboardData?.data?.resource?.jobRunStatus)
			setStoreProductAllocation(digiArsStore?.getArsDashboardData?.data?.resource?.storeProductAllocation)
			setTotalReqData(digiArsStore?.getArsDashboardData?.data?.resource?.totalReqData)
		}
	}, [digiArsStore.getArsDashboardData?.isSuccess])

	console.log('transferordersummary', transferOrderStatusSummary);

	// function to redirect to differnet pages
	const handleChange = (type) => {
		switch (type) {
			case 'lastJobRunData':
				history.push({
					pathname: '/inventoryPlanning/runOnDemand/wh'
				})
				break;

			case 'jobRunStatusTotal':
				history.push({
					pathname: '/inventoryPlanning/history'
				})
				break

			case 'jobRunStatusSuccessful':
				history.push({
					pathname: '/inventoryPlanning/history',
					state: { jobRunState: "SUCCEEDED" },
					datas: { manageRuleId: jobName === "SM-TURNINGCLOUD" ? 1 : 2 }
				})
				break

			case 'jobRunStatusFailed':
				history.push({
					pathname: '/inventoryPlanning/history',
					state: { jobRunState: "FAILED", manageRuleId: jobName === "SM-TURNINGCLOUD" ? 1 : 2 }
				})
				break

			case 'totalRequirementQty':
				history.push({
					pathname: '/inventoryPlanning/summary',
					search: '?status=%2522SUMMARY%2522',
					states: {
						jobData: {
							manageRuleId: jobName === "SM-TURNINGCLOUD" ? 1 : 2,
							activeJobName: jobName,
							jobList: jobListSummary, // this is to be integrate with implementation of getAllJob api
							redirectFromDashboard: true
						},
					},
				})
				break
			default:
				break;
		}
	}

	const storeProductAllocationMapping = {
		'total_product': 'Total Product',
		'total_store': 'Total Stores',
		'allocation_qty': 'Total Allocation Qty'
	}
	const storeAllocationUrlMapping = {
		'total_product': 'PRODUCT',
		'total_store': 'SITE',
		'allocation_qty': 'ALLOCATION'
	}

	// func to redirect storeProductAllocation
	const redirectFun = (key) => {
		console.log('redfunc', key);
		history.push({
			pathname: '/inventoryPlanning/summary',
			search: `?status=%2522${storeAllocationUrlMapping[key]}%2522`,
			states: {
				jobData: {
					manageRuleId: jobName === "SM-TURNINGCLOUD" ? 1 : 2,
					jobList: jobListSummary, // this is to be integrate with implementation of getAllJob api
					activeJobName: jobName,
					redirectFromDashboard: true
				},
			},
		})
	}

	// Define the desired order
	const transferOrderStatusSummaryOrder = ["PENDING", "APPROVED", "REJECTED", "CANCELLED"];

	return (
		<div className='tcd-tranfer-order'>
			{digiArsStore?.["getArsDashboardData"]?.isLoading ? <ArsSummarySkeletonLoader /> :
				<React.Fragment>
					{/* transferOrderStatusSummary */}
					<div className='flex'>
						{transferOrderStatusSummary?.sort((a, b) => transferOrderStatusSummaryOrder?.indexOf(a?.status) - transferOrderStatusSummaryOrder?.indexOf(b?.status))
							.map((transferOrder) => {
								return <ArsDashboardCard1 jobName={jobName} newEngineData={newEngineData} status={transferOrder.status} pendingQty={transferOrder.pending_qty} totalQty={transferOrder.total} />
							})
						}
					</div>
					<div className='flex p-top-12'>
						{/* lastJobRunData */}
						<div className='tcd-jobs' onClick={() => handleChange('lastJobRunData')}>
							<div className='flex justify-center'>
								{lastJobRunData?.last_job_run_status === 'SUCCEEDED' ? <Icons.GreenTick /> : <Icons.ArsDFailedIcon />}
							</div>
							<LanguageTranslator tag="p" className='p-normal'>Last Job Run Status</LanguageTranslator>
							<LanguageTranslator tag="h3" className={lastJobRunData?.last_job_run_status === 'SUCCEEDED' ? 'h3-succeed' : ''}>{lastJobRunData?.last_job_run_status}</LanguageTranslator>
							<LanguageTranslator tag="p" className='p-normal m-top-10'>{lastJobRunData?.last_job_run_timestamp}</LanguageTranslator>
						</div>
						{/* jobRunStatus */}
						<div className='tcd-status' onClick={() => handleChange('jobRunStatusTotal')}>
							<div className='tcds-left'>
								<LanguageTranslator tag="h1"> {jobRunStatus?.totalJobsRun}</LanguageTranslator>
								<LanguageTranslator tag="p">Total Jobs Run</LanguageTranslator>
							</div>
							<div className='tcds-right'>
								<div className='tcdsr-top' onClick={() => handleChange('jobRunStatusSuccessful')} >
									<LanguageTranslator tag="h1">{jobRunStatus?.successfulJobs}</LanguageTranslator>
									<LanguageTranslator tag="p">Sucessfull Jobs</LanguageTranslator>
								</div>
								<div className='tcdsr-bot' onClick={() => handleChange('jobRunStatusFailed')}>
									<LanguageTranslator tag="h1">{jobRunStatus?.failedJobs}</LanguageTranslator>
									<LanguageTranslator tag="p" className='p-failed'>Failed Jobs</LanguageTranslator>
								</div>
							</div>
						</div>
						{/* storeProductAllocation */}
						{Object.keys(storeProductAllocation).map((key) => {
							return (
								<div className='tcd-jobs' onClick={() => redirectFun(key)}>
									<LanguageTranslator tag="h1">{storeProductAllocation[key]}</LanguageTranslator>
									<LanguageTranslator tag="p">{storeProductAllocationMapping[key]}</LanguageTranslator>
								</div>
							)
						})}
						{/* totalReqData */}
						<div className='tcd-jobs' onClick={() => handleChange('totalRequirementQty')}>
							<LanguageTranslator tag="h1">{totalReqData?.total_req_qty}</LanguageTranslator>
							<LanguageTranslator tag="p">Total Requirement Qty</LanguageTranslator>
						</div>
					</div>
				</React.Fragment>}

		</div>
	)
}

export default ArsDashboard
