import React, { useState, useContext } from 'react'
import { deleteCookie, parseJwt } from '../../../helper/genericFunction'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import Icons from '../../../assets/icons';
import MergeAccountModal from './MergeAccountModal';
import ParentModal from '../../../genericComponents/ParentModal';
import FullPageLoader from '../../../loaders/FullPageLoader';
import { deleteDB } from 'idb';
import { InitialAnnouncementModal } from '../../../initialPhaseOperations/InitialAnnouncementModal';
import { ReloadContext, UserDetailsContext } from '../../../App';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';

const HeaderStrip = (props) => {
    const dispatch = useDispatch();
    const { handleDocumentStatusChange } = useContext(ReloadContext);
    const {clearUserContext} = useContext(UserDetailsContext);
    const [selectedModal, setSelectedModal] = useState("");

    const tokenData = parseJwt(sessionStorage.getItem("token"));

    const getValue = () => {
        let loginStripMessage = tokenData?.loginStripMessage;
        let loggedMessageArray = loginStripMessage?.split(" ");
        let asIndex = loggedMessageArray?.indexOf("as");
        let fromIndex = loggedMessageArray?.indexOf("from");
        let firstName = loggedMessageArray?.slice(asIndex + 1, fromIndex).join(" ");
        let lastName = loggedMessageArray?.slice(fromIndex + 1).join(" ");
        return {
            firstName,
            lastName
        }
    }

    // Function : To handle the merging of the account
    const handleMergeAccount = () => {
        setSelectedModal("mergeAccountModal");
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "mergeAccountModal": return MergeAccountModal
            case "fullPageLoader": return FullPageLoader;
        }
    }

    const logoutFunction = async () => {
        setSelectedModal("fullPageLoader")
        dispatchHandler(dispatch, "userSessionCreateRequest", { token: sessionStorage.getItem('token') || "", flowType: "LOGGEDOUT" });
        deleteCookie('jwt')
        localStorage.clear();
        sessionStorage.clear();
        clearUserContext?.();
        setTimeout(() => {
            setSelectedModal(false);
            handleDocumentStatusChange();
        }, 1000);
    }

    const getParentChildProps = () => {
        switch (selectedModal) {
            case "mergeAccountModal": {
                return {
                    closeModal: () => { setSelectedModal(false) },
                    postitionProps: { top: "10%", left: "30%", position: "fixed", borderRadius: 4 },
                    dimensions: { width: "40vw", height: "80vh" },
                    animationType: "center",
                    submitModal: () => {
                        logoutFunction();
                    }
                }
            }
            case "fullPageLoader": {
                return {}
            }
        }
    }

    return (
        <React.Fragment>
            {tokenData?.["loginStripMessage"] ?
                <div className="show-logged-strip">
                    <p>
                        <div className="strip-info-icon">
                            <Icons.LoginStripIcon />
                        </div>
                        {/* {loginStripMessage} */}
                        Currently you are loggedin as
                        <button type="button">{getValue()?.firstName}</button> from
                        <button type="button">{getValue()?.lastName}</button>
                    </p>
                </div> : null
            }
            {
                (tokenData?.uType === "VENDOR" && +tokenData?.isMergeEligible === 1) ? <div className="show-logged-strip">
                    <p>
                        <div className="strip-info-icon">
                            <Icons.LoginStripIcon />
                        </div>
                        <label>One click away from account merge !</label>
                        Complete the merging process now to avail benefits of merging and access your account from one place.
                        <span onClick={handleMergeAccount}>Know More</span>
                        <GenericCustomButton
                            className='complete-merge-btn'
                            label="Complete Merge Process"
                            onClick={() => handleMergeAccount()}
                        />
                    </p>
                </div> : null
            }

            {/* componet for the announcemnts */}
            {/* to render the annoucement and the final subscription */}
            <InitialAnnouncementModal tokenData={tokenData} {...props}/>


            {selectedModal && <ParentModal
                childComponent={getChildComponent()}
                getParentChildProps={getParentChildProps()}
                closeModal={() => setSelectedModal(false)}
            />}
        </React.Fragment>
    )
}

export default HeaderStrip