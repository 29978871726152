import React from 'react'
const divsArray = Array.from({ length: 20 }, index => index + 1);

const PANIndiaSkeleton = () => {
  return (
    <div className='ysm-skeleton'>
      <div className='flex w-100 h-100'>
        <div className='w-50 p-rgt-20'>
          <div className='generic-skeleton wid-150'></div>
          <div className='ars-graph-box'>
            <div className='flex flex-col h-100 w-100'>
              {divsArray.map(() => (
                <div className='generic-skeleton w-100 p-5'></div>
              ))}
            </div>
          </div>
        </div>
        <div className='w-50'>
          <div className='generic-skeleton wid-150'></div>
          <div className='ars-graph-box'>
            <div className='flex flex-col h-100 w-100'>
              {divsArray.map(() => (
                <div className='generic-skeleton w-100 p-5'></div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PANIndiaSkeleton