import React from 'react'
const divsArray = Array.from({ length: 4 }, index => index + 1);

const ArsDashboardSkeletonLoader = () => {
    return (
        <>
            <div className='flex'>
                {divsArray.map(() => (
                    <div className='tcdt-card hgt-195 flex flex-col justify-between p-lr-40 p-tb-10'>
                        <div className='flex justify-center'>
                            <div className='generic-skeleton wid-42 hgt-42' style={{ borderRadius: "50%" }}></div>
                        </div>
                        <h1>
                            <div className='generic-skeleton'></div>
                        </h1>
                        <h1>
                            <div className='generic-skeleton'></div>
                        </h1>
                        <h1>
                            <div className='generic-skeleton'></div>
                        </h1>
                    </div>
                ))}
            </div>
            <div className='flex p-top-12'>
                <div className='tcd-jobs hgt-160'>
                    <div className='flex justify-center'>
                        <div className='generic-skeleton wid-35 hgt-35' style={{ borderRadius: "50%" }}></div>
                    </div>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                </div>
                <div className='tcd-status hgt-160'>
                    <div className='tcds-left p-lr-20'>
                        <div className='generic-skeleton wid-100'></div>
                        <div className='generic-skeleton wid-100'></div>
                    </div>
                    <div className='tcds-right'>
                        <div className='tcdsr-top p-top-10'>
                            <div className='generic-skeleton wid-100'></div>
                            <div className='generic-skeleton wid-100'></div>
                        </div>
                        <div className='tcdsr-bot p-top-10'>
                            <div className='generic-skeleton wid-100'></div>
                            <div className='generic-skeleton wid-100'></div>
                        </div>
                    </div>
                </div>
                <div className='tcd-jobs hgt-160'>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                </div>
                <div className='tcd-jobs hgt-160'>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                </div>
                <div className='tcd-jobs hgt-160'>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                </div>
                <div className='tcd-jobs hgt-160'>
                    <div className='generic-skeleton wid-100'></div>
                    <div className='generic-skeleton wid-100'></div>
                </div>
            </div>
        </>
    )
}

export default ArsDashboardSkeletonLoader