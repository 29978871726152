import React from "react";
import moment from "moment";
// import GenericButton from "./genericButton";
import $ from 'jquery'
import GenericCustomButton from "../GenericCustomButton";
import LanguageTranslator from "../../locals/LanguageTranslator";

export default class RightClickModal extends React.Component {
    constructor(props) {
        super(props);
        this.rightClickRef = React.createRef();
        this.state = {
            filterItems: { ...this.props.filterItems },
            checkedFilters: {}
        }
    }
    applyFilter = () => {
        let filterValue = Object.values(this?.props?.selectedItems).map(item => item[this.props.selectedColumn])?.toString() || "SM_TEXT_EMPTY_VALUE";
        console.log("checkconsoletezt", filterValue);

        switch (this.state.filterItems.columnType[this.props.selectedColumn]) { 
            case "NUMBER": {
                this.setState({
                    checkedFilters: this.state.checkedFilters[this.props.selectedColumn] = {
                        from: filterValue,
                        to: filterValue
                    }
                });
                break;
            }
            case "DATE": {
                this.setState({
                    checkedFilters: this.state.checkedFilters[this.props.selectedColumn] = {
                        from: filterValue == "SM_TEXT_EMPTY_VALUE" ? filterValue : moment(filterValue).format('YYYY-MM-DD'),
                        to: filterValue == "SM_TEXT_EMPTY_VALUE" ? filterValue : moment(filterValue).format('YYYY-MM-DD')
                    }
                });
                break;
            }
            case "TIME": {
                this.setState({
                    checkedFilters: this.state.checkedFilters[this.props.selectedColumn] = {
                        from: filterValue == "SM_TEXT_EMPTY_VALUE" ? filterValue : moment(filterValue).format('YYYY-MM-DD HH:mm'),
                        to: filterValue == "SM_TEXT_EMPTY_VALUE" ? filterValue : moment(filterValue).format('YYYY-MM-DD HH:mm')
                    }
                });
                break;
            }
            default: {
                this.setState({
                    checkedFilters: this.state.checkedFilters[this.props.selectedColumn] = filterValue
                });
            }
        }
        this.props.applyFilter(this.state.checkedFilters)();
        this.props.close();
    }
    componentDidMount() {
        document.addEventListener('click', this.hideModal)
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.hideModal)
    }
    hideModal = (e) => {
        if (this.rightClickRef && !this.rightClickRef.current.contains(e.target)) {
            this.props.close()
        }
    }
    render() {
        const { actionBarConfig } = this.props;
        setTimeout(() => {
            let top = this.props.topPosition;
            let newLeft = this.props.leftPosition;
            $('.right-click-menu').css({ 'left': newLeft, 'top': top, visibility: "visible", display: "block", });
            $('.right-click-menu').removeClass('hideSmoothly');
        }, 0)
        return (
            <React.Fragment>
                <div className="backdrop-transparent zIndex9" onClick={this.props.close} onContextMenu={this.props.close}></div>
                <div className="right-click-menu" id="myDiv" ref={this.rightClickRef} style={{ visibility: "hidden", display: "none" }}>
                    <ul className="rcm-ul">
                        <li className="rcmul-heading">Table Action</li>
                        <li className="rcmu-list">
                            <button  type="button" onClick={() =>  {
                                this.applyFilter()
                            }}>
                                <LanguageTranslator>Search on selected Data</LanguageTranslator>
                            </button>
                        </li>
                        <li className="rcmu-list">
                            <button type="button" className={Object.keys(this.props.headersData.main["Mandate Headers"]) == 0 ? 'mayan1' : !Object.keys(this.props.headersData.main["Mandate Headers"]).some(key => key == this.props.selectedColumn) ? 'maya2n' : 'opacity04'} onClick={() => Object.keys(this.props.headersData.main["Mandate Headers"]) == 0 ? this.props.rightClickAction('hideColumn') : !Object.keys(this.props.headersData.main["Mandate Headers"]).some(key => key == this.props.selectedColumn) ? this.props.rightClickAction('hideColumn',) : null}>
                                <LanguageTranslator>Hide selected column</LanguageTranslator>
                            </button>
                        </li>
                        {actionBarConfig.rhsExtras &&
                            <div className="rcmul-page-action">
                                <li className="rcmul-heading">Page Actions</li>
                                <li className="rcmu-list">
                                    {actionBarConfig && actionBarConfig.newrhsExtras ? (
                                        actionBarConfig.newrhsExtras(this.props.selectedItems)) : (
                                        actionBarConfig.rhsExtras && actionBarConfig.rhsExtras(this.props.selectedItems)
                                    )}
                                    {actionBarConfig.moreMenu && Object.keys(actionBarConfig.moreMenu.options(this.props.selectedItems)).map(key => {
                                        let item = actionBarConfig.moreMenu.options(this.props.selectedItems)[key];
                                        return (
                                            <>
                                                <GenericCustomButton
                                                    sendEventAnalyticsToGoogle = {true}
                                                    eventAnlyticsLabel = {`rightClick-${item?.label}`}
                                                    key={key}
                                                    className={`generic-approve-btn gvpdtdm-${item.type} ${item.disabled && "opacity04"}`}
                                                    onClick={item.disabled ? null : item.confirm ? this.props.ConfirmAction(key) : item.excelExport ? item.onClick(this.props.tableDataPayload) : item.onClick}
                                                    disabled={false}
                                                    loading={false}
                                                    label={item.label}
                                                />
                                            </>

                                        );
                                    })}
                                    {actionBarConfig && actionBarConfig.documentLog &&
                                        <GenericCustomButton
                                            className={`gvpdtdm-inner generic-default-btn`}
                                            onClick={() => this.props.handleTrueFalse("openDocumentLog", this.props.selectedItems)}
                                            disabled={Object.keys(this.props.selectedItems).length !== 1}
                                            loading={false}
                                            label="Logs"
                                        />}
                                </li>
                            </div>}
                    </ul>
                </div>
            </React.Fragment>
        )
    }

}