import React from 'react'
import GenericCustomButton from '../GenericCustomButton';
import IconsPath from '../../assets/icons/index'
import LanguageTranslator from '../../locals/LanguageTranslator';

const GenericFormHeaderComponent = (props) => {

    const {label, closeModal, customStyles = {}} = props;

    return (
        // Render the Generic Form Header Component
        <div className='modal-header-section'>

            {/* The content of the header component */}
            {typeof(label) == "function" ? label() : <div className='mhs-left'>
                {/* Render the primary and secondry content  */}
                {label?.contentPrimary && ( typeof(label?.contentPrimary) == "function" ? label?.contentPrimary() :<LanguageTranslator tag="h4">{label?.contentPrimary || ""}</LanguageTranslator>)}
                {label?.contentSecondry && ( typeof(label?.contentSecondry) == "function" ? label?.contentSecondry() :<LanguageTranslator tag="p">{label?.contentSecondry || ""}</LanguageTranslator>)}
            </div>}


            {props.rightComponent && (typeof(props?.rightComponent) === "function" ? props?.rightComponent() : props?.rightComponent )}
            {/* The right side options for the header component */}
            <div className='mhs-right'>
            {props.rowEndComponent && (typeof(props?.rowEndComponent) === "function" ? props?.rowEndComponent() : props?.rowEndComponent) }
                {/* Render the close icon */}
                {/* TODO: Create the hover effect on this close icon */}
                {closeModal && 
                    <>
                        <LanguageTranslator tag="span" className='mhs-close-sortcode'>Esc</LanguageTranslator>
                        <span className='mhs-line-saprator'></span>
                        <GenericCustomButton
                            onClick={closeModal}
                            // type='default'
                            disabled={false}
                            loading={false}
                            leftIcon={<IconsPath.ModalCloseIcon/>}
                        />
                    </>
                }
            </div>

        </div>  
    )
}

export default GenericFormHeaderComponent