import React from 'react';
import ReactDragListView from 'react-drag-listview';
import ConfirmationModal from './ConfirmationModal';
import IconsPath from "../../assets/icons/index";
import LanguageTranslator from '../../locals/LanguageTranslator';

export default class ColumnSettingModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            search: "",
            saveEnabled: false,
            confirmationModal: false,
            currentTab: "main",
            customHeaders: Object.keys(this.props.headersData.main.data).length === 0 ? [] : Object.keys(this.props.headersData.main.data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData.main.data["Default Headers"]) : Object.keys(this.props.headersData.main.data["Custom Headers"]),
            availableHeaders: Object.keys(this.props.headersData.main.data).length === 0 ? [] : Object.keys(this.props.headersData.main.data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData.main.data["Fixed Headers"]).filter(key => this.props.headersData.main.data["Default Headers"][key] === undefined) : Object.keys(this.props.headersData.main.data["Fixed Headers"]).filter(key => this.props.headersData.main.data["Custom Headers"][key] === undefined)
        }
    }

    changeTab = (tab) => {
        this.setState({
            currentTab: tab,
            customHeaders: Object.keys(this.props.headersData[tab].data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData[tab].data["Default Headers"]) : Object.keys(this.props.headersData[tab].data["Custom Headers"]),
            availableHeaders: Object.keys(this.props.headersData[tab].data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData[tab].data["Fixed Headers"]).filter(key => this.props.headersData[tab].data["Default Headers"][key] === undefined) : Object.keys(this.props.headersData[tab].data["Fixed Headers"]).filter(key => this.props.headersData[tab].data["Custom Headers"][key] === undefined)
        });
    }

    componentDidMount() {
        setTimeout(() => {
            document.addEventListener('click', this.hideModal);
        }, 0);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.hideModal);
    }

    hideModal = (e) => {
        if (this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            console.log(4444444, e.target.classList)
            const shouldNotClose = e.target.classList.contains('chmblh-items') || e.target.classList.contains('chmbrh-items') || e.target.classList.contains('chmbrhi-close') || e.target.classList.contains('chmbrhi-close-1') || e.target.classList.contains('cs-prefix') || e.target.classList.contains('checkBoxLabel0');
            if (!shouldNotClose) {
                this.props.close();
            }
        }
    }

    addColumn = (key) => {
        this.setState(prevState => {
            let customHeaders = [...prevState.customHeaders], availableHeaders = [...prevState.availableHeaders];
            customHeaders.push(key);
            availableHeaders.splice(availableHeaders.indexOf(key), 1);
            return {
                customHeaders,
                availableHeaders
            }
        }, this.handleSaveButton);
    }

    deleteColumn = (key) => {
        this.setState(prevState => {
            let customHeaders = [...prevState.customHeaders], availableHeaders = [...prevState.availableHeaders];
            customHeaders.splice(customHeaders.indexOf(key), 1);
            availableHeaders.push(key);
            return {
                customHeaders,
                availableHeaders
            }
        }, this.handleSaveButton);
    }

    handleSaveButton = () => {
        let propsKeys = Object.keys(this.props.headersData[this.state.currentTab].data["Custom Headers"]).length === 0 ? Object.keys(this.props.headersData[this.state.currentTab].data["Default Headers"]) : Object.keys(this.props.headersData[this.state.currentTab].data["Custom Headers"]);
        let stateKeys = [...this.state.customHeaders];
        let saveEnabled = stateKeys.length !== propsKeys.length || stateKeys.some((key, index) => key !== propsKeys[index]);
        this.setState({
            saveEnabled
        });
    }

    saveColumnSetting = () => {
        let customHeadersObj = {};
        this.state.customHeaders.forEach(key => {
            customHeadersObj[key] = this.props.headersData[this.state.currentTab].data["Fixed Headers"][key];
        });
        console.log('custome', customHeadersObj)
        let payload = {
            displayName: this.props.headersData[this.state.currentTab].key,
            fixedHeaders: this.props.headersData[this.state.currentTab].data["Fixed Headers"],
            defaultHeaders: this.props.headersData[this.state.currentTab].data["Default Headers"],
            customHeaders: customHeadersObj
        }
        this.props.save(payload);
        this.props.close();
    }

    resetColumnSetting = () => {
        this.setState({
            confirmationModal: true
        });
    }

    onConfirmation = () => {
        let payload = {
            displayName: this.props.headersData[this.state.currentTab].key,
            fixedHeaders: this.props.headersData[this.state.currentTab].data["Fixed Headers"],
            defaultHeaders: this.props.headersData[this.state.currentTab].data["Default Headers"],
            customHeaders: [],
        }
        this.props.save(payload);
    }

    onCancellation = () => {
        this.setState({
            confirmationModal: false
        });
    }

    render() {
        if (Object.keys(this.props.headersData.main.data).length === 0) {
            return (
                <div className="columnFilterGeneric">
                    <span className="glyphicon" onClick={this.props.close}>
                        <svg className='rotate180' width="8" height="11" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.89781 0.491142C7.2558 0.849122 7.2558 1.42952 6.89781 1.7875L2.96266 5.72266L6.89781 9.65781C7.2558 10.0158 7.2558 10.5962 6.89781 10.9542C6.53983 11.3122 5.95943 11.3122 5.60145 10.9542L1.01812 6.37084C0.660139 6.01286 0.660139 5.43246 1.01812 5.07448L5.60145 0.491142C5.95943 0.133161 6.53983 0.133161 6.89781 0.491142Z" fill="#fff"></path>
                        </svg>
                    </span>
                    <div className="columnSetting">Columns Setting</div>
                    <div className="backdrop-transparent" id="columnSettingBackdrop"></div>
                    <div className="columnFilterDropDown custom-header-modal">
                        <div className="chm-body">
                            <div className="chmb-nodata">
                                <img src={require('../../assets/icons/no-header.svg')} /><span>No Headers available</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.customHeaders];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ customHeaders: data });
            },
            nodeSelector: "li",
            handleSelector: "span",
            lineClassName: "chmb-drag-line"
        };

        return (
            <div className="columnFilterGeneric" ref={this.modalRef}>
                <span className="glyphicon btr-radius" onClick={this.props.close}>
                    <svg className='rotate180' width="8" height="11" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.89781 0.491142C7.2558 0.849122 7.2558 1.42952 6.89781 1.7875L2.96266 5.72266L6.89781 9.65781C7.2558 10.0158 7.2558 10.5962 6.89781 10.9542C6.53983 11.3122 5.95943 11.3122 5.60145 10.9542L1.01812 6.37084C0.660139 6.01286 0.660139 5.43246 1.01812 5.07448L5.60145 0.491142C5.95943 0.133161 6.53983 0.133161 6.89781 0.491142Z" fill="#fff"></path>
                    </svg>
                </span>
                <LanguageTranslator tag="div" className="columnSetting">Columns Setting</LanguageTranslator>
                {/* <div className="backdrop-transparent" id="columnSettingBackdrop"></div> */}
                <div className="columnFilterDropDown custom-header-modal" id="columnSettingModal" >
                    <div className="col-lg-12 filterHeader chm-head flex justify-between">
                        <div className="col-lg-7 col-md-7 pad-0">
                            <div className="chmh-search">
                                <input type="search" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} placeholder="Search Columns..." />
                                {this.state.search == "" ?
                                    <div className='chmhs-search-ion'><IconsPath.SearchIcon /></div> :
                                    <button className="chmhs-cross" onClick={(e) => this.setState({ search: "" })}>
                                        <IconsPath.ClearSearch />
                                    </button>}
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 pad-right-0 columns">
                            <div className="chmh-right">
                                <button className="resetBtn" onClick={this.resetColumnSetting}><LanguageTranslator>Reset to default</LanguageTranslator></button>
                                <button className={`saveBtn ${!this.state.saveEnabled && "opacity04"}`} disabled={this.state.saveEnabled ? "" : "disabled"} onClick={this.saveColumnSetting}><LanguageTranslator>Save</LanguageTranslator></button>
                            </div>
                        </div>
                    </div>
                    <div className="chm-body">
                        <div className="chmb-sidebar">
                            <ul className="chmbs-menu">
                                {Object.keys(this.props.headersData).map(key =>
                                    <li key={key} className={`chmbsm-item ${this.state.currentTab == key ? "active" : ""}`} onClick={() => this.changeTab(key)}>
                                        <LanguageTranslator tag="span">{this.props.headersData[key].name}</LanguageTranslator>
                                    </li>)}
                            </ul>
                        </div>
                        <div className="chmb-left">
                            <div className="chmbl-head">
                                <LanguageTranslator tag="h3">Available Headers</LanguageTranslator>
                                <span><LanguageTranslator>Total Items</LanguageTranslator> <span className="bold">{this.state.availableHeaders.length}</span></span>
                            </div>
                            <ul className="chmbl-headers">
                                {this.state.availableHeaders.length === 0 ?
                                    <div className="chmbl-nodata">
                                        <div className='flex justify-center'><IconsPath.NoHeader /></div>
                                        <LanguageTranslator tag="span">No Headers Available</LanguageTranslator>
                                    </div> :
                                    this.state.availableHeaders.map(key =>
                                        key.toLowerCase().includes(this.state.search.toLowerCase()) ?
                                            <li key={key} className="chmblh-items">
                                                <label className="checkBoxLabel0" onClick={() => this.addColumn(key)}>
                                                    <input type="checkBox" /> {/* REMOVE ONCE IMPLEMENTED EVERYWHERE */}
                                                    {this.props.headersData[this.state.currentTab].data["Fixed Headers"][key]}
                                                </label>
                                            </li> :
                                            null
                                    )}
                            </ul>
                        </div>
                        <div className="chmb-right">
                            <div className="chmbr-head">
                                <LanguageTranslator tag="h3">Selected Headers</LanguageTranslator>
                                <span><LanguageTranslator>Total Items</LanguageTranslator> <span className="bold"></span>{this.state.customHeaders.length}</span>
                            </div>
                            <ReactDragListView {...dragProps}>
                                <ul className="chmbr-headers" onDragEnd={this.handleSaveButton}>
                                    {this.state.customHeaders?.length === 0 ? null :
                                        this.state?.customHeaders?.map(key =>
                                            <li key={key} className="chmbrh-items">
                                                <span className="chmbrhi-inner">
                                                    <span className="chmbrhi-text" tag='span'>{this.props?.headersData[this.state?.currentTab]?.data["Fixed Headers"]?.[key]}</span>
                                                    {this.props?.headersData?.[this.state?.currentTab]?.data?.["Mandate Headers"]?.[key] === undefined ?
                                                        <button className="chmbrhi-close" onClick={() => this.deleteColumn(key)}>
                                                            <IconsPath.ClearSearch className='chmbrhi-close-1' />
                                                        </button> :
                                                        <LanguageTranslator tag="span" className="chmbrhi-fixed">Fixed</LanguageTranslator>}
                                                </span>
                                            </li>
                                        )}
                                </ul>
                            </ReactDragListView>
                        </div>
                    </div>
                </div>
                {this.state.confirmationModal &&
                    <ConfirmationModal
                        headMsg="Reset columns setting to default?"
                        loading={this.props.loading}
                        onCancellation={this.onCancellation}
                        onConfirmation={this.onConfirmation}
                        paraMsg="Are you sure you want to perform this action? It cannot be undone."
                    />}
            </div>
        )
    }
}