import React, { useState } from 'react'
import GenericTabsComponent from '../../../../../genericComponents/GenericTabsComponent';
import WeChatConnect from './WeChatConnect';
import SocketConnectionError from '../../../../../socket/SocketConnectionError';

const Settings = () => {

    const [currentTab, setCurrentTab] = useState("wechatConnect");

    return (
        <div className='settingsTab'>
            <GenericTabsComponent
                tabsData={{
                    "wechatConnect": "Connect WeChat",
                    "2fa": "Configure 2FA"
                }}
                handleTabChange={(val) => {
                    setCurrentTab(val);
                }}
                current={currentTab}
                type={1}
            />

            {
                currentTab === "wechatConnect" && <SocketConnectionError>
                    <WeChatConnect
                        currentTab={currentTab}
                    />
                </SocketConnectionError>
            }

            {
                currentTab === "2fa" && <SocketConnectionError>
                    <WeChatConnect
                        currentTab={currentTab}
                    />
                </SocketConnectionError>
            }
        </div>
    )
}

export default Settings
