import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'


const LoginUsing2FA = (props) => {

    const {signUpLoading, setSignUpLoading} = props;

    const [otp, setOtp] = useState('')

    const verifyOtp = () => {
        props.loginAgain({
            mfaPasscode : btoa(otp)
        })
    }

    useEffect(() => {
        if (otp?.length === 6 && !signUpLoading) {
            verifyOtp()
        }
    }, [otp])

    return (
        <div className='px-[26px] text-[#0F172A] text-xs mt-4 mb-6'>
            <h2 className='text-[1rem] font-semibold'>Enter 2FA authentication Code</h2>
            <div className='wechatOtpInput'>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props, index) => <input id={`otpInput${index}`} {...props} autoComplete="off" />}
                    shouldAutoFocus={true}
                    inputType={"tel"}
                    placeholder="0"
                    containerStyle={{fontSize:'1rem', marginTop:'15px', marginBottom:'12px'}}
                />
            </div>

            <p className='text-[#71819B]'>Please enter 6 digit authentication code</p>

            <div className='mt-16'>
                <p className='mb-3'><span className='font-bold'>Note</span> : Open authenticator app on your phone to find the authentication code.</p>
                <GenericCustomButton
                    label="Verify & Login"
                    disabled={signUpLoading || otp?.length != 6}
                    loading = {signUpLoading}
                    onClick={verifyOtp}
                    className={'w-full bg-primary p-[12px] rounded text-white font-medium hover:opacity-75'}
                />
            </div>
        </div>
    )
}

export default LoginUsing2FA
