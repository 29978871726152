let CONFIG = {
  MICRO_SERVICE_BASE_URL: "https://smserviceqa.supplymint.com",
  MICRO_PRODUCTION_BASE_URL: "https://smservicelive.supplymint.com",
  SM_SERVICE_BASE_URL: "https://smservicelive.supplymint.com",

  ROLES: "/admin/role",
  SITE_MAPPING: "/admin/sitemap",
  USER: "/admin/user",   
  CORE_USER: "/core/user",
  ORGANIZATION: "/core/org",
  SITE: "/admin/site",
  ALLOCATION: "/admin/allocation",
  MCSTR: "/admin/mcstr",
  MODULE: "/core/rolemaster",
  PI: '/admin/pi',
  PO: '/admin/po',
  VENDOR: "/tenant/vendor",
  REPLENISHMENT: "/rule/engine/job",
  ASSORTMENT: "/assortment",
  INV: "/inv/plan",
  OTB: "/demand/otb",
  STORE_PROFILE: "/analytics/storeprofile",
  DEMAND_PLANNING: "/demand/plan",
  BUDGETED: "/demand/budgeted",
  DATASYNC: "/custom/datasync",
  INV_CONFIG: "/inv/config",

  VENDORPROTAL: "/vendorportal",
  ARS: "/ars/plan",
  SYSTEM_CONFIG: "/system/config",
  VENDINVOICEMANAGEMENT: "/vendor/invoice/management",
  CORE_CUSTOMER: "/core/customer",
  SO: "/admin/so",
  CUST: "/customer/so",
};  


const temp = {
  "production" :  "https://prodserver.supplymint.com",
  "quality" : "https://qaserver.supplymint.com",  
  "dev" : "https://devserver.supplymint.com",
  "sandbox" : "https://sandboxserver.supplymint.com"
}

const smService = {
  "production" : CONFIG.MICRO_PRODUCTION_BASE_URL,
  "quality"  : CONFIG.MICRO_SERVICE_BASE_URL,
  "development" : CONFIG.MICRO_SERVICE_BASE_URL,
  "sandbox" : CONFIG.MICRO_SERVICE_BASE_URL
}

const notifyapplication = {
  "production" :  "https://notifyapp.supplymint.com",
  "quality" :  "https://notifyappqa.supplymint.com",
  "development"  : "https://notifyappdev.supplymint.com",
  // "production" :  "http://localhost:4000"
}

const socketNotifyapplication = {
  "production" :  "https://notifyapp.supplymint.com",
  "quality" :  "https://notifyappqa.supplymint.com",
  "development"  : "https://notifyappdev.supplymint.com",
  //  "production" :  "http://localhost:4000"
}

let java17ServerApplication = {
  "production": "https://smbackendprod.supplymint.com",
  "quality": "https://smbackendqa.supplymint.com",
  "development": "https://smbackenddev.supplymint.com",
}


console.log("Environment :: " + process.env.REACT_APP_NODE_ENV);

CONFIG = {
  ...CONFIG, 
  BASE_URL: temp[process.env.REACT_APP_NODE_ENV ?? "production"], 
  BASE_MICRO_SERVICE_URL : smService[process.env.REACT_APP_NODE_ENV ?? "production"] ,
  NOTIFICATION_ROUTE_PROD : notifyapplication?.[process.env.REACT_APP_NODE_ENV ?? "production"],
  NOTIFICATION_ROUTE_PROD_SOCKET :  socketNotifyapplication?.[process.env.REACT_APP_NODE_ENV ?? "production"],
  SM_BACKEND_BASE_URL : java17ServerApplication?.[process.env.REACT_APP_NODE_ENV ?? "production"]
}

export { CONFIG };
