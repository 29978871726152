import GenericFormFooterComponent from "./forms/GenericFormFooterComponent";
import IconsPath from '../assets/icons/index'
import LanguageTranslator from "../locals/LanguageTranslator";

const GenericConfirmationModal = ({ closeModal, buttons, labelComponent, message, icon, headerComponent, extraClass }) => {

	return (
		<div className={`generic-confirmation-modal ${extraClass}`}>
			<div className="gcm-head">
				{icon ? <div className="ncmt-img">
					{icon && typeof icon === 'function' ? icon() : icon}
				</div> : <div></div>}
				<button type="button" onClick={closeModal} ><IconsPath.ModalCloseIcon /></button>
			</div>
			{headerComponent && typeof (headerComponent) == "function" ? headerComponent() : headerComponent}
			<div className={`gcm-body`}>
				{labelComponent && typeof labelComponent === 'function' ? labelComponent() : <LanguageTranslator tag='h3'>{labelComponent}</LanguageTranslator>}
				{message && typeof message === 'function' ? message() : message}
			</div>
			<GenericFormFooterComponent
				buttons={buttons}
			/>
		</div>
	);
};

export default GenericConfirmationModal;
