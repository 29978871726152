import React from 'react'
const divsArray = Array.from({ length: 11 }, index => index + 1);
const divsArray2 = Array.from({ length: 30 }, index => index + 1);

const DivisionTabTableSkeleton = () => {
    return (
        <>
            <div className='p-tb-10 p-lft-10'>
                <div className='generic-skeleton wid-200'></div>
            </div>
            <div className='dsr-skeleton ds-division-wise'>
                <table>
                    <tbody>
                        {divsArray2.map(() => (
                            <tr>
                                {divsArray.map(() => (
                                    <td>
                                        <div className='generic-skeleton'></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DivisionTabTableSkeleton