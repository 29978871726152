import React from 'react';
import { Progress } from 'antd';

const MultiColorCircularProgress = ({ percentage }) => {
    let color = '#ff4d4f'; // Default color: red

    if (percentage > 33 && percentage <= 66) {
        color = '#ffa940'; // Orange color for percentage between 33 and 66
    } else if (percentage > 66) {
        color = '#1890ff'; // Blue color for percentage above 66
    }

    return (
        <Progress type="circle" percent={percentage} strokeWidth={6} showInfo={false} strokeColor={color} />
    );
};

export default MultiColorCircularProgress;
