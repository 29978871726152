import React, { useEffect, useState } from 'react'
import { getDesiredPayload, provideInputs } from './components/CommonFunctions';

const IndustryFabricDetailsForm = ({ menuBarDetailsData = [], getPayloadData, getInitialPayload, getInputErrorState, brandsArrayRef }) => {
  const [inputData, setInputData] = useState({})
  const [enteredBrands, setEnteredBrands] = useState([])
  const [errorData, setErrorData] = useState({})

  // storing the data in the format as needed
  useEffect(() => {
    const newData = {};
    menuBarDetailsData?.forEach(item => {
      newData[item?.headerKey] = item;
    });

    // Setting the state with the constructed object
    setInputData(newData);

    // Check if brandData is not 0, null, undefined, or an empty string
    const brandData = newData?.brands_worked_with_in_past?.value?.[0]?.value;
    if (brandData && brandData != 'null' && brandData != 0 && brandData.trim() != '' && brandData != null && brandData.trim() != undefined) {
      setEnteredBrands(JSON.parse(brandData.split(',')));
    }
    // getting the initial payload data
    const initialPayloadData = getDesiredPayload(newData, { enteredBrands: brandData ? JSON.parse(brandData?.split(',')) : [] });
    getInitialPayload(initialPayloadData)

  }, [menuBarDetailsData])

  // function to get the desired payload & transfering the payload to parent component
  useEffect(() => {
    // Only call getPayloadData when inputData and enterdbrands is changed
    getPayloadData(getDesiredPayload(inputData, { enteredBrands }))
  }, [inputData, enteredBrands]);

  // getting InputErrorStatedata in parent component
  useEffect(() => {
    getInputErrorState({ inputData, errorData, setErrorData })
  }, [errorData, inputData])

  useEffect(() => {
    brandsArrayRef.current = enteredBrands;
  }, [enteredBrands])

  // logic to update the brands in input data
  useEffect(() => {
    setInputData(prevData => {
      const newData = { ...prevData };
      const type = 'brands_worked_with_in_past';
      // Find the index of the item with the specified type
      const valueIndex = newData[type]?.value?.findIndex(item => item?.labelKey === 'brand');
      if (valueIndex !== -1) {
        const updatedValue = { ...newData[type]?.value?.[valueIndex], value: "" };
        newData[type]?.value?.splice(valueIndex, 1, updatedValue);
      }
      return newData;
    });
  }, [menuBarDetailsData])

  // extra data object which is passed in the provide input function
  const extraData = { enteredBrands, setEnteredBrands }

  console.log('errordaradhwt4637e73e', errorData);
  console.log('inputdataisisisis90', inputData);

  return (
    <div className='vob-right'>
      {
        Object.values(inputData || {})?.map((inputField, index) => {
          return <div className='vobr-sec' key={index}>
            <h3> {inputField?.headerName} {inputField?.isSectionMandate && <span className='mandatory'>*</span>} </h3>
            {console.log('inputfieldkjdjhfe', inputField)}
            {provideInputs(inputField, inputData, setInputData, errorData?.[index], (data) => {
              let updatedErrorData = { ...errorData }
              updatedErrorData[index] = data
              setErrorData(updatedErrorData);
            }, { ...extraData },)}
          </div>
        })
      }
    </div>
  )
}

export default IndustryFabricDetailsForm