
import React from 'react';

const FullPageLoader = () => {
  return (
    <>
      <div className="full-page-loader">
      <div className='white-backdrop'></div>
          <div className="ball-scale-multiple">
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div>
    </>
  );
};

// const waveAnimation = `
//   @keyframes wave {
//     0%, 100% {
//       transform: translateY(0);
//     }
//     25% {
//       transform: translateY(-15px);
//     }
//     50% {
//       transform: translateY(0);
//     }
//     75% {
//       transform: translateY(15px);
//     }
//   }
// `;

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width:"200px",
    height: '100px', // Adjust as needed
  },
  dot: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    margin: '0 5px',
    animation: 'wave 1s infinite',
  },
};

// Apply inline CSS to the component
// const styleTag = document.createElement('style');
// styleTag.type = 'text/css';
// styleTag.appendChild(document.createTextNode(waveAnimation));
// document.head.appendChild(styleTag);

export default FullPageLoader;
