import React, { useContext, useEffect, useState } from 'react'
import CommentEditor from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentEditor';
import CommentBoxModalWrapper from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentBoxModalWrapper';
import ListComponent from '../../../genericComponents/ListComponent';
import CommentTableComponent from '../../../genericComponents/oldGenericComponents/tableHelperComponents/comments/CommentTableComponent';
import GenericSpinnerLoader from '../../../loaders/GenericSpinnerLoader';
import { getPayloadFromURLParams, setPayloadAsURLParams } from '../../../helper/URLParamsHelper';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { FileText, MessageSquareMore, MessageSquareText } from 'lucide-react';

const Comments = () => {

    const [currentSelectedDocument, setCurrentSelectedDocument] = useState(null);

    const [updateSelectedDocument, setUpdatedSelectedDocument] = useState(null);


    const location = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [initialSearch, setInitialSearch] = useState("");
    const [backupSelectedDocument, setBackupSelectedDocument] = useState(null);
    const getCommentsDocumentsListData = useSelector(state => state?.generalStore?.getCommentsDocumentsListData);

    useEffect(() => {
        // Initital comment modal changes
        getQueryPropsAndSetTheData()
    }, [])

    const getQueryPropsAndSetTheData = () => {

        if(!location) return;

        const urlSearchParams = new URLSearchParams(location.search),
            params = Object.fromEntries(urlSearchParams.entries());

        console.log("params ldajfsdf", params, params?.documentId && params?.documentNumber);

        if (params?.documentId && params?.documentNumber) {
            setInitialSearch(JSON.parse(decodeURIComponent(params?.documentNumber || "")));
            setBackupSelectedDocument({
                document_id: JSON.parse(decodeURIComponent(params?.documentId || "")),
                document_no: JSON.parse(decodeURIComponent(params?.documentNumber || "")),
                document_type: params?.documentType  ? JSON.parse(decodeURIComponent(params?.documentType || "")) : "",
            })

            setUpdatedSelectedDocument(null);
        }

        // Remove the applied selected items from the URL search params 
        setPayloadAsURLParams({
            data: {
                documentId : undefined,
                documentNumber : undefined,
                documentType : undefined,
            },
            l: location,
            h: history,
        })

        setLoading(false);
    }

    useEffect(() => {
        if (updateSelectedDocument) {
            setCurrentSelectedDocument(updateSelectedDocument);
        }
    }, [updateSelectedDocument]);

    useEffect(() => {
        if(getCommentsDocumentsListData?.isLoading && initialSearch){
            setInitialSearch(null);
        }
        if(getCommentsDocumentsListData?.isSuccess && backupSelectedDocument){
            console.log("Check34234234",getCommentsDocumentsListData, getCommentsDocumentsListData?.data, getCommentsDocumentsListData?.data?.data?.some(item => item?.document_no == backupSelectedDocument?.document_no));
            setInitialSearch(null);
            if(getCommentsDocumentsListData?.data?.data?.length > 0 && getCommentsDocumentsListData?.data?.data?.some(item => item?.document_no == backupSelectedDocument?.document_no)){
                setUpdatedSelectedDocument(backupSelectedDocument);
                setCurrentSelectedDocument(null);
                setBackupSelectedDocument(null);
            }
        }
        if(getCommentsDocumentsListData?.isError && backupSelectedDocument){
            setBackupSelectedDocument(null);
            setInitialSearch(null);
        }
    },[getCommentsDocumentsListData])

    const renderCommentDocuments = () => {
        return (
            <ListComponent
                initialSearch = {initialSearch || ""}
                useKeyboardShortcuts={false}
                headerKeys={{
                    "label": {}
                }}
                itemKeys={{
                    "label": ["label",]
                }}
                search={{
                    placeholder: "Search any document",

                    isSearchIconRequire: true,
                }}
                api={{
                    reduxState: "generalStore",
                    apiName: "getCommentsDocumentsList",
                    apiPayload: (params) => {
                        return {
                            "pageNo": params?.currentPage || 1,
                            "pageSize": 30,
                            "search": params?.currentSearch || "",
                        }
                    },
                    getMaxPage: (states, fucntions, response) => {
                        return response.maxPage;
                    },
                }}
                getListData={(data) => data?.data || []}
                renderCustomListCompleteItem={(s, f, item, index) => {
                    
                    let parsedAdditionalInfo = JSON.parse(item?.additional || "{}");
                    let updatedItem = {
                        ...item,
                        "vendorCode" : parsedAdditionalInfo?.["vendorCode"] || "",
                        "customerCode" : parsedAdditionalInfo?.["customerCode"] || "",
                    }

                    console.log("parsed additional info: ", item.additional, parsedAdditionalInfo);
                    return (
                        <div 
                            className={`group flex items-center justify-between gap-3 mx-3 px-3 py-3.5 rounded-lg cursor-pointer mb-1 last:mb-0 hover:bg-[#F7F8FA] ${currentSelectedDocument?.document_id === item?.document_id && 'bg-[#F7F8FA]'}`}
                            onClick={() => { 
                                if(currentSelectedDocument?.document_id !== item?.document_id || updateSelectedDocument?.document_id !== item?.document_id)
                                    setCurrentSelectedDocument(null); 
                                    setUpdatedSelectedDocument(updatedItem) 
                            }}
                        >
                            <div className='flex items-center gap-2.5'>
                                <div>
                                    <FileText size={20} strokeWidth={1.5} className={`text-[#718098] group-hover:text-black ${currentSelectedDocument?.document_id === item?.document_id && '!text-black'}`}/>
                                </div>
                                <div>
                                    <p className={`mb-0 text-xs font-medium group-hover:font-semibold ${currentSelectedDocument?.document_id === item?.document_id && 'font-semibold'}`}>{item?.["document_no"] || ""}</p>
                                </div>
                            </div>
                            <div>
                                <CommentTableComponent
                                    commentRowIndex={index}
                                    comment={{
                                        disabled: false,
                                        configKeys: {
                                            "documentId": "document_id",
                                            "documentNumber": "document_no",
                                            "vendorCode": "vendorCode",
                                            "customerCode": "customerCode",
                                            "documentType": "document_type"
                                        }
                                    }}
                                    commentModal={currentSelectedDocument}
                                    item={updatedItem || {}}
                                    mode={"central"}
                                />
                            </div>
                        </div>
                    )

                }}
            />
        )
    }


    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <GenericSpinnerLoader />
            </div>
        )
    }

    return (
        <React.Fragment>

            <div className='flex pt-[12px] ml-7 h-[calc(100vh-60px)] mr-3 overflow-hidden'>
                <div className='commentDocList h-full w-full max-w-[40%] lg:max-w-[350px] 2xl:max-w-[25%] bg-white'>
                    {renderCommentDocuments()}
                </div>
                <div className='w-full max-w-[calc(100%-300px)]'>
                    {currentSelectedDocument ? <div className='h-full'>
                        <CommentBoxModalWrapper
                            onClose={() => setCurrentSelectedDocument(null)}
                            item={currentSelectedDocument || {}}
                            comment={{
                                disabled: false,
                                configKeys: {
                                    "documentId": "document_id",
                                    "documentNumber": "document_no",
                                    "vendorCode": "vendorCode",
                                    "documentType": "document_type"
                                }
                            }}
                            // @Ankit : Uncomment this for central comment UI
                            mode='central'
                        />
                    </div> : <div className='h-full flex flex-col items-center justify-center p-[1rem]'>
                        {/* @Vishnu : Please create the no documents select icons*/}
                        <div className='mb-4'><MessageSquareText strokeWidth={1.5} size={50}/></div>
                        <p className='font-semibold mb-1'>No Document selected</p>
                        <p className='mb-0 text-xs text-fontGrey'>Please select a document to view comments</p>
                    </div>}
                </div>
            </div>

        </React.Fragment>

    )
}

export default Comments