import React from 'react'
const divsArray = Array.from({ length: 11 }, index => index + 1);
const divsArray2 = Array.from({ length: 30 }, index => index + 1);

const DailySalesTabTableSkeleton = () => {
  return (
    <div className='dsr-skeleton ds-division-wise'>
      <table>
        <tbody>
          {divsArray2.map(() => (
            <tr>
              {divsArray.map(() => (
                <td>
                  <div className='generic-skeleton'></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DailySalesTabTableSkeleton