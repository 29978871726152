import React, { useEffect, useState, useLayoutEffect } from 'react'
import IconsPath from '../../../assets/icons';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import { Tooltip } from 'antd';
import LanguageTranslator from '../../../locals/LanguageTranslator';
import { Image } from 'lucide-react';

function SalesCard(props) {

    const { outerIndex = null, handleMouseMove = () => { }, focusedIndex = null } = props;

    const [currentImgUrl, setCurrentImgUrl] = useState(props?.item?.[props.mainImgKey] || "");

    useEffect(() => {
        setCurrentImgUrl(props?.item?.[props.mainImgKey] || "")
    }, [props?.item])

    const small = (str) => {
        if (str != null) {
            var str = str.toString()
            if (str.length <= 27) {
                return false;
            }
            return true;
        }
    }
    return (
        <div ref={props.lastListElementRef} key={outerIndex} className={`splrscs-card splrscs-card-${props?.viewProductType} ${focusedIndex === outerIndex ? "hover" : ""}`} onMouseLeave={(e) => handleMouseMove(e, null)} onMouseEnter={(e) => handleMouseMove(e, Number(outerIndex))}>
            <div className='splrscsc-inner'>
                <div className="splrscsc-top">
                    {/* For multiselect icon */}
                    {props?.multipleSelect && <label className="generic-checkbox">
                        <input type="checkBox" checked={props?.selectedData[props?.item?.id] ? true : false} style={{zIndex:99}} onChange={(e) => {
                            console.log("changes in this")
                            props?.handleSelect(props?.item, e)} 
                        }/>
                        <span className="checkmark1"></span>
                    </label>}

                    {/* For wishlist icon */}
                    {/* {props.wishlist && (
                        <button className={`heart-icon ${props.wishlist?.isWishlisted?.() && "heart-icon-active"}`} onClick={() => props.wishlist.onClick()}>
                            <IconsPath.GreyHeart />
                        </button>
                    )} */}

                    {currentImgUrl ? <div className='splrscsct-img'><img src={currentImgUrl} /></div> :
                        <div className='splrscsc-no-img-found'>
                            <Image />
                        </div>}
                </div>
                {!props.showCardButtonsDownDirection && <div className='splrscsc-btns'>
                    {(props?.viewProductType == "grid") && props?.buttons?.map((item, index) => {
                        return <>
                            <GenericCustomButton
                                {...item}
                            />
                        </>
                    })}
                    {props?.comment && props?.comment()}
                </div>}
                <div className="splrscsc-details relative !pb-0">
                    {props?.topExtra && <div className='absolute top-[12px] right-[0px] z-[3]'>{typeof props?.topExtra == "function" ? props?.topExtra({
                            item : props.item
                        }) : props?.topExtra }</div>}
                    {props?.showColor && <span className='splrsmv-color splrsmv-top'>{props?.item?.[props?.showColor] || "NA"}</span>}
                    {props?.showDataKey && Object.keys(props?.showDataKey).map((key, index) =>
                        <div className="splrscscd-info">
                            <LanguageTranslator tag="span" className='splrscsci-head'>{props?.showDataKey?.[key]}</LanguageTranslator>
                            {small(props?.item?.[key]) ? <Tooltip overlayClassName="button-tooltip"
                                title={<p>{props?.item?.[key] || "NA"}</p>}
                                color='black'
                            >
                                <span className='splrscsci-para'>{props?.item?.[key] || "NA"}</span>
                            </Tooltip> : <span className='splrscsci-para'>{props?.item?.[key] || <LanguageTranslator>NA</LanguageTranslator>}</span>}
                            <div className='flex items-center'>
                                {props?.viewProductType == "list" && index == 0 && <>
                                    {props?.showColor && <span className='splrsmv-color splrsmv-bottom'>{props?.item?.[props?.showColor] || "NA"}</span>}
                                    {props?.tagsKey && Object.keys(props?.tagsKey).map((key) =>
                                        <div className="splrscscd-tags">
                                            {props?.tagsKey?.[key] && <LanguageTranslator tag="span" className='splrscsci-head'>{props?.tagsKey?.[key]}: </LanguageTranslator>}
                                            <span className='splrscsci-para'>{props?.item?.[key] || "NA"}</span>
                                        </div>
                                    )}
                                </>}
                            </div>
                        </div>
                    )}
                    {props?.viewProductType == "grid" && props?.tagsKey && Object.keys(props?.tagsKey).map((key) =>
                        <div className="splrscscd-tags">
                            {props?.tagsKey?.[key] && <LanguageTranslator tag="span" className='splrscsci-head'>{props?.tagsKey?.[key]}: </LanguageTranslator>}
                            <span className='splrscsci-para'>{props?.item?.[key] || "NA"}</span>
                        </div>
                    )}
                </div>

                {props?.bottomExtras && (typeof (props?.bottomExtras) == "function" ? props?.bottomExtras(props?.item, outerIndex) : props?.bottomExtras)}
                               
                {(props?.viewProductType == "list" || props.showCardButtonsDownDirection) && <div className='splrscsc-btns !border-0 pb-2.5'>
                    {props?.buttons?.map((item, index) => {
                        return <>
                            <GenericCustomButton
                                {...item}
                            />
                        </>
                    })}
                     {props?.comment && props?.comment()}
                </div>}
            </div>
        </div>
    )
}

export default SalesCard