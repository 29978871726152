import React, { useState, useEffect } from 'react'
import SalesDashboardCardsSkeletonLoader from '../../skeletons/SalesDashboardSkeletonLoader';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/icons';
import { parseJwt } from '../../../helper/genericFunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const SalesOrderDashboardCountCards = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let urlSearchParams = new URLSearchParams("?");
    let uType = parseJwt(sessionStorage?.getItem('token'))?.uType || "ENT";
    const [statusCountData, setStatusCountData] = useState({})
    const salesManagementStore = useSelector(state => state.salesManagementStore);

    useEffect(() => {
        let filters = props.getCurrentMainLevelFilters?.() || {};
        console.log("Count Filters", filters);
        dispatchHandler(dispatch, 'getSOStatusCountRequest', {
            filter: filters
        });
    }, [props?.filterData])

    useEffect(() => {
        if (salesManagementStore?.getSOStatusCountData?.isSuccess) {
            setStatusCountData(salesManagementStore?.getSOStatusCountData?.data?.resource || {})
        }
    }, [salesManagementStore?.getSOStatusCountData?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "redirectFunction": {
                if (props?.filterData?.orderDate && data.payload?.filter) {
                    let filters = props.getCurrentMainLevelFilters?.() || {};
                    data.payload.filter["creationTime"] = filters?.["orderDate"];
                }

                Object.keys(data.payload).forEach(key => {
                    if ((typeof data?.payload[key] != "object" && data?.payload[key]) || (typeof data?.payload[key] == "object" && Object.keys(data?.payload[key])?.length)) {
                        urlSearchParams.append(key, encodeURIComponent(JSON.stringify(data?.payload[key])));
                    }
                });

                if (uType === "CUSTOMER") {
                    history.push({
                        pathname: "/salesManagement/customer/viewSalesOrders",
                        search: urlSearchParams === "?" ? "" : urlSearchParams.toString(),
                    });
                } else {
                    history.push({
                        pathname: "/salesManagement/viewSalesOrders",
                        search: urlSearchParams === "?" ? "" : urlSearchParams.toString(),
                    });
                }
            }
        }
    }

    // Render all cards json
    const statusCount = {
        pending: {
            label: "Pending for Approval",
            count: statusCountData?.pendingCount,
            sum: statusCountData?.pendingNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "PENDING"
                }
            }
        },
        hold: {
            label: "On Hold",
            count: statusCountData?.holdCount,
            sum: statusCountData?.holdNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "HOLD"
                }
            }
        },
        reject: {
            label: "Rejected",
            count: statusCountData?.rejectedCount,
            sum: statusCountData?.rejectedNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "REJECTED"
                }
            }
        },
        approved: {
            label: "Approved Sales Order",
            count: statusCountData?.approvedCount,
            sum: statusCountData?.approvedNetAmount,
            payload: {
                type: 2,
                orderType: "order",
                filter: {
                    status: "APPROVED,PARTIAL,DC_CREATED"
                }
            }
        },
        draft: {
            label: "Draft",
            count: statusCountData?.DraftCount,
            sum: statusCountData?.draftNetAmount,
            payload: {
                type: 2,
                orderType: "indent",
                filter: {
                    status: "DRAFT"
                }
            }
        },
        cancelled: {
            label: "Cancelled",
            count: statusCountData?.cancelledCount,
            sum: statusCountData?.cancelledNetAmount,
            payload: {
                type: 2,
                orderType: "rejected",
                filter: {
                    status: "CANCELLED"
                }
            }
        }
    }

    const statusObject = {
        "pending": <Icons.ArsPendindIcon />,
        "hold": <Icons.ArsHold />,
        "reject": <Icons.ArsRejectedIcon />,
        "cancelled": <Icons.ArsRejectedIcon />,
        "approved": <Icons.ArsApprovedIcon />,
        "draft": <Icons.ArsDraft />
    }

    return (
        salesManagementStore?.getSOStatusCountData?.isLoading ? <SalesDashboardCardsSkeletonLoader  /> :
            <div className="flex justify-between p-bot-12">
                {Object.keys(statusCount).map(key =>
                    <div className="sd-cards" onClick={() => handleChange('redirectFunction', { payload: statusCount[key]?.payload, key: key })}>
                        <span className={`sdc-img ${key === "pending" ? "" : key === "hold" ? "sdc-hold" : key === "reject" || key === "cancelled" ? "sdc-rejected" : key === "approved" ? "sdc-approved" : "sdc-unauthorised"}`}>
                            {statusObject?.[key?.toLowerCase()] || null}
                        </span>
                        <LanguageTranslator tag="span" className="sdc-size">{statusCount[key]?.count}</LanguageTranslator>
                        <LanguageTranslator tag="p">{statusCount[key]?.label}</LanguageTranslator>
                        <div className="sdc-amount">
                            <LanguageTranslator tag="p">Amount</LanguageTranslator>
                            <span>₹ {statusCount[key]?.sum || "N/A"}</span>
                        </div>

                    </div>
                )}
            </div>
    )
}

export default SalesOrderDashboardCountCards