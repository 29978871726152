import React, { useEffect, useState } from 'react'
import Icons from '../../../assets/icons'
import GenericCustomButton from '../../GenericCustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import GenericInput from '../../input/GenericInputComponent';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const DocumentsDropdown = (props) => {

    const dispatch = useDispatch();

    const { checkItems = [], downloadDocumentsConfigurationObject = {}, hidePreview = false } = props;

    const downloadDocumentsData = useSelector(state => state?.generalStore?.downloadDocumentsData);
    const [expandedTable, setExpandedTable] = useState({});

    const [searchContent, setSearchContent] = useState("");

    const downloadFunction = (config) => {
        if (downloadDocumentsData?.isLoading) return;

        let { item, type, } = config

        let idKey = item?.["documentId"] || "shipmentId";
        let idDocumentNumber = item?.["documentNo"] || "shipmentAdviceCode";

        console.log("sdfsdfd", item);

        if (idKey && idDocumentNumber) {
            let payload = {
                "data": checkItems?.map((item, index) => ({
                    "documentId": item?.[idKey] || "",
                    "documentNo": item?.[idDocumentNumber] || "",
                })) || [],

                "filters": item?.["filters"] ? item?.["filters"] : {},
                "type": item?.key || "",
                "disposition": type
            }
            // Download the document data
            dispatchHandler(dispatch, "downloadDocumentsRequest", payload)
        }
    }

    const handleChange = (type, item, config = {}) => {

        let { level } = config;

        switch (type) {
            case "mouseOver": {
                if (item?.["explore"]?.data) {
                    let updatedData = { ...expandedTable };
                    updatedData[level] = item;
                    let updatedSearchContent = { ...searchContent };

                    let maxValue = Math.max(...Object.keys(expandedTable || {}))

                    // Remove all the data above this level from the expanded table
                    for (let i = level + 1; i <= maxValue; i++) {
                        delete updatedData[i];
                        delete updatedSearchContent?.[i]
                    }
                    console.log("mouse over", item?.key, level, updatedData);
                    setExpandedTable(updatedData);
                    setSearchContent(updatedSearchContent)
                }
                break;
            }
            case "mouseLeave": {
                let updatedData = { ...expandedTable };
                let maxValue = Math.max(...Object.keys(expandedTable || {}))

                console.log("Max level", maxValue, level);
                let updatedSearchContent = { ...searchContent };

                // Remove all the data above this level from the expanded table
                for (let i = level; i <= maxValue; i++) {
                    delete updatedData[i];
                    delete updatedSearchContent?.[i + 1]
                }
                setExpandedTable(updatedData);
                setSearchContent(updatedSearchContent)
                break
            }
            case "downloadBarcode": {
                let { expandMoreJson = {}, expandData = {} } = item
                downloadFunction({
                    item: expandData,
                    type: "FALSE",
                    exploreDataDownload: true,
                    expandMoreJson,
                    expandData,
                })
                props.OpenDocumentsDropdown();
            }
        }

    }

    const modifyItemForPrinterBarcode = ({ ...item }) => {
        if (item?.["exploreData"]) {
            item["explore"] = {};
            item["explore"]["data"] = [];

            item["explore"]["data"] = JSON.parse(item?.exploreData?.value || "[]")?.map(val => {

                let filters = {};
                ["barCodeId"]?.map(key => { filters[key] = val?.[key] })

                return {
                    ...item,
                    "explore": null,
                    "exploreData": null,
                    "value": val?.["displayName"] || "",
                    "filters": filters,
                    "type": "printerBarcode"
                }
            })
            delete item?.["exploreData"];
        }

        return item;
    }

    // Function :  TO check if the preview button should be hidden or not
    const isPreviewButtonHidden = (key) => {
        if(hidePreview) return true;

        if(Object.keys(expandedTable || {})?.length > 0 && ["ITEM_PRINTER_BARCODE", "SET_PRINTER_BARCODE"]?.includes(expandedTable?.[Object.keys(expandedTable)?.length-1]?.key) || ["PRODUCT_QR_CODE"]?.includes(key)){
            return true;
        }
        return false;
    }

    // console.log("Search content", searchContent)
    // console.log('checkItems?.length: ', checkItems)
    const renderDownloadDocumentList = (listData = [], level = 0) => {
        // console.log("List data", listData, listData?.filter(item => item?.value?.includes(searchContent?.[level] || "")))
        return (
            <div className={`${'download-document-dropdown'} mt-2`}>
                {!expandedTable?.hasOwnProperty(level) ?
                    <div>
                        <div className='px-3 pt-3 border-b'>
                            <LanguageTranslator tag='h2' className='text-custom-16 font-bold'>Documents Download</LanguageTranslator>

                            {listData?.length > 5 &&
                                <GenericInput
                                    className='placeholder:font-normal'
                                    inputKey={"searchIcon"}
                                    value={searchContent?.[level]}
                                    placeholder={"Search Document"}
                                    onChange={(e) => setSearchContent({ ...searchContent, [level]: e?.target?.value || "" })}
                                    iconLeft={<span className='mr-1'><Icons.GlobalSearchIcon /></span>}
                                    dependentFieldsObject={["searchIcon"]}
                                />}
                        </div>

                        <div className='py-1 max-h-[300px] overflow-y-auto'>
                            {level > 0 &&
                                <div className='flex items-center px-3 whitespace-nowrap'>
                                    <span className='p-1.5 bg-gray-100 rounded mr-2 cursor-pointer'
                                        onClick={() => { handleChange("mouseLeave", expandedTable?.[level - 1], { level: level - 1 }) }}
                                    ><Icons.BackArrowIcon2 /></span>

                                    {
                                        Object.keys(expandedTable || {})?.sort()?.map(key =>
                                            <GenericCustomButton
                                                className='hover:text-blue-500'
                                                style={{
                                                    background: 'none',
                                                    color: 'black',
                                                    borderRadius: 0,
                                                    border: 'none',
                                                    paddingInline: '1px',
                                                    width: 'fit-content',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                }}
                                                label={` ${key != 0 ? "/" : ""} ${expandedTable?.[key]?.value}`}
                                                onClick={() => handleChange("mouseLeave", expandedTable?.[key], { level: key })}
                                            />
                                        )
                                    }
                                </div>
                            }

                            {checkItems?.length != 0 && listData?.filter(item => item?.value?.toLowerCase()?.includes(searchContent?.[level]?.toLowerCase() || ""))?.map((item, index) => {
                                item = modifyItemForPrinterBarcode(item);
                                let isExpandAvailable = item?.["explore"]?.data ? true : false;
                                
                                

                                return (
                                    <div key={item?.key} className='h-full' style={{ position: 'relative' }}
                                        type="default"
                                        onClick={() => (isExpandAvailable && expandedTable?.[level]?.key != item?.key && handleChange("mouseOver", item, { level: level }))}
                                    >
                                        <button
                                            className={`py-2 flex justify-between w-full bg-white text-sm font-medium text-gray-700 hover:bg-gray-50`}
                                            style={{
                                                paddingInline: '12px',
                                            }}
                                        >
                                            <div className='flex items-center gap-2.5 cursor-pointer'>
                                                {item?.["url"] ? <img className='w-16 h-16 object-cover' src={item?.["url"]} /> : <Icons.DefaultReportIcon />}
                                                <label className='whitespace-nowrap font-medium text-[#0F172A] mr-[15px]'>{item?.value || ""}</label>
                                            </div>
                                            <div className='flex gap-2 items-center'>
                                                {
                                                    !isExpandAvailable && (
                                                        <GenericCustomButton
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            leftIcon={<span className='p-1.5 rounded bg-gray-100 hover:bg-white hover:text-blue-500'><Icons.DownloadIcon /></span>}
                                                            onClick={() => {
                                                                downloadFunction({
                                                                    item: item,
                                                                    type: "FALSE",
                                                                })
                                                            }}
                                                            disabled={downloadDocumentsData?.isLoading || props.downloadDocuments?.disableItems?.({
                                                                selectedItems: checkItems,
                                                                currentItem: item,
                                                            })}
                                                        />
                                                    )
                                                }

                                                {/* {item?.["type"] != "printerBarcode" && */}
                                                {!isPreviewButtonHidden(item?.key) && <GenericCustomButton
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                    // className='border rounded-full border-black hover:bg-black hover:text-white'
                                                    leftIcon={(item?.["type"] != "printerBarcode" && isExpandAvailable) ? <span className='p-1.5'><Icons.RightArrowExpand /></span> : <span className='p-1.5 rounded bg-gray-100 hover:bg-white hover:text-blue-500'><Icons.PasswordEyeIconSmall /></span>}
                                                    disabled={(isExpandAvailable ? props.downloadDocuments?.disableItems?.({
                                                        selectedItems: checkItems,
                                                        currentItem: item,
                                                    }) : downloadDocumentsData?.isLoading) || props.downloadDocuments?.disableItems?.({
                                                        selectedItems: checkItems,
                                                        currentItem: item,
                                                    })}
                                                    onClick={isExpandAvailable ? () => handleChange("mouseOver", item, { level: level }) : (e) => {
                                                        e.stopPropagation()
                                                        downloadFunction({
                                                            item: item,
                                                            type: "TRUE",
                                                        })
                                                    }}
                                                />}
                                                {/* } */}
                                            </div>
                                        </button>

                                    </div>
                                )
                            })
                            }

                            {listData?.filter(item => item?.value?.toLowerCase()?.includes(searchContent?.[level]?.toLowerCase() || "")).length === 0 && (
                                <div className='text-custom-14 text-center py-3'>No Results Found</div>
                            )}

                        </div>
                    </div> : renderDownloadDocumentList(expandedTable?.[level]?.["explore"]?.["data"], level + 1)}
            </div>

        )
    }


    return (
        <React.Fragment>
            {renderDownloadDocumentList(downloadDocumentsConfigurationObject?.["type"], 0)}
        </React.Fragment>
    )
}

export default DocumentsDropdown