import React, { Component } from 'react'

export default class DocumentAuditLogMoreData extends Component {

    render() {
        return (
            <div className="modal-content log-event-det-modal ledm-more-data">
                <div className='ledm-head'>
                    <div>
                        <div className='flex m-bot-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.88721 2.98568C3.47695 2.98568 3.14437 3.31826 3.14437 3.72852V14.1283C3.14437 14.5385 3.47695 14.8711 3.88721 14.8711H14.2869C14.6972 14.8711 15.0298 14.5385 15.0298 14.1283V3.72852C15.0298 3.31826 14.6972 2.98568 14.2869 2.98568H3.88721ZM1.65869 3.72852C1.65869 2.49774 2.65643 1.5 3.88721 1.5H14.2869C15.5177 1.5 16.5155 2.49774 16.5155 3.72852V14.1283C16.5155 15.359 15.5177 16.3568 14.2869 16.3568H3.88721C2.65643 16.3568 1.65869 15.359 1.65869 14.1283V3.72852Z" fill="#242A31"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85856 1.5C7.26882 1.5 7.6014 1.83258 7.6014 2.24284V15.6139C7.6014 16.0242 7.26882 16.3568 6.85856 16.3568C6.4483 16.3568 6.11572 16.0242 6.11572 15.6139V2.24284C6.11572 1.83258 6.4483 1.5 6.85856 1.5Z" fill="#242A31"/>
                            </svg>
                            <h3>Multiple Values</h3>
                        </div>
                        <p>{this.props.header}</p>
                        <span>{this.props.documentNo}</span>
                    </div>
                    <button onClick={this.props.Close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3198 5.59535C18.6866 5.96207 18.6866 6.55664 18.3198 6.92336L7.05129 18.1919C6.68457 18.5586 6.09 18.5586 5.72328 18.1919C5.35656 17.8252 5.35656 17.2306 5.72328 16.8639L16.9918 5.59535C17.3586 5.22863 17.9531 5.22863 18.3198 5.59535Z" fill="#242A31"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72328 5.59535C6.09 5.22863 6.68457 5.22863 7.05129 5.59535L18.3198 16.8639C18.6866 17.2306 18.6866 17.8252 18.3198 18.1919C17.9531 18.5586 17.3586 18.5586 16.9918 18.1919L5.72328 6.92336C5.35656 6.55664 5.35656 5.96207 5.72328 5.59535Z" fill="#242A31"/>
                        </svg>
                    </button>
                </div>
                <div className='ledm-body'>
                    {
                     Array.isArray(this.props.PassData) ? 
                     this.props.PassData && this.props.PassData.map((item)=>(
                        Object.keys(item).map((innerItem)=>(
                            <div className='ledmb-det'>
                            {console.log(2525, innerItem)}
                        <label>{innerItem} :</label>
                        <p>{item[innerItem] == "" ? "NA" : item[innerItem]}</p>
                    </div>
                            ))
                     )) 
                     : Object.keys(this.props.PassData).map((data)=>(
                        <div className='ledmb-det'>
                        <label>{data}:</label>
                        <p>{this.props.PassData[data] == "" ? "NA" : this.props.PassData[data]}</p>
                    </div>
                     ))
                    }
                </div>
            </div>
        )
    }
}
