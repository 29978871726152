import React from "react";
import GenericFormHeaderComponent from "../../genericComponents/forms/GenericFormHeaderComponent";
import GenericFormFooterComponent from "../forms/GenericFormFooterComponent";
import '../../styles/stylesheets/MasterData/Vendor.scss'
import LanguageTranslator from "../../locals/LanguageTranslator";

const GenericViewDetailsComponent = (props) => {
    const {
        label = "View Details",
        buttons = null,
        sections = {},
        headerKeys = {},
        selectedData = {},
    } = props;

    const renderDetailsSection = (key) => {
        let data = headerKeys?.[key]?.keys;
        return Object.keys(data || {})?.map(
            (labelKey) =>
                data?.[labelKey] &&
                (typeof data?.[labelKey] == "function" ? (
                    data?.[labelKey]?.()
                ) : (
                    <div className="gvmb" style={{ width: `${100 / (headerKeys?.[key]?.["numOfCols"] || 4)}%` }}>
                        {/* the label data */}
                        <LanguageTranslator tag="label">{data?.[labelKey]}</LanguageTranslator>
                        {/* the label value data */}
                        <LanguageTranslator tag="p">
                            {selectedData?.hasOwnProperty([labelKey])
                                ? selectedData?.[labelKey] === "" || selectedData?.[labelKey] === null ? '-' : selectedData?.[labelKey]
                                : data?.[labelKey]}
                        </LanguageTranslator>
                    </div>
                ))
        );
    };

    const renderSections = () => {
        return Object.keys(sections)?.map((key) => {
            return (
                <React.Fragment>
                    <LanguageTranslator tag="h2">{sections?.[key]}</LanguageTranslator>
                    <div className="gvm-body">
                        {renderDetailsSection(key)}
                    </div>
                </React.Fragment>
            );
        });
    };
    return (
        <div className="generic-view-modal">
            {/* // the View details component header component */}
            <GenericFormHeaderComponent
                label={{
                    contentPrimary: label,
                }}
                closeModal={props.closeModal}
            />

            {/* Render the section details */}
            <div className="gvm-scroll">
                {renderSections()}
            </div>

            {/* // the View details component footer component */}
            {buttons && <GenericFormFooterComponent buttons={buttons} />}
        </div>
    );
};

export default GenericViewDetailsComponent;
