import { Select } from "antd";
import { capitalizeFirstLetter } from "../../../helper/apiHelperFunctions";
import GenericInput from "../../../genericComponents/input/GenericInputComponent";
import { iconsMapping } from "../iconsMapping";
import statesJson from '../../../constants/states.json'
import Icons from "../../../assets/icons";
import dayjs from "dayjs";
import GenericCustomButton from "../../../genericComponents/GenericCustomButton";
const { Option } = Select

let allStates = statesJson?.["states"];

// function to get the desired payload required for saving the form data
export const getDesiredPayload = (inputData, extraData = {}) => {
    const { enteredBrands } = extraData;
    const requiredPayload = [];

    for (const key in inputData) {
        if (inputData.hasOwnProperty(key)) {
            const item = inputData?.[key];
            const newItem = {
                headerName: item?.headerName,
                parentCode: item?.parentCode,
                headerKey: item?.headerKey,
                value: item?.value?.map(field => ({
                    type: field?.type,
                    fieldDataType: field?.fieldDataType,
                    label: field?.label,
                    value: field?.value,
                    data: { ...field?.data }, // Create a shallow copy of the data object
                    isMandatory: field?.isMandatory,
                    multipleSelect: field?.multipleSelect
                }))
            };
            if (item?.headerKey === "brands_worked_with_in_past") {
                newItem.value[0].value = enteredBrands;
            }
            requiredPayload.push(newItem);
        }
    }
    return requiredPayload;
};

// function to provide the desired inputs based on the types
export const provideInputs = (inputField, inputData, setInputData, errorData, setErrorData, extraData = {}) => {
    const { deleteImgFromServer, setEnteredBrands, enteredBrands } = extraData

    let searchObj = (labelKey) => ({
        placeholder: "Type To Search",
        isSearchIconRequire: true,
        customSearchFunction: (listState, listFunctions) => {
            const { listData, currentSearch } = listState;
            return listData?.filter(item => item?.[labelKey]?.toLowerCase().includes(currentSearch?.toLowerCase()))
        }
    })
    let selectedState;
    // function to get the country,state and the city
    const getInputProps = (headerKey, labelKey) => {
        let labelObj = {
            "country": "Country",
            "state": "State",
            "city": "City"
        }
        let obj = {
            // label: {
            //     content: `Choose ${labelObj[labelKey]}`,
            // },
            search: searchObj(labelKey?.toLowerCase()),
            headerKeys: {
                [labelKey]: { value: labelObj?.[labelKey] },
            },
            itemKeys: {
                [labelKey]: [labelKey],
            },
        }

        selectedState = inputData?.[headerKey]?.value?.find(item => item?.labelKey == 'state')

        switch (labelKey) {
            case "country": {
                return {
                    ...obj,
                    getListData: () => ([{ "country": "India" }]),
                }
            }
            case "state": {
                return {
                    ...obj,
                    getListData: () => Object.keys(allStates || {})?.map(val => ({ [labelKey]: val })),
                }
            }
            case "city": {
                return {
                    ...obj,
                    getListData: () => allStates?.[selectedState?.value || {}]?.map(val => ({ [labelKey]: val })),
                }
            }
            default: break
        }
    }
    // function to handle checkbox and iconbox type for single and multiple select
    const handleIconCheckboxChange = (type, updatedValue, key, multipleSelect, labelKey) => {
        let updatedErrorData = { ...errorData }
        switch (multipleSelect) {
            case true:
                setInputData(prevData => {
                    const newData = { ...prevData };
                    const valueIndex = newData[type]?.value.findIndex(item => item.labelKey === labelKey);
                    if (valueIndex !== -1) {
                        const selectedValue = newData[type]?.value[valueIndex];
                        if (selectedValue.multipleSelect === 1) {
                            // For multiple select, toggle the value of the selected icon
                            selectedValue.data = {
                                ...selectedValue.data,
                                [key]: selectedValue.data[key] === "1" ? "0" : "1"
                            };
                        } else {
                            // For single select, directly update the value
                            selectedValue.value = updatedValue ? "1" : "0";
                        }
                        // Handle input errors
                        let errors = handleInputErrorChange({ field: selectedValue });
                        if (errors?.length > 0) {
                            if (!updatedErrorData[labelKey]) {
                                updatedErrorData[labelKey] = errors;
                            } else {
                                updatedErrorData[labelKey] = updatedErrorData[labelKey].concat(errors);
                            }
                        } else {
                            delete updatedErrorData?.[labelKey];
                        }
                    }

                    return newData;
                });
                setErrorData(updatedErrorData)

                break;
            case false:
                setInputData(prevData => {
                    const newData = { ...prevData };
                    const valueIndex = newData[type]?.value.findIndex(item => item.labelKey === labelKey);

                    if (valueIndex !== -1) {
                        const currentValue = newData[type]?.value[valueIndex].data[key];
                        const updatedValue = currentValue === "1" ? "0" : "1"; // Toggle the value

                        // Update the data object
                        Object.keys(newData[type]?.value[valueIndex].data).forEach(dataKey => {
                            newData[type].value[valueIndex].data[dataKey] = dataKey === key ? updatedValue : "0";
                        });

                        // Handle input errors
                        let errors = handleInputErrorChange({ field: newData[type]?.value[valueIndex] });
                        if (errors?.length > 0) {
                            updatedErrorData[labelKey] = errors;
                        } else {
                            delete updatedErrorData?.[labelKey];
                        }
                    }

                    return newData;
                });
                setErrorData(updatedErrorData)
                break;

            default:
                break;
        }
    }
    // function for input type
    const handleInputChange = (type, data, labelKey) => {
        setInputData(prevData => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                value: prevData[type]?.value?.map(item => {
                    if (item.labelKey === labelKey) {
                        return {
                            ...item,
                            value: data
                        };
                    }
                    return item;
                })
            }
        }));
    }
    // function for single select
    const handleSingleSelect = (type, selectedValue, labelKey) => {
        let updatedErrorData = { ...errorData }
        setInputData(prevData => {
            const newData = { ...prevData };
            // Initialize newData[type].value if it doesn't exist
            newData[type].value = newData[type].value || [{}];

            // Update the selected item in the value array
            newData[type].value = newData[type].value.map(item => {
                if (item.labelKey === labelKey) {
                    // Update the data object based on the selected value
                    item.data = Object.fromEntries(Object.entries(item.data || {}).map(([key, value]) => {
                        return [key, key == selectedValue?.key ? "1" : "0"];
                    }));

                    let errors = handleInputErrorChange({ field: item });
                    if (errors?.length > 0) {
                        updatedErrorData[labelKey] = errors;
                    } else {
                        delete updatedErrorData?.[labelKey];
                    }
                }
                return item;
            })
            return newData;
        });
        setErrorData(updatedErrorData)
    };
    // function for multiple select
    const handleMultipleSelect = (type, selectedValues, labelKey) => {
        let updatedErrorData = { ...errorData }
        setInputData(prevData => {
            const newData = { ...prevData };
            const index = newData[type]?.value?.findIndex(item => item.labelKey === labelKey);
            if (index != -1) {
                const updatedData = { ...newData[type]?.value?.[index]?.data };
                Object.keys(updatedData).forEach(key => {
                    updatedData[key] = selectedValues.includes(key) ? "1" : "0";
                });
                newData[type].value[index].data = updatedData;
            }
            let errors = handleInputErrorChange({ field: newData[type].value[index] });
            if (errors?.length > 0) {
                updatedErrorData[labelKey] = errors;
            } else {
                delete updatedErrorData?.[labelKey];
            }
            return newData;
        });
        setErrorData(updatedErrorData)
    };
    // function to handle the input with type dropdown
    const handleInputDropdown = (type, data, labelKey) => {
        let dataObj = data
        setInputData(prevData => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                value: prevData[type]?.value?.map(item => {
                    if (item.labelKey === labelKey) {
                        return {
                            ...item,
                            value: dataObj?.[labelKey]
                        };
                    }
                    return item;
                })
            }
        }));

        if (inputData?.[type]?.value?.find(item => item.labelKey === labelKey)?.value != data?.['country']) {
            delete inputData?.[type]?.value?.find(item => item.labelKey === 'state')?.value
            delete inputData?.[type]?.value?.find(item => item.labelKey === 'city')?.value
        }
        if (inputData?.[type]?.value?.find(item => item.labelKey === labelKey)?.value != data?.['state']) {
            delete inputData?.[type]?.value?.find(item => item.labelKey === 'city')?.value
        }
    }
    // function for upload
    const handleUpload = (type, data, labelKey) => {
        const filePathsArray = data?.map(file => file?.filePath);
        console.log('datattatataccgftw', data);
        setInputData(prevData => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                value: prevData[type]?.value?.map(item => {
                    if (item.labelKey == labelKey) {
                        return {
                            ...item,
                            // value: filePathsArray
                            value: data
                        };
                    }
                    return item;
                })
            }
        }));
    }

    // function for special case for document upload 
    const handleMultipleUploadSelect = (type, selectedValues, labelKey) => {
        let updatedErrorData = { ...errorData }

        setInputData(prevData => {
            const newData = { ...prevData };
            const index = newData[type]?.value?.findIndex(item => item.labelKey === labelKey);
            if (index !== -1) {
                const updatedData = { ...newData[type]?.value?.[index]?.data };
                Object.keys(updatedData).forEach(key => {
                    updatedData[key].isSelect = selectedValues.includes(key) ? 1 : 0;
                    updatedData[key].date = selectedValues.includes(key) ? dayjs().format('YYYY-MM-DD') : null;
                });
                newData[type].value[index].data = updatedData;
            }

            let errors = handleInputErrorChange({ field: newData[type].value[index] });
            if (errors?.length > 0) {
                updatedErrorData[labelKey] = errors;
            } else {
                delete updatedErrorData?.[labelKey];
            }

            return newData;
        });
        setErrorData(updatedErrorData)

    };
    // function for special case for document upload to remove the selected card
    const handleRemoveCard = (headerKey, labelKey, cardKey) => {
        setInputData(prevData => {
            const newData = { ...prevData };
            const headerValue = newData[headerKey]?.value;
            if (headerValue) {
                const item = headerValue.find(item => item?.labelKey === labelKey);
                if (item && item.data && item.data[cardKey]) {
                    item.data[cardKey].isSelect = 0;
                }
            }
            return newData;
        });
    };
    // function for special case for date and upload change
    const handleUploadChange = (type, headerKey, labelKey, cardKey, data) => {
        let updatedErrorData = { ...errorData }
        console.log('daatttatavd3323', data);
        switch (type) {
            default:
                setInputData(prevData => {
                    const newData = { ...prevData };
                    const headerValue = newData[headerKey]?.value;
                    if (headerValue) {
                        const item = headerValue.find(item => item?.labelKey === labelKey);
                        if (item && item.data && item.data[cardKey]) {
                            item.data[cardKey][type] = data
                        }
                    }
                    delete updatedErrorData?.[labelKey];
                    return newData;
                });
                setErrorData(updatedErrorData)
                break;
        }
    }

    // functioon to remove brand for special input
    const removeSelectedBrands = (index) => {
        const updatedBrands = [...enteredBrands];
        updatedBrands.splice(index, 1);
        setEnteredBrands(updatedBrands);
    }
    // Function for handling key press for special input brand
    const handleBrandKeyPress = (type, event) => {
        if (event.key === 'Enter' && event.target.value.trim() !== '') {
            const newValue = event.target.value.trim();
            const newValuesArray = newValue.split(',').map(value => value.trim()); // Split by commas and trim each value
            newValuesArray.forEach(newBrand => {
                // Check if the newBrand is not already in enteredBrands
                if (!enteredBrands.includes(newBrand)) {
                    setEnteredBrands(prevValues => [...prevValues, newBrand]); // Add new brand to enteredBrands
                }
            });
            // Update inputData with the new value
            setInputData(prevData => ({
                ...prevData,
                [type]: {
                    ...prevData[type],
                    value: [
                        {
                            ...prevData[type]?.value?.find((item) => item.labelKey == 'brand'),
                            value: ''
                        }
                    ]
                }
            }));
            // Clear the input field
            event.target.value = '';
        }
    };
    // Function for braand input type
    const handleInputBrandChange = (type, data, labelKey) => {
        handleInputErrorChange({ field: data, enteredBrands });
        setInputData(prevData => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                value: [
                    {
                        ...prevData[type]?.value?.find((item) => item?.labelKey == labelKey),
                        value: data
                    }
                ]
            }
        }));
    }

    console.log('inputdataais64644', inputField)

    let input = []

    inputField?.value?.forEach((field, index) => {
        let errorMessage = null;
        if (errorData && errorData[field.labelKey]) {
            errorMessage = errorData[field.labelKey]?.map((error) => error); // Assuming only one error message per field
        }
        console.log("these are the fields: ", field)
        switch (field.type) {
            case 'lov':
                if (field?.multipleSelect == '0') {
                    input.push(
                        <div className="vobrs-sel">
                            <span className="mandatory" >{field?.isMandatory == 1 && field?.label && '*'}</span>
                            <Select
                                showSearch
                                allowClear
                                required={field?.isMandatory}
                                placeholder={`Please Select ${field?.headerName}`}
                                onChange={(text, value) => {
                                    handleSingleSelect(inputField.headerKey, value, field?.labelKey, index);
                                }}
                                disabled={field?.isEditableVendor == 0 ? true : false}
                                value={Object.entries(field.data)?.find(([key, value]) => value === '1')?.[0] || null}
                            >
                                {Object.entries(field.data)?.map(([key, value]) => (
                                    <Option key={key} value={key}>
                                        {key}
                                    </Option>
                                ))}
                            </Select>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    );
                } else if (field?.multipleSelect == '1') {
                    input.push(<div className="vobrs-sel">
                        <span className="mandatory" >{field?.isMandatory == 1 && field.label && '*'}</span>
                        <Select
                            mode="multiple"
                            allowClear
                            required={field?.isMandatory}
                            disabled={field?.isEditableVendor == 0 ? true : false}
                            placeholder={`Please Select ${field?.headerName}`}
                            maxCount={field.headerKey === "categories" ? 3 : undefined}
                            onChange={(selectedValues, selectedOptions) => handleMultipleSelect(inputField.headerKey, selectedValues, field?.labelKey, field)}
                            defaultValue={Object.entries(field.data)?.filter(([key, value]) => value === '1')?.map(([key, value]) => key)} // Set the defaultValue 
                        >
                            {Object.entries(field.data)?.map(([key, value]) => (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            ))}
                        </Select>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    );
                }
                break;

            case "checkbox":
                field.multipleSelect == '0' ?
                    input.push(<>
                        <span className="mandatory" >{field?.isMandatory == 1 && field?.label && '*'}</span>
                        <div className={`vobrs-checkbox`}>
                            {Object.entries(field.data)?.map(([key, value]) => (
                                <div className={`vobrsc ${field?.isEditableVendor == 0 && 'opacity-60'}`} key={key}>
                                    <label className="generic-checkbox">
                                        <input
                                            disabled={field?.isEditableVendor == 0 ? true : false}
                                            type="radio"
                                            name={inputField.headerKey}
                                            value={value}
                                            checked={value === '1'}
                                            onChange={(e) => handleIconCheckboxChange(inputField.headerKey, e.target.checked, key, false, field?.labelKey)}
                                        />
                                        <span className="checkmark1"></span>
                                        {capitalizeFirstLetter(key)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </>)
                    :
                    input.push(<>
                        <span className="mandatory" >{field?.isMandatory == 1 && field?.label && '*'}</span>
                        <div className={`vobrs-checkbox`}>
                            {Object.entries(field.data)?.map(([key, value]) => (
                                <div className={`vobrsc ${field?.isEditableVendor == 0 && 'opacity-60'}`} key={key}>
                                    <label className="generic-checkbox">
                                        <input
                                            type="checkbox"
                                            id={key}
                                            checked={value === '1'}
                                            onChange={(e) => handleIconCheckboxChange(inputField.headerKey, e.target.checked, key, true, field?.labelKey)}
                                            name={key}
                                            disabled={field?.isEditableVendor == 0 ? true : false}
                                        />
                                        <span className="checkmark1"></span>
                                        {capitalizeFirstLetter(key)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </>
                    )
                errorMessage && input.push(<p className="error-message">{errorMessage}</p>)
                break;

            case 'input':
                input.push(
                    <div className={`vobrs-input ${field.labelKey === "address_l1" || field.labelKey === "address_l2" ? "w-100" : ""}`}>
                        {field?.labelKey === 'brand' ?
                            <GenericInput
                                genInputExtraClass="vobrsi-brand"
                                inputKey={field?.labelKey}
                                type={field?.fieldDataType}
                                value={field?.value || ''}
                                label={field?.label}
                                errorData={errorData}
                                setErrorData={(data) => {
                                    setErrorData(data)
                                }}
                                avoidMandatoryCheck={() => {
                                    return field?.labelKey?.toLowerCase() === "brand"
                                }}
                                mandatory={field?.isMandatory}
                                placeHolder='Type to add brand'
                                iconRight={<Icons.EnterInputIcon />}
                                onKeyPress={(e) => handleBrandKeyPress(inputField?.headerKey, e)}
                                onChange={(e) => handleInputBrandChange(inputField?.headerKey, e.target.value, field?.labelKey)}
                                validationFunction={(val) => {
                                    if (field?.labelKey == "brand") {
                                        return (field?.isMandatory && enteredBrands?.length == 0) ? ["Please enter the brands to proceeds further. This is a mandentory."] : null
                                    }
                                }}
                                inputComponents={
                                    {
                                        'bottom': () => {
                                            return <ul>
                                                {enteredBrands?.map((brand, index) => (
                                                    <li key={index}>
                                                        <p>{brand}</p>
                                                        <button><Icons.CrossIcon onClick={() => removeSelectedBrands(index)} /></button>
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    }
                                }
                                isDisabled={field?.isEditableVendor == 0 ? true : false}
                            />
                            :
                            <GenericInput
                                key={field.labelKey}
                                inputKey={field?.labelKey}
                                type={field?.fieldDataType}
                                label={field?.label}
                                errorData={errorData}
                                setErrorData={(data) => {
                                    setErrorData(data)
                                }}
                                // address_l1 , address_l2
                                iconRight={field?.fieldDataType == 'dropdown' && <Icons.DownArrowNew />}
                                isDisabled={(field?.isEditableVendor == 0 ? true : false) || (field?.labelKey == 'city' && !inputData?.[inputField?.headerKey]?.value?.find(item => item?.labelKey == 'state')?.value)}
                                validationFunction={(val) => {
                                    if (field?.labelKey == "gst_no") {
                                        return val ? !/^[0-9]{2}[A-Z]{4}[1-9A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(val) ? ["Please enter the correct 15 digit GST Number"] : null : null
                                    }
                                }}
                                value={field?.value != undefined && field?.value != null && field?.value != 0 ? field?.value : ''}
                                mandatory={field?.isMandatory}
                                onChange={(e, state) => field?.fieldDataType != 'dropdown' ? handleInputChange(inputField.headerKey, e.target.value, field?.labelKey)
                                    :
                                    handleInputDropdown(inputField.headerKey, e, field?.labelKey, state)}
                                inputProps={() => field?.fieldDataType == 'dropdown' ? getInputProps(inputField.headerKey, field?.labelKey) : null}
                            />
                        }
                    </div>)
                break;

            case "iconbox":
                if (field.multipleSelect == '0') {
                    input.push(<>
                        <span className="mandatory" >{field?.isMandatory == 1 && field.label && '*'}</span>
                        <div className="vobrs-btns">
                            {Object.entries(field.data)?.map(([key, value]) => (
                                <GenericCustomButton
                                    className={`${value === '1' && "btn-active"}`}
                                    label={key}
                                    leftIcon={iconsMapping[key]}
                                    onClick={(e) => handleIconCheckboxChange(inputField.headerKey, e, key, false, field?.labelKey)}
                                    disabled={field?.isEditableVendor == 0 ? true : false}
                                />
                            ))}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </>
                    );
                } else if (field.multipleSelect == '1') {
                    input.push(<>
                        <span className="mandatory" >{field?.isMandatory == 1 && field.label && '*'}</span>
                        <div className="vobrs-btns">
                            {Object.entries(field.data)?.map(([key, value]) => (
                                <GenericCustomButton
                                    className={`${value === '1' && "btn-active"}`}
                                    label={key}
                                    leftIcon={iconsMapping[key]}
                                    onClick={(e) => handleIconCheckboxChange(inputField.headerKey, e, key, true, field?.labelKey)}
                                    disabled={field?.isEditableVendor == 0 ? true : false}
                                />
                            ))}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </>
                    );
                }
                break;

            case "radio":
                input.push(
                    <div className="vobrs-checkbox">
                        <h2>{field?.label}<span className="mandatory" >{field?.isMandatory == 1 && field.label && '*'}</span></h2>
                        {Object.entries(field?.data)?.map(([key, value]) => (
                            <div className="vobrsc" key={key}>
                                <label className="generic-checkbox">
                                    <input
                                        type="radio"
                                        name={inputField?.headerKey}
                                        value={value}
                                        checked={value == '1'}
                                        disabled={field?.isEditableVendor == 0 ? true : false}
                                        onChange={(e) => handleIconCheckboxChange(inputField?.headerKey, e.target.checked, key, false, field?.labelKey)}
                                    />
                                    <span className="checkmark1"></span>
                                    {key}
                                </label>
                            </div>
                        )
                        )}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                );
                break

            case 'upload':
                let headerLabelFuc = () => {
                    const label = field?.label || '';
                    let headerLabel;
                    if (label.toLowerCase().startsWith("upload")) {
                        headerLabel = label;
                    } else {
                        headerLabel = `Upload ${label}`;
                    }
                    return headerLabel;
                }
                input.push(
                    <div className="vobrs-upload" key={field?.labelKey}>
                        {console.log('fjjteychwhfdywwdcsd', field?.value)}
                        <GenericInput
                            key={field?.labelKey}
                            label={field?.label}
                            inputKey={field?.labelKey}
                            value={(!Array.isArray(field?.value) || field?.value?.length == 0) ? 'Attach File' : `${+field?.value?.length} Files`}
                            mandatory={field?.isMandatory}
                            actualInputValue={field?.value || []}
                            dependentFieldsObject={[field?.labelKey]}
                            type="upload"
                            errorData={errorData}
                            setErrorData={(data) => {
                                setErrorData(data);
                            }}
                            inputProps={() => ({
                                labelData: {
                                    header: headerLabelFuc()
                                },
                                renderCustomPlaceholder: () => {
                                    return ((!Array.isArray(field?.value) || field?.value?.length == 0) ? 'Attach File' : `${field?.value?.[0]?.oldFileName}`)
                                },
                                // multipleFileUpload: true,
                                multipleFileUpload: field?.multipleSelect,
                                deleteImgFromServer: deleteImgFromServer,
                                maxSize: 10,
                                fileUploadActionName: "finalSubmit",
                                fileKeys: ["oldFileName", "", "url"],
                                getListData: (data) => data?.resource,
                                api: {
                                    reduxState: "digivendStore",
                                    apiName: "uploadUtilityDocument",
                                    apiPayload: (files, removedFiles) => {
                                        let formData = new FormData();
                                        Object.values(files)?.map(file => {
                                            formData.append("file", file);
                                        })

                                        formData.append('documentId', 0);
                                        formData.append('type', field?.uploadKey);
                                        formData.append('requestId', '');
                                        return formData;
                                    },
                                },
                            })}
                            onChange={(data) => handleUpload(inputField?.headerKey, data, field?.labelKey)}
                            isDisabled={field?.isEditableVendor == 0 ? true : false}
                        />
                    </div>
                )
                break;

            case 'docLov':
                input.push(
                    <div className="vobrs-sel">
                        <label className="flex" htmlFor={field?.labelKey}>{field?.label} <span className="mandatory" >{field?.isMandatory == 1 && field.label && '*'}</span> </label>
                        <Select
                            mode="multiple"
                            allowClear
                            required={field?.isMandatory}
                            disabled={field?.isEditableVendor == 0 ? true : false}
                            placeholder={`Please Select ${field?.headerName}`}
                            onChange={(selectedValues, selectedOptions) => handleMultipleUploadSelect(inputField?.headerKey, selectedValues, field?.labelKey)}
                            value={Object.entries(field?.data)?.filter(([key, value]) => value?.isSelect == '1')?.map(([key, value]) => key)}
                        >
                            {Object.entries(field?.data)?.map(([key, value]) => (
                                <Option key={key} value={key}>
                                    {key}
                                </Option>
                            ))}
                        </Select>
                        {/* cards special functionality */}
                        {
                            Object.entries(field?.data)
                                ?.filter(([key, value]) => value?.isSelect == 1)
                                ?.map(([key, value]) => {
                                    return <div className="vobrss-box">
                                        <div className="vobrssb-top">
                                            <div className="flex items-center">
                                                <Icons.CompliancesIcon className="m-rgt-5" />
                                                <h2>{key}</h2>
                                            </div>
                                            <GenericCustomButton
                                                leftIcon={<Icons.CrossIcon />}
                                                onClick={() => handleRemoveCard(inputField?.headerKey, field?.labelKey, key)}
                                            />
                                        </div>
                                        <div className="vobrssb-mid">
                                            <GenericInput
                                                label='Valid Through'
                                                type='date'
                                                mandatory={field?.isMandatory}
                                                value={value?.date ? dayjs(value?.date) : dayjs() || null}
                                                onChange={(date) => handleUploadChange('date', inputField?.headerKey, field?.labelKey, key, date?.format("YYYY-MM-DD"))}
                                            />
                                        </div>
                                        <div className="vobrssb-bot">
                                            <GenericInput
                                                key={key}
                                                inputKey={key}
                                                label='Upload Document'
                                                mandatory={true}
                                                dependentFieldsObject={[key]}
                                                value={(!Array.isArray(value?.filePath) || value?.filePath?.length == 0) ? 'Attach File' : `${+value?.filePath?.length} Files`}
                                                actualInputValue={value?.filePath || []}
                                                type="upload"
                                                inputProps={() => ({
                                                    renderCustomPlaceholder: () => {
                                                        return (<div className='gibu-sel'>
                                                            {value?.filePath?.length ? value?.filePath?.[0]?.oldFileName : '+ Add Document'}
                                                        </div>
                                                        )
                                                    },
                                                    deleteImgFromServer: deleteImgFromServer,
                                                    maxSize: 10,
                                                    fileUploadActionName: "finalSubmit",
                                                    fileKeys: ["oldFileName", "", "url"],
                                                    getListData: (data) => data?.resource,
                                                    api: {
                                                        reduxState: "digivendStore",
                                                        apiName: "uploadUtilityDocument",
                                                        apiPayload: (files, removedFiles) => {
                                                            let formData = new FormData();
                                                            Object.values(files)?.map(file => {
                                                                formData.append("file", file);
                                                            })

                                                            formData.append('documentId', 0);
                                                            formData.append('type', value?.uploadKey);
                                                            formData.append('requestId', '');
                                                            return formData;
                                                        },
                                                    },
                                                })}
                                                onChange={(data) => handleUploadChange('filePath', inputField?.headerKey, field?.labelKey, key, data)}
                                            />
                                        </div>
                                    </div>
                                })
                        }
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                );
                break;

            default:
                break;
        }
    });
    return input
}

// function to get the final submit error data
export const finalSubmitErrorValidationsCheck = (inputData, errorData, setErrorData, extra = {}) => {
    const { enteredBrands = [] } = extra
    let updatedErrorData = {};
    Object.values(inputData || {}).forEach((inputField, index) => {
        inputField.value.forEach((field) => {

            let errors = handleInputErrorChange({ field, enteredBrands })
            if (!updatedErrorData[index]) {
                updatedErrorData[index] = {};
            }
            if (!updatedErrorData[index][field.labelKey]) {
                updatedErrorData[index][field.labelKey] = [];
            }

            if (errors?.length > 0) {
                updatedErrorData[index][field.labelKey] = errors;
            } else {
                if (updatedErrorData[index][field.labelKey]) {
                    delete updatedErrorData[index][field.labelKey];
                }
            }
        });
    });

    // Set the updated error data
    setErrorData({ ...updatedErrorData });
    return updatedErrorData
};

// common function to update the error
const handleInputErrorChange = (config = {}) => {
    let { field, enteredBrands } = config;
    console.log(field, "Fieljdjfkdfdds")
    let errors = [];
    let isEmptyValue = false;

    console.log('filedidsyee83ydh3tre6d', config);

    // mandatory check condition
    if (field?.isMandatory) {
        if (field?.type == 'radio' || field?.type == 'checkbox' || field?.type == 'lov' || field?.type == 'iconbox') {
            isEmptyValue = Object.values(field?.data)?.every(option => option == '0');
        }
        else if (field?.type == 'docLov') {
            isEmptyValue = Object.values(field?.data)?.every(option => option?.isSelect == '0') || Object.values(field?.data)?.some(option => {
                if (option?.isSelect == 1) {
                    if (option?.filePath?.length > 0 && option?.date != '') return false
                    else return true
                }
            })
        }
        else if (field?.labelKey == 'brand') {
            isEmptyValue = !enteredBrands?.length;
            // isEmptyValue=''
        }
        else {
            // Check if field.value is null, empty string, empty array, or array of empty strings
            isEmptyValue = (field.value === null || field.value === "" ||
                (Array.isArray(field.value) && field.value.every(val => val === "")));
        }

        if (isEmptyValue) {
            errors.push(`${field?.label ? field?.label : 'This'} is a mandatory field, please provide a valid value inside ${field?.label ? 'input' : 'it'}.`)
        }
    }

    return errors?.length == 0 ? null : errors;
}
