import React from 'react'
import ViewProductDetails from '../digicatalogue/viewDetails/ViewProductDetails';

const SinglePageDocument = (props) => {
    const type = props?.selectedData?.actionType;
    switch (type) {
        case "viewProductDetails":
            return <>
                <ViewProductDetails {...props} />
            </>
        default: return
    }
}

export default SinglePageDocument