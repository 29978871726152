import React, { useEffect, useState } from 'react'
import ProfileMappingSkeletonLoader from '../../../skeletons/ProfileMappingSkeletonLoader';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from '../../../../helper/genericFunction';
import Icons from '../../../../assets/icons';
import LanguageTranslator from '../../../../locals/LanguageTranslator';

const DataMappingView = () => {
    const dispatch = useDispatch()
    const authStore = useSelector(state => state.authStore);
    const generalStore = useSelector(state => state.generalStore);

    const [mappingData, setMappingData] = useState({});

    useEffect(() => {
        if (Object.keys(mappingData || {}).length == 0 && parseJwt(sessionStorage.getItem('token')).uType === "ENT") {
            dispatchHandler(dispatch, 'getUserDataMappingRequest', {})
        }
    }, [])

    useEffect(() => {
        if (generalStore?.getUserDataMappingData?.isSuccess) {
            setMappingData(generalStore?.getUserDataMappingData?.data?.resource || {})
        }
    }, [generalStore?.getUserDataMappingData?.isSuccess])

    console.log(44444444, mappingData)

    const getKey = {
        vendorMapping: "name1",
        agentMapping: "name1",
        customerMapping: "",
        siteMapping: "name1",
        transporterMapping: "name1",
        itemMapping: {
            hl1_code: "hl1name",
            hl2_code: "hl2name",
            hl3_code: "hl3name",
            hl4_code: "hl4name",
            hl5_code: "hl5name",
            hl6_code: "hl6name"
        },
    };

    const getItemName = {
        hl1_code: "Divison",
        hl2_code: "Section",
        hl3_code: "Department",
        hl4_code: "Article",
        hl5_code: "HL5 Name",
        hl6_code: "Hl6 Name"
    }

    return (
        authStore?.["getProfileMappingData"]?.isLoading ? <ProfileMappingSkeletonLoader /> : 
        <div className="upr-data-mapping">
            <div className="pdmt-head">
                <LanguageTranslator tag="h3">Data Mapping</LanguageTranslator>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.PackageProducts/>
                        <LanguageTranslator tag="h3">Item Data</LanguageTranslator>
                        <span className="pdmtit-count">{Object.values(mappingData?.itemMapping || {})["0"]?.length || 0}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the items mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {Object.keys(mappingData?.["itemMapping"] || {})?.map((innerKey) => {
                                const items = mappingData["itemMapping"][innerKey];
                                if (Array.isArray(items) && items.length > 0) {
                                    return (
                                        <tr>
                                            <td className="pdmti-depart">{getItemName?.[innerKey] || ""}</td>
                                            <td className="pdmti-flex-td">
                                                {items.map((item) => <input type="text" autoComplete="off" value={item?.[getKey?.itemMapping?.[innerKey]] || ""}/>)}
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.SiteMappingData/>
                        <LanguageTranslator tag="h3">Site Data</LanguageTranslator>
                        <LanguageTranslator tag="span" className="pdmtit-count">{mappingData?.siteMapping?.length}</LanguageTranslator>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the Sites mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.siteMapping?.length != 0 ?
                                <tr>
                                    <td className="pdmti-depart">{"Site Name"}</td>
                                    <td className="pdmti-flex-td">
                                        {mappingData?.siteMapping?.map((data) => (
                                            <input type="text" autoComplete="off" value={data?.name1} />
                                        ))}
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.VendorMappingData/>
                        <LanguageTranslator tag="h3">Vendor Data</LanguageTranslator>
                        <span className="pdmtit-count">{mappingData?.vendorMapping?.length}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the vendors data mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.vendorMapping?.length != 0 ?
                                <tr>
                                    <td className="pdmti-depart">{"Vendor Name"}</td>
                                    <td className="pdmti-flex-td">
                                        {mappingData?.vendorMapping?.map((data) => (
                                            <input type="text" autoComplete="off" value={data?.name1} />
                                        ))}
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.VendorMappingData/>
                        <LanguageTranslator tag="h3">Agent Data</LanguageTranslator>
                        <span className="pdmtit-count">{mappingData?.agentMapping?.length}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the agent data mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.agentMapping?.length != 0 ?
                                <tr>
                                    <td className="pdmti-depart">{"Agent Name"}</td>
                                    <td className="pdmti-flex-td">
                                        {mappingData?.agentMapping?.map((data) => (
                                            <input type="text" autoComplete="off" value={data?.name1} />
                                        ))}
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.VendorMappingData/>
                        <LanguageTranslator tag="h3">Customer Data</LanguageTranslator>
                        <span className="pdmtit-count">{mappingData?.customerMapping?.length}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the customer data mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.customerMapping?.length != 0 ?
                                <tr>
                                    <td className="pdmti-depart">{"Customer Name"}</td>
                                    <td className="pdmti-flex-td">
                                        {mappingData?.customerMapping?.map((data) => (
                                            <input type="text" autoComplete="off" value={data?.name1} />
                                        ))}
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.VendorMappingData/>
                        <LanguageTranslator tag="h3">Transporter Data</LanguageTranslator>
                        <span className="pdmtit-count">{mappingData?.transporterMapping?.length}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the transporter data mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.transporterMapping?.length != 0 ?
                                <tr>
                                    <td className="pdmti-depart">{"Transporter Name"}</td>
                                    <td className="pdmti-flex-td">
                                        {mappingData?.transporterMapping?.map((data) => (
                                            <input type="text" autoComplete="off" value={data?.name1} />
                                        ))}
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="pdmt-item">
                <div className="pdmti-top">
                    <div className="pdmtit-heading">
                        <Icons.ManagementData/>
                        <LanguageTranslator tag="h3">Manager</LanguageTranslator>
                        <span className="pdmtit-count">{mappingData?.MANAGER_MAPPING != undefined ? mappingData?.MANAGER_MAPPING?.length : ""}</span>
                    </div>
                    <LanguageTranslator tag="span" className="pdmtit-text">Below are the Managers mapped with this account</LanguageTranslator>
                </div>
                <div className="pdmti-body">
                    <table className="table">
                        <tbody>
                            {mappingData?.MANAGER_MAPPING != undefined && mappingData?.MANAGER_MAPPING?.length != 0 ? mappingData?.MANAGER_MAPPING?.map((data1, key1) => (
                                <tr>
                                    <td className="pdmti-depart">{data1.column_key && data1.column_key}</td>
                                    <td className="pdmti-flex-td">
                                        {data1.search_value && JSON.parse(data1.search_value).map((data2, key2) => (
                                            <input type="text" autoComplete="off" value={data2} />
                                        ))}
                                    </td>

                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    )
}

export default DataMappingView