import React, { useState } from 'react'
import Icons from '../../../assets/icons'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import { parseJwt } from '../../../helper/genericFunction'
import { CheckCheck } from 'lucide-react'

const MaxUserLimitPopup = ({ type, closeModal }) => {

  const [raisedTicket, setRaisedTicket] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let time  = null;


  if(raisedTicket){
    return <div className='space-y-4 text-center px-16 pt-[1.75rem] pb-7 text-fontBlack'>
      <div className='w-fit mx-auto'>
        <Icons.SuccessIcon/>
      </div>
      <p className='text-[13px] mb-0 pb-4'>A ticket has been raised to the Supplymint support team, someone from the team will get back to you soon. Thank you!</p>
      <GenericCustomButton
        // type='primary'
        onClick={e => {closeModal?.()}}
        label = "Close"  
        className={'w-[50%] !rounded-none !h-[37px] border border-black font-semibold'}
      />
    </div>
  }

  return (
    <div className='px-4 pt-[1.6rem] pb-[24px] text-[13px] text-center space-y-8 text-[#0F172A]'>
      <div className='space-y-3 max-w-[350px] mx-auto'>
        <div className='w-fit mx-auto'>
          {type == 2 ? <Icons.SiteLimitReached /> : <Icons.UsersLimitReached />}
        </div>
        {/* {type == 1 ? "Users Limit Reached!" : "Site Limit Reached"} */}
        <h2 className='mb-0 font-semibold text-xl'>License Limit Exceeded</h2>
        <p className='text-[#575D6A]'>{type == 2 ? "It seems you've reached the maximum number of site licenses for your account." : "It seems you've reached the maximum number of user licenses for your account."}</p>
        <p>{type == 2 ? "To continue adding new sites, please contact your account administrator or Supplymint account manager." : "To continue adding new users, please contact your account administrator or Supplymint account manager."}</p>
      </div>

      <div className='h-[1px] w-2/3 bg-[#DAE4EE] mx-auto'></div>

      <div>
        <GenericCustomButton
          loading={loading}
          label={"Contact Support"}
          onClick={async () => {
            let tokenData = parseJwt(sessionStorage.getItem("token") || "") || {};
            setLoading(true);
            fetch(`https://supplymint.freshdesk.com/api/v2/tickets`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Basic RXQ2MExXcFViTktwT0Vtc3pheEc=",
                  "X-Auth-Token": sessionStorage.getItem("token") || "",
                  "Page-Link": window.location.hash.split("?")[0] || undefined,
                },
                body: JSON.stringify({
                  email: tokenData?.["eml"] || "",
                  subject:  type == 2 ? "Sites Limit Reached!" : "Users Limit Reached",
                  description: type == 2 ? "Site limits has been reached." : "Users limit has been reached.",
                  priority: 2,
                  status: 2,
                  cc_emails: undefined,
                  custom_fields: {
                    cf_enterprise_id: tokenData?.eid || "",
                    cf_issue_related_to: "Others", // "License Limit",
                    cf_issue_sub_category:"Other",  //"License Limit",
                    cf_module: "Others",  // "Supplymint",
                    cf_organisation_id: tokenData?.oid || "",
                    cf_organisation_name: tokenData?.orgName || "",
                    cf_organisation_search_key: (tokenData?.["X-TENANT-ID"] + "_" + tokenData?.["oid"]) || "",
                    cf_user_id: tokenData?.jti || "",
                    cf_user_type: tokenData?.uType || "",
                    cf_username: tokenData?.prn || "",
                    cf_billing_type: "Not Applicable",
                  }
                })
              }
            )?.then(response => {
              console.log(response, "create ticket response");
              setLoading(false);
              if(response.status === 200 || response.status === 201){
                setRaisedTicket(true);
              }else{
                setError("Failed to create ticket to contact supplymint support.");
                clearTimeout(time);
                time = setTimeout(() => {
                  setError(false);
                },5000)
              }
            })
          }}
          type="primary"
          className={'min-w-[140px]'}
        />
        {error && <p style={{color:"red"}}>{error}</p>}
      </div>
    </div>
  )
}

export default MaxUserLimitPopup