import React, { useState, useEffect, useRef } from 'react'
import Icons from '../../../../assets/icons';
import GenericCustomButton from '../../../GenericCustomButton';
import { CONFIG, CONFIG as config } from '../../../../config/index'
import axios from 'axios';
import { Mentions, Tooltip } from 'antd';
import { getFileTypeFromUrl } from '../../../../helper/helperTableFunctions';
import GenericSpinnerLoader from '../../../../loaders/GenericSpinnerLoader';
import {File, FileMusic, Icon, Paperclip, Trash2, X } from 'lucide-react';


const CommentEditor = (props = {}) => {

	let { enterButton, editable, handleChange = false, setErrorData, mode } = props;

	const [userResponse, setUserResponse] = useState(null);
	const [loading, setLoading] = useState({});

	const [currentQuery, setCurrentQuery] = useState("");
	const [taggedUser, setTaggedUser] = useState({});
	const [content, setContent] = useState("");

	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [uploadingFiles, setUploadingFiles] = useState([]);

	const fileInputRef = useRef();

	const fileIcons = {
		'PDF': <div className='scale-110'><Icons.PdfFileIcon/></div>,
		['XLSX']: <div><Icons.ExcelFileIcon/></div>,
		['XLS']: <div><Icons.ExcelFileIcon/></div>,
		['DOC']: <div><Icons.WordFileIcon/></div>,
		['DOCX']: <div><Icons.WordFileIcon/></div>
	}

	const handleIconClick = () => {
		fileInputRef.current.click();
	  };

	// Reset the data when the loading changes to false for the add comment 
	useEffect(() => {
		focusInput()
	}, [])

	const focusInput = () => {
		document.getElementById("mentionInputBox")?.focus();
	}

	// Function : To reset all the states for this editor here
	const resetData = () => {
		setLoading({});
		setUploadedFiles([]);
		setUploadingFiles([]);
		setContent("");
		setTaggedUser({});
		setCurrentQuery("");
		setUserResponse(null);
	}

	useEffect(() => {
		if (props.resetInputBox) {
			resetData();
			focusInput()
			props.setResetData(false)
		}
	}, [props.resetInputBox])

	// Upload File Function to upload the files
	const uploadFile = async (files) => {

		const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

		// Filter out files that exceed the size limit
		let validFiles = Array.from(files).filter(file => file.size <= maxFileSize)?.slice(0, 10);

		if (validFiles?.length === 0) {
			setErrorData(["File size must be less than or equal to 10 MB."])
			return;
		}
		 else if (validFiles?.length != Array.from(files)?.length){
			setErrorData(["Files under 10 MB uploaded; over 10 MB failed."])
			// return;
		}

		if (10 - uploadedFiles?.length >= validFiles?.length) {

			const body = new FormData();
			validFiles?.map(file => {
				body.append("file", file);
			})
			
			body.append("disposition", "TRUE")

			setUploadingFiles(validFiles);

			setLoading(prev => ({ ...prev, "uploadingFiles": true }))

			const response = await axios.post(encodeURI(`${config.NOTIFICATION_ROUTE_PROD}/fileUpload/new`), body, {
				body: body,
				headers: {
					"Content-Type": "multipart/form-data",
					"X-Auth-Token": sessionStorage.getItem("token"),
				}
			});

			setLoading(prev => ({ ...prev, "uploadingFiles": false }))
			setUploadingFiles([]);
			if (response.data) {
				setUploadedFiles(prev => {
					return ([...prev, ...response.data])
				})
				return response.data?.fileUrl;
			}
		}
	}

	const sendMessage = () => {
		if (!isEnterDisabled()) {
			console.log("Chekc234", content);
			handleChange({
				uploadedFiles: uploadedFiles,
				taggedUser: taggedUser,
				content: content.toString().trim(),
			});
		} else {
			if (props.errorData != "Please add some content to continue.")
				props.setErrorData(["Please add some content to continue."])
		}
	}

	const isEnterDisabled = (data) => {

		if(!props.editable){
			return true;
		}

		console.log("Disabled condition check", uploadingFiles?.length > 0 ? true : content ? false : uploadedFiles?.length == 0);
		return uploadingFiles?.length > 0 ? true : content.toString().trim() ? false : uploadedFiles?.length == 0;
	}

	const getCommentOrganisationUsers = (query) => {
		const getUsers = (response) => {
			let newResponse = {};
			response?.map(item => { newResponse[`${item?.firstName} ${item?.lastName}`] = item });

			if (JSON.stringify(userResponse) != JSON.stringify(newResponse)) {
				setUserResponse(prev => ({ ...newResponse }));
			}
		}

		let response;


		if (query != currentQuery || (query == "" ? userResponse == null : false)) {
			setCurrentQuery(query);
			axios.post(`${CONFIG.BASE_URL}/core/user/get/all`, {
				"type": 1,
				"pageNo": 1,
				"sortedBy": "",
				"sortedIn": "",
				"search": query || "",
				"filter": {},
				"pageSize": 30,
				"displayName": "MANAGE_USERS"
			}, {
				headers: {
					"X-AUTH-TOKEN": sessionStorage.getItem("token"),
					"Content-Type": "application/json",
					"Page-Link": window.location.hash.split("?")[0] || undefined,
				}
			})
				?.then(response => {
					console.log("Query changes", response);
					if (response.status == 200) {
						getUsers(response?.data?.data?.resource)
					}
				})
		} else {
			response = Object.values(userResponse || {});
			getUsers(response)
		}
	}

	const onCommentInputChange = (value) => {
		setContent(value);
	}

	function checkFileType(fileType) {
		const imageFormats = [
		  "jpg", "jpeg", "png", "gif", "bmp", "tiff", 
		  "image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/tiff"
		];
	  
		const videoFormats = [
		  "mp4", "video/mp4", "avi", "video/avi", "mov", "video/quicktime",
		  "wmv", "video/x-ms-wmv", "flv", "video/x-flv", "mkv", "video/x-matroska",
		  "webm", "video/webm", "mpeg", "video/mpeg", "3gp", "video/3gpp",
		  "ogv", "video/ogg", "mts", "video/mts", "m2ts", "video/m2ts",
		  "vob", "video/dvd", "f4v", "video/x-f4v", "asf", "video/x-ms-asf", 
		  "ts", "video/MP2T", "divx", "video/divx"
		];
	  
		const audioFormats = [
		  "mp3", "audio/mpeg", "wav", "audio/wav", "ogg", "audio/ogg", "flac", "audio/flac",
		  "aac", "audio/aac", "m4a", "audio/mp4", "wma", "audio/x-ms-wma"
		];
	  
		const docFormats = [
		  "pdf", "application/pdf", "doc", "application/msword", "docx", 
		  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
		  "xls", "application/vnd.ms-excel", "xlsx", 
		  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		  "ppt", "application/vnd.ms-powerpoint", "pptx", 
		  "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
		  "txt", "text/plain", "rtf", "application/rtf"
		];
	  
		if (imageFormats.includes(fileType)) {
		  return 'image';
		} else if (videoFormats.includes(fileType)) {
		  return 'video';
		} else if (audioFormats.includes(fileType)) {
		  return 'audio';
		} else if (docFormats.includes(fileType)) {
		  return 'doc';
		} else {
		  return 'unknown';
		}
	  }

	return (
		<React.Fragment>
			<div className='flex items-center gap-2 px-2' style={{fontFamily: 'poppins'}}>
				<div className={`${uploadingFiles?.length > 0 ? 'opacity-75 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`}>
					<Tooltip overlayClassName='!p-[0]' title="Attach files">
						<button className={`rounded duration-200 hover:bg-[#DAE4EE]/50 ${mode === 'central' ? 'p-[8px]' : 'p-[6px]'}`} type='button' onClick={handleIconClick}>
							<Paperclip size={mode === 'central' ? 19 : 17} />
						</button>
				</Tooltip>
				{/* Create the upload input here */}
				<input
					type="file"
					disabled={uploadingFiles?.length > 0}
					multiple={true}
					autoComplete="off"
					// accept=".pdf, image/*, .xls, .xlsx, .csv"
					onChange={(e) => {
						uploadFile?.(e?.target?.files)
						e.target.value = '';
					}}
					ref={fileInputRef}
					className="hidden"
				/>

				</div>

				{/* First we need to create the mention type of input */}
				<Mentions
					placeholder='Type Some Message...'
					id='mentionInputBox'
					value={content}
					className='text-[13px] leading-5 font-medium border-none outline-none focus:outline-none focus-withins:shadow-none'
					style={{
						fontFamily: 'poppins'
					}}
					onChange={onCommentInputChange}
					onKeyDown={(e) => {
						if (e.key == "Enter" && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
							if(!isEnterDisabled()){
								sendMessage()
								e.preventDefault();
							}
						}
					}}
					onSearch={getCommentOrganisationUsers}
					onSelect={(val) => {
						let updatedTaggedUser = { ...taggedUser };
						updatedTaggedUser[val?.key] = userResponse?.[val?.key]
						setTaggedUser(updatedTaggedUser)
					}}
					options={Object.keys(userResponse || {})?.map(key => ({
						key: key,
						value: key,
						label: (
							<label>
								{key}
							</label>
						)
					}))}
				/>

				{/* render the files data here */}

				{enterButton && <GenericCustomButton
					label={'Send'}
					disabled={!editable || isEnterDisabled()}
					loading={props?.loading?.["add_comment"]}
					rightIcon={<Icons.SendIcon />}
					onClick={() => {
						sendMessage()
					}}
					className={'bg-primary text-xs py-1.5 px-2 rounded flex items-center gap-1 text-white whitespace-nowrap hover:opacity-75 disabled:opacity-50'}
				/>}
			</div>
		{
			// (uploadingFiles?.length > 0 || uploadedFiles?.length > 0) &&

			<div className={`flex items-center gap-4 px-2 overflow-x-auto ${mode === 'central' ? '' : 'w-[calc(500px-24px)]'} duration-200 ${(uploadingFiles?.length > 0 || uploadedFiles?.length > 0) ? 'max-h-96 pt-[8px] pb-1 mt-[8px] border-t' : 'max-h-0'}`}>
				{
					[(uploadedFiles || [])?.map((item, index) => {
						let fileType = getFileTypeFromUrl(item?.fileUrl?.split('?')?.[0])
						// console.log('fileType', fileType)
						return <div className={`shrink-0 relative rounded border border-[#DAE4EE] w-[175px] ${checkFileType(fileType) === 'image' || checkFileType(fileType) === 'video' && 'rounded-l-none'}`}>
							{checkFileType(fileType) === 'image' || checkFileType(fileType) === 'video' ? <>
								<div className='flex items-center gap-2 pr-2'>
										<div className='h-[45px] w-[45px] flex-shrink-0 flex-grow-0 basis-[45px] overflow-hidden bg-gray-200'>
											{checkFileType(fileType) === 'image' ? 
											<img className='h-full w-full object-cover object-center' src={item?.fileUrl} />
											: checkFileType(fileType) === 'video' ?
											<video className='h-full w-full object-cover object-center' src={item?.fileUrl} />
											: null
										}
										</div>
										<div className='overflow-hidden text-xs py-1.5 pr-2 flex-1'>
										<p className='truncate whitespace-nowrap overflow-hidden mb-0 font-medium'>{item?.fileName}</p>
										<p className='mb-0 text-[10px] text-[#8496A9] whitespace-nowrap'>{fileType?.toUpperCase()}</p>
										</div>
									<button
									className={'p-[6px] rounded hover:text-danger hover:bg-red-50'}
									onClick={() => {
										setUploadedFiles(prev => {
											return prev?.filter((f, findex) => findex != index)
										})
									}}
								><Trash2 size={18}/></button>
								</div>
							</> : <>
								<div className='flex items-center justify-between px-2 gap-2 py-1.5'>
										<div>{checkFileType(fileType) === 'audio' ? <FileMusic size={20}/> : (checkFileType(fileType) === 'doc' || checkFileType(fileType) === 'unknown') && <File size={20}/>}</div> 
										<div className='overflow-hidden text-xs flex-1'>
										<p className='truncate whitespace-nowrap overflow-hidden mb-0 font-medium'>{item?.fileName}</p>
										<p className='mb-0 text-[10px] text-[#8496A9] whitespace-nowrap'>{fileType?.toUpperCase()}</p>
										</div>
									<button
									className={'ml-1 p-[6px] rounded hover:text-danger hover:bg-red-50'}
									onClick={() => {
										setUploadedFiles(prev => {
											return prev?.filter((f, findex) => findex != index)
										})
									}}
								><Trash2 size={18}/></button>
								</div>
							</>}
						</div>

					})]
				}
				{
					[(uploadingFiles || [])?.map(item => {

						console.log("vheckads", item)

						let fileType = getFileTypeFromUrl(item?.name)
							return <div className={`relative rounded border border-[#DAE4EE] w-[175px] ${checkFileType(fileType) === 'image' || checkFileType(fileType) === 'video' && 'rounded-l-none'}`}>
							{checkFileType(fileType) === 'image' || checkFileType(fileType) === 'video' ? <>
							<div className='shrink-0 flex items-center gap-2 pr-2'>
								<div className='h-[45px] w-[45px] flex-shrink-0 flex-grow-0 basis-[45px] overflow-hidden bg-gray-200'>
								{checkFileType(fileType) === 'image' ?
									<img className='w-full h-full object-cover object-center' src={item?.url} />
								:
								checkFileType(fileType) === 'video' ?
									<video className='w-full h-full object-cover object-center' src={item?.url} />
								: null
								}
								</div>
								<div className='overflow-hidden text-xs py-1.5 pr-2 flex-1'>
									<p className='truncate whitespace-nowrap overflow-hidden mb-0 font-medium'>{item?.name}</p>
									<p className='mb-0 text-[10px] text-[#8496A9] whitespace-nowrap'>{fileType?.toUpperCase()}</p>
								</div>
								<div className="scale-90">
									<GenericSpinnerLoader />
								</div>
							</div>
							</> : <>
								<div className='flex items-center gap-2 px-2 py-1.5'>
								<div>{checkFileType(fileType) === 'audio'? <FileMusic size={20}/> : (checkFileType(fileType) === 'doc' || checkFileType(fileType) === 'unknown') && <File size={20}/>}</div>
								<div className='text-xs overflow-hidden flex-1'>
									<p className='mb-0 truncate overflow-hidden whitespace-nowrap font-medium'>{item?.name}</p>
									<p className='mb-0 text-[10px] text-[#8496A9]'>{fileType?.toUpperCase()}</p>
								</div>
								<div className="scale-90">
									<GenericSpinnerLoader />
								</div>
								</div>
							</>}
						</div>

					})]
				}
			</div>
		}
		</React.Fragment>
	)
}

export default CommentEditor