import React from 'react'
const divsArray = Array.from({ length: 6 }, index => index + 1);

const SitesSectionSkeleton = () => {
    return (
        <div className='wab-left !overflow-hidden'>
            {divsArray.map(() => (
                <button className='hgt-100 p-10'>
                    <div className='generic-skeleton w-100'></div>
                    <div className='generic-skeleton w-100'></div>
                    <div className='generic-skeleton w-100'></div>
                </button>
            ))}
        </div>
    )
}

export default SitesSectionSkeleton