import React, { Component } from 'react'

export default class Pagination extends Component {
    render() {
        // console.log(this.props);
        // console.log(this.props.current);
        
        return (
            <div className="pagination-inner">
                <ul className="pagination-item">
                {this.props.current == 1 || this.props.current == undefined || this.props.current == "" ? 
                    <li>
                        <button className="disable-first-btn" disabled >
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                    <path id="chevron_1_" fill="#a7b3c1" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                    <path id="chevron_1_" fill="#a7b3c1" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li> :
                    <li>
                        <button className="first-btn" type="button" onClick={(e) => this.props.page(e)} id="first" >
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="first">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="first">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li>}
                    {this.props.prev != 0 && this.props.prev != "" && this.props.current != 1 && this.props.current != "" && this.props.current != undefined ?
                    <li>
                        <button className="prev-btn" type="button" onClick={(e) => this.props.page(e)} id="prev">
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="prev">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li> :
                    <li>
                        <button className="dis-prev-btn" disabled>
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                    <path id="chevron_1_" fill="#a7b3c1" d="M16.318 8.8a.752.752 0 0 1-1.063 0L8.269 1.815 1.283 8.8A.752.752 0 0 1 .22 7.738L7.738.22A.752.752 0 0 1 8.8.22l7.517 7.518a.752.752 0 0 1 .001 1.062z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li>}
                    <li>
                        <button className="pi-number-btn">
                            <span>{this.props.current}/{this.props.maxPage}</span>
                        </button>
                    </li>
                    {this.props.current != "" && this.props.next - 1 != this.props.maxPage && this.props.current != undefined ? 
                    <li>
                        <button className="next-btn" type="button" onClick={(e) => this.props.page(e)} id="next">
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="next">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li> : 
                    <li >
                        <button className="dis-next-btn" disabled>
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                    <path id="chevron_1_" fill="#a7b3c1" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                        </li>}
                    {this.props.current != 0 && this.props.next - 1 != this.props.maxPage && this.props.current != undefined && this.props.maxPage != this.props.current ? <li >
                        <button className="last-btn" type="button" onClick={(e) => this.props.page(e)} id="last">
                            <span className="page-item-btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="last">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539" onClick={(e) => this.props.page(e)} id="last">
                                    <path id="chevron_1_" fill="#21314b" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                                </svg>
                            </span>
                        </button>
                    </li> : 
                    <li >
                        <button className="dis-last-btn" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                <path id="chevron_1_" fill="#a7b3c1" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="9.021" height="14" viewBox="0 0 9.021 16.539">
                                <path id="chevron_1_" fill="#a7b3c1" d="M16.318.22a.752.752 0 0 0-1.063 0L8.269 7.206 1.283.22A.752.752 0 0 0 .22 1.283L7.738 8.8a.752.752 0 0 0 1.062 0l7.517-7.518A.752.752 0 0 0 16.318.22z" data-name="chevron (1)" transform="rotate(-90 8.27 8.27)"/>
                            </svg>
                        </button>
                    </li>}
                </ul>
            </div>
        )
    }
}
