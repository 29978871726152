export const PRIMARY_FILTERS = [
    {
        value: "Date Range",
        key: "orderDate",
        storeCode : "orderDate",
    },
    {
        value: "Dispatch Location",
        key: "dispatch_location_code",
        code: "dispatch_location",
        entity: "somain",
        storeCode: "dispatchLocationCode",
    },
    {
        value: "Customer",
        code: "customer_name",
        key: "customer_code",
        entity: "somain",
        storeCode: "customerCode"
    },
    {
        value: "Delivery Location",
        code: "delivery_location",
        key: "delivery_location_code",
        entity: "somain",
        storeCode: "deliveryLocationCode"
    },
    {
        value: "Division",
        code: "hl1name",
        key: "hl1code",
        entity: "item_master",
        storeCode: "hl1code"
    },
    {
        value: "Section",
        code: "hl2name",
        key: "hl2code",
        entity: "item_master",
        storeCode: "hl2code"
    },
    {
        value: "Department",
        code: "hl3name",
        key: "hl3code",
        entity: "item_master",
        storeCode: "hl3code"
    },
];

export const REPORT_FILTER = [
    {
        value: "name1",
        key: "name1",
        code: "name1",
        entity: "site",
        storeCode : "name1",
    },
    {
        value: "displayname",
        key: "displayname",
        code: "displayname",
        entity: "site",
        storeCode : "displayname",
    },
]

// debounce function 
export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        const context = this;

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}

export const MAIN_FILTERS = [
    {
        value: "Brand",
        key: "brand",
        entity: "product_catalogue_vw",
        storeCode : "Brand",
    },
    {
        value: "Department",
        key: "department",
        entity: "product_catalogue_vw",
        storeCode : "Department",
    },
    {
        value: "Design No",
        key: "design_group_no",
        entity: "product_catalogue_vw",
        storeCode : "Design No",
    },
    {
        value: "Colour",
        key: "color",
        entity: "product_catalogue_vw",
        storeCode : "Colour",
    }
];

export const CUSTOMER_FILTERS = [
    {
        value: "Customer Name",
        key: "customer_name",
        entity: "somain",
        storeCode : "Customer Name",
    },
    {
        value: "Customer Code",
        key: "customer_code",
        entity: "somain",
        storeCode : "Customer Code",
    },
];