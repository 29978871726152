// Handle the notification click handler here

import { eventMappingJSON } from "./EventComponentMapping";
import { setPayloadAsURLParams } from "../../helper/URLParamsHelper";
import { parseJwt } from "../../helper/genericFunction";

const notificationClickHandler = (data = {}) => {

    let { content = {}, config = {}, } = data;

    let { history, location } = config;

    window.parent.parent.focus();

    let configuration = eventMappingJSON?.[content?.notificationCode] || {};
    switch (configuration?.["type"]) {
        case "tableComponent": {
            // creating query params for different pages
            const queryParams = new URLSearchParams("");

            let filterObj = {};

            configuration?.["configKeys"]?.map(key => {
                filterObj[key] = content?.content?.userActivity?.documentRefNo || ""
            })

            let definedParams = {
                "expandRow1": content?.content?.userActivity?.documentId ? [content?.content?.userActivity?.documentId] : undefined,
                "pageNo": 1,
                "pageSize": 10,
                "search": "",
                "filter": filterObj || {},
                "selectedItems": content?.content?.userActivity?.documentId ? [content?.content?.userActivity?.documentId] : undefined,
            }

            Object.entries(definedParams || {})?.map(([key, value]) => {
                value && queryParams.set(key, encodeURIComponent(JSON.stringify(value)));
            })

            Object.entries(configuration?.["pageInformation"] || {})?.map(([key, value]) => {
                queryParams.set(key, encodeURIComponent(JSON.stringify(value)));
            })

            if (configuration?.["pagelink"]) {
                console.log('datssew34xxff656f', data, configuration);
                if (configuration?.pagelink == location?.pathname) {
                    console.log('datssew34xxff656f33', data, configuration?.["pagelink"], queryParams.toString());
                    history?.push({
                        pathname: configuration?.["pagelink"],
                        search: queryParams.toString()
                    });
                    window.location.reload();
                } else {
                    console.log('datssew34xxff656f', data, configuration?.["pagelink"], queryParams.toString());
                    history?.push({
                        pathname: configuration?.["pagelink"],
                        search: queryParams.toString()
                    })
                }
            }
        }
            break;
        case "viewProductDetails": {
            const queryParams = new URLSearchParams(location?.search || "");
            queryParams.append("productId", encodeURIComponent(JSON.stringify(content?.content?.userActivity?.documentRefNo || "")));
            queryParams.append("actionType", encodeURIComponent(JSON.stringify("viewProductDetails")));
            history?.push({
                pathname: location?.pathname || "/",
                search: queryParams.toString()
            });
            break;
        }
        case "centralCommentModal" : {
            const queryParams = new URLSearchParams(location?.search || "");
            content?.documentId && queryParams.set("documentId", encodeURIComponent(JSON.stringify(content?.documentId || "")));
            content?.documentNumber && queryParams.set("documentNumber", encodeURIComponent(JSON.stringify( content?.documentNumber || "")));
            content?.documentType && queryParams.set("documentType", encodeURIComponent(JSON.stringify(content?.documentType || "")));
            history?.push({
                pathname: '/central/comment',
                search: queryParams.toString()
            });

            console.log("check2e523",location);
            if(location.pathname == "/central/comment"){
                window.location.reload();
            }

            break;
        }
        default :
    }
}

export { notificationClickHandler }