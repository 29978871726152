import Lottie from 'lottie-react';
import { Check, FilePenLine } from 'lucide-react';
import React from 'react'
import JobSuccessTickMark from '../animationsJSON.js/JobSuccessTickMark.json'
import JobRunGif from '../assets/gif/jobRun.gif'
import LanguageTranslator from '../locals/LanguageTranslator';

const getAnimation = (type) => ({
    loop: true,
    autoplay: true,
    animationData: JobSuccessTickMark,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    },
    style: {
        width: 70,
        height: 70
    }
})

const GenericWizardComponent = (props) => {
    const {wizardSteps, currentStep, isRunOnDemand, handleChange, isEditable} = props;


    return (
        <ul class={`flex justify-start items-center gap-3 !text-[14px] !w-full bg-white py-4 px-5 my-3 text-sm font-medium text-center text-[#A9B6C3] dark:text-gray-400 sm:text-base`}>
            {Object.keys(wizardSteps || {})?.map((key, index) => {
                let item = wizardSteps[key];
                let mainClass = wizardSteps[key]?.mainClass

                // console.log("last step: ",Object.keys(wizardSteps || {})?.length - 1)
                return(
                    <li className= {` ${mainClass}`}>
                        <span class="flex items-center gap-3 after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                            {/* {index < currentStep ?  
                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><
                            <circle cx="7.97559" cy="7.66406" r="7.5" fill="#5D5FEF"/>
                            </svg> 
                            :  */}
                            {isRunOnDemand && index === currentStep && currentStep === Object.keys(wizardSteps || {})?.length - 1
                             ? <div className='w-[55px] h-[55px] aspect-square'>
                                {/* <Lottie
                                    {...getAnimation('success')}
                                /> */}
                                <img className='w-full h-full object-center object-contain' src={JobRunGif} alt="job run" />
                             </div> 
                             : <div className={`relative overflow-hidden duration-200 ${isEditable && index < currentStep &&'group hover:shadow-md hover:shadow-primary rounded-full'}`}><div class={`rounded-full border-[2px] h-[35px] w-[35px] aspect-square relative ${index < currentStep ? 'bg-primary' : ''} ${index <= currentStep ? 'border-primary' : 'border-[#A9B6C3]' }`}>
                                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold ${index === currentStep ? 'text-primary' : ''}`}>
                                {index < currentStep ? (
                                    <Check color="white" size={16}/>
                                    ) : (
                                    <span>{index + 1}</span>
                                    )}
                                </div>
                                </div>
                                <div onClick={handleChange ? () => handleChange("editStep", index) : null} className='rounded-full border-[2px] h-[35px] w-[35px] aspect-square duration-200 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none cursor-pointer opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 group-hover:pointer-events-auto flex justify-center items-center bg-primary border-primary z-[999]'>
                                    {/* <div className={``}> */}
                                        <FilePenLine size={16} color="white" />
                                    {/* </div> */}
                                </div>
                                </div>}
                            {/* } */}
                            <div className='text-start'>
                                {typeof(item?.label) == "function" ? item?.label() : <LanguageTranslator tag='label' className={`mb-0 ${index === currentStep ? 'font-semibold text-[#0F172A]' : index < currentStep ? 'font-semibold text-primary' : ''}`}>{item?.label}</LanguageTranslator>}
                                {item?.desc && index < currentStep && (typeof(item?.desc) == "function" ? item?.desc() : <p onClick={item?.onClick ? item?.onClick : null} className={`mb-0 text-[11px] leading-4 font-normal text-[#67768E] ${item?.onClick ? 'underline cursor-pointer hover:text-primary' : ''}`}>{item?.desc}</p>)}
                            </div>
                            {index !== Object.keys(wizardSteps || {})?.length - 1 && <div className={`w-[50px] h-[2px] ${index < currentStep ? 'bg-primary' : 'bg-[#A9B6C3]'}`}></div>}
                        </span>
                    </li>
                )
            })}
        </ul>

    )
}

export default GenericWizardComponent