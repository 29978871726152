import React, { useEffect, useState } from 'react'
import GenericCustomButton from '../../../../../genericComponents/GenericCustomButton'
import GenericSpinnerLoader from '../../../../../loaders/GenericSpinnerLoader';
import Icons from '../../../../../assets/icons';
import { dispatchHandler } from '../../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { QRCode } from 'antd';
import LanguageTranslator from '../../../../../locals/LanguageTranslator';
const Configure2FA = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState({});

    const [barcode, setBarcode] = useState(null);

    const [errorData, setErrorData] = useState(null);

    const get2FaBarcodeData = useSelector(state => state?.authStore?.get2FaBarcodeData)

    const generateBarcode = (eventName = "generate_2fa_barcode") => {
        setBarcode(null);
        setErrorData(null);

        dispatchHandler(dispatch, "get2FaBarcodeRequest", {
            flowType: "GENERATE_MFA_KEY",
            token: sessionStorage.getItem("token") || ""
        });
        setLoading({
            "generate_2fa_barcode": true
        })
    }

    useEffect(() => {
        generateBarcode();
    }, [])

    useEffect(() => {
        if (get2FaBarcodeData?.isSuccess) {
            setLoading({});
            setBarcode(get2FaBarcodeData?.data?.resource?.token || null)
        }
        if (get2FaBarcodeData?.isError) {
            setLoading({});
            setErrorData("Failed to generate the QR Code.")
        }
    }, [get2FaBarcodeData])

    const renderCustomQRCell = ({ x, y, size }) => (
        <circle cx={x + size / 2} cy={y + size / 2} r={size / 3} fill="black" />
    );

    return (
        <div className='px-6 pt-7 pb-5 text-center'>
            <h2 className='text-[20px] font-semibold text-[#0F172A]'><LanguageTranslator>Configure 2 Factor Authentication</LanguageTranslator></h2>
            <p className='font-medium text-xs text-[#4A5D7A]'><LanguageTranslator>Open authenticator app & Choose to scan barcode</LanguageTranslator></p>
            {errorData && <p className='mb-0 bg-red-100 text-red-500 px-2 py-1.5 text-xs mt-2'>{errorData}</p>}

            <div className={`max-w-[10.3125rem] mx-auto aspect-square relative overflow-hidden my-9 ${loading?.["generate_2fa_barcode"] || !barcode ? 'rounded-2xl' : ''}`}>
                {
                    loading?.["generate_2fa_barcode"] || !barcode ?
                        (
                            loading?.["generate_2fa_barcode"] ?
                                <>
                                    <Icons.DummyQRCode className='h-full w-full' />
                                    <div className='absolute top-0 left-0 z-20 h-full w-full bg-black/70 flex items-center justify-center flex-col gap-2'><GenericSpinnerLoader /><p className='text-white font-medium'>Loading...</p></div>
                                </> :
                                <>
                                    <Icons.DummyQRCode className='h-full w-full' />
                                    <div className='absolute top-0 left-0 z-20 h-full w-full bg-black/70 flex items-center justify-center flex-col gap-2' style={{ borderRadius: 15 }}>
                                        <button className='retryBtn p-[12px] bg-[#FD3757] rounded-full hover:opacity-75' onClick={() => generateBarcode()}>
                                            {/* // Render the error case here */}
                                            <Icons.RefreshIcon />
                                        </button>
                                        <p className='text-white font-medium text-xs'><LanguageTranslator>Error Generating QR Code</LanguageTranslator></p>
                                    </div>
                                </>
                        )

                        : (

                            <>
                                {/* <div className='p-[8px] rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-[#DFEECC]'>
                                                <Icons.WechatIcon className='h-[1.5625rem] w-[1.5625rem]'/>
                                            </div> */}
                                <div className="w-full h-full flex items-center justify-center">
                                    <QRCode
                                        value={barcode}
                                        // size={256}
                                        includeMargin={true}
                                        // className="w-full h-full object-contain object-center dotted-qr-code"
                                        className="!w-full !h-full object-contain object-center rounded-none p-[0] border-none"
                                    />
                                </div>

                                {/* <img
                                                className='w-full h-full object-contain object-center'
                                                src={barcode}
                                            /> */}
                            </>

                        )

                }
            </div>

            <div className='text-center'>
                <p className='text-[#111D31] font-semibold mb-[0.5rem]'><LanguageTranslator>Steps to configure</LanguageTranslator></p>
                <ul className='text-[#67768E] space-y-1.5 text-xs'>
                    <li className='w-full justify-center flex items-center gap-2'><div className='bg-[#67768E] h-[3px] w-[3px] rounded-full'></div> <LanguageTranslator>Open authenticator app on your phone</LanguageTranslator> </li>
                    <li className='w-full justify-center flex items-center gap-2'><div className='bg-[#67768E] h-[3px] w-[3px] rounded-full'></div> <LanguageTranslator>Scan this QR Code to configure</LanguageTranslator></li>
                </ul>
                <div className='w-1/2 my-5 h-[1px] bg-[#DAE4EE] mx-auto'></div>
            </div>

            <div className='mx-auto text-center mb-4 max-w-[75%]'>
                <p className='text-xs text-[#67768E]'><LanguageTranslator>Please confirm acknowledgement that you have scanned QR and configured 2FA</LanguageTranslator></p>
            </div>

            <GenericCustomButton
                label="Acknowledge 2FA Configuration"
                type="primary"
                onClick={() => props.closeModal()}
                className={"!rounded-none !w-full !h-[35px]"}
            />
        </div>
    )
}

export default Configure2FA
