import React, { useState } from 'react'
import GenericTabsComponent from '../../../../genericComponents/GenericTabsComponent'
import NotificationHandler from './NotificationHandler'
import '../../../../styles/stylesheets/home/Header.scss'

const ChangeSetting = () => {
    const [currentTab, setCurrentTab] = useState("notification")

    const renderSettingsComponent = () => {
        switch(currentTab){
            case "notification" : {
                return <NotificationHandler

                />
            }
        }
    }

    return (
        <div className='notification-handler'>
            <GenericTabsComponent
                current = {currentTab}
                handleTabChange = {(tab) => setCurrentTab(tab)}
                tabsData = {{
                    "notification" : "Notification"
                }}
            />

            {renderSettingsComponent()}
        </div>
    )
}

export default ChangeSetting