import Icons from "../../assets/icons";

export const iconsMapping = {
    'Apparels': <Icons.Apparels />,
    'Fabric': <Icons.Fabric />,
    'Fashion Accessories': <Icons.FashionAccessories />,
    'Home Furnishing': <Icons.HomeFurnishing />,
    'Battick': <Icons.Battick />,
    'Dyeing': <Icons.Dyeing />,
    'Embroidery': <Icons.Embroidery />,
    'Kaaj Button': <Icons.KaajButton />,
    'Printing': <Icons.Printing />,
    'Testing Facilities': <Icons.TestingFacilities />,
    'Washing': <Icons.Washing />,
    'Yarn Spinning': <Icons.YarnSpinning />,
    'On Supplier Payroll': <Icons.OnSupplierPayroll />,
    '3rd Party Contracts':<Icons.ThirdPartyContracts />
}