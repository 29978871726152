import React from 'react'
const divsArray = Array.from({ length: 6 }, index => index + 1);

const SalesDashboardCardsSkeletonLoader = (props) => {
    const { type = "card" } = props;
    return (
        <div className='flex justify-between p-bot-12'>
            {divsArray.map(() => (
                <div className='sd-cards hgt-195 flex flex-col justify-between p-lr-20 p-tb-10'>
                    <div className='flex justify-center'>
                        <div className='generic-skeleton wid-42 hgt-42' style={{ borderRadius: "50%" }}></div>
                    </div>
                    <h1 className='w-100 m-top-10'>
                        <div className='generic-skeleton'></div>
                    </h1>
                    <h1 className='w-100 m-top-10'>
                        <div className='generic-skeleton'></div>
                    </h1>
                    <h1 className='w-100 m-top-10'>
                        <div className='generic-skeleton'></div>
                    </h1>
                </div>
            ))}
        </div>
    )
}

export default SalesDashboardCardsSkeletonLoader