import Icons from "../assets/icons";
import { parseJwt } from "../helper/genericFunction";
import { notificationClickHandler } from "./notification/notificationHelper";

let LOGO = {
    "VENDOR": Icons.VendorSupplymintLogo,
    "CUSTOMER": Icons.CustomerSupplymintLogo,
    "ENT": Icons.NewSupplymintLogo
}

const handleSocketEvents = (socket, config) => {
    // console.log("afasfaf", socket);
    socket?.on('show_notification', (content) => {
        console.log("Show notification called");
        showNotifications(content, config);
    })

    socket.on('error_occured', (content) => {
        console.log("Socket Error", content);
    })   
}

const showNotifications = (content, config) => {
    console.log("Notification supported:", "Notification" in window);

    // Check if browser supports notifications
    if ("Notification" in window) {
        // Check permission status
        if (Notification.permission !== "granted") {
            // Request permission
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    // Permission granted, create notification
                    console.log("Permission granted, creating notification...");
                    let {title = "", options = {}} = getNotifications(content);
                    let notification = new Notification(title, options);

                    notification.onclick = () => {
                        notificationClickHandler({content,config})
                    };
                } else {
                    console.log("User denied permission for notifications.");
                }
            });
        } else {  
            // Permission already granted, create notification
            console.log("Permission already granted, creating notification...");
            let {title = "", options = {}} = getNotifications(content);
            let notification = new Notification(title, options);
            notification.onclick = () => {
                notificationClickHandler({content,config})
            };
        }
    } else {
        console.log("This browser does not support system notifications.");
    }
}


const getNotifications = (content = {}) => {
    console.log("Notificaton content", content);
    switch(content?.notificationCode){
        case "comment_show_notificaiton" :{
            let {fullName, eventDisplayName, documentRefNo} = content
            return {
                title : "Supplymint",
                options : {
                    icon : "./notificationIcon.png",
                    body : fullName + " " + eventDisplayName + " reference " + documentRefNo + "."
                },
            }
            break;
        }
        case "mention_show_notification" : {
            let {fullName, eventDisplayName, documentRefNo} = content
            return {
                title : "Supplymint",
                options : {
                    icon : "./notificationIcon.png",
                    body : fullName + " " + eventDisplayName + " reference " + documentRefNo + "."
                },
            }
        }
        default: {
            let {userActivity = {}} = content?.content || {};
            return {
                title : "Supplymint",
                options : {
                    icon : "./notificationIcon.png",
                    body : userActivity?.fullName + " " + userActivity?.eventDisplayName + " reference " + userActivity?.documentRefNo + "."
                },
            }
        }
    }
}

export {handleSocketEvents, showNotifications}