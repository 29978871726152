
import { Manager } from "socket.io-client";
import { handleSocketEvents } from "./socketEventHandler";
import { getCookie, setCookie } from "../helper/genericFunction";
import { CONFIG } from '../config/index'
import { v4 } from 'uuid';

let socket;

const getSocketConnection = () => {
    if (socket) {
        return socket
    }
    return null;
}


const connectWithSocketConnection = (config = {}) => {
    console.log("Socket connection", config);

    let {unreadComments, setUnreadComments} = config;

    if (!getSocketConnection()?.connected) {

        let deviceId = getCookie("supplymint-device-id");

        if (!deviceId) {
            let id = v4?.()
            deviceId = id;
            setCookie({ name: "supplymint-device-id", value: deviceId, hours: 24 })
        }

        console.log("Device ID Cookie", deviceId);

        const manager = new Manager(CONFIG.NOTIFICATION_ROUTE_PROD_SOCKET, {
            reconnectionDelayMax: 10000,
            reconnectionAttempts: 4,
            withCredentials: false,
            path: "/socket",
            transports: ['websocket'],
            query: {
                'x-auth-token': sessionStorage.getItem("token"),
                "x-device-id": deviceId
            },
        });

        socket = manager.socket("/")

        if (socket) {
            socket.on('connect', () => {
                handleSocketEvents(socket, config)
                console.log("Socket connection created successfully");
            })
        }
    } else {
        return getSocketConnection();
    }

}

const disconnectWithSocketConnection = () => {
    let socketConnection = getSocketConnection();
    console.log("Socket connectiomn check", socketConnection);
    if (socketConnection) {
        socketConnection?.disconnect?.();
        console.log("Socket connectiomn check", socketConnection);
    }
}

export { connectWithSocketConnection, getSocketConnection, disconnectWithSocketConnection }