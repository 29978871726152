import React from 'react';
import IconsPath from '../../assets/icons';

export default function GenericClearFunction(props) {
    // Function for Generic Clear functionality 
    let {
        dependentFieldsObject = {}, // Object to store the dependent fields according to the key.
        itemData = {}, // The data of item to identify if we have to show the Clear button or not and to retreive the key value.
        clearPressFunction = null,
        data = {},
        RightComponent = <div></div>,
        clearBtnClassName=''
    } = props || {};

    // console.log("ASdfasdf", data);

    // Function to handle the Clear Button OnPress
    let handleChange = () => {
        let obj = dependentFieldsObject?.[itemData?.key] || null

        // Firstly delete the current item key; 
        delete data[itemData.key]

        // Check for the conditon  -> Assert only works in node js and c++;
        // assert(obj && Array.isArray(obj), "Invalid Operation.");
        // assert(clearPressFunction && typeof clearPressFunction == "function");

        if ((obj && Array.isArray(obj) && clearPressFunction && typeof clearPressFunction == "function")) {
            // Continue if the conditions is true;
            obj?.forEach(key => {
                delete data[key];
            })
        }

        // Call the handle press functions
        clearPressFunction(data);

    }
    //console.log("Debug A", itemData?.key, (data?.[dependentFieldsObject?.[itemData?.key]?.[0]] || data?.[itemData?.key])  // Condition to check the value is present or not;
    // && !(itemData?.["hideCancelButton"]))  // Condition to check the value is present or not;&& !(itemData?.["hideCancelButton"]))
    return (
        // The Clear Button Component.
        // We will show the component of CLear button according to first key in the defined dependent fields array so place the first key
        // accordingly.
        (data?.[dependentFieldsObject?.[itemData?.key]?.[0]] || data?.[itemData?.key]) && !(itemData?.["hideCancelButton"])  // Condition to check the value is present or not;
             && !props?.isDisabled ?
            <div className={`gen-input-clear ${clearBtnClassName} ${clearPressFunction ? "hide": "show"}`} onClick={() => {clearPressFunction && handleChange() }}>
                <IconsPath.ModalCloseIcon className='!w-auto'/>
            </div>
            :
            <div className='gib-right-icon'>{RightComponent}</div>  //SHow the right component in case of conditions are not satisfied.

    )

}