import React from 'react'
import GenericCustomButton from '../genericComponents/GenericCustomButton'
import Icons from '../assets/icons'

const SupportRaiseSuccessScreen = (props) => {
  return (
    <div className='space-y-3 text-center px-14 pt-[1.75rem] pb-7 text-fontBlack'>
      <div className='w-fit mx-auto'>
        <Icons.SuccessIcon/>
      </div>
    <p className='font-semibold text-xl'>Support Ticket Raised Successfully</p>
    <p className='text-[13px] mb-0 pb-4'>A ticket has been raised to the Supplymint support team, someone from the team will get back to you soon. Thank you!</p>
    <GenericCustomButton
        label="Log out"
        type="primary"
        onClick={() => {
            props.onLogout()
        }}
        className={'w-[50%] !rounded-none !h-[37px]'}
    />
</div>
  )
}

export default SupportRaiseSuccessScreen