import {
    applyMiddleware,
    compose,
    createStore as createReduxStore,
} from "redux";
import createSagaMiddleware from "redux-saga";
import makeRootReducer from "../redux/reducers";
import rootSaga from "../redux/sagas";

function createStore(initialState = {}) {
    // console.log("1", new Date())
    const sagaMiddleware = createSagaMiddleware();
    // console.log("2", new Date())
    let composeEnhancers = compose;
    // console.log("3", new Date())
    const middleware = [sagaMiddleware];
    // console.log("4", new Date())
    const store = createReduxStore(
        makeRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );
    // console.log("5", new Date())
    store.asyncReducers = {};
    if (module.hot) {
        module.hot.accept("../redux/reducers", () => {
            const reducers = require("../redux/reducers").default;
            store.replaceReducer(reducers(store.asyncReducers));
        });
    }

    // console.log("6", new Date())

    sagaMiddleware.run(rootSaga);
    // console.log("End", new Date())
    return store;
};

export default createStore;
