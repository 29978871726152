const eventMappingJSON = {
    "SHIPMENT_APPROVAL" : {
        "pagelink" : "/enterprise/shipment/approvedAsn",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status" : "SHIPMENT_CONFIRMED",
        }
    },
    "SHIPMENT_INSPECTION_CANCELLED" : {
        "pagelink" : "/enterprise/qualityCheck/cancelledInspection",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"], 
        "pageInformation" : {
            "status" : "SHIPMENT_INSPECTION_CANCELLED",
        }
    },
    "DELETE_ROLE": {
        "pagelink" : "/administration/rolesMaster/manageRoles",
        "type" : "null",
        "configKeys" : ["id"]
    },
    "UPDATE_ROLE": {
        "pagelink" : "/administration/rolesMaster/manageRoles",
        "type" : "null",
        "configKeys" : ["id"],
        "pageInformation" : {
        }
    },
    "UPDATE_USER": {
        "pagelink" : "/administration/users",
        "type" : "tableComponent",
        "configKeys" : ["userId"]
    },
    "TO_REJECTED": {
        "pagelink" : "/inventoryPlanning/transferOrder",
        "type" : "tableComponent",
        "configKeys" : ["transferOrderNo"],
        "pageInformation" : {
            "status": "REJECTED"
        }
    },
    "CANCEL_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "tableComponent",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "DELETE_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "null",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "REJECT_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "tableComponent",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "MODIFY_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "CANCEL_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "UNAPPROVE_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "DELETE_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "null",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "REJECT_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "DIGIVEND_CANCEL_ORDER": {
        "pagelink" : "/enterprise/purchaseOrder/cancelledOrders",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"]
    },
    "DIGIVEND_REJECT_ORDER": {
        "pagelink" : "/enterprise/purchaseOrder/pendingOrders",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"],
        "pageInformation" : {
            "status": "REJECTED"
        }
    },
    "PO_MODIFIED": {
        "pagelink" : "/enterprise/purchaseOrder/pendingOrders",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "INSPECTION_CANCELLED": {
        "pagelink" : "/enterprise/qualityCheck/cancelledInspection",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_INSPECTION_CANCELLED"
        }
    },
    "INSPECTION_REJECTED": {
        "pagelink" : "/enterprise/qualityCheck/cancelledInspection",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_INSPECTION_CANCELLED"
        }
    },
    "SHIPMENT_CANCELLED" : {
        "pagelink" : "/enterprise/shipment/cancelledAsn",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"], 
        "pageInformation" : {
            "status" : "SHIPMENT_CANCELLED",
        }
    },
    "AUTOTRIGGER_MODIFED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "AUTOTRIGGER_DELETED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "APPT_REJECTED": {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "null",
        "configKeys" : []
    },
    "LR_APPT_REJECTED": {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "null",
        "configKeys" : [],
        "pageInformation" : {
            "status" : "SHIPMENT_APPT_PENDING",
        }
    },
    "UPDATE_SO_DRAFT": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType" : "indent",
        }
    },
    "DELETE_SO_DRAFT": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "null",
        "configKeys" : ["orderNo"],
    },
    "REJECT_SO_INDENT": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType" : "rejected",
        }
    },
    "SALE_ORDER_CANCEL": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType" : "rejected",
        }
    },
    "REJECT_DC": {
        "pagelink" : "/salesManagement/manageDcChallan",
        "type" : "tableComponent",
        "configKeys" : ["dcCode"],
        "pageInformation" : {
            "status" : "REJECTED",
        }
    },
    "JOB_FAILED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "JOB_STOPPED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "MODIFY_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "tableComponent",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "CREATE_ROLE": {
        "pagelink" : "/administration/rolesMaster/manageRoles",
        "type" : "tableComponent",
        "configKeys" : ["name"],
        "pageInformation" : {
            "userType": "ENT"
        }
    },
    "USER_ACTIVE": {
        "pagelink" : "/administration/users",
        "type" : "tableComponent",
        "configKeys" : ["userName", "userId"],
        "pageInformation" : {
            "userType": "ENT"
        }
    },
    "USER_INACTIVE": {
        "pagelink" : "/administration/users",
        "type" : "tableComponent",
        "configKeys" : ["userName", "userId"],
        "pageInformation" : {
            "userType": "ENT"
        }
    },
    "INSPECTION_ON_HOLD": {
        "pagelink" : "/enterprise/qualityCheck/pendingQc",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"],
        "pageInformation" : {
            "status": "PENDING_QC_CONFIRM"
        }
    },
    "DONELATER_AT_WAREHOUSE": {
        "pagelink" : "/enterprise/shipment/asnUnderApproval",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"],
        "pageInformation" : {
            "status": "SHIPMENT_REQUESTED"
        }
    },
    "DONELATER": {
        "pagelink" : "/enterprise/shipment/asnUnderApproval",
        "type" : "tableComponent",
        "configKeys" : ["documentNumber"],
        "pageInformation" : {
            "status": "SHIPMENT_REQUESTED"
        }
    },
    "CREATE_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "INVOICE_UPLOAD": {
        "pagelink" : "/vendor/shipment/approvedAsn",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_CONFIRMED"
        }
    },
    "APPROVE_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "tableComponent",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "APPROVED"
        }
    },
    "APPROVE_ORDER": {
        "pagelink" : "/purchase/purchaseOrderHistory",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "status": "APPROVED"
        }
    },
    "SHIPMENT_REQUESTED": {
        "pagelink" : "/vendor/shipment/asnUnderApproval",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_REQUESTED"
        }
    },
    "SHIPMENT_APPROVAL": {
        "pagelink" : "/enterprise/shipment/approvedAsn",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_CONFIRMED"
        }
    },
    "TO_APPROVED": {
        "pagelink" : "/inventoryPlanning/transferOrder",
        "type" : "tableComponent",
        "configKeys" : ["transferOrderNo"],
        "pageInformation" : {
            "status": "APPROVED"
        }
    },
    "CONFIRM_ORDER": {
        "pagelink" : "/enterprise/purchaseOrder/pendingOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNumber"],
        "pageInformation" : {
            "status": "APPROVED"
        }
    },
    "INSPECTION_REQUESTED": {
        "pagelink" : "/enterprise/qualityCheck/pendingQc",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "PENDING_QC"
        }
    },
    "INSPECTION_REQUIRED": {
        "pagelink" : "/enterprise/qualityCheck/pendingQc",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "PENDING_QC_CONFIRM"
        }
    },
    "INSPECTION_CONFIRMED": {
        "pagelink" : "/enterprise/shipment/asnUnderApproval",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_REQUESTED"
        }
    },
    "INSPECTION_REQC": {
        "pagelink" : "/enterprise/qualityCheck/pendingQc",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "REQC"
        }
    },
    "INVOICE_UPLOADED_APPROVAL": {
        "pagelink" : "/enterprise/qualityCheck/pendingQc",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "PENDING_QC_CONFIRM"
        }
    },
    "AUTOTRIGGER_CREATED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "APPT_PENDING": {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_APPT_PENDING"
        }
    },
    "APPT_APPROVED": {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_SHIPPED"
        }
    },
    "LR_APPT_PENDING":  {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_APPT_PENDING"
        }
    },
    "LR_APPT_APPROVED": {
        "pagelink" : "/enterprise/logistics/lrProcessing",
        "type" : "tableComponent",
        "configKeys" : ["shipmentAdviceCode"],
        "pageInformation" : {
            "status": "SHIPMENT_SHIPPED"
        }
    },
    "LR_CANCELLATION": {
        "pagelink" : "/enterprise/logistics/goodsIntransit",
        "type" : "tableComponent",
        "configKeys" : ["logisticNo"],
        "pageInformation" : {
            "status": "SHIPMENT_INTRANSIT_CANCELLED"
        }
    },
    "LR_DELETION": {
        "pagelink" : "/enterprise/logistics/goodsIntransit",
        "type" : "null",
        "configKeys" : ["logisticNo"],
        "pageInformation" : {
            "status": "SHIPMENT_INTRANSIT_CANCELLED"
        }
    },
    "GATE_ENTRY_CONFIRMATION": {
        "pagelink" : "/enterprise/logistics/goodsDelivered",
        "type" : "tableComponent",
        "configKeys" : ["logisticNo"],
        "pageInformation" : {
            "status": "SHIPMENT_DELIVERED"
        }
    },
    "GATE_ENTRY_CANCELLATION": {
        "pagelink" : "/enterprise/logistics/goodsDelivered",
        "type" : "tableComponent",
        "configKeys" : ["gateEntryNo"],
        "pageInformation" : {
            "status": "SHIPMENT_CANCELLED"
        }
    },
    "GRC_CONFIRMATION": {
        "pagelink" : "/enterprise/logistics/grcStatus",
        "type" : "tableComponent",
        "configKeys" : ["smgrcnumber"]
    },
    "CREATE_INDENT": {
        "pagelink" : "/purchase/purchaseIndentHistory",
        "type" : "tableComponent",
        "configKeys" : ["indentNo"],
        "pageInformation" : {
            "status": "ALL_STATUS"
        }
    },
    "CREATE_USER": {
        "pagelink" : "/administration/users",
        "type" : "tableComponent",
        "configKeys" : ["userId"]
    },
    "USER_PASSWORD_RESET": {
        "pagelink" : "/administration/users",
        "type" : "null",
        "configKeys" : ["userId"]
    },
    "CREATE_SO_DRAFT": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType": "indent"
        }
    },
    "CREATE_SO_INDENT": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType": "indent"
        }
    },
    "SALE_ORDER_CREATED": {
        "pagelink" : "/salesManagement/viewSalesOrders",
        "type" : "tableComponent",
        "configKeys" : ["orderNo"],
        "pageInformation" : {
            "orderType": "order"
        }
    },
    "CREATE_DC": {
        "pagelink" : "/salesManagement/manageDcChallan",
        "type" : "tableComponent",
        "configKeys" : ["dcCode"],
        "pageInformation" : {
            "status": "PENDING"
        }
    },
    "APPROVE_DC": {
        "pagelink" : "/salesManagement/manageDcChallan",
        "type" : "tableComponent",
        "configKeys" : ["dcCode"],
        "pageInformation" : {
            "status": "APPROVED"
        }
    },
    "TO_CREATED": {
        "pagelink" : "/inventoryPlanning/transferOrder",
        "type" : "tableComponent",
        "configKeys" : ["transferOrderNo"],
        "pageInformation" : {
            "status": "PENDING"
        }
    },
    "LR_CREATION": {
        "pagelink" : "/enterprise/logistics/goodsIntransit",
        "type" : "tableComponent",
        "configKeys" : ["logisticNo"],
        "pageInformation" : {
            "status": "SHIPMENT_INTRANSIT"
        }
    },
    "JOB_SUCCESS_MAIL": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "JOB_SUCCESS": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "JOB_CREATED": {
        "pagelink" : "/inventoryPlanning/history",
        "type" : "tableComponent",
        "configKeys" : ["jobId"]
    },
    "DELETE_USER": {
        "pagelink" : "/administration/users",
        "type" : "null",
        "configKeys" : ["userId"]
    },
    "USER_DATA_MAPPING_UPDATED": {
        "pagelink" : "/administration/userDataMapping",
        "type" : "tableComponent",
        "configKeys" : ["userName"],
        "pageInformation" : {
            "key": "ITEM_MAPPING"
        }
    },
    "TO_CANCELLED": {
        "pagelink" : "/inventoryPlanning/transferOrder",
        "type" : "tableComponent",
        "configKeys" : ["transferOrderNo"],
        "pageInformation" : {
            "status": "CANCELLED"
        }
    },
    "VIEW_PRODUCT": {
        "type" : "viewProductDetails",
        "configKeys" : ["productId"]
    },
    "comment_show_notificaiton" : {
        "type" : "centralCommentModal",
    },
    "mention_show_notification" : {
        "type" : "centralCommentModal",
    }
}


export {eventMappingJSON};