import React from 'react'
import IconsPath from '../assets/icons'

const LoginSupplymintAnimation = ({msg = "Logging in to Supplymint.ai Portal..."}) => {
    return (
        <div className='sm-alerts'>
            <div>
                <div className='sa-img'>
                    <img src={IconsPath.NewSupplymintLogo} alt='Supplymint Auth Logo Image.' />
                </div>
                <h3>{msg}</h3>
                <div className="full-page-loader">
                    <div className="ball-scale-multiple">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginSupplymintAnimation