import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import Cron, { HEADER } from '../../../localNodeModules/customCronGenerator';
import GenericSearchModalNew from '../tableHelperComponents/GenericSearchModalNew';
import "../../../styles/stylesheets/Breadcrumb/ExportExcel.scss"
import IconsPath from "../../../assets/icons/index";
import dayjs from 'dayjs';
import LanguageTranslator from '../../../locals/LanguageTranslator';
const { RangePicker } = DatePicker;

let SITE_FILTER = {
    key: "email",
    entity: "supplymint.users",
    code: "username",
    value: ""
}
class EmailScheduler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Loader, success modal and error modal
            loading: false,
            error: "",
            errorCode: "",
            errorMessage: "",
            successMessage: "",

            // Other 
            manageSchedule: false,
            scheduler: false,

            toEmail: "",
            toEmailArray: [],
            emailToErr: false,
            emailStr: "",
            ccEmail: [],
            fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            dateRange: "SM_DATE_LAST7DAYS_VALUE",
            lineItemHeaders: {},
            hasAttachment: true,
            isAllData: "false",
            filterCondition: true,

            schedulerName: "",
            cronExpression: "0 00 09 * ? * *",

            errors: {
                toEmail: "",
                schedulerName: "",
                fromDate: false,
                toDate: false
            },

            getDataSuccess: false,
            getDataError: false,
            emailSchedules: [],
            hoursValid:true,
            toEmailsObj: {},
            ccEmailsObj: {},
            headersMap: this.props.mainHeadersData[this.props.mainHeadersType],
            level: "headerLevel",
            headersType: this.props.mainHeadersType,
            selectedItems: this.props.mainHeadersData[this.props.mainHeadersType],
        }
    }

    componentDidMount() {
        if(this.props.headerLevelModule === "VIEW_INDENTS") {
            this.props.handleApiChange("getHeadersRequest", {
                enterpriseName: "TURNINGCLOUD",
                displayName: "PI_EXCEL_HEADER",
                basedOn: "main"
            })
        }
        else if(this.props.headerLevelModule === "VIEW_ORDERS") {
            this.props.handleApiChange("getHeadersRequest", {
                enterpriseName: "TURNINGCLOUD",
                displayName: "PO_EXCEL_HEADER",
                basedOn: "main"
            })
        }
        document.addEventListener("keydown", this.closeOnEsc);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closeOnEsc);
    }

    closeOnEsc = e => {
        if(e.key === "Escape") {
            this.props.close();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.generalStore.getHeadersData.isSuccess) {
            // nextProps.getHeadersClear();
            return {
                loading: false,
                lineItemHeaders: nextProps.generalStore.getHeadersData.data?.resource?.["Default Headers"]?.lineItemHeaders
            };
        }
        else if (nextProps.generalStore.getHeadersData.isError) {
            // nextProps.getHeadersClear();
            return {
                loading: false,
                error: nextProps.generalStore.getHeadersData.message.status,
                errorCode: nextProps.generalStore.getHeadersData.message.error == undefined ? undefined : nextProps.generalStore.getHeadersData?.message?.error?.errorCode,
                errorMessage: nextProps.generalStore.getHeadersData.message.error == undefined ? undefined : nextProps.generalStore.getHeadersData?.message?.error?.errorMessage
            };
        }

        if(nextProps.generalStore.getEmailSchedulesData?.isLoading) {
            return {
                loading: true,
                getDataSuccess: false,
                getDataError: false
            };
        }
        if (nextProps.generalStore.emailReportData?.isSuccess) {
            // nextProps.emailReportClear();
            return {
                loading: false,
            };
        }
        else if (nextProps.generalStore.emailReportData.isError) {
            // nextProps.emailReportClear();
            return {
                loading: false,
                error: nextProps.generalStore.emailReportData?.message?.status,
                errorCode: nextProps.generalStore.emailReportData?.message?.error == undefined ? undefined : nextProps.generalStore.emailReportData.message?.error?.errorCode,
                errorMessage: nextProps.generalStore.emailReportData?.message?.error == undefined ? undefined : nextProps.generalStore.emailReportData?.message?.error?.errorMessage
            };
        }

        if(nextProps.generalStore.getEmailSchedulesData?.isSuccess) {
            // nextProps.getEmailSchedulesClear();
            return {
                loading: false,
                emailSchedules: (nextProps.generalStore.getEmailSchedulesData?.data?.resource && nextProps.generalStore.getEmailSchedulesData?.data?.resource?.data) || [],
                getDataSuccess: true
            };
        }
        else if(nextProps.generalStore.getEmailSchedulesData.isError) {
            // nextProps.getEmailSchedulesClear();
            return {
                loading: false,
                error: nextProps.generalStore.getEmailSchedulesData?.message?.status,
                errorCode: nextProps.generalStore.getEmailSchedulesData?.message?.error == undefined ? undefined : nextProps.generalStore.getEmailSchedulesData?.message?.error?.errorCode,
                errorMessage: nextProps.generalStore.getEmailSchedulesData?.message?.error == undefined ? undefined : nextProps.generalStore.getEmailSchedules?.message?.error?.errorMessage,
                getDataError: true
            };
        }

        if(nextProps.generalStore.createEmailSchedulesData.isSuccess) {
            // nextProps.createEmailSchedulesClear();
            return {
                loading: false,
            };
        }
        else if(nextProps.generalStore.createEmailSchedulesData.isError) {
            // nextProps.createEmailSchedulesClear();
            return {
                loading: false,
                error: nextProps.generalStore.createEmailSchedulesData.message && nextProps.generalStore.createEmailSchedulesData.message.data && nextProps.generalStore.createEmailSchedulesData.message.data.status ? nextProps.generalStore.createEmailSchedulesData.message.data.status : "",
                errorCode: nextProps.generalStore.createEmailSchedulesData.message && nextProps.generalStore.createEmailSchedulesData.message.data && nextProps.generalStore.createEmailSchedulesData.message.data.errorCode ? nextProps.generalStore.createEmailSchedulesData.message.data.errorCode : "",
                errorMessage: nextProps.generalStore.createEmailSchedulesData.message && nextProps.generalStore.createEmailSchedulesData.message.data && nextProps.generalStore.createEmailSchedulesData.message.data.error ? nextProps.generalStore.createEmailSchedulesData.message.data.error : "",
            };
        }

        if(nextProps.generalStore.deleteEmailSchedulesData.isSuccess) {
            // nextProps.deleteEmailSchedulesClear();
            return {
                loading: false,
                successMessage: nextProps.generalStore.deleteEmailSchedulesData.data.message
            };
        }
        else if(nextProps.generalStore.deleteEmailSchedulesData.isError) {
            // nextProps.deleteEmailSchedulesClear();
            return {
                loading: false,
                error: nextProps.generalStore.deleteEmailSchedulesData?.message?.status,
                errorCode: nextProps.generalStore.deleteEmailSchedulesData?.message?.error == undefined ? undefined : nextProps.generalStore.deleteEmailSchedulesData?.message?.error.errorCode,
                errorMessage: nextProps.generalStore.deleteEmailSchedulesData?.message?.error == undefined ? undefined : nextProps.generalStore.deleteEmailSchedulesData?.message?.error.errorMessage
            };
        }

        if(nextProps.generalStore.emailReportData.isLoading || nextProps.generalStore.getEmailSchedulesData.isLoading || nextProps.generalStore.createEmailSchedulesData.isLoading || nextProps.generalStore.deleteEmailSchedulesData.isLoading || nextProps.generalStore.getHeadersData.isLoading) {
            return {
                loading: true
            };
        }

        return null;
    }

    componentDidUpdate() {
        // if(this.props.generalStore.emailReport.isSuccess) {
        //     this.props.close();
        // }

        if(this.props.generalStore.emailReportData.isSuccess || this.props.generalStore.createEmailSchedulesData.isSuccess) {
            this.props.close();
            this.clearData();
        }

        if(this.props.generalStore.deleteEmailSchedulesData.isSuccess) {
            this.props.handleApiChange("getEmailSchedulesRequest",{
                pageNo: 1,
                type: 1,
                search: "",
                filter: {},
                sortedBy: "",
                sortedIn: ""
            });
        }
    }

    closeModal = () => {
        this.setState({
            error: "",
            errorCode: "",
            errorMessage: "",
            successMessage: ""
        });
    }

    multipleToggle = type => e => {
        if(type === "manageSchedule" && !this.state.manageSchedule) {
            this.props.handleApiChange("getEmailSchedulesRequest",{
                pageNo: 1,
                type: 1,
                search: "",
                filter: {},
                sortedBy: "",
                sortedIn: ""
            });
        }
        this.setState(prevState => ({
            [type]: !prevState[type]
        }));
    }

    handleInput = (e, level) => {
        e.persist();
        this.setState(prevState => {
            if(e.target.type === "checkbox") {
                return {
                    [e.target.name]: e.target.checked,
                };
            }
            else if(e.target.name === "emailStr") {
                return {
                    [e.target.name]: e.target.value.trim()
                };
            } else if(e.target.type === "radio") {
                return {
                    level: level
                }
            }
            return {
                [e.target.name]: e.target.value
            };
        }, () => {
            this.state.errors[e.target.name] !== undefined && this.validateInput(e.target.name);
            this.setHeaders();
        });
    }

    handleDateInput = (date, dateStr) => {
        this.setState({
            fromDate: dateStr[0],
            toDate: dateStr[1]
        }, () => {
            this.validateInput("fromDate");
            this.validateInput("toDate");
        });
    }

    handleBlur = (listName , emailName) => {
        if(this.state[emailName]) {
            this.addEmail({
                key: "Enter"
            },listName,emailName);
        }
    }

    validateInput = field => {
        let err = false;
        switch(field) {
            case "toEmail": {
                if(!(this.state.toEmail == "" && this.state.toEmailArray.length > 0)){
                    if((this.state.toEmail == "" && this.state.toEmailArray.length == 0 ) || !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(this.state.toEmail)) {
                        err = true;
                    }
                }
                break;
            }
            default: {
                if(this.state[field] === "") {
                    err = true;
                }
            }
        }
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [field]: err
            }
        }));
        return err;
    }

    addEmail = (e,listName,emailName) => {
        if((e.key === "Enter" || e.key === " " || e.key === ",") && /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(this.state[emailName])) {
            this.setState(prevState => {
                let ccEmail = [...prevState[listName]];
                if(!~ccEmail.indexOf(prevState[emailName])) {
                    ccEmail.push(prevState[emailName]);
                }
                return {
                    [listName] : ccEmail,
                    [emailName]: ""
                };
            });
        }
    }

    removeEmail =(item,listName) => e => {
        this.setState(prevState => {
            let ccEmail = [...prevState[listName]];
            ccEmail.splice(ccEmail.indexOf(item), 1);
            return {
                [listName] : ccEmail
            };
        });
    }

    setCronExp = cronExpression => {
        console.log({cronExpression});
        this.setState({
            cronExpression
        });
    }

    onSubmit = (listName,emailName) => {
        if(this.state[emailName]) {
            this.addEmail({key: "Enter"},listName,emailName);
        }

        //setTimeout is used to make sure that emailStr is converted to a tag before API call
        setTimeout(() => {
            if(this.state.scheduler) {
                let valid = true;
                Object.keys(this.state.errors).forEach(key => {
                    if(this.validateInput(key)) {
                        valid = false;
                    }
                });
                // if(this.state.cronExpression.split(' ')[3].match("1/1") && this.state.cronExpression[7]== ' ' )
                //     if(Number(this.state.cronExpression[6])<6){this.setState({hoursValid:false})}
                //     else{this.setState({hoursValid:true})}
                // if(valid && this.state.hoursValid) {
                    let payload = {
                        status: 1,
                        schedulerName: this.state.schedulerName,
                        cronExpression: this.state.cronExpression,
                        toEmail: this.state.toEmailArray.join(),
                        ccEmail: this.state.ccEmail.join(),
                        hasAttachment: this.state.hasAttachment,
                        dataExportJson: {}
                    };
                    if(["VIEW_INDENTS", "VIEW_ORDERS"].includes(this.props.headerLevelModule)) {
                        payload.dataExportJson = {
                            mName: this.props.headerLevelModule,
                            isAllItemLevelData: "false",
                            isOnlyCurrentPage: "false",
                            exportDataNode: {
                                fromDate: moment().format("YYYY-MM-DD"),
                                toDate: moment().format("YYYY-MM-DD"),
                                dateRange: this.state.dateRange,
                                isMainHeader: this.state.isAllData === "false",
                                search: this.state.filterCondition ? this.props.exportDataNode.search : "",
                                filter: this.state.filterCondition ? this.props.exportDataNode.filter : "",
                                // headers: this.state.isAllData === "false" ? this.props.headers : this.state.lineItemHeaders,
                                headers: this.state.selectedItems
                            }
                        }
                    }
                    else {
                        payload.dataExportJson = {
                            mName: this.state.isAllData === "true" ? this.props.detailLevelModule : this.props.headerLevelModule,
                            isAllItemLevelData: this.state.isAllData,
                            isOnlyCurrentPage: "false",
                            exportDataNode: {
                                type: this.state.filterCondition ? this.props.exportDataNode.type : 1,
                                ...this.props.exportDataNode,
                                headers: this.state.selectedItems
                            }
                        };
                    }
                    this.props.handleApiChange("createEmailSchedulesRequest",payload);
                // }
            }
            else {
                let valid = true;
                Object.keys(this.state.errors).forEach(key => {
                    if(key !== "schedulerName" && this.validateInput(key)) {
                        valid = false;
                    }
                });
                if(valid) {
                    let payload;
                    if(["VIEW_INDENTS", "VIEW_ORDERS"].includes(this.props.headerLevelModule)) {
                        payload = {
                            module: this.props.headerLevelModule,
                            isAllData: "false",
                            isOnlyCurrentPage: "false",
                            data: {
                                toEmail: this.state.toEmail,
                                ccEmail: this.state.ccEmail.join(),
                                fromDate: this.state.fromDate,
                                toDate: this.state.toDate,
                                isMainHeader: this.state.isAllData === "false",
                                search: this.state.filterCondition ? this.props.exportDataNode.search : "",
                                filter: this.state.filterCondition ? this.props.exportDataNode.filter : "",
                                headers: this.state.selectedItems
                                // headers: this.state.isAllData === "false" ? this.props.headers : this.state.lineItemHeaders
                            }
                        };
                    }
                    else {
                        payload = {
                            module: this.state.level === "headerLevel" ? this.props.headerLevelModule : this.props.detailLevelModule,
                            // module: this.state.isAllData === "true" ? this.props.detailLevelModule : this.props.headerLevelModule,
                            // isAllData: this.state.isAllData,
                            isAllData: this.state.level === "headerLevel" ? "false" : "true",
                            isOnlyCurrentPage: "false",
                            data: {
                                toEmail: this.state.toEmailArray.join(),
                                ccEmail: this.state.ccEmail.join(),
                                type: this.state.filterCondition ? this.props.exportDataNode.type : 1,
                                ...this.props.exportDataNode,
                                headers: this.state.selectedItems
                            }
                        };
                    }
                    this.props.handleApiChange("emailReportRequest",payload);
                }
            }
        }, 0);
    }

    clearData = () => {
        console.log("Clear Data Called");
        this.setState({
            toEmail: "",
            emailToErr: false,
            emailStr: "",
            ccEmail: [],
            hasAttachment: true,
            isAllData: "false",
            filterCondition: true,

            schedulerName: "",
            cronExpression: "0 0 09 1/1 * ? *",

            errors: {
                toEmail: "",
                schedulerName: ""
            }
        });
    }

    isFilterApplied = data => {
        let parsedData;
        try {
            parsedData = JSON.parse(data);
            if(parsedData.exportDataNode.type == 1) {
                return false;
            }
            return true;
        }
        catch(e) {
            console.log(e);
            return false;
        }
    }

    // editSchedule = item => e => {
    //     this.setState({
    //         manageSchedule: false,
    //         scheduler: true,
    //         id: item.id,
    //         schedulerName: item.schedulerName,
    //         cronExpression: this.state.cronExpression,
    //         toEmail: item.toEmail,
    //         ccEmail: item.ccEmail ? item.ccEmail.split(",") : [],
    //         hasAttachment: item.hasAttachment,
    //     });
    // }

    // disableSchedule = item => e => {
    //     let dataExportJson = JSON.parse(item.requestNodeProvider),
    //     cronExpression = item.cronExpression.substring(5, item.cronExpression.length - 1);
    //     this.props.createEmailSchedulesRequest({
    //         id: item.id,
    //         status: item.status == 1 ? 0 : 1,
    //         schedulerName: item.schedulerName,
    //         toEmail: item.toEmail,
    //         ccEmail: item.ccEmail,
    //         hasAttachment: item.hasAttachment,
    //         cronExpression,
    //         dataExportJson
    //     });
    // }

    deleteSchedule = item => e => {
        this.props.handleApiChange("deleteEmailSchedulesRequest",{
            id: item.id,
            schedulerName: item.schedulerName
        });
    }

    selectToEmails = (field, items) => {
        let selectedItems = { ...this.state.toEmailsObj };
        if (items.length !== 0) {
            selectedItems[field.key] = items;
        }
        else if (items.length === 0 && selectedItems[field.key] !== undefined) {
            delete selectedItems[field.key];
        }
        this.setState({
            toEmailsObj: selectedItems,
            toEmailArray: [...items]
        });
    }

    selectCcEmails = (field, items) => {
        let selectedItems = { ...this.state.ccEmailsObj };
        if (items.length !== 0) {
            selectedItems[field.key] = items;
        }
        else if (items.length === 0 && selectedItems[field.key] !== undefined) {
            delete selectedItems[field.key];
        }
        this.setState({
            ccEmailsObj: selectedItems,
            ccEmail: [...items]
        });
    }

    setHeaders = () => {
        let clonDeepedHeadersMap = JSON.parse(JSON.stringify((this.props.mainHeadersData[this.state.headersType])))
        let headersMap = {...clonDeepedHeadersMap};
        if(this.state.level === "detailLevel") {
            this.props.detailHeadersData.forEach(item => {
                headersMap = {
                    ...headersMap,
                    ...item[this.state.headersType]
                };
            });
        }
        this.setState({
            headersMap,
            selectedItems: headersMap
        }, () => {
            // console.log(this.state.level, this.state.selectedItems);
        });
    }
	closeEmailModal = () => {
		document.getElementById("reportModal")?.classList?.remove("from-right-in")
		document.getElementById("reportModal")?.classList?.add("from-right-out")
		setTimeout(()=>{
			this.props.close();
		}, 250)
	}

    render() {
        // console.log("cronExpression", this.state.cronExpression, this.props.mainHeadersData, this.props.mainHeadersType, this.state.level, this.props.detailHeadersData);
        console.log(this.props.mainHeadersData,9898)
        return(
            <React.Fragment>
                <div className="modal">
                    <div className="backdrop" onClick={this.closeEmailModal}></div>
                    <div id='reportModal' className={`modal-content email-report-modal from-right-in`}>
                        <div className="aerm-head">
                            <div className="aermh-left">
                                {this.state.manageSchedule ? <LanguageTranslator tag="h3">MANAGE EMAIL SCHEDULES</LanguageTranslator> : this.state.scheduler ? <LanguageTranslator tag="h3">SCHEDULE EMAIL</LanguageTranslator> : <LanguageTranslator tag="h3">EMAIL REPORT</LanguageTranslator>}
                            </div>
                            <div className="aermh-right">
                                <button type="button" className="aermh-manage" onClick={this.multipleToggle("manageSchedule")}>{this.state.manageSchedule ? <LanguageTranslator>Back</LanguageTranslator> : <LanguageTranslator>Manage Schedules</LanguageTranslator>}</button>
                                <span className="ermh-esc">Esc</span>
                                <IconsPath.ClearSearch onClick={this.closeEmailModal} />
                            </div>
                        </div>
                        {!this.state.manageSchedule &&
                        <div className="erm-scheduler">
                            <div className="generic-toggle">
                                <label className="gt-switch">
                                    <input type="checkbox" checked={this.state.scheduler} onChange={this.multipleToggle("scheduler")} />
                                    <span className="gt-slider"></span>
                                </label>
                            </div>
                            <LanguageTranslator tag="span" className="nph-text">Scheduler</LanguageTranslator>
                        </div>}
                        {this.state.manageSchedule ?
                        this.state.getDataSuccess ?
                        <div className="erm-body aerm-body">
                            {this.state.emailSchedules.map((item, index) =>
                                <div key={item.id} className={`scheduled-item ${index === 0 ? "m-top-20" : ""}`}>
                                    <div className="si-left">
                                        <div className="sil-top">
                                            <h3>{item.schedulerName}</h3>
                                            <span className="silt-mail">{item.toEmail} <span className="siltm-count">{item.ccEmail ? `+${item.ccEmail.split(",").length} more` : ""}</span></span>
                                            {item.status == 1 ? <p className="silt-enabled">Enabled</p> : <p className="silt-disabled">Disabled</p>}
                                        </div>
                                        <div className="sil-bottom">
                                            {/* 
                                                // TODO
                                                <span className="silb-text">Daily</span>
                                            */}
                                            {this.isFilterApplied(item.requestNodeProvider) && <LanguageTranslator tag="span" className="silb-text">Filter applied</LanguageTranslator>}
                                            {item.hasAttachment === "true" && <LanguageTranslator tag="span" className="">Send as attachment</LanguageTranslator>}
                                            <span className="silb-img">
                                                <svg xmlns="http://www.w3.org/2000/svg" id="attach_1_" width="18.526" height="18.526" viewBox="0 0 21.526 21.526">
                                                    <path id="Path_851" fill="#e0edff" d="M17.49 21.526H4.036A4.036 4.036 0 0 1 0 17.49V4.036A4.036 4.036 0 0 1 4.036 0H17.49a4.036 4.036 0 0 1 4.036 4.036V17.49a4.036 4.036 0 0 1-4.036 4.036z"/>
                                                    <path fill="#2681ff" id="Path_852" d="M18.314 12.675v.843a2.466 2.466 0 1 1-4.933 0V12.4h-.713a.115.115 0 0 1-.076-.032l-.637-.636a.777.777 0 0 0-.555-.232H9.233A1.232 1.232 0 0 0 8 12.733v5.606a1.233 1.233 0 0 0 1.233 1.234h8.3a1.233 1.233 0 0 0 1.234-1.233v-4.71a1.246 1.246 0 0 0-.453-.955z" class="cls-2" transform="translate(-2.618 -3.764)"/>
                                                    <path fill="#2681ff" id="Path_853" d="M18.9 14.433a1.572 1.572 0 0 1-1.569-1.569v-2.355a1.009 1.009 0 1 1 2.018 0v2.242a.336.336 0 1 1-.673 0v-2.242a.336.336 0 0 0-.673 0v2.354a.9.9 0 0 0 1.793 0V9.836a.336.336 0 1 1 .673 0v3.027a1.572 1.572 0 0 1-1.569 1.57z" class="cls-2" transform="translate(-5.673 -3.109)"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="si-right">
                                        {/* <button type="button" className="mb-3" onClick={this.disableSchedule(item)}>Disable</button> */}
                                        {/* <button type="button" className="sir-edit" onClick={this.editSchedule(item)}>Edit</button> */}
                                        <button type="button" className={this.props.generalStore.deleteEmailSchedulesData.isLoading && "opacity04"} onClick={this.props.generalStore.deleteEmailSchedulesData.isLoading ? null : this.deleteSchedule(item)}><LanguageTranslator>Delete</LanguageTranslator></button>
                                    </div>
                                </div>
                            )}
                        </div> :
                        this.state.getDataError ?
                        <div className="erm-body aerm-body">
                            <LanguageTranslator tag="label">Failed to fetch data!</LanguageTranslator>
                        </div> :
                        <div className="erm-body aerm-body">
                            <LanguageTranslator tag="label">Fetching data...</LanguageTranslator>
                        </div> :
                        <div className="erm-body aerm-body">
                            {this.state.scheduler &&
                            <div className="aermb-row">
                                <label><LanguageTranslator>Schedule Name</LanguageTranslator><span className="mandatory">*</span></label>
                                <input name="schedulerName" autoComplete="off" type="text" className={`aermb-input ${this.state.errors.schedulerName ? "errorBorder" : "onFocus"}`} value={this.state.schedulerName} onChange={this.handleInput} />
                                {this.state.errors.schedulerName && <LanguageTranslator tag="span" className="error-msg">Enter schedule name</LanguageTranslator>}
                            </div>}
                            <div className="aermb-row">
                                <label><LanguageTranslator>To</LanguageTranslator><span className="mandatory">*</span></label>
                                <GenericSearchModalNew
                                    isMandatory={false}
                                    multipleSelection={true}
                                    key={SITE_FILTER.key}
                                    field={SITE_FILTER}
                                    selectedItems={this.state.toEmailsObj}
                                    selectItems={this.selectToEmails}
                                    {...this.props}
                                />
                                {this.state.errors.toEmail && <span className="error-msg">Enter valid email address</span>}
                            </div>
                            <div className="aermb-row">
                                <LanguageTranslator tag="label">CC</LanguageTranslator>
                                <GenericSearchModalNew
                                    isMandatory={false}
                                    multipleSelection={true}
                                    key={SITE_FILTER.key}
                                    field={SITE_FILTER}
                                    selectedItems={this.state.ccEmailsObj}
                                    selectItems={this.selectCcEmails}
                                    {...this.props}
                                />
                            </div>
                            {["VIEW_INDENTS", "VIEW_ORDERS"].includes(this.props.headerLevelModule) &&
                            <div className="aermb-row">
                                <label>Date Range</label>
                                {this.state.scheduler ?
                                <span className="date-range">
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_TODAY_VALUE"} checked={this.state.dateRange === "SM_DATE_TODAY_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Today</span>
                                    </label>
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_YESTERDAY_VALUE"} checked={this.state.dateRange === "SM_DATE_YESTERDAY_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Yesterday</span>
                                    </label>
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_LAST7DAYS_VALUE"} checked={this.state.dateRange === "SM_DATE_LAST7DAYS_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Last 7 Days</span>
                                    </label>
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_LASTWEEK_VALUE"} checked={this.state.dateRange === "SM_DATE_LASTWEEK_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Last Week</span>
                                    </label>
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_THISMONTH_VALUE"} checked={this.state.dateRange === "SM_DATE_THISMONTH_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Current Month</span>
                                    </label>
                                    <label className="radio-label">
                                        <input type="radio" name="dateRange" value={"SM_DATE_LASTMONTH_VALUE"} checked={this.state.dateRange === "SM_DATE_LASTMONTH_VALUE"} onChange={this.handleInput} />
                                        <span className="radio-check"></span>
                                        <span className="radio-text">Last Month</span>
                                    </label>
                                </span> :
                                <RangePicker
                                    className={this.state.errors.fromDate || this.state.errors.toDate ? "errorBorder" : ""}
                                    picker="date"
                                    onChange={this.handleDateInput}
                                    value={this.state.fromDate && this.state.toDate ? [dayjs(this.state.fromDate), dayjs(this.state.toDate)] : [null, null]}
                                    format={"YYYY-MM-DD"}
                                />}
                                {(this.state.errors.fromDate || this.state.errors.toDate) && <span className="error-msg">Select valid date range</span>}
                            </div>}
                            {this.state.scheduler &&
                            <React.Fragment>
                                {/* <div className="aermb-row mb-0">
                                    <label style={{color: this.state.hoursValid ?'#94a5c6':"#FF0000"}}><em>Emails can be scheduled with a minimum gap of <b>6 hours</b></em></label>
                                </div> */}
                                <div className="ist-update">
                                    <LanguageTranslator tag="label" className='utc-time'>Start time should be in UTC timezone which is minus (-) 5:30 HRS from IST (Indian Standard Timezone).</LanguageTranslator>
                                </div>
                                <div className="aermb-row">
                                    <Cron
                                        onChange={this.setCronExp}
                                        // value={this.state.cronExpression}
                                        showResultText={true}
                                        showResultCron={true}
                                        options={{
                                            headers: [HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY]
                                        }}
                                    />
                                </div>
                            </React.Fragment>}
                            <div className="ermb-dashdata">
                                <LanguageTranslator tag="h3">Attachments</LanguageTranslator>
                                <div className="ermbdd-inner">
                                    <div className="ermbddi-checkbox">
                                        <label className="checkBoxLabel0">
                                            <input type="checkBox" name="hasAttachment" checked={this.state.hasAttachment} onChange={this.handleInput} /><LanguageTranslator>Send as attachment</LanguageTranslator>
                                            <span className="checkmark1"></span>
                                        </label>
                                    </div>
                                    <div className="gen-pi-formate">
                                        <ul className="gpf-radio-list">
                                            <li>
                                                <label className="gen-radio-btn">
                                                    <input type="radio" name="isAllData" value="false" checked={this.state.level === "headerLevel"} onChange={(e) => {this.handleInput(e, "headerLevel")}} />
                                                    <span className="checkmark"></span>
                                                    <LanguageTranslator>Header level</LanguageTranslator>
                                                </label>
                                            </li>
                                            {this.props.detailHeadersData && <li>
                                                <label className="gen-radio-btn">
                                                    <input type="radio" name="isAllData" value="true" checked={this.state.level === "detailLevel"} onChange={(e) => {this.handleInput(e, "detailLevel")}} />
                                                    <span className="checkmark"></span>
                                                    <LanguageTranslator>Detail Level</LanguageTranslator>
                                                </label>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="ermb-dashdata">
                                <div className="ermbdd-inner">
                                    <div className="ermbddi-checkbox">
                                        <label className="checkBoxLabel0">
                                            <input name="filterCondition" type="checkBox" checked={this.state.filterCondition} onChange={this.handleInput} />
                                            <LanguageTranslator tag="h3" style={{lineHeight: "initial"}}>Send with applied filters and search</LanguageTranslator>
                                            <span className="checkmark1"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {!this.state.manageSchedule &&
                        <div className="erm-footer">
                            {this.props.generalStore.emailReportData.isLoading || this.props.generalStore.createEmailSchedulesData.isLoading ?
                            <button type="button" className="ermf-send btn-loader" disabled><div class="spinner-border text-light btn-loader-inner"></div></button> :
                            <button type="button" className="ermf-send" onClick={this.onSubmit}>{this.state.scheduler ? <LanguageTranslator>Schedule Email</LanguageTranslator> : <LanguageTranslator>Send Now</LanguageTranslator>}</button>}
                            <button type="button" className="ermf-clear" onClick={this.clearData}><LanguageTranslator>Clear</LanguageTranslator></button>
                        </div>}
                    </div>
                </div>
                {/* {this.state.loading ? <FilterLoader /> : null} */}
                {/* {this.state.successMessage ? <RequestSuccess {...this.props} {...this.state} successMessage={this.state.successMessage} closeRequest={this.closeModal} /> : null} */}
                {/* {this.state.error ? <RequestError {...this.props} {...this.state} code={this.state.error} errorCode={this.state.errorCode} errorMessage={this.state.errorMessage} closeErrorRequest={this.closeModal} /> : null} */}
            </React.Fragment>
        )
    }
}

export default EmailScheduler