import React from 'react'

const UserProfileSkeletonLoader = () => {
  return (
    <div className='h-full w-full px-9 py-5 space-y-6'>
      <div className='flex items-center gap-8'>
        <div className='generic-skeleton !w-[90px] !h-[90px] !rounded-full !mb-0'></div>
        <div>
          <div className='generic-skeleton !w-[130px]'></div>
          <div className='generic-skeleton !w-[170px] !h-[13px]'></div>
          <div className='generic-skeleton !w-[90px] !h-[30px] !rounded-full'></div>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-x-7 gap-y-4'>
        {
          Array.from({ length: 10 }, (_, index) => index + 1).map((obj, index) =>
            <div>
              <div className='generic-skeleton !w-[70px]'></div>
              <div className='generic-skeleton !w-full !h-[35px]'></div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default UserProfileSkeletonLoader