import React, { useState, useEffect, useContext } from 'react';
import Icons from '../assets/icons';
import { connectWithSocketConnection, getSocketConnection } from './socketHelperConnection';
import GenericCustomButton from '../genericComponents/GenericCustomButton';
import { CommentContext } from '../components/AppStack';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SocketConnectionError = (props) => {
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const socket = getSocketConnection();

    const {unreadComments, setUnreadComments} = useContext(CommentContext)


    useEffect(() => {
        const handleConnection = () => setLoading(false);
        const handleError = () => {
            setLoading(false);
            console.log("Socket connection error314");
        };
        const handleDisconnect = () => setLoading(false);

        socket?.on('connect', handleConnection);
        socket?.on('error', handleError);
        socket?.on('disconnect', handleDisconnect);
        socket?.on('connect_error',handleDisconnect)

        return () => {
            socket?.off('connect', handleConnection);
            socket?.off('error', handleError);
            socket?.off('disconnect', handleDisconnect);
        };
    }, []);

    useEffect(() => {
        if(socket.connected){
            setConnected(true)
        }else{
            setConnected(false)
        }
    },[getSocketConnection()])

    if(connected){
        return props.children
    }else{
        return (
            <div>
                {loading ? "Reconnecting to the server" : <p>Failed to connect with Server.</p>}
                <GenericCustomButton
                    loading={loading}
                    label="Retry"
                    disabled={loading}
                    leftIcon={<Icons.ReloadIcon />}
                    onClick={() => {
                        setLoading(true);
                        connectWithSocketConnection({
                            history : history,
                            location : location,
                            unreadComments : unreadComments,
                            setUnreadComments : setUnreadComments
                        });
                    }}
                />
            </div>
        );
    }
};

export default SocketConnectionError;
