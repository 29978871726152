import React from 'react'
import GenericTableComponent from '../../../genericComponents/tableComponent/GenericTableComponent'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'

const RequestOnboardingComponent = (props) => {
	const dispatch = useDispatch();
	const generalStore = useSelector(state => state?.generalStore)
	const approveVendorOnboardingData = useSelector(state => state?.masterDbStore?.approveVendorOnboardingData)

	const tableHeadersMap = {
		main: {
			name: "Main",
			key: "VENDOR_REQUESTED"
		}
	}

	const handleChange = (type, data) => {
		switch (type) {
			case "cancelVendorOnboarding": {
				dispatchHandler(dispatch, "approveVendorOnboardingRequest", {
					"ids": data?.map(item => item?.id),
					"status": "CANCELLED",
				})
				break;
			}
		}
	}

	const renderRHSExtras = (selectedItems) => {
		return (
			<div>
				<GenericCustomButton
					sendEventAnalyticsToGoogle = {true}
					eventAnlyticsLabel = {"Cancel Vendor Onboarding Request"}
					disabled={generalStore?.getTableComponentListData?.isLoading || Object.keys(selectedItems).length === 0 || !Object.values(selectedItems).every(item => item?.status?.toUpperCase() === "PENDING")}
					type='default'
					label="Cancel Request"
					onClick={() => handleChange("cancelVendorOnboarding", Object.values(selectedItems))}
				/>
			</div>
		)
	}

	return (
		<GenericTableComponent
			actionBarConfig={{
				isItemAvailable: true,
				rhsExtras: renderRHSExtras,
			}}
			tableConfig={{
				select: {
					disabled: false
				}
			}}
			tableHeadersPayload={tableHeadersMap}
			tableDataPayload={{
				"pageNo": 1,
				"type": 1,
				"search": "",
				"filter": {},
				"sortedBy": "",
				"sortedIn": "",
				"status": "",
				"isActive": 1,
				"displayName": "VENDOR_REQUESTED",
				"apiUrl": "/core/enterprise/get/all/vendor/onboarding/request"
			}}
			tableData={{
				data: generalStore?.getTableComponentListData?.data?.resource?.data || [],
				totalItems: generalStore?.getTableComponentListData?.data?.resource?.totalCount || 0,
				currentPage: generalStore?.getTableComponentListData?.data?.resource?.currPage || 1,
				maxPage: generalStore?.getTableComponentListData?.data?.resource?.maxPage || 1,
				getTableApiData: generalStore?.getTableComponentListData?.resource?.data || []
			}}
			refreshData={approveVendorOnboardingData?.isSuccess}
			availableStores={["generalStore"]}
			{...props}
		/>
	)
}

export default RequestOnboardingComponent