import { parseJwt } from "../../../helper/genericFunction";

// Function : To check if the user will set the cookie for auto login or not
const isSetAutoLoginCookie = () => {
    const tokenData = parseJwt(sessionStorage.getItem("token"));
    let val = true;
    // Cases to handle
    // 1. If the user is using vendor redirection

    if(tokenData?.loginStripMessage) val = false;
    return val;
}

// Function : To check if the user will auto login using cookie
const isGetAutoLoginCookie = ({location}) => {
    const tokenData = parseJwt(sessionStorage.getItem("token"));
    let val = true;
    console.log("Check condition 1", tokenData, location?.pathname);
    // Cases to handle
    // 1. If the user is using vendor redirection
    if(tokenData?.loginStripMessage) val = false;
    // 2. If the user is working with pending sign up
    
    // 3. If the user is using vendor onboarding, 
    if(location?.pathname?.toLowerCase()?.includes('newvendorregistration')) val = false;

    return val;
}

export {isSetAutoLoginCookie, isGetAutoLoginCookie}