import React from "react";
import { notification } from 'antd';

const useCustomToast = () => {
  const customToast = ({ title, description, status = "info" }) => {

    const openNotificationModal = (type) => {
      notification.config({
        placement: 'bottomLeft'
      });
      notification[status]({
        message: title || "",
        description: description || "",
        key: (new Date()).getTime()?.toString(),
        duration: 3,
      });
    };

    openNotificationModal()
    return (
      <React.Fragment>

      </React.Fragment>
    )
  };

  return customToast;
};

export default useCustomToast;
