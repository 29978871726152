import React, { useContext, useEffect, useState } from 'react'
import ParentModal from '../genericComponents/ParentModal';
import AnnoucementPreview from '../components/administration/globalConfiguration/configComponent/annoucements/AnnoucementPreview';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/stylesheets/Administration/GlobalConfiguration.scss'
import Icons from '../assets/icons';
import GenericCustomButton from '../genericComponents/GenericCustomButton';
import AnnoucementStrip from '../components/administration/globalConfiguration/configComponent/annoucements/AnnouncementStrip';
import dayjs from 'dayjs';
import LanguageTranslator from '../locals/LanguageTranslator';
import { deleteCookie, getCookie } from '../helper/genericFunction';
import { dispatchHandler } from '../helper/apiHelperFunctions';
import { ReloadContext } from '../App';
import SupportRaiseSuccessScreen from './SupportRaiseSuccessScreen';

let getNumberOfStrips = () => 0

const InitialAnnouncementModal = (props) => {
    const { tokenData = {} } = props;

    const { handleDocumentStatusChange } = useContext(ReloadContext);

    const dispatch = useDispatch();

    const getUserAnnoucementDetails = useSelector((state) => state.generalStore?.getAnnouncementData);

    const [stripAnnoucements, setStripAnnoucements] = useState([]);
    const [popupAnnoucements, setPopAnnoucements] = useState([]);
    const [selectedModal, setSelectedModal] = useState('');
    const [selectedData, setSelectedData] = useState(null);

    const [currentType, setCurrentType] = useState("normal");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if ( getUserAnnoucementDetails?.isSuccess && getUserAnnoucementDetails?.data?.resource) {
            dispatchHandler(dispatch, "getAnnouncementClearRequest");
            let updatedAnnoceuments = [];
            const subscriptionDetails = getUserAnnoucementDetails?.data?.resource?.subscriptionDetails || {};
            const announcements = getUserAnnoucementDetails?.data?.resource?.announcement || []

            let updatedStripAnnoucenments = [];

            // move the subscription expire popup
            if (subscriptionDetails?.level == 'L2' || subscriptionDetails?.level == 'L3') {
                updatedAnnoceuments?.push({
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    message: subscriptionDetails?.level_msg || "",
                    ...subscriptionDetails,
                    bannerImage: () => getBannerImage(subscriptionDetails),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons
                });

                subscriptionDetails?.level == 'L2' && updatedStripAnnoucenments?.push({
                    ...subscriptionDetails,
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    level: "L2",
                    message: subscriptionDetails?.level_msg || "",
                    external_link: '',
                    bannerImage: () => getBannerImage({ ...subscriptionDetails, level: "L2" }),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons,
                    // stripButtonLabel: "Know More",
                })
            }

            // Update the subscription strip data here
            if (subscriptionDetails?.level == "L1") {
                updatedStripAnnoucenments?.push({
                    ...subscriptionDetails,
                    type: `subscriptionLevel${subscriptionDetails?.level}`,
                    message: subscriptionDetails?.level_msg || "",
                    external_link: '',
                    bannerImage: () => getBannerImage(subscriptionDetails),
                    renderCustomData: renderCustomData,
                    renderCustomButtons: renderCustomButtons,
                    // stripButtonLabel: "Know More",
                })
            }

            // update the annoceument data inside strips and the popups here.
            announcements?.map(item => {
                let obj = {
                    type: "popupAnnoucements",
                    ...item,
                    bannerImage: item?.uploaded_doc_path,
                    title: item?.display_name,
                    annoucementView: item?.announcement_view
                }
                if (item?.announcement_view == "popup") {
                    updatedAnnoceuments?.push(obj);
                } else {
                    updatedStripAnnoucenments?.push(obj);
                }
            });

            setPopAnnoucements(updatedAnnoceuments);
            if (updatedAnnoceuments?.length > 0) {
                sessionStorage.setItem("popupAnnoucements", JSON.stringify(updatedAnnoceuments));
                setSelectedModal("announcement");
            }
            setStripAnnoucements(updatedStripAnnoucenments);
            if (updatedStripAnnoucenments?.length > 0) {
                sessionStorage.setItem("stripAnnoceuments", JSON.stringify(updatedStripAnnoucenments))
            }
        } else {
            if (sessionStorage?.getItem("stripAnnoceuments")) {
                let data = JSON.parse(sessionStorage.getItem("stripAnnoceuments"));
                if (data?.[0]?.type?.includes("subscriptionLevel")) {
                    data[0] = {
                        ...data?.[0],
                        bannerImage: () => getBannerImage(data?.[0]),
                        renderCustomData: renderCustomData,
                        renderCustomButtons: renderCustomButtons
                    }
                }
                setStripAnnoucements(data || [])
            }
            if (sessionStorage.getItem("popupAnnoucements")) {
                let data = JSON.parse(sessionStorage.getItem("popupAnnoucements"));
                if (data?.[0]?.type?.includes("subscriptionLevel")) {
                    data[0] = {
                        ...data?.[0],
                        bannerImage: () => getBannerImage(data?.[0]),
                        renderCustomData: renderCustomData,
                        renderCustomButtons: renderCustomButtons
                    }
                }
                setPopAnnoucements(data || []);
                setSelectedModal("announcement");
            }
        }
    }, [getUserAnnoucementDetails?.isSuccess])

    useEffect(() => {
        if(getUserAnnoucementDetails?.isError){
            dispatchHandler(dispatch, "getAnnouncementClearRequest");
        }
    },[getUserAnnoucementDetails?.isError])

    // console.log("ASDfasdfsadf", popupAnnoucements);


    getNumberOfStrips = () => {
        let num = 0;

        if (tokenData?.["loginStripMessage"]) num += 35;
        if (tokenData?.uType === "VENDOR" && +tokenData?.isMergeEligible === 1) num += 35;
        if (stripAnnoucements?.length > 0 && (selectedModal ? !sessionStorage.getItem("popupAnnoucements") : true)) num += 35;

        return num;
    }


    const renderCustomData = ({ item }) => {
        // console.log("Grace period", item?.level);
        return (
            <div className='apb-subscription-det'>
                <LanguageTranslator tag="h2">Subscription Details</LanguageTranslator>
                {item?.["message"] && <div className='apb-para'>
                    <LanguageTranslator tag="label">Message</LanguageTranslator>
                    <p>{item?.["message"] || "-"}</p>
                </div>}
                <div className='apbs-current'>
                    <div>
                        <Icons.Calender />
                    </div>
                    <div className='p-lft-15'>
                        <LanguageTranslator tag="h1">Current Subscription Period</LanguageTranslator>
                        <p>
                            {`${item?.subs_activation_date ? dayjs(item?.subs_activation_date)?.format("DD-MMM-YYYY") : ""} - ${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : ""}`}
                        </p>
                        <span>{`Subscription ${item?.level == "L1" ? "will expire" : "ended"} on ${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : "-"} 12:00 Midnight `}</span>
                    </div>
                </div>

                {/* Render Grace Period if level is L2 */}
                {(item?.level == 'L2') && (
                    <div className='apbs-grace'>
                        <div>
                            <Icons.Calender />
                        </div>
                        <div className='p-lft-15'>
                            <LanguageTranslator tag="h1">Grace Period</LanguageTranslator>
                            <p>
                                {`${item?.subs_expiry_date ? dayjs(item?.subs_expiry_date)?.format("DD-MMM-YYYY") : ""} - ${item?.grace_expiry_date ? dayjs(item?.grace_expiry_date)?.format("DD-MMM-YYYY") : ""}`}
                            </p>
                            <span>{`Grace Period will end on ${item?.grace_expiry_date ? dayjs(item?.grace_expiry_date)?.format("DD-MMM-YYYY") : "-"} 12:00 Midnight `}</span>
                        </div>
                    </div>
                )}

                {/* Render Modules if level is L3 */}
                {item?.level == 'L3' && item?.module_details?.value && (
                    <div className='apbs-current'>
                        <div className='flex'>
                            <Icons.ModuleIcon />
                        </div>
                        <div className='p-lft-15'>
                            <LanguageTranslator tag="h1">Modules</LanguageTranslator>
                            <p>
                                {JSON.parse(item?.module_details?.value || "[]")?.map(item => item?.module_name)?.join(', ')}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    let time = null;
    const renderCustomButtons = ({ item, setCurrentActivePost, posts, loading, setLoading }) => {
        return (
            <>
                <div className='ap-sub-footer'>
                    {(item?.level == 'L2') && <GenericCustomButton
                        label={'Continue With Grace Period'}
                        type='default'
                        onClick={() => {
                            // console.log("sadfsdfsad", popupAnnoucements);
                            if (posts?.length == 1) {
                                setSelectedModal("");
                                sessionStorage.removeItem("popupAnnoucements")
                            } else {
                                setCurrentActivePost(1);
                            }
                        }}
                    />}
                    {item?.level == 'L3' && <div className='flex items-center gap-3 w-full'>
                        <GenericCustomButton
                            label="Raise Support"
                            type="primary"
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                fetch(`https://supplymint.freshdesk.com/api/v2/tickets`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": "Basic RXQ2MExXcFViTktwT0Vtc3pheEc=",
                                            "X-Auth-Token": sessionStorage.getItem("token") || "",
                                            "Page-Link": window.location.hash.split("?")[0] || undefined,
                                        },
                                        body: JSON.stringify({
                                            email: tokenData?.["eml"] || "",
                                            subject: "Subscription Expired.",
                                            description: "Subscription Expired.",
                                            priority: 2,
                                            status: 2,
                                            cc_emails: undefined,
                                            custom_fields: {
                                                cf_enterprise_id: tokenData?.eid || "",
                                                cf_issue_related_to: "Others", // "License Limit",
                                                cf_issue_sub_category: "Other",  //"License Limit",
                                                cf_module: "Others",  // "Supplymint",
                                                cf_organisation_id: tokenData?.oid || "",
                                                cf_organisation_name: tokenData?.orgName || "",
                                                cf_organisation_search_key: (tokenData?.["X-TENANT-ID"] + "_" + tokenData?.["oid"]) || "",
                                                cf_user_id: tokenData?.jti || "",
                                                cf_user_type: tokenData?.uType || "",
                                                cf_username: tokenData?.prn || "",
                                                cf_billing_type: "Not Applicable",
                                            }
                                        })
                                    }
                                )?.then(response => {
                                    console.log(response, "create ticket response");
                                    loading = false
                                    setLoading(false);
                                    if (response.status === 200 || response.status === 201) {
                                        setCurrentType("successScreen");
                                    } else {
                                        setError("Failed to create ticket to contact supplymint support.");
                                        clearTimeout(time);
                                        time = setTimeout(() => {
                                            setError(false);
                                        }, 5000)
                                    }
                                })
                            }}
                            className={'flex-shrink-0 flex-grow-0 basis-3/5'}
                        />
                        <GenericCustomButton
                            label="Log out"
                            type="default"
                            onClick={() => { onLogout() }}
                        />
                    </div>}
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
                <LanguageTranslator tag="p" className='ap-p'><LanguageTranslator>If you have any questions or need assistance, feel free to contact our support team at</LanguageTranslator> <b>support@supplymint.com</b>.</LanguageTranslator>
            </>
        );
    };
    const getBannerImage = (item) => {
        return <div className='p-5'>
            <div className='api-expired'>
                <div>
                    <Icons.SubscriptionExpired />
                </div>
                <div className='p-lft-15 py-2'>
                    {item?.level == "L1" ? <LanguageTranslator tag="h1">Subscription will expire soon!</LanguageTranslator> : <LanguageTranslator tag="h1">Subscription Expired.</LanguageTranslator>}
                    {item?.level == "L3" && <LanguageTranslator tag="p" className='!text-[11px]'>To continue with our services, please renew your subscription now. Click the button below to renew your subscription and regain access to our portal.</LanguageTranslator>}
                    {item?.level == "L2" && item?.grace_period_days &&
                        <LanguageTranslator tag="p">
                            However,
                            <LanguageTranslator tag="b">{` we're offering a grace period of `}</LanguageTranslator>
                            <b>{item?.grace_period_days || ""}</b>
                            <LanguageTranslator tag='b'> days to renew your subscription without interruption to our services.`</LanguageTranslator></LanguageTranslator>}
                    {item?.level == "L1" && <LanguageTranslator tag="p">Your subscription is ending soon.  To keep using our services without interruption please renew your subscription.</LanguageTranslator>}
                </div>
            </div>
        </div>
    };

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => setSelectedModal(''),
            animationType: "bottom",
        }
        switch (selectedModal) {
            case "stripAnnoucenmentPreview":
            case "announcement": {
                return {
                    ...payload,
                    postitionProps: { top: "50%", left: "50%", transform: 'translate(-50%, -50%)',  borderRadius: 0 },
                    dimensions: { width: "40vw", height: "auto", maxWidth: '500px', maxHeight: '90vh', overflowY: 'auto' },
                    isCloseBlocked: selectedModal == "stripAnnoucenmentPreview" ? false : true,
                    closeModalOnFinalPost: () => {
                        setSelectedModal("");
                        setSelectedData(null);
                        sessionStorage.removeItem("popupAnnoucements")
                    },
                    parentModalMainClass: "announcement-modal-main",
                    posts: selectedModal == "stripAnnoucenmentPreview" ? stripAnnoucements || [] : popupAnnoucements || [],
                    currentActiveStrip: selectedData || 0,
                    onLogout : onLogout,
                    loading : loading,
                    setLoading : setLoading
                }
            }
            case "successScreen": {
                return{
                    ...payload,
                    postitionProps: { top: "50%", left: "50%", transform: 'translate(-50%, -50%)', borderRadius: 0 },
                    dimensions: { width: "470px", height: "fit-content", maxHeight: '90vh', overflowY: 'auto' },
                }
            }
            default: break
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "stripAnnoucenmentPreview":
            case "announcement": return AnnoucementPreview;
            default:
        }
    }

    const onLogout = () => {
        let registrationJwt = getCookie('registrationJwt');
        props.setLoading(true)
        dispatchHandler(dispatch, "userSessionCreateRequest", { token: sessionStorage.getItem('token') || "", flowType: "LOGGEDOUT" });
        deleteCookie('jwt')
        if (registrationJwt) {
            deleteCookie('registrationJwt')
        }
        localStorage?.clear();
        sessionStorage?.clear();
        setTimeout(() => {
            props.setLoading(false);
            handleDocumentStatusChange();
        }, 1000);
    }


    let SuccessComponent = <div>
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.5898" cy="28.2852" r="27.5898" fill="#5D5FEF" fill-opacity="0.1" />
            <path d="M41.6471 63.6693C53.6133 63.6693 63.3138 53.9688 63.3138 42.0026C63.3138 30.0364 53.6133 20.3359 41.6471 20.3359C29.681 20.3359 19.9805 30.0364 19.9805 42.0026C19.9805 53.9688 29.681 63.6693 41.6471 63.6693Z" stroke="#5D5FEF" stroke-width="4.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M35.1484 41.9974L39.4818 46.3307L48.1484 37.6641" stroke="#5D5FEF" stroke-width="4.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p>Support Ticket Raised Successfully</p>
        <p>A ticket has been raised to the Supplymint support team, someone from the team will get back to you soon. Thank you!</p>
        <GenericCustomButton
            label="Log out"
            type="primary"
            onClick={() => {
                onLogout()
            }}
        />
    </div>

    if (currentType == "successScreen") {
        return <ParentModal
            getParentChildProps={getParentChildProps()}
            childComponent={SupportRaiseSuccessScreen}
        />
    }

    return (
        <>
            <div className='initial-announcement'>
                {stripAnnoucements?.length > 0 && (selectedModal ? !sessionStorage.getItem("popupAnnoucements") : true) && <AnnoucementStrip posts={stripAnnoucements} setStripAnnoucements={setStripAnnoucements} setSelectedModal={setSelectedModal} setSelectedData={setSelectedData} />}

                {/* <div className='initial-announcement-popup h-100'>
                </div> */}
            </div>
            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
            />}
        </>
    )
}

export { InitialAnnouncementModal, getNumberOfStrips }