import { dispatchHandler } from '../helper/apiHelperFunctions'
// import { parseJwt } from '../helper/genericFunction';

export const getAllAnnouncements = ({ dispatch }) => {
    // const uType = parseJwt(sessionStorage.getItem('token')).uType?.toUpperCase() || "ENT";
    // if(uType == "ENT") {
        dispatchHandler(dispatch, 'getAnnouncementRequest', {
            token: sessionStorage.getItem('token'),
            "flowType": "ENT_DETAILS"
        });
    // }
};


