import React, { useEffect, useState } from 'react'
import { parseJwt } from '../../../helper/genericFunction';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import Icons from '../../../assets/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProcDashboardCardsSkeletonLoader from './ProcDashboardCardsSkeletonLoader';
import '../../../styles/stylesheets/digiProc/ProcDashboard.scss'
import LanguageTranslator from '../../../locals/LanguageTranslator';

const currentDate = dayjs();
const currFormatDate = currentDate.format('YYYY-MM-DD');
const oneYearFromNow = currentDate.subtract(1, 'year').format('YYYY-MM-DD');

const ProcDashBottomCards = (props) => {
    const { procDashboardType } = props
    const dispatch = useDispatch()
    const digiProcStore = useSelector(state => state.digiProcStore)
    const history = useHistory()

    const [dashboardData, setDashboardData] = useState({})
    const [number, setNumber] = useState('1')
    const [fromDate, setFromDate] = useState(currFormatDate)

    useEffect(() => {
        let payload = {
            isPO: props.procDashboardType === "pi" ? false : true,
            fromDate: oneYearFromNow,
            toDate: currFormatDate,
            expireDays: "",
            siteCode: "",
            hl1Code: "",
            hl2Code: "",
            hl3Code: "",
            hl4Code: "",
            isToggle: true,
            slCode: ''
        }
        let mainHeaderPayload = props.procDashboardType === "pi" ? {
            enterpriseName: parseJwt(sessionStorage.getItem('token')).ename,
            attributeType: "PI_TABLE_HEADER",
            displayName: "TABLE HEADER"
        } : {
            enterpriseName: parseJwt(sessionStorage.getItem('token')).ename,
            attributeType: "PO_TABLE_HEADER",
            displayName: "PO_TABLE_HEADER"
        }
        dispatchHandler(dispatch, 'piDashBottomRequest', payload)
        // dispatchHandler(dispatch, 'getHeadersRequest', mainHeaderPayload)
        setDashboardData({})
    }, [procDashboardType]);

    useEffect(() => {
        if (digiProcStore?.piDashBottomData?.isSuccess) {
            setDashboardData(digiProcStore?.piDashBottomData?.data?.resource || {})
        }
    }, [digiProcStore?.piDashBottomData?.isSuccess])

    const changeNumber = (e) => {
        if (e.target.dataset.value !== 1) {
            let payload = {
                isPO: true,
                fromDate: oneYearFromNow,
                toDate: currFormatDate,
                expireDays: e.target.dataset.value,
                siteCode: "",
                hl1Code: "",
                hl2Code: "",
                hl3Code: "",
                hl4Code: "",
                slCode: '',
                isToggle: ''
            }
            dispatchHandler(dispatch, 'piDashBottomRequest', payload)
        }
        const date = currentDate.subtract(e.target.dataset.value, 'day').format('YYYY-MM-DD');
        setNumber(e.target.dataset.value)
        setFromDate(date)
    }

    console.log(4444444444, dashboardData)
    let mappingData = [
        {
            label: `${procDashboardType === 'pi' ? 'PI' : 'PO'} Pending for Approval`,
            icon: <span><Icons.ArsPendindIcon /></span>,
            status: "PENDING",
            totalData: procDashboardType === 'pi' ? dashboardData?.totalPendingPI || 0 : dashboardData?.totalPendingPO || 0,
            totalQty: dashboardData?.pendingPOQty || 0,
            totalAmount: dashboardData?.pendingPOAmount || 0,
            totalHoverData: dashboardData?.hoverData?.totalPendingPI || 0,
            totalHoverQty: dashboardData?.hoverData?.pendingPOQty || 0,
            totalHoverAmount: dashboardData?.hoverData?.pendingPOAmount || 0
        },
        {
            label: procDashboardType === 'pi' ? 'PI Pending saved as Draft' : 'PO Pending saved as Draft',
            icon: <span className='drafted'><Icons.DraftFileIcon /></span>,
            status: "DRAFTED",
            totalData: procDashboardType === 'pi' ? dashboardData?.totalDraftedPI || 0 : dashboardData?.totalDraftedPO || 0,
            totalQty: dashboardData?.draftedPOQty || 0,
            totalAmount: dashboardData?.draftedPOAmount || 0,
            totalHoverData: dashboardData?.hoverData?.totalDraftedPI || 0,
            totalHoverQty: dashboardData?.hoverData?.draftedPOQty || 0,
            totalHoverAmount: dashboardData?.hoverData?.draftedPOAmount || 0
        },
        {
            label: procDashboardType === 'pi' ? 'Purchase Indent Approved' : 'Purchase Order Approved ',
            icon: <span className='approved'><Icons.ArsApprovedIcon /></span>,
            status: "APPROVED",
            totalData: procDashboardType === 'pi' ? dashboardData?.totalApprovedPI || 0 : dashboardData?.totalApprovedPO || 0,
            totalQty: dashboardData?.approvedPOQty || 0,
            totalAmount: dashboardData?.approvedPOAmount || 0,
            totalHoverData: dashboardData?.hoverData?.totalApprovedPI || 0,
            totalHoverQty: dashboardData?.hoverData?.approvedPOQty || 0,
            totalHoverAmount: dashboardData?.hoverData?.approvedPOAmount || 0
        },
        {
            label: procDashboardType === 'pi' ? 'Purchase Indent Rejected' : 'Purchase Order Rejected',
            icon: <span className='rejected'><Icons.ArsRejectedIcon /></span>,
            status: "REJECTED",
            totalData: procDashboardType === 'pi' ? dashboardData?.totalRejectedPI || 0 : dashboardData?.totalRejectedPO || 0,
            totalQty: dashboardData?.rejectedPOQty || 0,
            totalAmount: dashboardData?.rejectedPOAmount || 0,
            totalHoverData: dashboardData?.hoverData?.totalRejectedPI || 0,
            totalHoverQty: dashboardData?.hoverData?.rejectedPOQty || 0,
            totalHoverAmount: dashboardData?.hoverData?.rejectedPOAmount || 0
        },
    ]

    const renderDashboardContent = () => {
        if (digiProcStore?.piDashBottomData?.isLoading) {
            return <ProcDashboardCardsSkeletonLoader />;
        } else if (digiProcStore?.piDashBottomData?.isError) {
            return
        } else {
            return mappingData.map((data) => (
                <div className={`pdb-cards ${procDashboardType === 'po' ? "w-20" : "w-25"}`} onClick={() => history.push({ pathname: procDashboardType === 'pi' ? '/purchase/purchaseIndentHistory' : '/purchase/purchaseOrderHistory', status: data?.status, type: 2, filter: { ...props.filter }, filterItems: { ...props.filterItems }, ...props })} >
                    <div className='pdbc-det'>
                        <div className='pdbcd-top'>
                            {data?.icon}
                            <LanguageTranslator tag="h3">{data.totalData}</LanguageTranslator>
                            <LanguageTranslator tag="h5">{data?.label}</LanguageTranslator>
                        </div>
                        <div className='pdbcd-bot'>
                            <div className='pdbcdb-det'>
                                <LanguageTranslator tag="h5">Quantity</LanguageTranslator>
                                <div className="pdbcdbd">
                                    <LanguageTranslator tag="h3">{data?.totalHoverQty}
                                        <LanguageTranslator tag="span" className="generic-tooltip">{data?.totalQty === undefined ? "NA" : data?.totalQty}</LanguageTranslator></LanguageTranslator>
                                </div>
                            </div>
                            <div className='pdbcdb-det'>
                                <LanguageTranslator tag="h5">Amount</LanguageTranslator>
                                <div className="pdbcdbd">
                                    <LanguageTranslator tag="h3"><LanguageTranslator tag="span" className='rupFontIcon'>&#8377;</LanguageTranslator> {data?.totalHoverAmount || 0}</LanguageTranslator>
                                    <LanguageTranslator tag="span" className="generic-tooltip">{Number(data.totalAmount).toFixed(2) || 0}</LanguageTranslator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ));
        }
    };

    return (
        <div className='pd-body'>
            {renderDashboardContent()}

            {procDashboardType === 'po' &&
                <div className="pdb-cards w-20" onClick={() => history.push({ pathname: '/purchase/purchaseOrderHistory', status: "PENDING", type: 2, filter: { ...props.filter, createdTime: fromDate + '|' + currFormatDate }, filterItems: { ...props.filterItems } })}>
                    <div className='pdbc-det'>
                        <div className="pdbcd-top">
                            <span className="rejected">
                                <Icons.ArsRejectedIcon />
                            </span>
                            <h3 className="clr-red">{number === 1 ? dashboardData?.expireToday || 0 : dashboardData?.expireOnDays}</h3>
                            <div className="pdbcdt-info"> <Icons.InfoWhiteIcon /> </div>
                            <LanguageTranslator tag='h5'>PO Near Expiry</LanguageTranslator>
                        </div>
                        <div className="pdbcd-bot">
                            <div className='pdbcdb-days'>
                                <label><LanguageTranslator>PO Expires</LanguageTranslator>  {number === 1 ? "Today" : `in next ${number} Days`}</label>
                                <Icons.DownArrowNew />
                                <ul>
                                    <li><span data-show="tenDay" data-value="10" onClick={changeNumber}>10 Days</span></li>
                                    <li><span data-show="sevenDay" data-value="7" onClick={changeNumber}>7 Days</span></li>
                                    <li><span data-show="fiveDay" data-value="5" onClick={changeNumber}>5 Days</span></li>
                                    <li><span data-show="threeDay" data-value="3" onClick={changeNumber}>3 Days</span></li>
                                    <li><span data-show="currentDay" data-value="1" onClick={changeNumber}>Today</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default ProcDashBottomCards