/* eslint-disable default-case */
import script from '../script';
import { call, put } from 'redux-saga/effects';
import fireAjax from '../services';
import { CONFIG } from '../../config/index'
import { getTokenData, parseJSON } from '../../helper/apiHelperFunctions';
import axios from 'axios';

export default function embedLocalActions({ ...actions }, eachStateAction) {
	let tokenData = getTokenData() || undefined;
	actions["getHeadersRequest"] = function* (action) {
		let dispatch = action?.payload?.dispatch;
		delete action?.payload?.dispatch;
		try {
			const lfResponse = action.payload.masterDbResponse
			// console.log("MAster responsed", action.payload);
			if (Object.keys(lfResponse || {})?.length !== 0) {
				yield put(eachStateAction.getHeadersSuccessRequest({
					basedOn: action.payload.basedOn || "",
					message: "",
					resource: {...(lfResponse || {}), displayName : action.payload.displayName}
				}));
			}
			else {
				const callApi = async () => {
					const response = await axios.get(encodeURI(`${CONFIG.BASE_URL}/headerconfig/get/header?enterpriseName=${action.payload.enterpriseName}&attributeType=${action.payload.basedOn}&displayName=${action.payload.displayName}&orgId=`), {
						headers: {
							"Content-Type": "application/json",
							"X-Auth-Token": sessionStorage.getItem("token"),
							"Page-Link": window.location.hash.split("?")[0] || undefined,
						}
					},)
					return response;
				}

				callApi()?.then(async response => {
					const finalResponse = script(response);
					if (finalResponse.success && response?.data?.data) {
						response.data.data.basedOn = action.payload.basedOn || "";
						dispatch(eachStateAction.getHeadersSuccessRequest(response.data.data));
					} else if (finalResponse.failure) {
						dispatch(eachStateAction.getHeadersErrorRequest(response.data));
					}
				})
			}
		} catch (e) {
			dispatch(
				eachStateAction[`getHeadersErrorRequest`]({
					errorMessage: e.message,
					pageName: "getHeaders",
				})
			);
			console.warn(`Error encountered in ${"getHeaders"}Request\n`, e);
		}
	}

	actions["getQuickFiltersRequest"] = function* (action) {
		let dispatch = action?.payload?.dispatch;
		delete action?.payload?.dispatch;
		try {
			const lfResponse = action.payload.masterDbResponse
			if (Object.keys(lfResponse || {})?.length !== 0) {
				yield put(eachStateAction.getQuickFiltersSuccessRequest({
					message: "",
					resource: lfResponse
				}));
			}
			else {
				const callApi = async () => {
					const response = await axios.post(encodeURI(`${CONFIG.BASE_URL}/headerconfig/get/filter`), action?.payload, {
						headers: {
							"Content-Type": "application/json",
							"X-Auth-Token": sessionStorage.getItem("token"),
							"Page-Link": window.location.hash.split("?")[0] || undefined,
						}
					},)
					return response;
				}

				callApi()?.then(async response => {
					const finalResponse = script(response);
					if (finalResponse.success && response?.data?.data) {
						response.data.data.basedOn = action.payload.basedOn || "";
						dispatch(eachStateAction.getQuickFiltersSuccessRequest(response.data.data));
					} else if (finalResponse.failure) {
						dispatch(eachStateAction.getQuickFiltersSuccessRequest(response.data));
					}
				})
			}
		} catch (e) {
			dispatch(
				eachStateAction[`getQuickFiltersErrorRequest`]({
					errorMessage: e.message,
					pageName: "getQuickFilters",
				})
			);
			console.warn(`Error encountered in ${"getQuickFilters"}Request\n`, e);
		}
	}

	actions["getDropdownRequest"] = function* (action) {
		let dispatch = action?.payload?.dispatch;
		delete action?.payload?.dispatch;
		try {
			const lfResponse = action.payload.masterDbResponse
			if (Array?.isArray(lfResponse) == true ? lfResponse?.length > 0 : Object.keys(lfResponse || {})?.length > 0) {
				yield put(eachStateAction.getDropdownSuccessRequest({
					message: "",
					resource: lfResponse,
					type: action?.payload?.type
				}));
			}
			else {
				const callApi = async () => {
					const response = await axios.get(encodeURI(`${CONFIG.BASE_URL}/core/dropdown/get?type=${action?.payload?.type}`), {
						headers: {
							"Content-Type": "application/json",
							"X-Auth-Token": sessionStorage.getItem("token"),
							"Page-Link": window.location.hash.split("?")[0] || undefined,
						}
					},)
					return response;
				}

				callApi()?.then(async response => {
					const finalResponse = script(response);
					if (finalResponse.success && response?.data?.data) {
						response.data.data.type = action?.payload?.type || "";
						dispatch(eachStateAction.getDropdownSuccessRequest(response.data.data));
					} else if (finalResponse.failure) {
						dispatch(eachStateAction.getDropdownErrorRequest(response.data));
					}
				})
			}
		} catch (e) {
			dispatch(
				eachStateAction[`getDropdownErrorRequest`]({
					errorMessage: e.message,
					pageName: "getQuickFilters",
				})
			);
			console.warn(`Error encountered in ${"getDropdown"}Request\n`, e);
		}
	}

	actions["getDocumentDropdownRequest"] = function* (action) {
		let dispatch = action?.payload?.dispatch;
		// console.log("Payload 21w", dispatch);/
		delete action?.payload?.dispatch;

		try {
			const lfResponse = action.payload.masterDbResponse || {};
			// console.log("Lf response", lfResponse);
			if (Array?.isArray(lfResponse) == true ? lfResponse?.length > 0 : Object.keys(lfResponse || {})?.length > 0) {
				yield put(eachStateAction.getDocumentDropdownSuccessRequest({
					message: "",
					resource: lfResponse,
					type: action?.payload?.displayName
				}));
			}
			else {
				const callApi = async () => {
					const response = await axios.get(encodeURI(`${CONFIG.BASE_URL}/system/config/list/download/document?displayName=${action?.payload?.displayName || ""}`), {
						headers: {
							"Content-Type": "application/json",
							"X-Auth-Token": sessionStorage.getItem("token"),
							"Page-Link": window.location.hash.split("?")[0] || undefined,
						}
					},)
					return response;
				}

				callApi()?.then(async response => {
					const finalResponse = script(response);
					// console.log("Final response", response);
					if (finalResponse.success && response?.data?.data) {
						response.data.data.resource = {
							...response.data.data?.resource?.["downloadConfig"]?.[0],
						};
						response.data.data.displayName = action?.payload?.displayName || "";
						dispatch(eachStateAction.getDocumentDropdownSuccessRequest(response.data.data));
					} else if (finalResponse.failure) {
						dispatch(eachStateAction.getDocumentDropdownErrorRequest(response.data));
					}
				})
			}
		} catch (e) {
			dispatch(
				eachStateAction[`getDocumentDropdownSuccessRequest`]({
					errorMessage: e.message,
					pageName: "getDocumentDropdownSuccessRequest",
				})
			);
			console.warn(`Error encountered in ${"getDocumentDropdownSuccessRequest"}Request\n`, e);
		}
	}

	return actions;
}