import React from 'react'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import Icons from '../../../assets/icons';
import { Info, Laptop, Monitor, RectangleEllipsis, SquareMousePointer } from 'lucide-react';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const SingleLogin = (props) => {

    let { details = {}, invalidToken = false } = props;

    const getIcons = (type) => {
        console.log('type : ', type)
        if (type?.includes("Mac OS")) return <Icons.MacIcon />
        if (type?.includes("windows")) return <Icons.WindowsIcon />

        return <Monitor size={13.5}  color='#8E9095'/>
    }

    return (
        <div className='text-[#0F172A] text-center px-[1.5625rem]'>
            <p className='text-lg font-medium leading-[1.6875rem] lg:text-base'>We have notice that you are already logged in on another device</p>

            <Icons.DesktopWarningIcon className='mx-auto my-5'/>

            {!invalidToken && <p className='text-xs font-medium text-[#67768E] ml-2.5 lg:text-[11px]'>Last Access</p>}
            {!invalidToken && <div className='flex items-center justify-center gap-4 mb-3.5 mt-4 text-xs font-medium'>

                <div className='flex items-center gap-1'>
                    <div><Laptop size={13.5}  color='#8E9095'/></div>
                    <p className='mb-0 whitespace-nowrap'>{details?.["systemos"]}</p>
                </div>

                <div className='flex items-center gap-1'>
                    <div><SquareMousePointer size={13.5}  color='#8E9095'/></div>
                    <p className='mb-0 whitespace-nowrap'>{details?.["browser"]}</p>
                </div>

                <div className='flex items-center gap-1'>
                    <div><RectangleEllipsis size={13.5} color='#8E9095'/></div>
                    <p className='mb-0 whitespace-nowrap'>{details?.["sourceIp"]}</p>
                </div>
            </div>}

        <p className='text-[#575D6A] text-[13px] mb-4 lg:text-xs'>If that was you, you can access your account by clicking the "Log Me In" button. If it wasn't you, secure your account by resetting your password.</p>

        <div className='flex items-center gap-3 px-3 py-2 bg-[#E7F1FF] mb-7'>
            <div>
                <Info color='#2B83FF' size={17}/>
            </div>
            <LanguageTranslator tag='p' className='mb-0 text-xs font-semibold text-start'>Existing user will be logged out once you login to your account</LanguageTranslator>
        </div>

        <div className='space-y-2.5 mb-4'>
            <GenericCustomButton
                label="Log me In"
                type='primary'
                onClick={() => {
                    details?.loginAgain?.();
                }}
                className={'w-full !rounded-none !h-[35px]'}
                />

            {/* <GenericCustomButton
                label="Secure my account"
                // onClick={() => {
                // }}
                className={'w-full !rounded-none !h-[35px] border border-[#D6D9E4] text-[13px] font-medium hover:bg-[#D6D9E4]'}
                /> */}
        </div>
        </div>
    )
}

export default SingleLogin