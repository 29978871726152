import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { handleApiChange } from '../helper/apiHelperFunctions'

const UseRenderClassComponent = (Component, ComponentProps, availableReduxStates, sideEffect) => {
    const dispatch = useDispatch();

    const location = useLocation()
    const history = useHistory();
    const generalStore = useSelector(state => state.generalStore)
    const digiArsStore = useSelector(state => state.digiArsStore)
    const digivendStore = useSelector(state => state.digivendStore)
    const salesManagementStore = useSelector(state => state.salesManagementStore);
    const seasonPlanningStore = useSelector(state => state.seasonPlanningStore)

    return (
        <>
            {
                React.createElement(Component, {
                    ...ComponentProps,
                    generalStore: availableReduxStates?.includes("generalStore") ? generalStore : {},
                    digivendStore: availableReduxStates?.includes("digivendStore") ? digivendStore : {},
                    digiArsStore: availableReduxStates?.includes("digiArsStore") ? digiArsStore : {},
                    salesManagement: availableReduxStates?.includes("salesManagement") ? salesManagementStore : {},
                    seasonPlanning: availableReduxStates?.includes("seasonPlanning") ? seasonPlanningStore : {},
                    handleApiChange: (apiName, apiPayload) => handleApiChange(dispatch, apiName, apiPayload),
                    location: location,
                    history: history,
                })
            }
        </>
    )
}

export default UseRenderClassComponent