import React from 'react'

const EscalationMatrixSkeleton = () => {
    return (
        <>
            <div className='emb-cards'>
                <div className='generic-skeleton wid-80'></div>
                <div className='generic-skeleton wid-160'></div>
                <div className='embc-det'>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                </div>
            </div>
            <div className='emb-cards'>
                <div className='generic-skeleton wid-80'></div>
                <div className='generic-skeleton wid-160'></div>
                <div className='embc-det'>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                </div>
            </div>
            <div className='emb-cards'>
                <div className='generic-skeleton wid-80'></div>
                <div className='generic-skeleton wid-160'></div>
                <div className='embc-det'>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                    <div className='generic-skeleton w-100 m-bot-15'></div>
                </div>
            </div>
        </>
    )
}

export default EscalationMatrixSkeleton