import React from 'react'

const ListComponentSkeltonLoader = (props) => {
    const { numberOfRows = 1, numberOfCols = 1 } = props;
    const divsArray = Array.from({ length: 25 }, index => index + 1);
    const colsArray = Array.from({ length: numberOfCols }, index => index + 1);
    let width1 = 100 / numberOfCols
    return (
        <div className='h-100 p-lr-15 lctv-table-loader'>
            {divsArray.map(() => (
                <div className='flex flex-row lctv-loader'>
                    {colsArray?.map((_, index) => (
                        <div className={`w-${width1}  m-lr-5 lctvl-col`}>
                            <div className={`generic-skeleton w-100`}></div>
                            {numberOfRows >= 2 && <div className={`generic-skeleton w-50`}></div>}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default ListComponentSkeltonLoader