// CopyText.js
import React, { useState } from 'react'
import Icons from '../../assets/icons';
import { Tooltip } from 'antd';

const CopyText = ({ textToCopy }) => {
    const [isCopySuccess, setIsCopySuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');

        document.body.removeChild(textarea);

        setIsCopySuccess(true);
        setSuccessMessage("Copied to Clipboard");

        setTimeout(() => {
            setIsCopySuccess(false);
        }, 5000);
    };

    return (
        <button onClick={() => copyToClipboard(textToCopy)}>
            <Icons.NewCopyIcon />
            <span className='generic-tooltip'>{isCopySuccess ? successMessage : "Copy"}</span>
        </button>
    );
}

export default CopyText;
