import React from 'react'
const divsArray = Array.from({ length: 20 }, index => index + 1);

const GraphSkeleton = () => {
    return (
        <div className='otdb-right overflow-hidden p-15' style={{ height: 'calc(100vh - 430px)' }}>
            {divsArray.map(() => (
                <div className='generic-skeleton w-100'></div>
            ))}
        </div>
    )
}

export default GraphSkeleton