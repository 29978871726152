import React from 'react'
const divsArray = Array.from({ length: 6 }, index => index + 1);

const OrderTrackingCardSkeleton = () => {
    return (
        <>
            {divsArray.map(() => (
                <div className='otd-gap'>
                    <div className='otdc-cards p-top-20 p-lr-10' style={{ background: "white" }}>
                        <div className='flex w-100 h-100'>
                            <div className='w-50 p-rgt-20'>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100'></div>
                            </div>
                            <div className='w-50'>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100'></div>
                                <div className='generic-skeleton w-100'></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default OrderTrackingCardSkeleton