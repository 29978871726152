import { createAction, handleActions } from 'redux-actions';
import { call, put } from 'redux-saga/effects';
import update from 'react-addons-update';
import fireAjax from '../services';
import script from '../script';
import { APIState } from './apiState';
import { generateUpperCase } from './commonFunction';
import { getTokenData } from '../../helper/apiHelperFunctions';
import embedLocalActions from './apiLocalFunction';

const initialStateData = {
    data: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
};

const generateInitialState = (stateName) => {
    const tempState = {};
    APIState[stateName].forEach((item) => {
        const { title } = item;
        tempState[title] = initialStateData;
    });
    return tempState;
};

const generateRequest = (state, action, stateName) =>
    update(state, {
        [stateName]: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            message: { $set: '' },
        },
    });

const generateSuccessRequest = (state, action, stateName, message) =>
    update(state, {
        [stateName]: {
            data: { $set: action.payload },
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            message: { $set: message },
        },
    });

const generateErrorRequest = (state, action, stateName, reduxMessage) => {
    return update(state, {
        [stateName]: {
            isLoading: { $set: false },
            isSuccess: { $set: false },
            isError: { $set: true },
            message: { $set: {...action.payload, "reduxMessage": reduxMessage } },
        },
    })
};


const generateClearRequest = (state, action, stateName) =>
    update(state, {
        [stateName]: {
            isLoading: { $set: false },
            isSuccess: { $set: false },
            isError: { $set: false },
            message: { $set: '' },
        },
    });

const actionHandler = (stateName) => {
    const allAction = {};
    APIState[stateName].forEach((item) => {
        const { title, message, actionPrefix, errorMessage } = item;
        allAction[`${actionPrefix}Request`] = (state, action) =>
            generateRequest(state, action, title);
        allAction[`${actionPrefix}SuccessRequest`] = (state, action) =>
            generateSuccessRequest(state, action, title, message);
        allAction[`${actionPrefix}ErrorRequest`] = (state, action) =>
            generateErrorRequest(state, action, title, errorMessage);
        allAction[`${actionPrefix}ClearRequest`] = (state, action) =>
            generateClearRequest(state, action, title);
    });
    return allAction;
};

const allConstants = generateGlobalConstant();

const actionHandlerData = (stateName) => {
    const allHandler = {};
    const allActionHandler = actionHandler(stateName);
    APIState[stateName].forEach((item) => {
        const { actionPrefix } = item;
        const handlerName = generateUpperCase(actionPrefix);
        allHandler[allConstants[`${handlerName}_REQUEST`]] =
            allActionHandler[`${actionPrefix}Request`];
        allHandler[allConstants[`${handlerName}_SUCCESS`]] =
            allActionHandler[`${actionPrefix}SuccessRequest`];
        allHandler[allConstants[`${handlerName}_ERROR`]] =
            allActionHandler[`${actionPrefix}ErrorRequest`];
        allHandler[allConstants[`${handlerName}_CLEAR`]] =
            allActionHandler[`${actionPrefix}ClearRequest`];
    });
    return allHandler;
};

function registerActionData() {
    const allData = {};
    const dataKey = Object.keys(APIState);
    dataKey.forEach((item) => {
        allData[item] = handleActions(
            actionHandlerData(item),
            generateInitialState(item)
        );
    });
    return allData;
}

function generateGlobalConstant() {
    let allConstantData = {};
    const stateValue = Object.values(APIState);
    stateValue.forEach((item) => {
        item.forEach((item) => {
            const { actionPrefix } = item;
            const handlerName = generateUpperCase(actionPrefix);
            const request = `${handlerName}_REQUEST`;
            const success = `${handlerName}_SUCCESS`;
            const error = `${handlerName}_ERROR`;
            const clear = `${handlerName}_CLEAR`;
            allConstantData = {
                ...allConstantData,
                [request]: request,
                [success]: success,
                [error]: error,
                [clear]: clear,
            };
        });
    });
    return allConstantData;
};

function generateGlobalActions() {
    let allActions = {};
    const stateValue = Object.values(APIState);
    stateValue.forEach((item) => {
        item.forEach((item) => {
            const { actionPrefix } = item;
            const handlerName = generateUpperCase(actionPrefix);
            allActions = {
                ...allActions,
                [`${actionPrefix}Request`]: createAction(
                    allConstants[`${handlerName}_REQUEST`]
                ),
                [`${actionPrefix}SuccessRequest`]: createAction(
                    allConstants[`${handlerName}_SUCCESS`]
                ),
                [`${actionPrefix}ErrorRequest`]: createAction(
                    allConstants[`${handlerName}_ERROR`]
                ),
                [`${actionPrefix}ClearRequest`]: createAction(
                    allConstants[`${handlerName}_CLEAR`]
                ),
            };
        });
    });
    return allActions;
};

const eachStateAction = generateGlobalActions();

function generateRequestAction() {
    let action = {};
    const stateValue = Object.values(APIState);
    stateValue.forEach((item) => {
        item.forEach((item) => {
            const { actionPrefix, requestData, responseData = {} } = item;
            const {
                buildUrl,
                method = 'POST',
                token = actionPrefix === 'forgotPassword' ||
                    actionPrefix === 'forgotUsername'
                    ? false
                    : true,
                extraHeader = {},
                isPayloadExistType = false,
                isPayloadExistParams = false,
                updatePayload = null,
                getExtraHeaderWithPayload = null
            } = requestData;
            const { responseHandler, successDataHandler } = responseData;
            const actualAction = function* (action) {
                try {
                    action.payload && delete action.payload['requestResponseHeaders'];
                    let tokenData;
                    if (token) {
                        tokenData = getTokenData() || undefined;
                    }
                    const url = buildUrl(action.payload);

                    let newHeader = {
                        ...extraHeader,
                    }
                    if(getExtraHeaderWithPayload){
                        newHeader = {
                            ...newHeader,
                            ...getExtraHeaderWithPayload(action.payload),
                        }
                    }

                    let payLoad =
                        method === 'GET'
                            ? null
                            : isPayloadExistType
                                ? updatePayloadType(action, actionPrefix)
                                : isPayloadExistParams
                                    ? action.payload.data : action.payload

                    payLoad = updatePayload ? updatePayload(payLoad) : payLoad;
                    const response = yield call(
                        fireAjax,
                        method,
                        url,
                        payLoad,
                        tokenData,
                        newHeader
                    );
                    const finalResponse = responseHandler
                        ? responseHandler(response, action)
                        : script(response);

                    if (finalResponse.success) {
                        const resourceData = successDataHandler
                            ? yield successDataHandler(response, action.payload)
                            : response.data.data;
                        yield put(
                            eachStateAction[`${actionPrefix}SuccessRequest`](Array?.isArray(resourceData) ? resourceData  : {...resourceData, uniqueKey: action?.payload?.uniqueKey || "" })
                        );
                    } else if (finalResponse.failure) {
                        yield put(
                            eachStateAction[`${actionPrefix}ErrorRequest`]({
                                ...response.data.error,
                                pageName: actionPrefix,
                                uniqueKey: action?.payload?.uniqueKey || "",
                                response : response?.data?.data
                            })
                        );
                    }
                } catch (e) {
                    yield put(
                        eachStateAction[`${actionPrefix}ErrorRequest`]({
                            errorMessage: e.message,
                            pageName: actionPrefix,
                        })
                    );
                }
            };
            action = {
                ...action,
                [`${actionPrefix}Request`]: actualAction,
            };
        });
    });

    action = embedLocalActions(action, eachStateAction)
    return action;
};

const updatePayloadType = (action, actionPrefix) => {
    if (actionPrefix === 'addToRetailerBag') {
        delete action.payload.orderNo;
        return { ...action.payload };
    } else {
        let filter =
            action.payload.filter === undefined ? '' : action.payload.filter;
        let isFilter =
            typeof filter == 'string'
                ? false
                : Object.keys(filter).length === 0
                    ? false
                    : true;
        let search =
            action.payload.search === undefined ? '' : action.payload.search;
        let type =
            search && isFilter
                ? 4
                : !search && isFilter
                    ? 2
                    : search && !isFilter
                        ? 3
                        : 1;
        return { ...action.payload, type };
    }
};

export {
    eachStateAction,
    registerActionData,
    generateRequestAction,
    generateGlobalConstant,
    generateGlobalActions,
};
