import React, { useState, useEffect } from 'react'
import GenericFormHeaderComponent from '../forms/GenericFormHeaderComponent'
import Icons from '../../assets/icons'
import useCustomToast from '../../hooks/useCustomToast';
import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton,
} from "react-share";


const GenericFileShareModal = (props) => {
    const [successMessage, setSuccessMessage] = useState("");
    let Buttons = { "Whatsapp": WhatsappShareButton, "Email": EmailShareButton, "Facebook": FacebookShareButton, "Instapaper": InstapaperShareButton, "Linkedin": LinkedinShareButton, "Telegram": TelegramShareButton }
    let IconList = { "Whatsapp": WhatsappIcon, "Email": EmailIcon, "Facebook": FacebookIcon, "Instapaper": InstapaperIcon, "Linkedin": LinkedinIcon, "Telegram": TelegramIcon }
    // Function to copy the url of the content to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props?.fileUrl);
        setSuccessMessage(true);
    }

    useEffect(() => {
        if (successMessage)
            setTimeout(() => {
                setSuccessMessage(false);
            }, 3000)
    }, [successMessage])

    return (
        <React.Fragment>
            <GenericFormHeaderComponent
                label={{
                    contentPrimary: "Share File",
                }}
                closeModal={props.closeModal}
            />
            <div className="share-file">
                <h3>Copy File URL</h3>
                <div className="sf-data">
                    <div className='flex items-center justify-end'>
                        {successMessage && <span>Copied</span>}
                        <button onClick={() => copyToClipboard()}>
                            <Icons.CopyIcon />
                        </button>
                    </div>
                    <p id={`copyShareDataContent`}>
                        {props?.fileUrl}
                    </p>
                </div>
                {/* Render all the share options available to the user  */}
                <div className='sf-icons'>
                    {Object.keys(Buttons)?.map(key => {
                        let Icon = IconList?.[key];
                        let Component = Buttons?.[key]
                        return <div className='sfi'>
                            <Component url={props.fileUrl}>
                                <Icon />
                            </Component>
                        </div>
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default GenericFileShareModal