import React, { useEffect, useState, useRef } from 'react'
import { parseJwt } from '../../../helper/genericFunction';
import Icons from '../../../assets/icons'
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent';
import { Radio } from 'antd';
import GenericInput from '../../../genericComponents/input/GenericInputComponent';
import { CUSTOMER_FILTERS, MAIN_FILTERS } from './constants'
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import NewTableLoader from '../../../genericComponents/oldGenericComponents/tableHelperComponents/TableSkeletonLoader';
import GenericSpinnerLoader from '../../../loaders/GenericSpinnerLoader';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const SalesOrderSummary = (props) => {
    const dispatch = useDispatch();
    const salesManagementStore = useSelector(state => state.salesManagementStore);
    const generalStore = useSelector(state => state.generalStore);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsData, setItemsData] = useState({
        itemType: 'brand', itemLevel: "desc", itemLimit: 10
    })
    const [filterData, setFilterData] = useState({});
    // State to store the api response of the data
    const [summaryResponse, setSummaryResponse] = useState({});
    // State to store the table data array (current) 
    const [tableData, setTableData] = useState([])
    // State to store the currently expanded row
    const [expandedRow, setExpandedRow] = useState(false);
    // State to store the expanded row data from the api response
    const [expandedRowData, setExpandedRowData] = useState(false);
    let observerTarget = useRef();

    // Ref to last element for infinte scroll
    const lastListElementRef = (node) => {
        if (salesManagementStore?.getSalesDashboardSummaryData?.isLoading) return;
        if (observerTarget?.current) observerTarget.current.disconnect();
        observerTarget.current = new IntersectionObserver(entries => {
            if (entries?.[0].isIntersecting) {
                // So that the state current page and max page state gets updated
                moveNextPage();
            }
        })
        if (node) observerTarget.current.observe(node);
    }

    useEffect(() => {
        if (generalStore?.["downloadReportData"]?.isSuccess) {
            generalStore?.["downloadReportData"]?.data?.resource && window.open(generalStore?.["downloadReportData"]?.data?.resource);
        }
    }, [generalStore?.downloadReportData?.isSuccess])

    // Call the api to get the data of the expanded row
    useEffect(() => {
        if (expandedRow && Object.keys(expandedRow || {})?.length > 0) {
            dispatchHandler(dispatch, "getSalesDashboardOrderSummaryDetailRequest", itemsData?.["itemType"] == "brand" ? {
                "brand": expandedRow.brand || "",
                "department": expandedRow.department || "",
                "designGroupNo": expandedRow.designGroupNo || "",
                "color": expandedRow.color || "",
                "apiUrl": "/admin/so/v2/dashboard/byBrand/customer",
            } : {
                "customerCode": expandedRow?.customer_code || "",
                "apiUrl": "/admin/so/v2/details/byCustomer"
            })
        }
    }, [expandedRow])

    useEffect(() => {
        if (salesManagementStore?.["getSalesDashboardOrderSummaryDetailData"]?.isSuccess) {
            let data = salesManagementStore?.["getSalesDashboardOrderSummaryDetailData"]?.data;
            setExpandedRowData(data?.resource?.data);
        }
    }, [salesManagementStore?.["getSalesDashboardOrderSummaryDetailData"]?.isSuccess])

    // handle the response of the summmary api
    useEffect(() => {
        if (salesManagementStore?.["getSalesDashboardSummaryData"]?.isSuccess) {
            let data = salesManagementStore?.["getSalesDashboardSummaryData"]?.data;
            setSummaryResponse(salesManagementStore?.["getSalesDashboardSummaryData"]?.data || {});
            if (data?.resource?.currPage == 1) {
                setTableData(data?.resource?.data || []);
                setCurrentPage(1);
            } else {
                data?.resource?.data && setTableData([...tableData, ...data?.resource?.data])
                setCurrentPage(data?.resource?.currPage);
            }
        }
    }, [salesManagementStore?.["getSalesDashboardSummaryData"]?.isSuccess])

    useEffect(() => {
        // Items data filter change value
        getAllData(1);
    }, [itemsData, filterData])

    const getAllData = (pageNo) => {
        let filters = {};
        Object.keys(filterData || {})?.map(key => {
            filters[key] = Object.keys(filterData?.[key] || {})?.join(',')
        })
        let brandPayload = {
            type: 1,
            pageNo: pageNo || 1,
            pageSize: itemsData?.itemLimit || 10,
            sortedBy: "",
            sortedIn: "",
            search: "",
            filter: filters || {},
            itemLevel: itemsData?.itemLevel || "asc",
            itemType: itemsData?.itemType,
            apiUrl: itemsData?.itemType?.toLowerCase() == "brand" ? "/admin/so/v2/orders/summary/brand" : "/admin/so/v2/orders/summary/customer"
        };
        dispatchHandler(dispatch, 'getSalesDashboardSummaryRequest', brandPayload)
    }

    // Function: To move the data to the next page
    const moveNextPage = () => {
        let maxPage = summaryResponse?.resource?.maxPage;
        if (Number(maxPage) && Number(currentPage) < Number(maxPage)) {
            getAllData(Number(currentPage) + 1)
        }
    }

    // Function : To update the sales dashbaord performancen analysis settings
    const updatePerformanceAnalysisData = (key, data) => {
        let updatedItemsData = { ...itemsData };
        updatedItemsData[key] = data;
        setItemsData(updatedItemsData);
        if (key == "itemType") {
            setFilterData({});
            setCurrentPage(1);
            setSummaryResponse({});
            setTableData([]);
            setExpandedRow(false);
            setExpandedRowData(false)
        }
    }

    // Function : To downloa the export excel data
    const handleExportExcel = () => {
        const filters = props?.getCurrentMainLevelFilters?.();
        let payload = {
            module: itemsData?.["itemType"] == "customer" ? "SO_DASHBOARD_CUSTOMER" : "SO_DASHBOARD_BRAND_DETAIL",
            data: {
                type: 1,
                pageNo: currentPage || 1,
                pageSize: itemsData?.itemLimit || 10,
                sortedBy: "",
                sortedIn: "",
                search: "",
                filter: {},
                itemLevel: itemsData?.["itemLevel"] || "asc",
                dateFrom: filters?.["orderDate"]?.[0] ? moment(filters?.["orderDate"]?.[0]) : "",
                dateTo: filters?.["orderDate"]?.[1] ? moment(filters?.["orderDate"]?.[1]) : "",
            }
        }
        if (payload?.data?.startDate == "") {
            delete payload.data.dateFrom
            delete payload.data.dateTo
        }
        dispatchHandler(dispatch, "downloadReportRequest", payload);
    }

    // toggle items buttons
    const toggleItemsButtons = () => {
        return (
            <React.Fragment>
                <Radio.Group value={itemsData?.["itemLevel"]} onChange={() => updatePerformanceAnalysisData("itemLevel", itemsData?.["itemLevel"] == "asc" ? "desc" : "asc")}>
                    <Radio.Button value={"asc"}><LanguageTranslator>Top</LanguageTranslator></Radio.Button>
                    <Radio.Button value={"desc"}><LanguageTranslator>Bottom</LanguageTranslator></Radio.Button>
                </Radio.Group>

                <Radio.Group value={itemsData?.["itemType"]} onChange={() => updatePerformanceAnalysisData("itemType", itemsData?.["itemType"] == "brand" ? "customer" : "brand")}>
                    <Radio.Button value={"brand"}><LanguageTranslator>Brand</LanguageTranslator></Radio.Button>
                    <Radio.Button disabled={parseJwt(sessionStorage.getItem('token'))?.uType != "ENT"} value={"customer"}><LanguageTranslator>Customer</LanguageTranslator></Radio.Button>
                </Radio.Group>
                <GenericInput
                    key="itemLimit"
                    iconRight={<Icons.DownArrowNew />}
                    value={itemsData?.["itemLimit"] || 10}
                    onChange={(e) => updatePerformanceAnalysisData("itemLimit", e?.limit)}
                    type="dropdown"
                    inputProps={() => ({
                        headerKeys: { "limit": {} },
                        itemKeys: { "limit": ["limit"] },
                        getListData: () => ([
                            { limit: 5 }, { limit: 10 }, { limit: 20 }, { limit: 50 }, { limit: 100 },
                        ])
                    })}
                />
            </React.Fragment>
        )
    }

    const inputPropsMachine = (filter) => {
        return {
            viewDataType: "table-view",
            modalMainClass: "use-as-generic-dropdown",
            search: {
                placeholder: `Search ${filter?.value}`,
                searchOptions: {
                    "CONTAINS": "Contains",
                    "STARTS_WITH": "Starts With",
                    "EXACT_MATCH": "Exactly Matched"
                },
                searchOptionLabel: "Search By",
            },
            headerKeys: {
                multiSelectCol: {},
                [filter?.key]: {},
            },
            itemKeys: {
                [filter?.key]: [filter?.key],
            },
            multiselect: {
                selectId: `${filter?.key}`,
                selectedData: filterData?.[filter?.storeCode] || {}
            },
            api: {
                reduxState: "generalStore",
                apiName: "getSearchFilters",
                apiPayload: (params) => {
                    const otherData = props?.getFiltersOtherData(filter, filterData);
                    // console.log("others data", otherData);
                    return {
                        "entity": filter?.entity,
                        "key": filter.key,
                        "code": "",
                        "search": params?.currentSearch || "",
                        "searchBy": params.searchFilter || "STARTS_WITH",
                        "pageNo": params?.currentPage || 1,
                        "moduleName": "",
                        "other_data": otherData,
                        "columnName": {}
                    }
                },
                getMaxPage: (states, functions, response) => {
                    return Number(response?.resource?.maxPage || "");
                }
            },
            getListData: (data) => data?.resource?.searchResultNew || [],
        }
    }

    // Function  to provide the filter inputs fields
    const provideFilterInputs = () => {
        let availableFilters = itemsData?.["itemType"] == "brand" ? MAIN_FILTERS : CUSTOMER_FILTERS;
        return availableFilters?.map(filter => ({
            key: filter.storeCode,
            type: filter.key == "orderDate" ? "range" : "dropdown",
            inputProps: () => inputPropsMachine(filter),
            iconRight: filter?.key == "orderDate" ? null : <Icons.GlobalSearchIcon />,
            placeholder: filter.key != "orderDate" ? filter?.value : ""
        }))
    }

    // Function to render the primary filters data
    const renderPrimaryFilters = () => {
        return (
            <div className='sds-filter'>
                <GenericCustomButton
                    className='sdsf-btn'
                    leftIcon={<Icons.DataExportFileIconNew />}
                    onClick={() => handleExportExcel()}
                    tooltip={"Export To Excel"}
                    disabled={salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading || generalStore?.["downloadReportData"]?.isLoading}
                />
                <GenericFormComponent
                    rowInput={{
                        rowType: "singleRow",
                        availableInputs: provideFilterInputs(),
                        inputState: { ...filterData },
                        inputStateChangeFunction: (data) => { setFilterData(data) },
                        rowRightExtras: <React.Fragment>
                            <GenericCustomButton
                                label="Clear All"
                                type="default"
                                onClick={() => setFilterData({})}
                                disabled={() => salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading || Object.keys(filterData || {})?.length == 0}
                            />
                        </React.Fragment>
                    }}
                />
            </div>
        )
    }

    // Function : To handle the expand and collapse of the row
    const handleExpand = (item) => {
        if (expandedRow?.uuid == item?.uuid) {
            setExpandedRow(false);
        } else {
            setExpandedRow(item);
        }
        setExpandedRowData(false);
    }

    const renderOrderSummaryTable = () => {
        const headersData = summaryResponse?.resource?.excelHeaders;
        return <div className='table-component-new-style '>
            {currentPage == 1 && salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading  && (tableData?.length == 0) && <NewTableLoader />}
            {!salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading && (tableData?.length == 0) &&
                <div className='tcns-no-data'>
                    <LanguageTranslator tag="p">No Data Found!</LanguageTranslator>
                </div>}
            {!(currentPage == 1 && tableData?.length == 0  &&  salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading) &&
                <div className='tableBody-border'>
                    <div className="invoice-manage-table">
                        <div className='imt-manage-table'>
                            <table className="table imt-main-table">
                                <thead>
                                    <tr>
                                        <th className="fix-action-btn"></th>
                                        {Object.keys(headersData || {})?.map(key => <th>
                                            <div className='td-header'>
                                                <label>{headersData?.[key]}</label>
                                            </div>
                                        </th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData?.map(item =>
                                            <React.Fragment>
                                                <tr >
                                                    <td className="fix-action-btn">
                                                        <ul className="table-item-list">
                                                            <GenericCustomButton
                                                                leftIcon={expandedRow?.["uuid"] && (expandedRow?.["uuid"] == item["uuid"]) ? <Icons.ExpandedTableIcon /> : <Icons.NotExpandedTableIcon />}
                                                                tooltip={expandedRow?.["uuid"] && (expandedRow?.["uuid"] == item["uuid"]) ? "Collapse" : "Expand"}
                                                                onClick={() => handleExpand(item)}
                                                            />
                                                        </ul>
                                                    </td>
                                                    {Object.keys(headersData || {})?.map(key =>
                                                        <td><label>{item[key]}</label></td>
                                                    )}
                                                </tr>
                                                {expandedRow?.["uuid"] && (expandedRow?.["uuid"] === item["uuid"]) ?
                                                    salesManagementStore?.["getSalesDashboardOrderSummaryDetailData"]?.isLoading ?
                                                        <tr className="spatf-expanded-row"><td colSpan={100}><LanguageTranslator tag="label">Fetching Data...</LanguageTranslator></td></tr>
                                                        : !expandedRowData ?
                                                            <tr className="spatf-expanded-row"><td colSpan={100}><LanguageTranslator tag="label">No Data Found</LanguageTranslator></td></tr>
                                                            : expandedRowData?.map(detail =>
                                                                <tr className="spatf-expanded-row">
                                                                    <td colSpan={1}></td>
                                                                    {Object.keys(headersData).map(key =>
                                                                        <td><LanguageTranslator tag="label">{detail[key]}</LanguageTranslator></td>
                                                                    )}
                                                                </tr>
                                                            ) : null}
                                            </React.Fragment>
                                        )
                                    }
                                        {(salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading) && <tr>
                                            <td colSpan={8}>
                                                <div className='imt-spinner'>
                                                    <GenericSpinnerLoader />
                                                </div>
                                            </td>
                                        </tr>}
                                    <div style={{ width: "10px", height: "1px", backgroundColor: "red" }} ref={lastListElementRef}></div>
                                </tbody>
                            </table>
                        </div>
                        {/* {(salesManagementStore?.["getSalesDashboardSummaryData"]?.isLoading) &&
                            <div className='imt-spinner'>
                                <GenericSpinnerLoader />
                            </div>
                        } */}
                    </div>
                </div>
            }
        </div>
    }


    return (<div className='sd-sec m-top-12'>
        <LanguageTranslator tag="h3">Orders Summary</LanguageTranslator>
        <div class="sds-top">
            {/* Render the above toggle items and buttons */}
            {toggleItemsButtons()}
        </div>

        {renderPrimaryFilters()}

        {renderOrderSummaryTable()}

    </div>
    )
}

export default SalesOrderSummary