// CombinedProvider.js
import React, { useState } from 'react';

let CollectionsContext = React.createContext({});

const CombinedProvider = ({ children }) => {

    // Collections Context states

    const [collectionsConfigurations, setCollectionsConfigurations] = useState({});

    // -- Collections Context States Ends

    return (
        <CollectionsContext.Provider value = {{
            collectionsConfigurations, setCollectionsConfigurations,
        }}>
            {children}
        </CollectionsContext.Provider>
    );
};

export {
    CombinedProvider,
    CollectionsContext,
}
