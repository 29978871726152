const generateUpperCase = (actionPrefix) => {
    const firstItemPattern = /([a-z]*)/;
    const capitalItemPattern = /([A-Z][^A-Z]*)/g;
    const firstItem = actionPrefix.match(firstItemPattern)[0].toUpperCase();
    const lastItem = actionPrefix.match(capitalItemPattern)
        ? actionPrefix
            .match(capitalItemPattern)
            .map((item) => item.toUpperCase())
            .join("_")
        : "";
    const separator = lastItem ? "_" : "";
    return `${firstItem}${separator}${lastItem}`;
};

const generateInitialState = (allState, stateName, initialStateData) => {
    const tempState = {};
    allState[stateName].forEach((item) => {
        const { title } = item;
        tempState[title] = initialStateData;
    });
    return tempState;
};

export { generateUpperCase, generateInitialState };
