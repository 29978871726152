/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react'
import GenericFormComponent from '../../../genericComponents/forms/GenericFormComponent'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import Icons from '../../../assets/icons';
import { Radio } from 'antd';
import ProcDashBottomCards from './ProcDashBottomCards';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import { useDispatch } from 'react-redux';
import '../../../styles/stylesheets/digiProc/ProcDashboard.scss'
import LanguageTranslator from '../../../locals/LanguageTranslator';

const FILTERS = [
    {
        label: "Site",
        key: "SiteName",
        code: "SiteCode",
    },
    {
        label: "Vendor",
        key: "slName",
        code: "slCode",
    },
]
const PRIMARY_FILTERS = [
    {
        label: "Division",
        key: "hl1Name",
        uniqueKey: 'HL1GET',
        code: "hl1Code",
    }, {
        label: "Section",
        key: "hl2Name",
        uniqueKey: 'HL2GET',
        code: "hl2Code",
    }, {
        label: "Department",
        key: "hl3Name",
        uniqueKey: 'HL3GET',
        code: "hl3Code",
    }, {
        label: "Article",
        key: "hl4Name",
        uniqueKey: 'HL4GET',
        code: "hl4Code",
    }
]
let headerKeys = {
    "division": {
        "hl1Name": {},
    },
    "section": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
    },
    "department": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
        "hl3Name": {
            value: "Department",
        }
    },
    "article": {
        "hl1Name": {
            value: "Division",
        },
        "hl2Name": {
            value: "Section",
        },
        "hl3Name": {
            value: "Department",
        },
        "hl4Name": {
            value: "Article",
        }
    }
}
let itemKeys = {
    "division": {
        "hl1Name": ["hl1Name"],
    },
    "section": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
    },
    "department": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
        "hl3Name": ["hl3Name"],
    },
    "article": {
        "hl1Name": ["hl1Name"],
        "hl2Name": ["hl2Name"],
        "hl3Name": ["hl3Name"],
        "hl4Name": ["hl4Name"],
    }
}

const ProcurementDashboard = (props) => {
    const dispatch = useDispatch()
    const [inputData, setInputData] = useState({
        dateRange: '',
        site: { SiteName: '', SiteCode: '', site: '' },
        vendor: { slCode: '', slName: '', vendor: '' },
        division: { division: '', hl1Name: '', hl1Code: '' },
        section: { section: '', hl1Name: '', hl2Name: '', hl1Code: '', hl2Code: '' },
        department: { department: '', hl1Name: '', hl2Name: '', hl1Code: '', hl2Code: '', hl3Code: '', hl3Name: '' },
        article: { article: '', hl1Name: '', hl2Name: '', hl1Code: '', hl2Code: '', hl3Code: '', hl3Name: '', hl4Name: '', hl4Code: '' },
    })
    const [errorData, setErrorData] = useState({})
    const [procDashboardType, setProcDashboardType] = useState('pi')
    const [toggleChanged, setToggleChanged] = useState(false)
    const [isdisable, setIsDisable] = useState(true)

    // function to get the payloads
    const getPayloads = (key, params, getBy) => {
        let payload = {}
        switch (key) {
            case 'Site':
                payload = {
                    isPO: procDashboardType === "pi" ? false : true,
                    fromDate: '',
                    toDate: '',
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    pageNo: params?.currentPage || 1,
                    apiUrl: '/procurement/dashboard/get/site'
                }
                break;
            case 'Vendor':
                payload = {
                    isPO: procDashboardType === "pi" ? false : true,
                    fromDate: '',
                    toDate: '',
                    type: params?.currentSearch.length === 0 ? 1 : 3,
                    pageNo: params?.currentPage || 1,
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    siteCode: inputData?.site?.SiteCode || "",
                    apiUrl: '/procurement/dashboard/get/sl'
                }
                break
            case 'Division':
            case 'Section':
            case 'Department':
            case 'Article':
                payload = {
                    type: params?.currentSearch.length === 0 ? 1 : 3,
                    isPO: procDashboardType === "pi" ? false : true,
                    "hl1Name": inputData?.division?.hl1Name || "",
                    "hl2Name": inputData?.section?.hl2Name || "",
                    "hl3Name": inputData?.department?.hl3Name || "",
                    "hl4Name": inputData?.article?.hl4Name || "",
                    fromDate: inputData?.dateRange?.[0]?.format('YYYY-MM-DD') || '',
                    toDate: inputData?.dateRange?.[1]?.format('YYYY-MM-DD') || '',
                    slCode: inputData?.vendor?.slCode || '',
                    getBy: getBy,
                    pageNo: params?.currentPage || 1,
                    search: params?.currentSearch || "",
                    searchBy: params.searchFilter || "CONTAINS",
                    siteCode: inputData?.site?.SiteCode || "",
                    apiUrl: '/procurement/dashboard/get/hierarachylevels'
                }
                break
            default: return payload
        }
        return payload
    }

    const handleFilterChange = (type, data) => {
        let { value, state } = data;
        setIsDisable(false);
        if(!value){
            switch (type) {
                case "division":{
                    delete state.section;
                }
                case 'section': {
                    delete state.department;
                }
                case 'department': {
                    delete state.article
                    break;
                }
                default:
                    break;
            }
        }else{
            switch (type) {
                case 'section': {
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                    }
                    break;
                }
                case 'department': {
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                        section: { hl2Name: value?.hl2Name, hl2Code: value?.hl2Code }
                    }
                    break;
                }
                case 'article':
                    state = {
                        ...state,
                        division: { hl1Name: value?.hl1Name, hl1Code: value?.hl1Code },
                        section: { hl2Name: value?.hl2Name, hl2Code: value?.hl2Code },
                        department: { hl3Name: value?.hl3Name, hl3Code: value?.hl3Code }
                    }
                    break;
                default:
                    break;
            }
        }

        if(!value){
            handleApplyFilter(state)
        }

        console.log("updated state",  state);

        return state;
    };

    const handleApplyFilter = (state = inputData) => {
        console.log("sdfsdf", state);
        let payload = {
            isPO: procDashboardType === "pi" ? false : true,
            fromDate: state?.dateRange?.[0]?.format('YYYY-MM-DD') || '',
            toDate: state?.dateRange?.[1]?.format('YYYY-MM-DD') || '',
            expireDays: "",
            siteCode: state?.site?.SiteCode || '',
            slCode: state?.vendor?.slCode || '',
            hl1Code: state?.division?.hl1Code || "",
            hl2Code: state?.section?.hl2Code || "",
            hl3Code: state?.department?.hl3Code || "",
            hl4Code: state?.article?.hl4Code || "",
            isToggle: toggleChanged === true ? true : false,
        }

        dispatchHandler(dispatch, 'piDashBottomRequest', payload)
    }


    const handleChange = (type, data) => {
        let { value, state } = data;
        switch (type) {
            case 'dateRange':
            case 'filterChange':
                setIsDisable(false)
                break;

            default:
                break;
        }

        if(!value){
            handleApplyFilter(state)
        }
    }
    const provideInputs = () => {
        let inputFieldsData = [
            { key: "dateRange", type: "range", placeholder: ['Start Date', 'End Date'], onChange: (value, state) => handleChange('dateRange',  {
                value : value,
                state : state,
            } ) },]
        inputFieldsData.push(...FILTERS.map((config) => {
            return {
                key: config?.label?.toLowerCase(),
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `Select ${config?.label}`,
                type: 'dropdown',
                onChange: (value, state) => handleChange('filterChange', {
                    value : value,
                    state : state,
                }),
                inputProps: () => ({
                    search: {
                        placeholder: `Search ${config?.label}`,
                    },
                    viewDataType: "table-view",
                    api: {
                        reduxState: 'digiProcStore',
                        apiName: "piPoDashboardFilter",
                        apiPayload: (params) => getPayloads(config?.label, params),
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: response => response?.resource?.map(data => ({ [config.label.toLowerCase()]: data[config.key], ...data })),
                    headerKeys: {
                        [config.label.toLowerCase()]: { value: [config.label] },
                        [config.code]: { value: 'Code' },
                    },
                    itemKeys: {
                        [config.label.toLowerCase()]: [config.key],
                        [config.code]: [config.code]
                    },
                }),

            }
        }))

        inputFieldsData.push(...PRIMARY_FILTERS.map((config) => {
            return {
                key: config?.label?.toLowerCase(),
                iconRight: <Icons.DownArrowFilled />,
                viewDataType: "table-view",
                modalMainClass: "use-as-generic-dropdown",
                placeholder: `Select ${config?.label}`,
                type: 'dropdown',
                customInputValue: (data) => data?.[config?.key] || "",
                onChange: (val, state) => {
                    return handleFilterChange(config?.label?.toLowerCase(), {
                        value: val,
                        state: state
                    })
                },
                inputProps: () => ({
                    search: {
                        placeholder: `Search ${config?.label}`,
                    },
                    viewDataType: "table-view",
                    api: {
                        reduxState: 'digiProcStore',
                        apiName: "piPoDashboardFilter",
                        apiPayload: (params) => getPayloads(config?.label, params, config?.uniqueKey),
                        getMaxPage: (states, functions, response) => Number(response?.resource?.maxPage || ""),
                    },
                    getListData: response => response?.resource?.map(data => ({ ...data })),
                    headerKeys: headerKeys?.[config?.label?.toLowerCase()],
                    itemKeys: itemKeys?.[config?.label?.toLowerCase()],
                }),

            }
        }))
        return inputFieldsData
    }

    const handleProcType = (data) => {
        setProcDashboardType(data?.target?.value)
        setToggleChanged(true)
    }

    const handleClearFilters = () => {
        setInputData({})
        setIsDisable(true)
        handleApplyFilter({})
    }

    // Function to render the primary filters data
    const renderPrimaryFilters = () => {
        return (
            <>
                <Radio.Group value={procDashboardType} onChange={handleProcType}>
                    <Radio.Button value={"pi"}>PI</Radio.Button>
                    <Radio.Button value={"po"}><LanguageTranslator>PO</LanguageTranslator></Radio.Button>
                </Radio.Group>
                <GenericFormComponent
                    rowInput={{
                        rowType: "singleRow",
                        availableInputs: provideInputs(),
                        inputState: { ...inputData },
                        inputStateChangeFunction: (data) => {
                            setInputData(data);
                        },
                        errorData: { ...errorData },
                        updateErrorData: (data) => {
                            setErrorData(data);
                        },
                        rowRightExtras: <React.Fragment>
                            <div className='gfbi-btns'>
                                <GenericCustomButton
                                    label='Apply Filter'
                                    type='primary'
                                    onClick={() => handleApplyFilter()}
                                    disabled={isdisable}

                                />
                                <GenericCustomButton
                                    label="Clear All"
                                    type="default"
                                    onClick={handleClearFilters}
                                    disabled={isdisable}
                                />
                            </div>
                        </React.Fragment>,
                    }}
                    availableDocument={props.availableDocument || null}
                />
            </>
        )
    }

    let startDate = inputData?.dateRange?.[0]?.format('YYYY-MM-DD') || '';
    let endDate = inputData?.dateRange?.[1]?.format('YYYY-MM-DD') || '';

    let filter = {
        ...(startDate && endDate) ?
            (procDashboardType === 'pi' ?
                { indentDate: startDate + '|' + endDate } :
                { createdTime: startDate + '|' + endDate })
            : undefined,
            siteName: inputData?.site?.SiteName || undefined,
        slName: inputData?.vendor?.slName || undefined,
        siteCode: inputData?.site?.SiteCode || undefined
    }
    let filterItems = {
        hl1Name: inputData?.division?.hl1Name || undefined,
        hl2Name: inputData?.section?.hl2Name || undefined,
        hl3Name: inputData?.department?.hl3Name || undefined,
        hl4Name: inputData?.article?.hl4Name || undefined,
    }
    Object.keys(filter || {})?.map((key) => {
        if (!filter?.[key]) {
            delete filter[key]
        }
    })
    Object.keys(filterItems || {})?.map((key) => {
        if (!filterItems?.[key]) {
            delete filterItems[key]
        }
    })

    return (
        <div className="proc-dashboard">
            {renderPrimaryFilters()}
            <ProcDashBottomCards filterItems={filterItems} filter={filter} procDashboardType={procDashboardType} toggleChanged={toggleChanged} />
        </div>
    )
}

export default ProcurementDashboard