import React, { useEffect } from 'react'
import qz from 'qz-tray'
import { RSAKey, KJUR, stob64, hextorstr, sign, KEYUTIL } from 'jsrsasign'

const QzTraySecurityChecker = () => {

    useEffect(() => {

        qz.security.setSignatureAlgorithm("SHA512");

        qz.security.setCertificatePromise(function (resolve, reject) {
            resolve("-----BEGIN CERTIFICATE-----\n" +
                "MIIEGTCCAwGgAwIBAgIUYsfvZcb9Lbf0ZL/gKyo8vhFfCSEwDQYJKoZIhvcNAQEL\n" +
                "BQAwgZoxCzAJBgNVBAYTAklOMQswCQYDVQQIDAJIUjERMA8GA1UEBwwIR3VydWdy\n" +
                "YW0xEzARBgNVBAoMClN1cHBseW1pbnQxFTATBgNVBAsMDFR1cm5pbmdDbG91ZDEZ\n" +
                "MBcGA1UEAwwQKi5zdXBwbHltaW50LmNvbTEkMCIGCSqGSIb3DQEJARYVc3VtZWV0\n" +
                "QHN1cHBseW1pbnQuY29tMCAXDTI0MDcyNTA1MTg1NFoYDzIwNTYwMTE4MDUxODU0\n" +
                "WjCBmjELMAkGA1UEBhMCSU4xCzAJBgNVBAgMAkhSMREwDwYDVQQHDAhHdXJ1Z3Jh\n" +
                "bTETMBEGA1UECgwKU3VwcGx5bWludDEVMBMGA1UECwwMVHVybmluZ0Nsb3VkMRkw\n" +
                "FwYDVQQDDBAqLnN1cHBseW1pbnQuY29tMSQwIgYJKoZIhvcNAQkBFhVzdW1lZXRA\n" +
                "c3VwcGx5bWludC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC6\n" +
                "QlKFNL/BTwDIx0pAKENtJmKDt/IaxfM4Y7uMU2LnhL0Swcb/OBNjvr1CB8SHLJzY\n" +
                "/QknJ7r8VGrQyHWUh9ZGru6Phst2ZEpanE6fKA9GTYD76yyHpqYInIozS9kOyBo3\n" +
                "Fv2IFSrt2DlxaZoe+D7OeyWRbLYJ/gj9XI8pq4AaW6XETIXCuLhL2z4ZrNUDHcMF\n" +
                "8u0mhTDhgtGZDSIiWFwHreRJKwFsM76AZG4lP6LpKPr6ek25Zzrw7sYd7uKtsRXY\n" +
                "LM6+gfqgYkrOjsE7RSvkORFnKvWh7A3fGv5bnHs3+fSdm5mMCwtwYoR/8ni4HQ4D\n" +
                "lRXEkd7Q2F9FP8j6BhGLAgMBAAGjUzBRMB0GA1UdDgQWBBRZzhYqNMlKQVTJ6cFh\n" +
                "h5YJou6vqTAfBgNVHSMEGDAWgBRZzhYqNMlKQVTJ6cFhh5YJou6vqTAPBgNVHRMB\n" +
                "Af8EBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQBqlvUg2okXkrXeuu6U2QZL8XBE\n" +
                "W2kvwk/86QbHnqyZvvfP1ZTY4+cOfArJVe2QG6ot63jkDO/pRl/nLV82oPQBVyzu\n" +
                "5NVOS84uj9mOSOD4yw86a+tkRWZ6VN1ib3PmwuIZpEYd8FibbzlROjFTHjsQzMsu\n" +
                "xMv8ky1jze+ZVut5ToTGctxHFvAjWn8WDVMtyknvpLPFpaAMBQNJRu6vBioolm3O\n" +
                "N/NHrx4z9LZ3ErDqDnHx+kL6Ky5kq0IhMOZWerbegCOSI85TPx6j9wA0gGGYPO4q\n" +
                "NzZuwHU0vxwh5QsvEqaHhRelf000QglmDA0H2IztgnhQ5oSxb0i37gF2+Z2q\n" +
                "-----END CERTIFICATE-----");
        });

        var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
            "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC6QlKFNL/BTwDI\n" +
            "x0pAKENtJmKDt/IaxfM4Y7uMU2LnhL0Swcb/OBNjvr1CB8SHLJzY/QknJ7r8VGrQ\n" +
            "yHWUh9ZGru6Phst2ZEpanE6fKA9GTYD76yyHpqYInIozS9kOyBo3Fv2IFSrt2Dlx\n" +
            "aZoe+D7OeyWRbLYJ/gj9XI8pq4AaW6XETIXCuLhL2z4ZrNUDHcMF8u0mhTDhgtGZ\n" +
            "DSIiWFwHreRJKwFsM76AZG4lP6LpKPr6ek25Zzrw7sYd7uKtsRXYLM6+gfqgYkrO\n" +
            "jsE7RSvkORFnKvWh7A3fGv5bnHs3+fSdm5mMCwtwYoR/8ni4HQ4DlRXEkd7Q2F9F\n" +
            "P8j6BhGLAgMBAAECggEABQG/mYbgX4NoQePT89aOCj/KZsA8/1ale5urhBGnxBGp\n" +
            "SkXjbtZK9L1aj0BWZzKeHFns8ylm8t2xqcW06qNRV/QS3Wo7sbjvcL3otSMmcF1C\n" +
            "Y6mf0eoUzRpCjGKnePWMrHQ8Dq22/WhfEiF+SM/WhflnrWzIYr6T7xYCX5XiUZMF\n" +
            "g20Gt3QFpGJha4QycfgSXY0KAEChowvYyTGjfYRbc9IxIeq1d9rn0Q72c8YybErQ\n" +
            "LccuIJ0PS5GhQBiN6BiP++V6Tcc2qUKxMc+iss5ULnC1Mz5jN/YHgebYSIeMmcG4\n" +
            "w7UuyWG//EmOP/Eht4SapD1gODHkzjB8P4RSvwhE+QKBgQDxJQHnQYZ/AJVMbNjM\n" +
            "KlKtjT7iptsP7zq/zbVBTYdQlMUjrrXME4C/tyJDFmMdZaJZ4lcxwRgM9DKffejK\n" +
            "SXTkMt/feP/mAq++q7Mex+NaLLVTB63OpVGf21Zj1FCLt4UYDLS78hjgkPKwRpv3\n" +
            "cLGX7puAejHj8pwhyq2lV4QXWQKBgQDFu7z9Yh9VaVImWhkx9g6e8zAeA/9bZSAt\n" +
            "FBHH7k/zuOLUjcBaPzq8wrjq2lZ0f8HKd2z1BtP7Iz1VF8MTdUU8QnagETFHtlXB\n" +
            "nH7+0ibs2fwWRB0MBh1GdKeseoEWTMAd4WYlqvT6Dmzq1dCeVahTIKQhO3pxDfjs\n" +
            "1/fsMHo3gwKBgHww6wIGpRXTXxC1HhJH2by3+aMQ+9EBC+isMKUpIH9Hnx/5Yndv\n" +
            "s+cIkgSrCvCg3zQbdXySTaieO1QYrENCAYllChc1YdU7nPc9xkob9+MBE4FnewPp\n" +
            "8Fe7xoozVigeo9T29j21OUquW86dDsYoq83rfPp19CvJTs+4aiW1JLpBAoGAdyMh\n" +
            "wM2hMXYu4BrDOX4W2yCNqlggQLISi/aerAbjt2Z7jTQHZJjaTCdLAX4iPzlGyzXf\n" +
            "uVau3I8kAiQ6DnxHBgLJtLIHLj0nOGuRfPmkKGtC3yuVEMySUQQyjtKU+pmt25MW\n" +
            "V7XEuvF8IJZJh0z3CXcOTVOn93xCsnmB0JZ43P8CgYBsI4DXqq82tBUS9276IVvm\n" +
            "pJUWQSKMi4lijDbIpw59U3vggEwCeuFwmBD0r3CalVsuRKaaU+4wvvF7zVl1SG43\n" +
            "Y9IuHfztqgsyRXgECmEXD8qIV4ouPrZghyzITgVN9WNx3XXDCS4HAxdxcg/um8O3\n" +
            "eDM8MbJWb1N1osUxHJnKqQ==\n" +
            "-----END PRIVATE KEY-----"

            qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
            qz.security.setSignaturePromise(function(toSign) {
                return function(resolve, reject) {
                    try {
                        var pk = KEYUTIL.getKey(privateKey);
                        var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                        sig.init(pk); 
                        sig.updateString(toSign);
                        var hex = sig.sign();
                        console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                        resolve(stob64(hextorstr(hex)));
                    } catch (err) {
                        console.error(err);
                        reject(err);
                    }
                };
            });

        // qz.security.setSignaturePromise( function() { return function(resolve) { resolve(); } })


    function strip(key) {
        if (key.indexOf('-----') !== -1) {
            return key.split('-----')[2].replace(/\r?\n|\r/g, '');
        }
    }
}, [])

return (
    <React.Fragment>

    </React.Fragment>
) 
}

export default QzTraySecurityChecker