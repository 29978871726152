import React from 'react'
import { renderUpgradePopup } from '../../helper/components/CacheChecker';
import {useClearCache} from 'react-clear-cache'

const UpgradePopUpRender = () => {
    const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache();
    console.log("LAtest version", isLatestVersion);
  return (
    renderUpgradePopup({
        isLatestVersion : false,
        emptyCacheStorage,
        renderFromModal: true,
        latestVersion
    })
  )
}

export default UpgradePopUpRender