import React, { Component } from 'react'
import { CONFIG } from '../../config/index'
import axios from 'axios';
import './QrScanStyles.scss'
import LanguageTranslator from '../../locals/LanguageTranslator';

class QrScan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: "ProductDetail",
            mainData: []
        };
    }

    componentDidMount() {
        if (window.location.hash && window.location.hash.includes("=")) {
            const hash = window.location.hash.split("?")[1];
            let newHash = hash, result = "";
            const refIdMatch = newHash.split('='); // Split once to get the refId
            const refId = refIdMatch ? refIdMatch[1] : null;
            if (refId) {
                if (refId?.includes("%25")) {
                    result = decodeURIComponent(refId?.replace(/%25|25%/g, ''));
                } else if (refId?.includes("%")) {
                    result = decodeURIComponent(refId?.replace(/%/g, ''));
                } else {
                    result = refId;
                } axios.get(`${CONFIG?.BASE_URL}/open/key/get/qrcode/detail/${result}`).then((responce) => {
                    console.log(7777, responce.data.data.resource[0])
                    this.setState({ mainData: responce.data && responce.data.data && responce.data.data.resource && responce.data.data.resource[0] })
                }).catch((error) => {
                    console.log(7777777, error)
                })

                // return "unknown";
                // if(navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod'){
                //     let newHash = this.props.location.search;
                //     const refIdMatch = newHash.match(/[?&]refId=([^&]+)/);
                //     const refId = refIdMatch ? refIdMatch[1] : null;
                //     let result = refId.replace(/%25|25%/g, '');
                //     console.log(99999,newHash)
                //     axios.get(`${CONFIG.BASE_URL}/open/key/get/qrcode/detail/${result}`).then((responce) => {
                //         console.log(7777, responce.data.data.resource[0])
                //         this.setState({ mainData:responce.data && responce.data.data && responce.data.data.resource && responce.data.data.resource[0] })
                //     }).catch((error) => {
                //         console.log(7777777, error)
                //     })
                // }
                // else{
                //     let newHash = this.props.location.search;
                //     const refIdMatch = newHash.match(/[?&]refId=([^&]+)/);
                //     const refId = refIdMatch ? refIdMatch[1] : null;
                //     let result = refId.replace(/%|%/g, '');
                //     console.log(99999,newHash)
                //     axios.get(`${CONFIG.BASE_URL}/open/key/get/qrcode/detail/${result}`).then((responce) => {
                //         console.log(7777, responce.data.data.resource[0])
                //         this.setState({ mainData:responce.data && responce.data.data && responce.data.data.resource && responce.data.data.resource[0] })
                //     }).catch((error) => {
                //         console.log(7777777, error)
                //     })
                // }

            }
        }
    }

    render() {
        // console.log(9999999, this.state.mainData.productDetail && this.state.mainData.productDetail.hl1Name)
        // const constSheetData = this.state.mainData.costSheetDets && this.state.mainData.costSheetDets.map((item) => item.materialDets)
        // console.log(8888888,constSheetData )
        // console.log(999999, this.state.mainData.productDetail.imageUrlFront)

        return (
            <div className="modal cost-sheet-new-modal">
                <div className="csn-head">
                    <svg xmlns="http://www.w3.org/2000/svg" width="138" height="30" viewBox="0 0 138 30" fill="none">
                        <rect x="0.324219" y="0.796875" width="28.6602" height="28.6602" rx="4.77669" fill="#5D5FEF"></rect>
                        <rect x="13.8018" y="8.35156" width="1.27947" height="6.82385" fill="white"></rect>
                        <rect x="13.7607" y="7.33594" width="1.27947" height="11.3167" transform="rotate(51.8154 13.7607 7.33594)" fill="white"></rect>
                        <rect x="14.0225" y="15.4062" width="1.27947" height="9.50726" transform="rotate(33.096 14.0225 15.4062)" fill="white"></rect>
                        <rect x="5.1123" y="15.3828" width="1.27947" height="9.50726" transform="rotate(-24.7049 5.1123 15.3828)" fill="white"></rect>
                        <rect x="23.0518" y="14.8125" width="1.27947" height="8.9592" transform="rotate(27.4657 23.0518 14.8125)" fill="white"></rect>
                        <rect x="4.80078" y="15.9453" width="1.27947" height="16.5532" transform="rotate(-62.2575 4.80078 15.9453)" fill="white"></rect>
                        <rect x="23.8213" y="15.1328" width="1.27947" height="16.6877" transform="rotate(62.9104 23.8213 15.1328)" fill="white"></rect>
                        <circle cx="14.6542" cy="7.50284" r="1.70596" fill="white"></circle>
                        <circle cx="14.6542" cy="15.1825" r="1.70596" fill="white"></circle>
                        <circle cx="6.12491" cy="15.1825" r="1.70596" fill="white"></circle>
                        <circle cx="9.53702" cy="22.8544" r="1.70596" fill="white"></circle>
                        <circle cx="23.1845" cy="15.1825" r="1.70596" fill="white"></circle>
                        <circle cx="19.7724" cy="22.8544" r="1.70596" fill="white"></circle>
                        <path d="M39.6483 20.7124C38.9286 20.7124 38.278 20.5891 37.6963 20.3427C37.1246 20.0962 36.6711 19.7413 36.3359 19.278C36.0007 18.8147 35.8282 18.2675 35.8184 17.6366H38.0365C38.066 18.0605 38.2139 18.3957 38.4801 18.6421C38.7561 18.8886 39.1307 19.0118 39.6039 19.0118C40.087 19.0118 40.4665 18.8985 40.7425 18.6717C41.0186 18.4351 41.1566 18.1295 41.1566 17.7549C41.1566 17.4493 41.0629 17.1979 40.8756 17.0008C40.6883 16.8036 40.4517 16.6508 40.1658 16.5423C39.8898 16.4241 39.5053 16.2959 39.0124 16.1579C38.3421 15.9607 37.7949 15.7685 37.371 15.5812C36.957 15.384 36.5972 15.0932 36.2916 14.7087C35.9958 14.3144 35.8479 13.7919 35.8479 13.1413C35.8479 12.5301 36.0007 11.9977 36.3063 11.5442C36.6119 11.0908 37.0408 10.7457 37.5928 10.5091C38.1449 10.2627 38.7758 10.1395 39.4856 10.1395C40.5503 10.1395 41.4129 10.4007 42.0734 10.9232C42.7437 11.4358 43.1134 12.1555 43.1824 13.0821H40.9052C40.8855 12.7272 40.7327 12.4364 40.4468 12.2097C40.1707 11.9731 39.8011 11.8548 39.3377 11.8548C38.9335 11.8548 38.6082 11.9583 38.3618 12.1653C38.1252 12.3723 38.0069 12.673 38.0069 13.0673C38.0069 13.3434 38.0956 13.575 38.273 13.7623C38.4604 13.9398 38.6871 14.0877 38.9533 14.206C39.2293 14.3144 39.6138 14.4426 40.1067 14.5904C40.777 14.7876 41.3242 14.9848 41.7481 15.1819C42.172 15.3791 42.5367 15.6748 42.8423 16.0692C43.1479 16.4635 43.3007 16.981 43.3007 17.6218C43.3007 18.1739 43.1578 18.6865 42.8719 19.1597C42.586 19.6329 42.167 20.0124 41.615 20.2983C41.0629 20.5743 40.4073 20.7124 39.6483 20.7124Z" fill="#21314B"></path>
                        <path d="M53.2729 12.4167V20.6089H51.1879V19.5737C50.9217 19.9286 50.5717 20.2096 50.138 20.4166C49.7141 20.6138 49.2508 20.7124 48.748 20.7124C48.1072 20.7124 47.5404 20.5793 47.0474 20.3131C46.5545 20.0371 46.1651 19.6378 45.8793 19.1153C45.6032 18.583 45.4652 17.9521 45.4652 17.2226V12.4167H47.5354V16.9268C47.5354 17.5775 47.6981 18.0802 48.0234 18.4351C48.3487 18.7802 48.7923 18.9527 49.3543 18.9527C49.926 18.9527 50.3746 18.7802 50.6999 18.4351C51.0252 18.0802 51.1879 17.5775 51.1879 16.9268V12.4167H53.2729Z" fill="#21314B"></path>
                        <path d="M57.8293 13.5997C58.0955 13.2251 58.4603 12.9145 58.9236 12.6681C59.3968 12.4118 59.9341 12.2836 60.5354 12.2836C61.2353 12.2836 61.8663 12.4561 62.4282 12.8012C63 13.1462 63.4485 13.6391 63.7738 14.2799C64.109 14.9108 64.2766 15.6453 64.2766 16.4832C64.2766 17.3211 64.109 18.0654 63.7738 18.7161C63.4485 19.3569 63 19.8547 62.4282 20.2096C61.8663 20.5645 61.2353 20.7419 60.5354 20.7419C59.9341 20.7419 59.4017 20.6187 58.9384 20.3723C58.4849 20.1258 58.1152 19.8153 57.8293 19.4407V24.5127H55.7591V12.4167H57.8293V13.5997ZM62.162 16.4832C62.162 15.9903 62.0585 15.5664 61.8515 15.2115C61.6543 14.8467 61.3881 14.5707 61.053 14.3834C60.7276 14.1961 60.3727 14.1024 59.9883 14.1024C59.6137 14.1024 59.2588 14.201 58.9236 14.3982C58.5983 14.5855 58.3321 14.8615 58.1251 15.2263C57.9279 15.591 57.8293 16.0199 57.8293 16.5128C57.8293 17.0057 57.9279 17.4345 58.1251 17.7993C58.3321 18.164 58.5983 18.445 58.9236 18.6421C59.2588 18.8294 59.6137 18.9231 59.9883 18.9231C60.3727 18.9231 60.7276 18.8245 61.053 18.6274C61.3881 18.4302 61.6543 18.1492 61.8515 17.7845C62.0585 17.4197 62.162 16.986 62.162 16.4832Z" fill="#21314B"></path>
                        <path d="M68.2948 13.5997C68.561 13.2251 68.9257 12.9145 69.3891 12.6681C69.8623 12.4118 70.3995 12.2836 71.0009 12.2836C71.7008 12.2836 72.3317 12.4561 72.8936 12.8012C73.4654 13.1462 73.914 13.6391 74.2393 14.2799C74.5745 14.9108 74.7421 15.6453 74.7421 16.4832C74.7421 17.3211 74.5745 18.0654 74.2393 18.7161C73.914 19.3569 73.4654 19.8547 72.8936 20.2096C72.3317 20.5645 71.7008 20.7419 71.0009 20.7419C70.3995 20.7419 69.8672 20.6187 69.4039 20.3723C68.9504 20.1258 68.5807 19.8153 68.2948 19.4407V24.5127H66.2246V12.4167H68.2948V13.5997ZM72.6275 16.4832C72.6275 15.9903 72.524 15.5664 72.3169 15.2115C72.1198 14.8467 71.8536 14.5707 71.5184 14.3834C71.1931 14.1961 70.8382 14.1024 70.4537 14.1024C70.0791 14.1024 69.7242 14.201 69.3891 14.3982C69.0637 14.5855 68.7976 14.8615 68.5905 15.2263C68.3934 15.591 68.2948 16.0199 68.2948 16.5128C68.2948 17.0057 68.3934 17.4345 68.5905 17.7993C68.7976 18.164 69.0637 18.445 69.3891 18.6421C69.7242 18.8294 70.0791 18.9231 70.4537 18.9231C70.8382 18.9231 71.1931 18.8245 71.5184 18.6274C71.8536 18.4302 72.1198 18.1492 72.3169 17.7845C72.524 17.4197 72.6275 16.986 72.6275 16.4832Z" fill="#21314B"></path>
                        <path d="M78.7603 9.66627V20.6089H76.69V9.66627H78.7603Z" fill="#21314B"></path>
                        <path d="M89.0865 12.4167L84.0145 24.4831H81.8112L83.5857 20.4018L80.3029 12.4167H82.6245L84.7391 18.1394L86.8832 12.4167H89.0865Z" fill="#21314B"></path>
                        <path d="M100.82 12.2984C101.825 12.2984 102.634 12.6089 103.245 13.23C103.866 13.8412 104.176 14.6989 104.176 15.803V20.6089H102.106V16.0839C102.106 15.4432 101.944 14.9552 101.618 14.62C101.293 14.275 100.849 14.1024 100.287 14.1024C99.7255 14.1024 99.277 14.275 98.9418 14.62C98.6165 14.9552 98.4538 15.4432 98.4538 16.0839V20.6089H96.3836V16.0839C96.3836 15.4432 96.2209 14.9552 95.8956 14.62C95.5703 14.275 95.1267 14.1024 94.5647 14.1024C93.993 14.1024 93.5395 14.275 93.2043 14.62C92.879 14.9552 92.7163 15.4432 92.7163 16.0839V20.6089H90.6461V12.4167H92.7163V13.4074C92.9825 13.0624 93.3226 12.7913 93.7367 12.5941C94.1606 12.397 94.6239 12.2984 95.1267 12.2984C95.7674 12.2984 96.3392 12.4364 96.842 12.7124C97.3448 12.9786 97.7342 13.3631 98.0102 13.8659C98.2764 13.3927 98.6608 13.0131 99.1636 12.7272C99.6762 12.4413 100.228 12.2984 100.82 12.2984Z" fill="#21314B"></path>
                        <path d="M107.635 11.4407C107.27 11.4407 106.964 11.3274 106.718 11.1006C106.481 10.864 106.363 10.5732 106.363 10.2282C106.363 9.88315 106.481 9.59726 106.718 9.37052C106.964 9.13392 107.27 9.01562 107.635 9.01562C107.999 9.01562 108.3 9.13392 108.537 9.37052C108.783 9.59726 108.906 9.88315 108.906 10.2282C108.906 10.5732 108.783 10.864 108.537 11.1006C108.3 11.3274 107.999 11.4407 107.635 11.4407ZM108.655 12.4167V20.6089H106.585V12.4167H108.655Z" fill="#21314B"></path>
                        <path d="M115.684 12.2984C116.659 12.2984 117.448 12.6089 118.049 13.23C118.651 13.8412 118.952 14.6989 118.952 15.803V20.6089H116.881V16.0839C116.881 15.4333 116.719 14.9355 116.393 14.5904C116.068 14.2355 115.624 14.0581 115.062 14.0581C114.491 14.0581 114.037 14.2355 113.702 14.5904C113.377 14.9355 113.214 15.4333 113.214 16.0839V20.6089H111.144V12.4167H113.214V13.437C113.49 13.0821 113.84 12.8061 114.264 12.6089C114.698 12.4019 115.171 12.2984 115.684 12.2984Z" fill="#21314B"></path>
                        <path d="M123.774 14.1172V18.0802C123.774 18.3563 123.838 18.5583 123.966 18.6865C124.104 18.8048 124.331 18.864 124.647 18.864H125.608V20.6089H124.306C122.562 20.6089 121.689 19.761 121.689 18.0654V14.1172H120.713V12.4167H121.689V10.3908H123.774V12.4167H125.608V14.1172H123.774Z" fill="#21314B"></path>
                        <path d="M132.352 10.0234V10.6782H131.274V14.0681H130.463V10.6782H129.385V10.0234H132.352Z" fill="#21314B"></path>
                        <path d="M137.527 10.0234V14.0681H136.716V11.4373L135.632 14.0681H135.018L133.929 11.4373V14.0681H133.117V10.0234H134.039L135.325 13.0308L136.611 10.0234H137.527Z" fill="#21314B"></path>
                    </svg>
                </div>
                <div className="csn-head">
                    <h3>Product Details </h3>
                </div>
                <div className="csn-tab">
                    <button className={this.state.currentTab === "ProductDetail" && "sel-btn"} onClick={() => this.setState({ currentTab: "ProductDetail" })}>Product Detail</button>
                    <button className={this.state.currentTab === "fabricDetail" && "sel-btn"} onClick={() => this.setState({ currentTab: "fabricDetail" })}>Fabric Details</button>
                    {/* <button className={this.state.currentTab === "costSheet" && "sel-btn"} onClick={() => this.setState({ currentTab: "costSheet" })} >Cost Sheet</button> */}
                </div>
                {/* product detail */}
                {this.state.currentTab === "ProductDetail" &&
                    <div className="csn-body">
                        <h3>Basic Details</h3>
                        <div className='csnb'>
                            <div className="csnb-left">
                                <table>
                                    <thead >
                                        <tr>
                                            <th><label>Label</label></th>
                                            <th><label>Values</label></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><label>Hl1 Name</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state?.mainData?.productDetail && this.state.mainData?.productDetail?.hl1Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Hl2 Name</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.hl2Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Hl3 Name</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state.mainData?.productDetail && this.state?.mainData?.productDetail?.hl3Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Design Number</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.designGroupNumber} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Vendor Barcode</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state?.mainData?.productDetail && this.state.mainData?.productDetail?.vendorBarcode} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Season</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state?.mainData.productDetail && this.state?.mainData?.productDetail?.season} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Item Name</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.itemName} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Size</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.size} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Color</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        disabled
                                                        value={
                                                            this.state.mainData &&
                                                                this.state.mainData?.productDetail &&
                                                                this.state.mainData?.productDetail?.colorJson
                                                                ? this.state.mainData?.productDetail?.colorJson?.map((item) => item?.name)
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Description</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.description} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Brand</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.brand} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Pattern/Texture</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.pattern} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Fabric</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Neck/Fit</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.neck} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Usage</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Collection</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><label>Length</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.length} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Breadth</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state?.mainData?.productDetail && this.state.mainData?.productDetail?.breadth} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Height</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state?.mainData && this.state.mainData?.productDetail && this.state?.mainData?.productDetail?.height} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Weight</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.weight} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>MRP</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.mrp} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Cost Price</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled value={this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.costPrice} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Product Stage</label></td>
                                            <td className='vales-td'>
                                                <div className="imhl-search">
                                                    <input type="text" autoComplete="off" disabled />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="csnb-right">
                                <div className='w-100 hgt-300'>
                                    {this.state.mainData && this.state.mainData?.productDetail && this.state.mainData?.productDetail?.imageUrlFrontUrl ? (
                                        <img src={this.state.mainData?.productDetail?.imageUrlFrontUrl} className="w-100 h-100" />
                                    ) : (
                                        <LanguageTranslator tag="span">No image available</LanguageTranslator>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.currentTab === "fabricDetail" &&
                    <div className="csn-body plm-box">
                        {this.state?.mainData && this.state.mainData?.fabricDetail?.length !== 0 && this.state.mainData?.fabricDetail?.map((item, index) => (
                            <React.Fragment key={index}>
                                <LanguageTranslator tag="h1">Fabric Name</LanguageTranslator>
                                <LanguageTranslator tag="p">{item?.name}</LanguageTranslator>
                                <LanguageTranslator tag="h1" className='m-top-5'>Fabric code</LanguageTranslator>
                                <LanguageTranslator tag="p">{item?.fabricCode && item?.fabricCode}</LanguageTranslator>
                                <div className='plmb-inner'>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>Fabric Type</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.type && item?.type} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC COMPOSITION</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.composition && item?.composition} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC STRUCTURE</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.structure && item?.structure} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>YARN COUNT</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.yarn && item?.yarn} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>YARN FORM</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.fabricFrom && item?.fabricFrom} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC CONSTRUCTION</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.type && item?.type} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC WEIGHT</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.fabricWT && item?.fabricWT} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC WIDTH</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.fiberWidth && item?.fiberWidth} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC DYE TYPE</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.fabricDyeType && item?.fabricDyeType} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC DYE COLOR</LanguageTranslator></label>
                                        <input disabeld value={item?.color && item?.color} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>FABRIC FINISH</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.fabricFinish && item?.fabricFinish} />
                                    </div>
                                    <div className='plmbi'>
                                        <LanguageTranslator tag="label">FABRIC MILL</LanguageTranslator>
                                        <input disabeld value={item?.fiberMill && item?.fiberMill} />
                                    </div>
                                    <div className='plmbi'>
                                        <LanguageTranslator tag="label">COUNTRY OF ORIGIN</LanguageTranslator>
                                        <input disabeld value={item?.countryOfOrigin && item?.countryOfOrigin} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>RATE</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.rate && item?.rate} />
                                    </div>
                                    <div className='plmbi'>
                                        <label><LanguageTranslator>UOM</LanguageTranslator><span className="mandatory">*</span></label>
                                        <input disabeld value={item?.uom && item?.uom} />
                                    </div>
                                    <div className='plmbi'>
                                        <LanguageTranslator tag="label">SEASON</LanguageTranslator>
                                        <input disabeld value={item?.season && item?.season} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                }
                {this.state.currentTab === "costSheet" &&
                    <div className="csn-body">
                        <div className='cost-head'>
                            <LanguageTranslator tag="h3">Cost Sheet</LanguageTranslator>
                            <React.Fragment>
                                <div className="costh-col">
                                    <LanguageTranslator tag="h4">Total Supplier Cost</LanguageTranslator>
                                    <input
                                        className="costhc-input"
                                        disabled
                                        value={
                                            this.state?.mainData &&
                                                this.state.mainData?.costSheetDets &&
                                                Array.isArray(this.state.mainData?.costSheetDets?.[0]) // Check if array exists
                                                ? this.state.mainData?.costSheetDets?.[0]?.map((item) =>
                                                    item?.totalSupplierCost &&
                                                        item?.totalSupplierCost[0] !== undefined
                                                        ? item?.totalSupplierCost
                                                        : "N/A"
                                                )
                                                : "N/A" // Provide a default value if the array or its elements are undefined
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        </div>
                        <div className='csnb'>
                            <div className='csnb-left'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th><label>Material</label></th>
                                            <th><label>SUB TYPE</label></th>
                                            <th><label>QTY</label></th>
                                            <th><label>UNIT COST</label></th>
                                            <th><label>PROPOSED COST</label></th>
                                            {/* <th><label>PERCENTAGE</label></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.mainData && this.state.mainData?.costSheetDets?.length !== 0 && this.state.mainData?.costSheetDets?.[0]?.materialDets?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <label>{item?.type}</label>
                                                </td>
                                                <td>
                                                    <label>{item?.subType}</label>
                                                </td>
                                                <td>
                                                    <label>{item?.qty}</label>
                                                </td>
                                                <td>
                                                    <label>{item?.unitCost}</label>
                                                </td>
                                                <td>
                                                    <label>{item?.proposedCost}</label>
                                                </td>
                                                {/* <td>
                                                    <label>kjsdkjhaskdjh</label>
                                                </td> */}
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default QrScan;

