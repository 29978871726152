import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import Icons from '../../../assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import EscalationMatrixSkeleton from './EscalationMatrixSkeleton'
import CopyText from '../../helperComponents/CopyText'

const EscalationMatrixModal = (props) => {
    const administrationStore = useSelector(state => state.administrationStore)
    const dispatch = useDispatch()

    const [supportData, setSupportData] = useState({
        name: '',
        email: '',
        phoneNo: ''
    })

    useEffect(() => {
        dispatchHandler(dispatch, 'getGeneralSettingRequest')
    }, [])

    useEffect(() => {
        if (administrationStore?.getGeneralSettingData?.isSuccess) {
            setSupportData({
                name: administrationStore?.getGeneralSettingData?.data?.resource?.data?.accManagerName,
                email: administrationStore?.getGeneralSettingData?.data?.resource?.data?.accManagerEmail,
                phoneNo: administrationStore?.getGeneralSettingData?.data?.resource?.data?.accManagerContact
            })
        }
    }, [administrationStore?.getGeneralSettingData?.isSuccess])


    const cardsData = [
        {
            level: 'LEVEL 1',
            levelColor: 'h1-blue',
            label: 'Technical Support',
            data: [
                {
                    icon: <Icons.EmailReport />,
                    label: 'Support Email ',
                    value: <> <a href={`mailto:${'support@supplymint.com'}`} target='_blank' rel="noreferrer" >support@supplymint.com</a>
                        <CopyText textToCopy={'support@supplymint.com'} />
                    </>
                },
                {
                    icon: <Icons.PhoneIcon />,
                    label: 'Phone Number',
                    value: '+91 8800519799'
                }
            ]
        },
        {
            level: 'LEVEL 2',
            levelColor: 'h1-orange',
            label: 'Specialist Support',
            data: [
                {
                    icon: <Icons.ProfileFaceIcon />,
                    label: 'Account Manager Name',
                    value: supportData.name || ''
                },
                {
                    icon: <Icons.EmailReport />,
                    label: 'Account Manager Email',
                    value: <> <a href={`mailto:${supportData.email}`} target='_blank' rel="noreferrer" >{supportData.email}</a>
                        <CopyText textToCopy={supportData?.email} />
                    </>
                },
                {
                    icon: <Icons.PhoneIcon />,
                    label: 'Phone Number',
                    value: supportData.phoneNo || ''
                }
            ]
        },
        {
            level: 'LEVEL 3',
            levelColor: 'h1-red',
            label: 'Management or Executive Support',
            data: [
                // {
                //     icon: <Icons.ProfileFaceIcon />,
                //     label: 'Customer Support Manager',
                //     value: 'Sumeet Agarwal'
                // },
                {
                    icon: <Icons.EmailReport />,
                    label: 'Support Email',
                    value: <>
                        <a href={`mailto:${'escalations@supplymint.com'}`} target='_blank' rel="noreferrer" >{'escalations@supplymint.com'}</a>
                        <CopyText textToCopy={'escalations@supplymint.com'} />
                    </>

                }
            ]
        }
    ]

    return (<div className='escalation-matrix'>
        <GenericFormHeaderComponent
            label={{
                contentPrimary: "Escalation Matrix",
            }}

            closeModal={props.closeModal}
        />

        {/* cards dispaly */}
        <div className='em-body' >
            {administrationStore.getGeneralSettingData?.isLoading ? <EscalationMatrixSkeleton /> :
                cardsData.map((card) => {
                    return <div className='emb-cards'>
                        <h1 className={card.levelColor}>{card.level}</h1>
                        <h2>{card.label}</h2>
                        <div className='embc-det'>
                            {card.data.map((item) => {
                                return <div className='embcd-sec'>
                                    <div className='embcds-icons'>
                                        {item.icon}
                                    </div>
                                    <div className='grid'>
                                        <label>{item.label}</label>
                                        <p>{item.value}</p>
                                    </div>
                                </div>
                            })
                            }
                        </div>
                    </div>
                })
            }
        </div>

    </div>
    )
}

export default EscalationMatrixModal