import React from "react";
import ClearCache from 'react-clear-cache';
import Icons from '../../assets/icons/index'
import GenericCustomButton from "../../genericComponents/GenericCustomButton";

class CacheChecker extends React.Component {
    render() {
        return (
            <div>
                <ClearCache duration={600000}>
                    {({ isLatestVersion, emptyCacheStorage, latestVersion }) => (
                        renderUpgradePopup({
                            isLatestVersion, emptyCacheStorage, latestVersion
                        })
                    )}
                </ClearCache>
            </div>
        );
    }
};

const renderUpgradePopup = ({ isLatestVersion, emptyCacheStorage, renderFromModal, latestVersion}) => {
    // if(!isLatestVersion){
    //     emptyCacheStorage(latestVersion);
    //     window.location.reload();
    //     return null;
    // }
    return <>
        {!isLatestVersion && (
            <div className={`notification-alert ${renderFromModal && "notification-pop-up"}`}>
                <div className="na-top">
                    {renderFromModal && <h3>Update Software</h3>}
                    <div className="npu-icon">{renderFromModal ? <Icons.UpgradSoft /> : <Icons.GreenCircledGreenTick />}</div>
                    <div className="nat-det">
                        <h1>A New Version of Supplymint is Available </h1>
                        <p>We have resolve some important issues. Please update to the latest version by clicking on below upgrade now button.</p>
                        <div className="natd-btns">
                            <GenericCustomButton  
                                type="primary"
                                onClick={e => {
                                    e.preventDefault();
                                    emptyCacheStorage(latestVersion);
                                    window.location.reload();
                                }}
                                label="Upgrade Now"
                            />
                        </div>
                    </div>
                    {/* <button className="nat-close-btn"><Icons.ModalCloseIcon /></button> */}
                </div>
            </div>
        )}
    </>
}

export {CacheChecker, renderUpgradePopup};