import React, { useState, useEffect, useContext, } from 'react'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import Icons from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../helper/apiHelperFunctions';
import GenericFormFooterComponent from '../../../genericComponents/forms/GenericFormFooterComponent';
import { parseJwt } from '../../../helper/genericFunction';
import MergeSuccessModal from '../../defaultPopUp/MergeSuccessModal';
import { UserDetailsContext } from '../../../App';

const MergeAccountModal = (props) => {

    const dispatch = useDispatch();
    const getMergeAccountListData = useSelector(state => state?.authStore?.getMergeAccountListData);
    const mergeVendorsAccountData = useSelector(state => state?.authStore?.mergeVendorsAccountData);
    const [accountMergeList, setAccountMergeList] = useState([])

    const [successModal, setSuccessModal] = useState(false);

    const tokenData = parseJwt(sessionStorage.getItem("token"));

    const [openBenefits, setOpenBenefits] = useState(false);

    let {userDetails} = useContext(UserDetailsContext)
    useEffect(() => {
        let loginResponse = JSON.parse(userDetails?.["loginResponse"] || "{}");
        if (loginResponse && Object.keys(loginResponse).length !== 0 && loginResponse?.accMobNumDetailsList?.length > 0) {
            let accMobNumDetailsList = loginResponse.accMobNumDetailsList;
            setAccountMergeList(accMobNumDetailsList);
        } else {
            dispatchHandler(dispatch, "getMergeAccountListRequest", {
                token: sessionStorage.getItem('token'),
                flowType: "MERGE_ACCOUNT_LIST"
            });
        }
    }, [])


    useEffect(() => {
        if (mergeVendorsAccountData?.isSuccess) {
            setSuccessModal(true);
        }
    }, [mergeVendorsAccountData?.isSuccess])

    useEffect(() => {
        if (getMergeAccountListData?.isSuccess) {
            setAccountMergeList(getMergeAccountListData?.data?.resource?.accMobNumDetailsList || []);
        }
    }, [getMergeAccountListData?.isSuccess])


    // Function : To render the merge information
    const renderMergeInformation = () => {
        return (
            <>
                <div className='sapb-acc-form'>
                    <div className='af-heading'>
                        <label>Get All your accounts accessible from</label>
                        <span>one place</span>
                    </div>
                    <div className='af-benefits'>
                        <label>BENEFITS</label>
                        <span>Simplified account management</span>
                        <p>Users can easily manage all of their data and settings from a single account, reducing the need to remember multiple login credentials and navigate between different accounts.</p>
                        <span className='p-top-10'>Improved data accuracy</span>
                        <p>When users merge their accounts, all of their data is consolidated into a single account, which reduces the risk of errors or inconsistencies that can occur when data is scattered across multiple accounts.</p>
                        {openBenefits ? "" :
                            <span className='flex items-center p-top-15 cursor-pointer' onClick={() => setOpenBenefits(true)}>More Benefits
                                <svg className='m-lft-5' width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.476869 0.74933C0.746808 0.47939 1.18447 0.47939 1.45441 0.74933L5.11298 4.4079L8.77155 0.74933C9.04149 0.47939 9.47914 0.47939 9.74908 0.74933C10.019 1.01927 10.019 1.45693 9.74908 1.72687L5.60174 5.87421C5.33181 6.14415 4.89415 6.14415 4.62421 5.87421L0.476869 1.72687C0.206929 1.45693 0.206929 1.01927 0.476869 0.74933Z" fill="#2A2A64" />
                                </svg>
                            </span>}
                        {openBenefits &&
                            <React.Fragment>
                                <span className='p-top-10'>Enhanced user experience</span>
                                <p> By providing users with a streamlined and intuitive account management process, account merging can improve the overall user experience of the application, leading to increased user satisfaction and loyalty.</p>
                                <span className='p-top-10'>Increased engagement and retention</span>
                                <p>By reducing the friction associated with managing multiple accounts, users are more likely to remain active and engaged with the application, leading to increased retention and revenue for the SaaS provider.</p>
                            </React.Fragment>}
                    </div>
                </div>
                <div className='sapb-warning'>
                    <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.20274 17.1291C13.922 17.1291 17.7478 13.3034 17.7478 8.58409C17.7478 3.8648 13.922 0.0390625 9.20274 0.0390625C4.48345 0.0390625 0.657715 3.8648 0.657715 8.58409C0.657715 13.3034 4.48345 17.1291 9.20274 17.1291Z" fill="#24A148" />
                            <path d="M9.20241 5.70657C9.79232 5.70657 10.2705 5.22835 10.2705 4.63844C10.2705 4.04853 9.79232 3.57031 9.20241 3.57031C8.61249 3.57031 8.13428 4.04853 8.13428 4.63844C8.13428 5.22835 8.61249 5.70657 9.20241 5.70657Z" fill="white" />
                            <path d="M9.20266 13.6095C8.97603 13.6095 8.75868 13.5194 8.59843 13.3592C8.43818 13.1989 8.34815 12.9816 8.34815 12.755V8.48244C8.12153 8.48244 7.90418 8.39241 7.74393 8.23216C7.58368 8.07191 7.49365 7.85457 7.49365 7.62794C7.49365 7.40131 7.58368 7.18397 7.74393 7.02372C7.90418 6.86347 8.12153 6.77344 8.34815 6.77344H9.20266C9.42929 6.77344 9.64663 6.86347 9.80688 7.02372C9.96713 7.18397 10.0572 7.40131 10.0572 7.62794V12.755C10.0572 12.9816 9.96713 13.1989 9.80688 13.3592C9.64663 13.5194 9.42929 13.6095 9.20266 13.6095Z" fill="white" />
                            <path d="M10.0572 13.6074H8.34815C8.12153 13.6074 7.90418 13.5174 7.74393 13.3572C7.58368 13.1969 7.49365 12.9796 7.49365 12.7529C7.49365 12.5263 7.58368 12.309 7.74393 12.1487C7.90418 11.9885 8.12153 11.8984 8.34815 11.8984H10.0572C10.2838 11.8984 10.5011 11.9885 10.6614 12.1487C10.8216 12.309 10.9117 12.5263 10.9117 12.7529C10.9117 12.9796 10.8216 13.1969 10.6614 13.3572C10.5011 13.5174 10.2838 13.6074 10.0572 13.6074Z" fill="white" />
                        </svg>
                    </span>
                    <p>Your all other accounts listed below will get merge in with your primary selected account. Later you can access all your account under profile section.</p>
                </div>
            </>
        )
    }

    const handleChange = (type) => {
        switch (type) {
            case "mergeAccount": {
                let ids = accountMergeList?.map(item => item.id);
                let result = ids.join(',');
                dispatchHandler(dispatch, "mergeVendorsAccountRequest",{
                    "functionsName": "supplymint.merge_account_utype_vendor",
                    "attributeValues": false,
                    "additionalAttribute": {}
                })
                break;
            }
        }
    }

    // Function : To render the account list
    const renderAccount = (data = [], type) => {
        return data?.map(item => (
            <div className='sapba-primary-account'>
                <div className='spa-acc-name'>
                    <div className='flex items-center justify-between'>
                        <span className='spa-company-logo'>
                            {item?.orgLogo ? <img src={item.orgLogo && item.orgLogo} alt="Organization Logo" /> : <Icons.ImageThumbnail />}
                        </span>
                        <div className='acc-name'>
                            <label>Enterprise Name</label>
                            <span>{item.orgName || ""}</span>
                        </div>
                    </div>
                    {type == "primary" && <div className='spanl-acc'>
                        <span>Primary Account</span>
                    </div>}
                </div>
                <div className='spa-mob-no'>
                    <label>Linked Mobile Number</label>
                    {tokenData?.mob ? <span>+91 {tokenData?.mob || ""}</span> : ""}
                </div>
                <div className='spa-org-details'>
                    <div className='grid'>
                        <label>Organization Name</label>
                        <span>{item.orgName || ""}</span>
                    </div>
                    <div className='grid p-lft-50'>
                        <label>Username</label>
                        <span>{item.username || ""}</span>
                    </div>
                    <div className='grid p-lft-50'>
                        <label>First Name</label>
                        <span>{item.firstName || ""}</span>
                    </div>
                    <div className='grid p-lft-50'>
                        <label>Last Name</label>
                        <span>{item.lastName || ""}</span>
                    </div>
                </div>
            </div>
        ))
    }

    let primaryAccounts = accountMergeList?.filter(item => item?.isActive == 1);
    let otherAccounts = accountMergeList?.filter(item => item?.isActive == 0);

    if (successModal) {
        return <MergeSuccessModal
            submitModal={() => {
                props.submitModal();
            }}
        />
    }

    return (
        <div className='acc-facility'>
            <GenericFormHeaderComponent
                label={{
                    contentPrimary: "Account Merge Facility"
                }}
                closeModal={() => props.closeModal()}
            />
            <div className='af-body'>
                {/* Render the merge information  */}
                {renderMergeInformation()}

                {getMergeAccountListData?.isLoading ?
                    <div>
                        <p>Account List Skeleton Loader</p>
                    </div> :
                    <div className='sapb-accounts'>
                        {primaryAccounts?.length > 0 && <div>
                            <h3>Primary Account</h3>
                            {renderAccount(primaryAccounts, "primary")}
                        </div>}
                        {otherAccounts?.length > 0 && <div>
                            <h3>Other Accounts</h3>
                            {renderAccount(otherAccounts, "other")}
                        </div>}
                    </div>
                }
            </div>

            <GenericFormFooterComponent
                buttons={[
                    { label: "Skip", type: "default", onClick: () => props.closeModal() },
                    { label: "Confirm Merge", type: "primary", onClick: () => handleChange("mergeAccount") }
                ]}
            />

        </div>
    )
}

export default MergeAccountModal