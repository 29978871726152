import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import IconsPath from '../../../../assets/icons/index'
import { getSocketConnection, } from '../../../../socket/socketHelperConnection';
import GenericSpinnerLoader from '../../../../loaders/GenericSpinnerLoader';
import {CommentContext} from '../../../../components/AppStack'

const CommentTableComponent = (props) => {

    let { comment = {}, item, commentRowIndex , mode = "normal"} = props;

    const {unreadComments, getUnreadCommentCountForThisUser, setUnreadComments} = useContext(CommentContext)

    // const [selectedModal, setSelectedModal] = useState(null);

    const [numberOfUnreadComments, setNumberOfUnreadComments] = useState(0);

    const [loading, setLoading] = useState(false);

    const [errorData, setErrorData] = useState(null)

    let socket = getSocketConnection();


    let apiCalledOnce = false;

    // Function : To get the current number of unread comments
    const getNumberOfUnreadComments = () => {

        if(item?.[comment?.configKeys?.["documentId"]] &&  item?.[comment?.configKeys?.["documentNumber"]]){
            let newKey = `${item?.[comment?.configKeys?.["documentId"]]}-${item?.[comment?.configKeys?.["documentNumber"]]}`

            if(unreadComments === null){
                fireSocketEvent("get_all_unread_comments_count", {
                    "documentId": item?.[comment?.configKeys?.["documentId"]],
                    "documentNumber": item?.[comment?.configKeys?.["documentNumber"]]
                })
                if(commentRowIndex == 0 && !apiCalledOnce){
                    apiCalledOnce = true;
                    getUnreadCommentCountForThisUser();
                }
            }else{
                setNumberOfUnreadComments(unreadComments?.[newKey || ""] || 0)
            }
        }
    }

    useEffect(() => {
        // console.log("Unread comment changes done", unreadComments);
        getNumberOfUnreadComments();
    }, [unreadComments])

    // Function : To handle the json validation error socket event
    const handleSocketEventError = (data, type) => {
        setLoading(prev => {
            delete prev?.[data?.name];
            return prev;
        });

        switch(type){
            case "json_validation_error" :{
                let updatedErrorData = [];
                data?.errors?.map(item => {
                    updatedErrorData?.push(`${item?.instancePath?.slice(1)} ${item?.message}`);
                })
                setErrorData(updatedErrorData);
                break;
            }
        }
    }

    // Function : To fire the event for the socket connection
    const fireSocketEvent = (name, payload) => {
        if (socket?.connected) {
            setErrorData(null);
            socket.emit(name, payload)
            setLoading(prev => ({ ...prev, "add_comment": true }));
        }
        else {
            console.log("Socket connection error");
        }
    }


    // Function : To handle the sucess of the events on the frontend
    const handleEventSuccess = (data, config = {}) => {
        let { eventName } = config;

        if (data?.resource?.error) {
            handleSocketEventError({
                "name": eventName
            });
        } else {
            switch (eventName) {
                case "get_all_unread_comments_count": {
                    if (data?.documentId == item?.[comment?.configKeys?.["documentId"]] && data?.documentNumber == item?.[comment?.configKeys?.["documentNumber"]]) {
                        let count = data?.count || 0;

                        setNumberOfUnreadComments(count);
                        let updatedUnreadComments = {...unreadComments};
                        if(!updatedUnreadComments) { updatedUnreadComments = {}}
                        updatedUnreadComments[`${item?.[comment?.configKeys?.["documentId"]]}-${item?.[comment?.configKeys?.["documentNumber"]]}`] = count;
                        setUnreadComments(updatedUnreadComments);
                    }
                    break;
                }
                case "store_user_read_time" : {
                    if (data?.documentId == item?.[comment?.configKeys?.["documentId"]] && data?.documentNumber == item?.[comment?.configKeys?.["documentNumber"]]) {
                        let count = 0;
                        // console.log(`Step 4 : `, props.commentRowIndex, eventName, data?.documentId , item?.[comment?.configKeys?.["documentId"]]);
                        setNumberOfUnreadComments(count);
                        let updatedUnreadComments = {...unreadComments};
                        if(!updatedUnreadComments) { updatedUnreadComments = {}}
                        updatedUnreadComments[`${item?.[comment?.configKeys?.["documentId"]]}-${item?.[comment?.configKeys?.["documentNumber"]]}`] = count;
                        setUnreadComments(updatedUnreadComments);
                        // console.log(`Step 5 : `, props.commentRowIndex, eventName, data?.documentId );
                    }
                    break;
                }
                default : 
            }
        }

        setTimeout(() => {
            let updatedLoading = { ...loading };
            delete updatedLoading?.[eventName];
            setLoading(updatedLoading);
        }, 100)
    }

    useEffect(() => {
        let obj = {
            "json_validation_error" : (data) => handleSocketEventError(data, "json_validation_error"),
            "get_all_unread_comments_count" :  (data) => handleEventSuccess(data, { eventName: "get_all_unread_comments_count" }),
            "store_user_read_time" : (data) =>  handleEventSuccess(data, { eventName: "store_user_read_time" })
        }
        Object.keys(obj || {})?.forEach(key => {
            // console.log(`Step 1 : `, props.commentRowIndex,key);
            socket.on(key, obj?.[key]);
        })

        return () => {
            Object.keys(obj || {})?.forEach(key => {
                // console.log(`Step 2 : `, props.commentRowIndex,key);
                socket.off(key);
            })
        }

    }, [props.commentModal])

    // console.log('mode123', props.commentModal)

    return (
        <React.Fragment>

            <li className={`til-inner til-chat-icon list-none ${numberOfUnreadComments > 0 ? 'comments-col': ''} !w-auto`} data-interactive="true" data-id="openChat" onClick={e => mode == "normal" && props.openCommentModal()}>
                {/* <img src={<I} data-id="openChat" className="height16 displayPointer" /> */}
                <div className={numberOfUnreadComments > 0 ? `comments-button relative flex items-center  ${mode === 'central' ? 'justify-center bg-[#20BF6B] px-1.5 py-0.5 rounded-full aspect-square h-[24px] w-auto' : 'comments-without-count'}` : 'comments-without-count'}>
                    {mode == "normal" && <span className={numberOfUnreadComments > 0 ? 'comment-icon comments-icon-without-count' : 'comments-icon-without-count'}>
                        <IconsPath.ChatIconTableComponent className='hgt-17' data-id="openChat" />
                    </span>}
                    {numberOfUnreadComments > 0 && (loading?.["get_all_unread_comments_count"] ? <GenericSpinnerLoader/>  :<p className={`${mode === 'central' ? 'text-[11px] text-white font-semibold mb-0' : 'mb-0 absolute z-10 -top-[2px] left-[9px] text-[9px] text-white text-center min-w-[15px] font-medium bg-[#20BF6B] px-[3px] py-[1px] leading-[12px] rounded-full'}`}>{mode === 'central' ? numberOfUnreadComments : ( numberOfUnreadComments > 99 ? '99+' : numberOfUnreadComments)}</p>)}
                </div>
            {mode == "normal" && <span className="generic-tooltip">Comment</span>}
            </li>

        </React.Fragment>
    )
}

export default CommentTableComponent