import React from 'react'
const divsArray = Array.from({ length: 4 }, index => index + 1);

const ProcDashboardCardsSkeletonLoader = () => {
    return (
        <>
            {divsArray.map(() => (
                <div className='pdb-cards w-25'>
                    <div className='pdbc-det'>
                        <div className='pdbcd-top'>
                            <span className='m-bot-10'>
                                <div className='generic-skeleton'></div>
                            </span>
                            <div className='generic-skeleton w-50'></div>
                            <div className='generic-skeleton w-50'></div>
                        </div>
                        <div className='pdbcd-bot'>
                            <div className='pdbcdb-det'>
                                <h5>
                                    <div className='generic-skeleton w-75'></div>
                                </h5>
                                <div className='pdbcdbd'>
                                    <h3>
                                        <div className='generic-skeleton w-75'></div>
                                    </h3>
                                </div>
                            </div>
                            <div className='pdbcdb-det p-lft-20'>
                                <h5>
                                    <div className='generic-skeleton w-75'></div>
                                </h5>
                                <div className='pdbcdbd'>
                                    <h3>
                                        <div className='generic-skeleton w-75'></div>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProcDashboardCardsSkeletonLoader